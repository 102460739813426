/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  Container,
  Button,
  Form,
  Card,
  Tab,
  Nav,
  Table,
} from 'react-bootstrap';
import { CustomSelect } from '@components';

//* Utils
import Utils from '@common/Utils';

//* CONSTS
import {
  SEMESTER_DROPDOWN,
  JOB_TYPE_LIST,
  UNIV_TYPE_LIST,
  APPLY_TYPE_LIST,
  CERTIFICATE,
  ACTIVITY,
} from '@common/consts';

//* API
import {
  selectFormCareer,
  insertUpdateFormCareer,
} from '@api/student/careerDesign';

// 인적사항 Form
const initialUserInfo = {
  name: '',
  major: '',
  grade: '',
  class: '',
  num: '',
};

// 취업 Form
const initialJobForm = {
  hopeJobName: '', // 희망 직업
  hopeJobGubun: '', // 기업형태
  hopeJobIndustry: '', // 희망 산업체
  hopeJobReason: '', // 진로희망 사유
  hopeJobCertificate: '', // 희망직업 관련 자격증
  hopeJobWork: '', // 희망직업 업무 내용
  hopeJobSkill: '', // 필요기술 및 지식
};

// 진학 Form
const initialUnivForm = {
  hopeUnivName: '', // 희망대학
  hopeUnivGubun: '', // 대학유형
  hopeUnivApply: '', // 지원형태
  hopeUnivMajor: '', // 희망학과
  hopeUnivReason: '', // 진학희망 사유
  hopeUnivGrade: '', // 필요등급
};

// 교과이수 Form
const initialStudyPlan = {
  '1-1': {
    mon: ';;;;;;;',
    tue: ';;;;;;;',
    wed: ';;;;;;;',
    thu: ';;;;;;;',
    fri: ';;;;;;;',
  },
};

// 테이블 행-열
const scheduleDays = ['월', '화', '수', '목', '금']; // 요일
const scheduleClasses = [1, 2, 3, 4, 5, 6, 7]; // 교시

//* [ Main ]
export default React.memo(function CareerDesignWrite(props) {
  //* #################################################################
  //* [ States ]
  //* #################################################################
  // const history = useHistory();

  // Form SEQ ( 0 = 신규등록 )
  const { seq: formSeq = 0 } = useParams();

  // 중복요청 방지
  // const [isSentRequest, setIsSentRequest] = useState(false);

  // ====================== 인적사항 ======================

  const [userInfo, setUserInfo] = useState(initialUserInfo); // 이름, 학과, 학년, 반, 번호

  // ====================== 진로방향 ======================

  // 진로방향 ( 1 = 취업, 2 = 진학 )
  const [hopeDirection, setHopeDirection] = useState(1);

  // 취업
  const [jobForm, setJobForm] = useState(initialJobForm); // 취업 Form

  // 진학
  const [univForm, setUnivForm] = useState(initialUnivForm); // 진학 Form

  // ====================== 교과이수 ======================

  // 학기 선택
  const [curSemester, setCurSemester] = useState('1-1'); // 현재 학기
  // const [semester, setSemester] = useState('1-1'); // 선택 학기
  // const [selectedOption, setSelectedOption] = useState(SEMESTER_DROPDOWN[0]); // 선택 학기 (드랍다운 옵션)
  // const [semesterDropdown, setSemesterDropdown] = useState(SEMESTER_DROPDOWN); // 학기 드랍다운

  // 교과 이수 내용
  const [studyPlan, setStudyPlan] = useState(initialStudyPlan);

  // ====================== 자격증 취득계획 ======================

  // 전공자격증 ( 구분값 = 1 )
  const [majorCertPlan, setMajorCertPlan] = useState([
    { certi_gubun: 1, certi_info: '' },
  ]);

  // 정보화 자격증 ( 구분값 = 2 )
  const [itCertPlan, setItCertPlan] = useState([
    { certi_gubun: 2, certi_info: '' },
  ]);

  // 외국어 능력 ( 구분값 = 3 )
  const [langCertPlan, setLangCertPlan] = useState([
    { certi_gubun: 3, certi_info: '' },
  ]);

  // ====================== 창의적 체험활동 ======================

  // 동아리 활동
  const [clubActPlan, setClubActPlan] = useState([
    { act_gubun: 1, act_info: '', act_detail: '' },
  ]);

  // 봉사 활동
  const [volActPlan, setVolActPlan] = useState([
    { act_gubun: 2, act_info: '', act_detail: '' },
  ]);

  // 독서 계획
  const [readActPlan, setReadActPlan] = useState([
    { act_gubun: 3, act_info: '' },
  ]);

  // 기타 활동
  const [etcActPlan, setEtcActPlan] = useState([
    { act_gubun: 4, act_info: '', act_detail: '' },
  ]);

  //* #################################################################
  //* [ Utils ]
  //* #################################################################

  // ====================== 진로설계 ======================

  // Tab 선택 ( 1 = 취업, 2 = 진학 )
  const tabHandler = type => {
    setHopeDirection(type);

    // Form 초기화
    // if (type === 1) {
    //   setUnivForm({ ...initialUnivForm });
    // } else if (type === 2) {
    //   setJobForm({ ...initialJobForm });
    // }
  };

  // 기업형태 선택
  const jobTypeHandler = data => {
    jobForm.hopeJobGubun = data;
    setJobForm({ ...jobForm });
  };

  // 대학 선택
  const univTypeHandler = data => {
    univForm.hopeUnivGubun = data;
    setUnivForm({ ...univForm });
  };

  // 진학 선택
  const applyTypeHandler = data => {
    univForm.hopeUnivApply = data;
    setUnivForm({ ...univForm });
  };

  // ====================== 교과이수 ======================

  // 학기 선택
  // const semesterHandler = data => {
  //   // 학기 변경
  //   setSemester(data.value);
  //   setSelectedOption(data);
  // };

  // 직업검색 이동
  const moveToSearchJob = () => {
    window.open('https://ncs.go.kr/index.do');
  };

  // 대학검색 이동
  const moveToSearchUniv = () => {
    window.open('https://adiga.kr/EgovPageLink.do?link=EipMain');
  };

  // 자격증검색 이동
  const moveToSearchCert = () => {
    window.open('https://www.q-net.or.kr/man001.do?imYn=Y&gSite=Q');
  };

  // ====================== 자격증 취득계획 & 창의적 체험활동 ======================

  // 행 추가
  const addRow = ({ type, gubun, list, setList }) => {
    // 자격증
    if (type === CERTIFICATE) {
      list.push({ certi_gubun: gubun, certi_info: '' });
    }
    // 체험활동
    else if (type === ACTIVITY) {
      const defaultObj = { act_gubun: gubun, act_info: '', act_detail: '' };
      if (gubun === 3) delete defaultObj.act_detail;

      list.push(defaultObj);
    }

    setList([...list]);
  };

  // 행 삭제
  const removeRow = (index, list, setList) => {
    list.splice(index, 1);

    setList([...list]);
  };

  // 교과이수 입력
  const studyInputHandler = (day, time, value) => {
    // const tempMap = { ...studyPlan };
    // const tempList = tempMap[semester][day].split(';');
    // tempList[time - 1] = value; // 요일 > 교시 > input 교체
    // tempMap[semester][day] = tempList.join(';');
    // setStudyPlan(tempMap);
  };

  // 자격증 입력 ( 전공자격증, 정보화 자격증, 외국어 자격증 )
  const certInputHandler = (index, gubun, inputValue) => {
    switch (gubun) {
      // 전공자격증 ( gubun = 1 )
      case 1:
        majorCertPlan[index] = {
          ...majorCertPlan[index],
          certi_gubun: gubun,
          certi_info: inputValue,
        };

        setMajorCertPlan([...majorCertPlan]);
        break;
      // 정보화 자격증 ( gubun = 2 )
      case 2:
        itCertPlan[index] = {
          ...itCertPlan[index],
          certi_gubun: gubun,
          certi_info: inputValue,
        };

        setItCertPlan([...itCertPlan]);
        break;
      // 외국어 자격증 ( gubun = 3 )
      case 3:
        langCertPlan[index] = {
          ...langCertPlan[index],
          certi_gubun: gubun,
          certi_info: inputValue,
        };

        setLangCertPlan([...langCertPlan]);
        break;
      default:
        break;
    }
  };

  // 체험활동 입력 ( 동아리, 봉사, 계획, 기타 )
  const actInputHandler = (index, gubun, firstValue, secondValue) => {
    switch (gubun) {
      // 동아리 ( gubun = 1 )
      case 1:
        if (firstValue != null) {
          // 동아리 이름
          clubActPlan[index] = {
            ...clubActPlan[index],
            act_gubun: gubun,
            act_info: firstValue,
          };
        }
        // 활동내역
        else if (secondValue != null) {
          clubActPlan[index] = {
            ...clubActPlan[index],
            act_gubun: gubun,
            act_detail: secondValue,
          };
        }

        setClubActPlan([...clubActPlan]);
        break;

      // 봉사 ( gubun = 2 )
      case 2:
        // 봉사 활동 내용
        if (firstValue != null) {
          volActPlan[index] = {
            ...volActPlan[index],
            act_gubun: gubun,
            act_info: firstValue,
          };
        }
        // 봉사 시간
        else if (secondValue != null) {
          volActPlan[index] = {
            ...volActPlan[index],
            act_gubun: gubun,
            act_detail: secondValue,
          };
        }

        setVolActPlan([...volActPlan]);
        break;

      // 계획 ( gubun = 3 )
      case 3:
        readActPlan[index] = {
          act_gubun: gubun,
          act_info: firstValue,
        };

        setReadActPlan([...readActPlan]);
        break;

      // 기타 ( gubun = 4 )
      case 4:
        // 활동명
        if (firstValue != null) {
          etcActPlan[index] = {
            ...etcActPlan[index],
            act_gubun: gubun,
            act_info: firstValue,
          };
        }
        // 활동 내용
        else if (secondValue != null) {
          etcActPlan[index] = {
            ...etcActPlan[index],
            act_gubun: gubun,
            act_detail: secondValue,
          };
        }

        setEtcActPlan([...etcActPlan]);
        break;
      default:
        break;
    }
  };

  //* #################################################################
  //* [ API ] 진로설계 정보 가져오기
  //* #################################################################
  const selectFormData = async () => {
    // 파라미터 설정
    const param = {
      formSeq,
    };

    // Axios
    try {
      const { data } = await selectFormCareer(param);

      if (data.code === 0) {
        const { career_form: career } = data.data;

        // 인적사항
        setUserInfo({
          name: career.name,
          major: career.major,
          grade: career.grade,
          class: career.ban_no,
          num: career.student_no,
        });

        // ( 신규입력 Only ) 교과이수 학기 지정 - default
        const defaultSemester = SEMESTER_DROPDOWN.filter(
          item => item.value === `${career.grade}-${career.current_nth}`,
        );

        // setSemesterDropdown(defaultSemester); // 드랍다운 갱신
        // setCurSemester(defaultSemester[0].value); // 현재 학기
        // setSemester(defaultSemester[0].value); // 선택된 학기
        // setSelectedOption(defaultSemester[0]); // 선택된 학기 Dropdown Option

        // setStudyPlan({
        //   [defaultSemester[0].value]: {
        //     mon: ';;;;;;;',
        //     tue: ';;;;;;;',
        //     wed: ';;;;;;;',
        //     thu: ';;;;;;;',
        //     fri: ';;;;;;;',
        //   },
        // });

        // ( 기존 Form 수정 ) 진로뱡향, 교과이수, 자격증, 체험활동
        if (formSeq > 0 && career.has_form) {
          const {
            study_plan: study = {},
            certi_list: certi = [],
            act_list: act = [],
          } = data.data;

          // 진로뱡향
          setHopeDirection(+career.hope_direction);

          // 진로뱡향 > 진학
          if (+career.hope_direction === 1) {
            setJobForm({
              hopeJobName: career.hope_job_name, // 희망 직업
              hopeJobGubun: career.hope_job_gubun, // 기업형태 (코드)
              hopeJobIndustry: career.hope_job_industry, // 희망 산업체
              hopeJobReason: career.hope_job_reason, // 진로희망 사유
              hopeJobCertificate: career.hope_job_certificate, // 희망직업 관련 자격증
              hopeJobWork: career.hope_job_work, // 희망직업 업무 내용
              hopeJobSkill: career.hope_job_skill, // 필요기술 및 지식
            });
          } else if (+career.hope_direction === 2) {
            setUnivForm({
              hopeUnivName: career.hope_univ_name, // 희망대학
              hopeUnivGubun: career.hope_univ_gubun, // 대학유형 (코드)
              hopeUnivApply: career.hope_univ_apply, // 지원형태 (코드)
              hopeUnivMajor: career.hope_univ_major, // 희망학과
              hopeUnivReason: career.hope_univ_reason, // 진학희망 사유
              hopeUnivGrade: career.hope_univ_grade, // 필요등급
            });
          }

          // 교과이수
          // setStudyPlan(study);

          // 학기 리스트 필터링
          const storedSemsterList = Object.keys(study);

          const filteredSemesterList = SEMESTER_DROPDOWN.filter(item =>
            storedSemsterList.includes(item.value),
          ).sort(
            (a, b) => b.value.replaceAll('-', '') - a.value.replaceAll('-', ''),
          );

          // setSemesterDropdown(filteredSemesterList);

          // 최신 학기 > default 선택
          // setCurSemester(filteredSemesterList[0].value); // 현재 학기
          // setSemester(filteredSemesterList[0].value); // 선택된 학기
          // setSelectedOption(filteredSemesterList[0]); // 선택된 학기 Dropdown Option

          // 자격증 취득계획
          const tempMajorCertList = [];
          const tempItCertList = [];
          const tempLangCertList = [];

          certi.forEach(item => {
            switch (+item.certi_gubun) {
              // 전공 자격증
              case 1:
                tempMajorCertList.push(item);
                break;
              // 정보화 자격증
              case 2:
                tempItCertList.push(item);
                break;
              // 외국어 자격증
              case 3:
                tempLangCertList.push(item);
                break;
              default:
                break;
            }
          });

          setMajorCertPlan(
            tempMajorCertList.length > 0
              ? [...tempMajorCertList]
              : [{ certi_gubun: 1, certi_info: '' }],
          );
          setItCertPlan(
            tempItCertList.length > 0
              ? [...tempItCertList]
              : [{ certi_gubun: 2, certi_info: '' }],
          );
          setLangCertPlan(
            tempLangCertList.length > 0
              ? [...tempLangCertList]
              : [{ certi_gubun: 3, certi_info: '' }],
          );

          // 창의적 체험활동
          const tempClubActList = [];
          const tempVolActList = [];
          const tempReadActList = [];
          const tempEtcActActList = [];

          act.forEach(item => {
            switch (+item.act_gubun) {
              // 동아리 활동
              case 1:
                tempClubActList.push(item);
                break;
              // 봉사 활동
              case 2:
                tempVolActList.push(item);
                break;
              // 독서 계획
              case 3:
                tempReadActList.push(item);
                break;
              // 기타 활동
              case 4:
                tempEtcActActList.push(item);
                break;
              default:
                break;
            }
          });

          setClubActPlan(
            tempClubActList.length > 0
              ? [...tempClubActList]
              : [{ act_gubun: 1, act_info: '', act_detail: '' }],
          );
          setVolActPlan(
            tempVolActList.length > 0
              ? [...tempVolActList]
              : [{ act_gubun: 2, act_info: '', act_detail: '' }],
          );
          setReadActPlan(
            tempReadActList.length > 0
              ? [...tempReadActList]
              : [{ act_gubun: 3, act_info: '' }],
          );
          setEtcActPlan(
            tempEtcActActList.length > 0
              ? [...tempEtcActActList]
              : [{ act_gubun: 4, act_info: '', act_detail: '' }],
          );
        }
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 진로설계 신규등록 & 수정
  //* #################################################################
  const insertUpdateCareerDesign = async (event, reqStatus) => {
    // 중복요청 확인
    // if (isSentRequest) {
    //   return;
    // }
    const target = event.currentTarget;
    target.disabled = true;

    // 자격증 리스트 null 검증 > filter
    const allCertList = [...majorCertPlan, ...itCertPlan, ...langCertPlan];

    const filteredAllCertList = allCertList.filter(
      item => item.certi_gubun && item.certi_info,
    );

    // 체험활동 리스트 null 검증 > filter
    const allActList = [
      ...clubActPlan,
      ...volActPlan,
      ...readActPlan,
      ...etcActPlan,
    ];

    const filteredAllActList = allActList.filter(item => {
      // 단일 Input 입력 ( 독서 계획 )
      if (+item.act_gubun === 3) {
        return item.act_gubun && item.act_info;
      }
      // 복수 Input 입력
      return item.act_gubun && item.act_info && item.act_detail;
    });

    // JSON 파라미터
    const param = {
      // Form SEQ ( 0 = 신규등록 )
      formSeq,

      // 요청 상태
      status: reqStatus,

      // 진로방향
      hope_direction: hopeDirection,

      // 진로방향 > 취업
      hope_job_name: jobForm.hopeJobName, // 희망 직업
      hope_job_gubun: jobForm.hopeJobGubun, // 기업형태
      hope_job_industry: jobForm.hopeJobIndustry, // 희망 산업체
      hope_job_reason: jobForm.hopeJobReason, // 진로희망 사유
      hope_job_certificate: jobForm.hopeJobCertificate, // 희망직업 관련 자격증
      hope_job_work: jobForm.hopeJobWork, // 희망직업 업무 내용
      hope_job_skill: jobForm.hopeJobSkill, // 필요기술 및 지식

      // 진로방향 > 대학
      hope_univ_name: univForm.hopeUnivName, // 희망대학
      hope_univ_gubun: univForm.hopeUnivGubun, // 대학형태
      hope_univ_apply: univForm.hopeUnivApply, // 지원형태
      hope_univ_major: univForm.hopeUnivMajor, // 희망학과
      hope_univ_reason: univForm.hopeUnivReason, // 진학희망 사유
      hope_univ_grade: univForm.hopeUnivGrade, // 필요등급

      // 교과 이수
      study_plan_list: studyPlan[curSemester],

      // 자격증
      certi_list: filteredAllCertList,

      // 체험활동
      act_list: filteredAllActList,
    };

    // 중복요청 방지 해제
    // setIsSentRequest(true);

    // Axios
    try {
      const { data } = await insertUpdateFormCareer(param);

      if (data.code === 0) {
        // 작성된 결과 확인
        props.history.push('/student/indivAbility/careerDesign');
      }
      target.disabled = false;
    } catch (e) {
      target.disabled = false;
      // 중복요청 방지 해제
      // setIsSentRequest(false);
    }
    target.disabled = false;
  };

  //* #################################################################
  //* [ useEffect ]
  //* #################################################################
  useEffect(() => {
    selectFormData();

    // return () => {}
  }, []);

  //* #################################################################
  //* [ return ]
  //* #################################################################
  return (
    <main id="student-careerDesign-write">
      <Container>
        <article className="content">
          <ul className="path">
            <li>
              <Link to="/">HOME</Link>
            </li>
            <li>
              <Link to="#none">진로설계</Link>
            </li>
            <li>
              <Link to="#none">작성페이지</Link>
            </li>
          </ul>
          {/* ////////// ↓ 상단 타이틀영역 ////////// */}
          <section>
            <div className="title">
              <h5>진로설계</h5>
            </div>
          </section>

          {/* ////////// ↓ 컨텐츠 ////////// */}
          {/* 인적사항 */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>인적사항</h5>
            </div>
            <Card>
              <ul className="form-list">
                <li>
                  <h5>이름</h5>
                  <Form.Control
                    type="text"
                    readOnly
                    value={Utils.decrypt(userInfo.name)}
                  />
                </li>
                <li>
                  <h5>학과</h5>
                  <Form.Control type="text" readOnly value={userInfo.major} />
                </li>
                <li>
                  <h5>학년/반/번호</h5>
                  <Form.Control
                    type="text"
                    readOnly
                    value={`${+userInfo.grade}학년 ${+userInfo.class}반 ${+userInfo.num}번`}
                  />
                </li>
              </ul>
            </Card>
          </section>

          {/* 진로방향 */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>진로방향</h5>
            </div>
            <Card>
              <Tab.Container
                defaultActiveKey="1"
                activeKey={hopeDirection}
                id="carrer-tab"
              >
                {/* TAB - 취업, 진학 선택 */}
                <Nav className="basic-tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="1" onClick={ㄷe => tabHandler(1)}>
                      취업
                    </Nav.Link>
                    <Nav.Link eventKey="2" onClick={() => tabHandler(2)}>
                      진학
                    </Nav.Link>
                  </Nav.Item>
                </Nav>

                {/* TAB (1) - 취업 */}
                <Tab.Content>
                  <Tab.Pane eventKey="1">
                    <div className="sub-title sm">
                      <h5>진로희망 및 산업체 탐색</h5>
                      <Button
                        variant="text-underline"
                        onClick={moveToSearchJob}
                      >
                        직업검색
                      </Button>
                    </div>
                    <ul className="form-list">
                      {/* 희망직업 */}
                      <li>
                        <h5>희망직업</h5>
                        <Form.Control
                          type="text"
                          placeholder="희망직업을 입력하세요."
                          value={jobForm.hopeJobName}
                          onChange={e => {
                            jobForm.hopeJobName = e.target.value;
                            setJobForm({
                              ...jobForm,
                            });
                          }}
                          maxLength={30}
                        />
                      </li>
                      {/* 기업형태 */}
                      <li>
                        <h5>기업형태</h5>
                        <div className="radio-group">
                          {JOB_TYPE_LIST.map(item => {
                            return (
                              <Form.Check
                                key={`enterprise-type-${item.value}`}
                                name="enterprise-type"
                                type="radio"
                                className="type-priamry"
                                label={item.label}
                                id={item.value}
                                value={item.value}
                                onChange={() => jobTypeHandler(item.value)}
                                checked={+jobForm.hopeJobGubun === item.value}
                              />
                            );
                          })}
                        </div>
                      </li>
                      {/* 희망산업체 */}
                      <li>
                        <h5>희망산업체</h5>
                        <Form.Control
                          type="text"
                          placeholder="희망산업체를 입력하세요."
                          value={jobForm.hopeJobIndustry}
                          onChange={e => {
                            jobForm.hopeJobIndustry = e.target.value;
                            setJobForm({
                              ...jobForm,
                            });
                          }}
                          maxLength={30}
                        />
                      </li>
                      {/* 진로희망 사유 */}
                      <li>
                        <h5>진로희망 사유</h5>
                        <Form.Control
                          type="text"
                          placeholder="진로희망 사유를 입력하세요."
                          value={jobForm.hopeJobReason}
                          onChange={e => {
                            jobForm.hopeJobReason = e.target.value;
                            setJobForm({
                              ...jobForm,
                            });
                          }}
                          maxLength={100}
                        />
                      </li>
                      {/* 희망직업 관련 자격증 */}
                      <li>
                        <h5>희망직업 관련 자격증</h5>
                        <Form.Control
                          type="text"
                          placeholder="희망직업 관련 자격증을 입력하세요."
                          value={jobForm.hopeJobCertificate}
                          onChange={e => {
                            jobForm.hopeJobCertificate = e.target.value;
                            setJobForm({
                              ...jobForm,
                            });
                          }}
                          maxLength={100}
                        />
                      </li>
                      {/* 희망직업이 하는 일 */}
                      <li>
                        <h5>희망직업이 하는 일</h5>
                        <Form.Control
                          type="text"
                          placeholder="희망직업이 하는 일을 입력하세요."
                          value={jobForm.hopeJobWork}
                          onChange={e => {
                            jobForm.hopeJobWork = e.target.value;
                            setJobForm({
                              ...jobForm,
                            });
                          }}
                          maxLength={100}
                        />
                      </li>
                      {/* 필요기술 및 지식 */}
                      <li>
                        <h5>필요기술 및 지식</h5>
                        <Form.Control
                          type="text"
                          placeholder="필요기술 및 지식을 입력하세요."
                          value={jobForm.hopeJobSkill}
                          onChange={e => {
                            jobForm.hopeJobSkill = e.target.value;
                            setJobForm({
                              ...jobForm,
                            });
                          }}
                          maxLength={100}
                        />
                      </li>
                    </ul>
                  </Tab.Pane>

                  {/* TAB (2) - 진학 */}
                  <Tab.Pane eventKey="2">
                    <div className="sub-title sm">
                      <h5>진로대학 탐색</h5>
                      <Button
                        variant="text-underline"
                        onClick={moveToSearchUniv}
                      >
                        대학검색
                      </Button>
                    </div>
                    <ul className="form-list">
                      {/* 희망대학 */}
                      <li>
                        <h5>희망대학</h5>
                        <Form.Control
                          type="text"
                          placeholder="희망대학을 입력하세요."
                          value={univForm.hopeUnivName}
                          onChange={e => {
                            univForm.hopeUnivName = e.target.value;
                            setUnivForm({
                              ...univForm,
                            });
                          }}
                          maxLength={30}
                        />
                      </li>
                      {/* 대학형태 */}
                      <li>
                        <h5>대학형태</h5>
                        <div className="radio-group">
                          {UNIV_TYPE_LIST.map(item => {
                            return (
                              <Form.Check
                                key={`univ-type-${item.value}`}
                                name="univ-type"
                                type="radio"
                                className="type-priamry"
                                label={item.label}
                                id={item.value}
                                value={item.value}
                                onChange={() => univTypeHandler(item.value)}
                                checked={+univForm.hopeUnivGubun === item.value}
                              />
                            );
                          })}
                        </div>
                      </li>
                      {/* 지원형태 */}
                      <li>
                        <h5>지원형태</h5>
                        <div className="radio-group">
                          {APPLY_TYPE_LIST.map(item => {
                            return (
                              <Form.Check
                                key={`apply-type-${item.value}`}
                                name="apply-type"
                                type="radio"
                                className="type-priamry"
                                label={item.label}
                                id={item.value}
                                value={item.value}
                                onChange={() => applyTypeHandler(item.value)}
                                checked={+univForm.hopeUnivApply === item.value}
                              />
                            );
                          })}
                        </div>
                      </li>
                      {/* 희망학과 */}
                      <li>
                        <h5>희망학과</h5>
                        <Form.Control
                          type="text"
                          placeholder="희망학과를 입력하세요."
                          value={univForm.hopeUnivMajor}
                          onChange={e => {
                            univForm.hopeUnivMajor = e.target.value;
                            setUnivForm({
                              ...univForm,
                            });
                          }}
                          maxLength={30}
                        />
                      </li>
                      {/* 진학희망 사유 */}
                      <li>
                        <h5>진학희망 사유</h5>
                        <Form.Control
                          type="text"
                          placeholder="진학희망 사유를 입력하세요."
                          value={univForm.hopeUnivReason}
                          onChange={e => {
                            univForm.hopeUnivReason = e.target.value;
                            setUnivForm({
                              ...univForm,
                            });
                          }}
                          maxLength={100}
                        />
                      </li>
                      {/* 필요등급 */}
                      <li>
                        <h5>필요등급</h5>
                        <Form.Control
                          type="text"
                          placeholder="과목과 등급을 입력하세요."
                          value={univForm.hopeUnivGrade}
                          onChange={e => {
                            univForm.hopeUnivGrade = e.target.value;
                            setUnivForm({
                              ...univForm,
                            });
                          }}
                          maxLength={100}
                        />
                      </li>
                    </ul>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Card>
          </section>

          {/* 교과 이수 */}
          {/* <section className="mb-5">
            <div className="sub-title">
              <h5>교과 이수</h5>
            </div>
            <Card>
              <div className="sw-input mb-3">
                <CustomSelect
                  options={semesterDropdown}
                  value={selectedOption}
                  onChange={semesterHandler}
                />
              </div>
              <Table className="bt-none has-input">
                <colgroup>
                  <col width={140} />
                  <col width="*" />
                  <col width="*" />
                  <col width="*" />
                  <col width="*" />
                  <col width="*" />
                </colgroup>
                <thead>
                  <tr>
                    <th>구분</th>
                    {scheduleDays.map((day, index) => (
                      <th key={index}>{day}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {scheduleClasses.map((item, index) => {
                    return (
                      <tr key={`${item}-${index}`}>
                        <th className="text-gray">{item}교시</th>
                        <td>
                          <Form.Control
                            id={`${item}_mon`}
                            type="text"
                            placeholder={
                              curSemester !== semester
                                ? ''
                                : '과목을 입력하세요.'
                            }
                            readOnly={curSemester !== semester}
                            onChange={e =>
                              studyInputHandler('mon', item, e.target.value)
                            }
                            value={
                              (studyPlan[semester] &&
                                studyPlan[semester].mon.split(';')[index]) ||
                              ''
                            }
                          />
                        </td>
                        <td>
                          <Form.Control
                            id={`${item}_tue`}
                            type="text"
                            placeholder={
                              curSemester !== semester
                                ? ''
                                : '과목을 입력하세요.'
                            }
                            readOnly={curSemester !== semester}
                            onChange={e =>
                              studyInputHandler('tue', item, e.target.value)
                            }
                            value={
                              (studyPlan[semester] &&
                                studyPlan[semester].tue.split(';')[index]) ||
                              ''
                            }
                          />
                        </td>
                        <td>
                          <Form.Control
                            id={`${item}_wed`}
                            type="text"
                            placeholder={
                              curSemester !== semester
                                ? ''
                                : '과목을 입력하세요.'
                            }
                            readOnly={curSemester !== semester}
                            onChange={e =>
                              studyInputHandler('wed', item, e.target.value)
                            }
                            value={
                              (studyPlan[semester] &&
                                studyPlan[semester].wed.split(';')[index]) ||
                              ''
                            }
                          />
                        </td>
                        <td>
                          <Form.Control
                            id={`${item}_thu`}
                            type="text"
                            placeholder={
                              curSemester !== semester
                                ? ''
                                : '과목을 입력하세요.'
                            }
                            readOnly={curSemester !== semester}
                            onChange={e =>
                              studyInputHandler('thu', item, e.target.value)
                            }
                            value={
                              (studyPlan[semester] &&
                                studyPlan[semester].thu.split(';')[index]) ||
                              ''
                            }
                          />
                        </td>
                        <td>
                          <Form.Control
                            id={`${item}_fri`}
                            type="text"
                            placeholder={
                              curSemester !== semester
                                ? ''
                                : '과목을 입력하세요.'
                            }
                            readOnly={curSemester !== semester}
                            onChange={e =>
                              studyInputHandler('fri', item, e.target.value)
                            }
                            value={
                              (studyPlan[semester] &&
                                studyPlan[semester].fri.split(';')[index]) ||
                              ''
                            }
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card>
          </section> */}

          {/* 자격증 취득계획 */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>자격증 취득계획</h5>
              <Button variant="text-underline" onClick={moveToSearchCert}>
                자격증검색
              </Button>
            </div>
            <Card>
              {/* 전공자격증 */}
              <ul className="form-list top">
                <li>
                  <h5>전공자격증</h5>
                  <div className="w-100">
                    {majorCertPlan.map((item, index) => {
                      return (
                        <div key={index} className="right-outside-btn-input">
                          <Form.Control
                            type="text"
                            placeholder="자격증을 입력하세요."
                            maxLength={50}
                            onChange={e =>
                              certInputHandler(index, 1, e.target.value)
                            }
                            value={item.certi_info}
                          />
                          <Button
                            className={
                              index === majorCertPlan.length - 1
                                ? 'btn-plus'
                                : 'btn-minus'
                            }
                            onClick={
                              index !== majorCertPlan.length - 1
                                ? () =>
                                    removeRow(
                                      index,
                                      majorCertPlan,
                                      setMajorCertPlan,
                                    )
                                : () =>
                                    addRow({
                                      type: CERTIFICATE,
                                      gubun: 1,
                                      list: majorCertPlan,
                                      setList: setMajorCertPlan,
                                    })
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                </li>
              </ul>
              {/* 정보화 자격증 */}
              <ul className="form-list top">
                <li>
                  <h5>정보화 자격증</h5>
                  <div className="w-100">
                    {itCertPlan.map((item, index) => {
                      return (
                        <div key={index} className="right-outside-btn-input">
                          <Form.Control
                            type="text"
                            placeholder="자격증을 입력하세요."
                            maxLength={50}
                            onChange={e =>
                              certInputHandler(index, 2, e.target.value)
                            }
                            value={item.certi_info}
                          />
                          <Button
                            className={
                              index === itCertPlan.length - 1
                                ? 'btn-plus'
                                : 'btn-minus'
                            }
                            onClick={
                              index !== itCertPlan.length - 1
                                ? () =>
                                    removeRow(index, itCertPlan, setItCertPlan)
                                : () =>
                                    addRow({
                                      type: CERTIFICATE,
                                      gubun: 2,
                                      list: itCertPlan,
                                      setList: setItCertPlan,
                                    })
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                </li>
              </ul>
              {/* 외국어 능력 */}
              <ul className="form-list top">
                <li>
                  <h5>외국어 능력</h5>
                  <div className="w-100">
                    {langCertPlan.map((item, index) => {
                      return (
                        <div key={index} className="right-outside-btn-input">
                          <Form.Control
                            type="text"
                            placeholder="자격증을 입력하세요."
                            maxLength={50}
                            onChange={e =>
                              certInputHandler(index, 3, e.target.value)
                            }
                            value={item.certi_info}
                          />
                          <Button
                            key={index}
                            className={
                              index === langCertPlan.length - 1
                                ? 'btn-plus'
                                : 'btn-minus'
                            }
                            onClick={
                              index !== langCertPlan.length - 1
                                ? () =>
                                    removeRow(
                                      index,
                                      langCertPlan,
                                      setLangCertPlan,
                                    )
                                : () =>
                                    addRow({
                                      type: CERTIFICATE,
                                      gubun: 3,
                                      list: langCertPlan,
                                      setList: setLangCertPlan,
                                    })
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                </li>
              </ul>
            </Card>
          </section>

          {/* 창의적 체험활동 */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>창의적 체험활동</h5>
            </div>
            <Card>
              {/* 창의적 체험활동 > 동아리 활동 */}
              <ul className="form-list top">
                <li>
                  <h5>동아리 활동</h5>
                  <div className="w-100">
                    {clubActPlan.map((item, index) => {
                      return (
                        <div key={index} className="right-outside-btn-input">
                          <Form.Control
                            type="text"
                            placeholder="동아리명을 입력하세요."
                            maxLength={50}
                            onChange={e => {
                              actInputHandler(index, 1, e.target.value, null);
                            }}
                            value={item.act_info}
                          />
                          <Form.Control
                            className="ms-2"
                            type="text"
                            placeholder="활동내용을 입력하세요."
                            maxLength={100}
                            onChange={e => {
                              actInputHandler(index, 1, null, e.target.value);
                            }}
                            value={item.act_detail}
                          />
                          <Button
                            className={
                              index === clubActPlan.length - 1
                                ? 'btn-plus'
                                : 'btn-minus'
                            }
                            onClick={
                              index !== clubActPlan.length - 1
                                ? () =>
                                    removeRow(
                                      index,
                                      clubActPlan,
                                      setClubActPlan,
                                    )
                                : () =>
                                    addRow({
                                      type: ACTIVITY,
                                      gubun: 1,
                                      list: clubActPlan,
                                      setList: setClubActPlan,
                                    })
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                </li>
              </ul>

              {/* 창의적 체험활동 > 봉사 활동 */}
              <ul className="form-list top">
                <li>
                  <h5>봉사 활동</h5>
                  <div className="w-100">
                    {volActPlan.map((item, index) => {
                      return (
                        <div key={index} className="right-outside-btn-input">
                          <Form.Control
                            type="text"
                            placeholder="봉사활동 내용을 입력하세요."
                            maxLength={50}
                            onChange={e => {
                              actInputHandler(index, 2, e.target.value, null);
                            }}
                            value={item.act_info}
                          />
                          <Form.Control
                            className="ms-2"
                            type="text"
                            placeholder="봉사활동 시간을 입력하세요."
                            maxLength={10}
                            onChange={e => {
                              actInputHandler(
                                index,
                                2,
                                null,
                                e.target.value.replace(/\D/, ''),
                              );
                            }}
                            value={item.act_detail}
                          />
                          <Button
                            className={
                              index === volActPlan.length - 1
                                ? 'btn-plus'
                                : 'btn-minus'
                            }
                            onClick={
                              index !== volActPlan.length - 1
                                ? () =>
                                    removeRow(index, volActPlan, setVolActPlan)
                                : () =>
                                    addRow({
                                      type: ACTIVITY,
                                      gubun: 2,
                                      list: volActPlan,
                                      setList: setVolActPlan,
                                    })
                            }
                          />
                        </div>
                      );
                    })}

                    <small className="text-gray flex-end">
                      총 시간 :
                      {volActPlan.reduce((totalHour, volAct) => {
                        return (
                          totalHour +
                          (volAct.act_detail ? +volAct.act_detail : 0)
                        );
                      }, 0)}
                      &nbsp;시간
                    </small>
                  </div>
                </li>
              </ul>

              {/* 창의적 체험활동 > 독서계획 */}
              <ul className="form-list top">
                <li>
                  <h5>독서계획</h5>
                  <div className="w-100">
                    {readActPlan.map((item, index) => {
                      return (
                        <div key={index} className="right-outside-btn-input">
                          <Form.Control
                            type="text"
                            placeholder="도서명과 작가명을 입력하세요. (예 : 완득이-김려령)"
                            maxLength={100}
                            onChange={e => {
                              actInputHandler(index, 3, e.target.value, null);
                            }}
                            value={item.act_info}
                          />
                          <Button
                            className={
                              index === readActPlan.length - 1
                                ? 'btn-plus'
                                : 'btn-minus'
                            }
                            onClick={
                              index !== readActPlan.length - 1
                                ? () =>
                                    removeRow(
                                      index,
                                      readActPlan,
                                      setReadActPlan,
                                    )
                                : () =>
                                    addRow({
                                      type: ACTIVITY,
                                      gubun: 3,
                                      list: readActPlan,
                                      setList: setReadActPlan,
                                    })
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                </li>
              </ul>

              {/* 창의적 체험활동 > 기타 활동 */}
              <ul className="form-list top">
                <li>
                  <h5>기타활동</h5>
                  <div className="w-100">
                    {etcActPlan.map((item, index) => {
                      return (
                        <div key={index} className="right-outside-btn-input">
                          <Form.Control
                            type="text"
                            placeholder="활동명을 입력하세요."
                            maxLength={50}
                            onChange={e => {
                              actInputHandler(index, 4, e.target.value, null);
                            }}
                            value={item.act_info}
                          />
                          <Form.Control
                            className="ms-2"
                            type="text"
                            placeholder="활동내용을 입력하세요."
                            maxLength={100}
                            onChange={e => {
                              actInputHandler(index, 4, null, e.target.value);
                            }}
                            value={item.act_detail}
                          />
                          <Button
                            className={
                              index === etcActPlan.length - 1
                                ? 'btn-plus'
                                : 'btn-minus'
                            }
                            onClick={
                              index !== etcActPlan.length - 1
                                ? () =>
                                    removeRow(index, etcActPlan, setEtcActPlan)
                                : () =>
                                    addRow({
                                      type: ACTIVITY,
                                      gubun: 4,
                                      list: etcActPlan,
                                      setList: setEtcActPlan,
                                    })
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                </li>
              </ul>
            </Card>
          </section>

          {/* ↓ 하단 버튼 */}
          <div className="btn-area">
            <Button
              size="lg"
              variant="outline-primary"
              onClick={e => insertUpdateCareerDesign(e, 'ING')}
            >
              저장
            </Button>
          </div>
        </article>
      </Container>
    </main>
  );
});
