/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useState, useRef, useEffect } from 'react';
import { useHistory, useLocation, Link, useParams } from 'react-router-dom';
import { Container, Button, Table, Modal, Form, Image } from 'react-bootstrap';
import { CustomModal } from '@components';
import { images } from '@assets';
import DatePicker from 'react-datepicker';
import { useReactToPrint } from 'react-to-print';
import 'react-datepicker/dist/react-datepicker.css';
import Swal from 'sweetalert2';

//* Utils
import Utils from '@common/Utils';

//* Consts
import { FormStatus, FILE_FORM_TYPE } from '@common/consts'; // Form 상태 text, class

//* API
import {
  selectFormInsungGrade,
  insertUpdateInsung,
} from '@api/student/basicJob';
import { getFilePathFromRedis } from '@api/file';

//* #################################################################
//* [ Main ]
//* #################################################################
export default React.memo(function TenacityActionReport(props) {
  //* #################################################################
  //* [ States ]
  //* #################################################################
  const history = useHistory();

  const { type: typeParam, grade } = useParams();

  // type
  const [viewType, setViewType] = useState(typeParam);

  const today = new Date();
  // const yesterday = new Date(today.setDate(today.getDate() - 1));

  //* 조회 학년
  const validGrades = ['1', '2', '3'];

  //* 활동내역 리스트
  const [insungList, setInsungList] = useState([]);

  //* Form 변수 (수정)
  const [formSeq, setFormSeq] = useState(); // SEQ
  const [actGov, setActGov] = useState(''); // 활동일
  const [actTime, setActTime] = useState(''); // 활동시간
  const [actTitle, setActTitle] = useState(''); // 활동내용
  const [actDate, setActDate] = useState(today);
  const [files, setFiles] = useState([]); // 증빙자료 (복수)

  const selectFile = useRef(''); // 증빙자료

  // 중복요청 방지
  const [isSentRequest, setIsSentRequest] = useState(false);

  //* #################################################################
  //* [ Modal ]
  //* #################################################################
  const [showWriteActivityModal, setShowWriteActivityModal] = useState(false);

  const handleShowWriteActivityModal = seq => {
    // 모달 열기
    setShowWriteActivityModal(true);

    // 입력값 업데이트
    const editForm = insungList.filter(form => form.seq === seq)[0];

    setFormSeq(seq);
    setActDate(new Date(editForm.act_date));
    setActTime(editForm.act_time);
    setActTitle(editForm.act_title);
    setActGov(editForm.act_gov);
    setFiles(editForm.files);
  };

  //* #################################################################
  //* [ Utils ]
  //* #################################################################
  // 활동 시간 입력
  const actDateHandler = date => {
    setActDate(date);
  };

  // 활동 시간 입력
  const actTimeHandler = e => {
    setActTime(e.target.value.replace(/[^0-9]/g, ''));
  };

  // 활동 내용 입력
  const actTitleHandler = e => {
    setActTitle(e.target.value);
  };

  // 활동 기관 입력
  const actGovHandler = e => {
    setActGov(e.target.value);
  };

  // 파일 첨부
  const addFile = e => {
    if (files.length >= 2) {
      Swal.fire({
        text: '첨부 파일은 2개까지 등록할 수 있습니다.',
        confirmButtonText: '확인',
      });
      return;
    }

    if (e.target.files[0]) setFiles([...files, e.target.files[0]]);
    selectFile.current.value = null;
  };

  // 첨부 파일 삭제
  const removeFile = (seq, idx) => {
    const filteredList = files.filter((file, index) => index !== idx);
    setFiles([...filteredList]);
  };

  // 인쇄
  const printWindowRef = useRef();

  const handlePrint = useReactToPrint({
    onBeforeGetContent: () => {
      printWindowRef.current.classList.add('print');
    },
    onAfterPrint: () => {
      printWindowRef.current.classList.remove('print');
    },
    onPrintError: () => {
      printWindowRef.current.classList.remove('print');
    },
    content: () => {
      return printWindowRef.current;
    },
  });

  //* #################################################################
  //* [ API ] 인성 자존감 활동 파일 조회
  //* #################################################################
  const openFile = async fileSeq => {
    // API 파라미터
    const paramMap = {
      formType: FILE_FORM_TYPE.FILE_INSUNG,
      fileSeq,
    };

    // Axios
    try {
      const { data } = await getFilePathFromRedis(paramMap);

      if (data.code === 0) {
        const redisKey = data.data;
        const hostName = window.location.hostname;

        const url = Utils.getFileOpenUrl(hostName, redisKey);

        window.open(url);
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 인성 자존감 활동  인성 자존감 활동 조회 (학년별)
  //* #################################################################
  const [firstCallDone, setFirstCallDone] = useState(false);
  const getInsungList = async () => {
    // 조회 학년 확인
    if (!validGrades.includes(grade)) {
      Swal.fire({
        text: '잘못된 접근입니다.',
        confirmButtonText: '확인',
      });
      history.goBack();
    }

    // 파라미터 설정
    const paramMap = {
      grade,
    };

    // Axios
    try {
      const { data } = await selectFormInsungGrade(paramMap);

      if (data.code === 0) {
        setFirstCallDone(true);
        const list = data.data;

        list.forEach(form => {
          const isValid =
            // Utils.checkDateTime(new Date(form.act_date)) &&
            Utils.checkNumber(form.act_time) &&
            Utils.checkNull(form.act_title) &&
            Utils.checkNull(form.act_gov) &&
            form.files.length > 0;

          form.isValid = isValid;
        });

        setInsungList(list);
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 인성 자존감 취소, 삭제, 승인요청
  //* #################################################################
  const updateRequest = async (seq, reqStatus) => {
    // 중복요청 확인
    if (isSentRequest) {
      return;
    }

    // 승인요청 파라미터 검증
    if (reqStatus === 'REQ') {
      const reqForm = insungList.filter(act => act.seq === seq)[0];

      // if (!Utils.checkDateTime(new Date(reqForm.act_date))) {
      //   Swal.fire({
      //     text: '활동일을 확인해주세요.',
      //     confirmButtonText: '확인',
      //   });
      //   return;
      // }

      if (!reqForm.act_time) {
        Swal.fire({
          text: '활동시간이 입력되지 않았습니다.',
          confirmButtonText: '확인',
        });
        return;
      }

      if (!reqForm.act_title) {
        Swal.fire({
          text: '활동내용이 입력되지 않았습니다.',
          confirmButtonText: '확인',
        });
        return;
      }

      if (!reqForm.act_gov) {
        Swal.fire({
          text: '증빙기관이 입력되지 않았습니다.',
          confirmButtonText: '확인',
        });
        return;
      }

      // if (!reqForm.files || reqForm.files.length < 1) {
      //   Swal.fire({
      //     text: '증빙자료가 등록되지 않았습니다.',
      //     confirmButtonText: '확인',
      //   });
      //   return;
      // }
    }

    // eslint-disable-next-line no-restricted-globals
    if (
      reqStatus === 'DEL' &&
      !(await Swal.fire({
        text: '삭제하시겠습니까?',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        showCancelButton: true,
        reverseButtons: true,
      }).then(result => !!result.isConfirmed))
    ) {
      return;
    }

    // eslint-disable-next-line no-restricted-globals
    if (
      reqStatus === 'CAN' &&
      !(await Swal.fire({
        text: '승인요청을 취소하시겠습니까?',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        showCancelButton: true,
        reverseButtons: true,
      }).then(result => !!result.isConfirmed))
    ) {
      return;
    }

    // eslint-disable-next-line no-restricted-globals
    if (
      reqStatus === 'REQ' &&
      !(await Swal.fire({
        text: '승인요청하시겠습니까?',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        showCancelButton: true,
        reverseButtons: true,
      }).then(result => !!result.isConfirmed))
    ) {
      return;
    }

    // FormData
    const formData = new FormData();

    // 수정 요청 > 파일 검증
    const saveFileSeq = [];
    const deleteFileSeq = [];

    if (reqStatus === 'ING') {
      // 모든 파일 Seq 추출 (기존파일)
      const { files: allFiles } = insungList.filter(
        form => form.seq === formSeq,
      )[0];

      const allFileSeq = allFiles.map(file => file.file_seq);

      // 파일 append (File 타입 Only)
      for (let i = 0; i < files.length; i += 1) {
        // File 타입
        if (Object.getPrototypeOf(files[i]) === File.prototype) {
          formData.append('files', files[i]);
        }
        // Object 타입
        else {
          saveFileSeq.push(files[i].file_seq);
        }
      }

      // 삭제 파일 Seq 추출
      allFileSeq.forEach(fileSeq => {
        if (saveFileSeq.indexOf(fileSeq) === -1) deleteFileSeq.push(fileSeq);
      });
    }

    // JSON 파라미터 설정
    // [ Default ] 삭제, 승인요청
    const param = {
      formSeq: seq,
      status: reqStatus,
    };

    // [ CASE 1 ] 수정 > 임시저장
    if (reqStatus === 'ING') {
      param.act_title = actTitle;
      param.act_date = actDate;
      param.act_time = +actTime;
      param.act_gov = actGov;
      param.deleteFileSeq = deleteFileSeq;
    }

    // [ CASE 2 ] 취소
    else if (reqStatus === 'CAN') {
      param.status = 'ING';
    }

    // JSON append
    formData.append(
      'param',
      new Blob([JSON.stringify(param)], { type: 'application/json' }),
    );

    // 중복요청 방지
    setIsSentRequest(true);

    // Axios
    try {
      const { data } = await insertUpdateInsung(formData);

      if (data.code === 0) {
        // 리스트 갱신
        getInsungList();

        // 모달 Close
        setShowWriteActivityModal(false);

        // 중복요청 방지 해제
        setIsSentRequest(false);
      }
    } catch (e) {
      // 중복요청 방지 해제
      setIsSentRequest(false);

      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ useEffect ]
  //* #################################################################
  useEffect(() => {
    getInsungList();
  }, []);

  //* #################################################################
  //* [ Components ]
  //* #################################################################
  // 버튼 (인쇄, 목록, 삭제, 수정, 취소)
  const customButton = (type, seq) => {
    let variant;
    let event;
    let text;

    switch (type) {
      case 'delete':
        variant = 'outline-primary';
        event = () => updateRequest(seq, 'DEL');
        text = '삭제';
        break;
      case 'edit':
        variant = 'primary';
        event = () => handleShowWriteActivityModal(seq);
        text = '수정';
        break;
      case 'cancel':
        variant = 'primary';
        event = () => updateRequest(seq, 'CAN');
        text = '승인요청 취소';
        break;
      case 'request':
        variant = 'primary';
        event = () => updateRequest(seq, 'REQ');
        text = '승인요청';
        break;
      default:
        break;
    }

    return (
      <Button size="sm" variant={variant} onClick={event}>
        {text}
      </Button>
    );
  };

  //* #################################################################
  //* [ return ]
  //* #################################################################
  return (
    <main id="program-tenacityAction-report">
      <Container>
        <article className="content" ref={printWindowRef}>
          {viewType === 'student' && (
            <ul className="path">
              <li>
                <Link to="/">HOME</Link>
              </li>
              <li>
                <Link to="#none">인성자존감 활동</Link>
              </li>
              <li>
                <Link to="#none">결과 보고서</Link>
              </li>
            </ul>
          )}

          {/* ////////// ↓ 상단 타이틀영역 ////////// */}
          <section>
            <div className="title">
              <h5>인성자존감 활동 결과 보고서</h5>
            </div>
          </section>

          {/* ////////// ↓ 컨텐츠 ////////// */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>{grade} 학년 활동내역</h5>
            </div>
            <Table>
              <colgroup>
                <col width={140} />
                <col width={100} />
                <col width="*" />
                <col width={180} />
                <col width={200} />
                {/* <col width={80} /> */}
                <col width={110} />
                <col width={220} />
              </colgroup>
              <thead>
                <tr>
                  <th>취득일</th>
                  <th>활동시간</th>
                  <th>활동내용</th>
                  <th>발급기관</th>
                  <th>증빙자료</th>
                  {/* <th>점수</th> */}
                  <th>상태</th>
                  <th>관리</th>
                </tr>
              </thead>
              <tbody>
                {insungList.length > 0 ? (
                  <>
                    {insungList.map((form, idx) => (
                      <tr key={idx}>
                        <td>{form.act_date}</td>
                        <td>{form.act_time}</td>
                        <td className="text-start table-ellipsis">
                          {form.act_title}
                        </td>
                        <td>{form.act_gov}</td>
                        <td className="text-start">
                          {form.files.length > 0 &&
                            form.files.map((file, fileIdx) => {
                              return (
                                <Button
                                  key={fileIdx}
                                  className="btn-icon ic-down"
                                  onClick={() => openFile(file.file_seq)}
                                >
                                  {file.file_name_org}
                                </Button>
                              );
                            })}
                        </td>
                        {/* <td>{form.point}</td> */}
                        <td>
                          <strong className={FormStatus[form.status].class}>
                            {FormStatus[form.status].text}
                          </strong>
                        </td>
                        <td>
                          <div className="btn-area">
                            {/* 승인 */}
                            {form.status === 'CON'}
                            {/* 승인요청 */}
                            {form.status === 'REQ' && (
                              <>{customButton('cancel', form.seq)}</>
                            )}
                            {/* 보완 */}
                            {form.status === 'REJ' && (
                              <>{customButton('edit', form.seq)}</>
                            )}
                            {/* 임시저장 */}
                            {form.status === 'ING' && (
                              <>
                                {customButton('delete', form.seq)}
                                {customButton('edit', form.seq)}
                                {customButton('request', form.seq)}
                              </>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  firstCallDone && (
                    <tr>
                      <td colSpan="7" className="no-data">
                        작성한 결과 보고서가 없습니다.
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          </section>

          {/* ////////// ↓ 하단 버튼 //////////  */}
          {viewType === 'student' ? (
            <div className="btn-area">
              <Button
                size="lg"
                variant="outline-primary"
                onClick={() => handlePrint()}
              >
                인쇄
              </Button>
              <Button
                onClick={() => history.push('/student/basicJob/tenacityAction')}
                variant="primary"
                size="lg"
              >
                목록으로
              </Button>
            </div>
          ) : (
            <div className="btn-area">
              <Button
                onClick={() => {
                  history.goBack();
                }}
                variant="primary"
                size="lg"
              >
                목록
              </Button>
            </div>
          )}
        </article>
      </Container>

      {/* 인성 자존감 활동 작성 모달 */}
      <CustomModal
        id="writeActivityModal"
        title="활동내역"
        show={showWriteActivityModal}
        onHide={() => setShowWriteActivityModal(false)}
        size="sm"
      >
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>활동일</Form.Label>
            <div className="w-100">
              <DatePicker
                className="input-datepicker"
                name="activity-date"
                dateFormat="yyyy.MM.dd"
                selected={new Date(actDate)}
                onChange={actDateHandler}
                // maxDate={today}
                customInput={<Form.Control style={{ width: '100%' }} />}
                showPopperArrow={false}
              />
            </div>
          </Form.Group>
          {/* 활동시간 */}
          <Form.Group className="mb-3">
            <Form.Label>활동시간</Form.Label>
            <Form.Control
              type="text"
              placeholder="활동시간을 숫자로 입력하세요."
              maxLength={4}
              value={actTime}
              onChange={actTimeHandler}
            />
          </Form.Group>
          {/* 활동내용 */}
          <Form.Group className="mb-3">
            <Form.Label>활동내용</Form.Label>
            <Form.Control
              type="text"
              placeholder="활동대상을 입력하세요."
              maxLength={50}
              value={actTitle}
              onChange={actTitleHandler}
            />
          </Form.Group>
          {/* 증빙기관 */}
          <Form.Group className="mb-3">
            <Form.Label>증빙기관</Form.Label>
            <Form.Control
              type="text"
              placeholder="증빙기관을 입력하세요."
              maxLength={50}
              value={actGov}
              onChange={actGovHandler}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>증빙자료</Form.Label>
            {/* ↓ 첨부된 파일 */}
            <ul className="drag-attachment-list sm mb-3">
              {files.length > 0 &&
                files.map((file, idx) => {
                  return (
                    <li key={idx}>
                      <p className="text-primary">
                        {file.file_name_org || file.name}
                      </p>
                      <Button variant="iconOnly" size="sm">
                        <Image
                          src={images.icCancel}
                          onClick={() => removeFile(file.file_seq, idx)}
                        />
                      </Button>
                    </li>
                  );
                })}
            </ul>
            {/* ↓ 파일 첨부 */}
            <Button
              onClick={() => selectFile.current.click()}
              variant="outline-primary"
              className="d-block"
            >
              파일첨부
            </Button>
            <Form.Control
              ref={selectFile}
              type="file"
              // accept="image/*, .pdf"
              style={{ display: 'none' }}
              onChange={addFile}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-primary"
            onClick={() => updateRequest(formSeq, 'ING')}
          >
            임시저장
          </Button>
        </Modal.Footer>
      </CustomModal>
    </main>
  );
});
