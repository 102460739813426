/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo, useState } from 'react';
import { Container, Form, Button, Card, Modal, Badge } from 'react-bootstrap';
import { CustomModal } from '@components';
import Swal from 'sweetalert2';

//* API
import {
  getStep,
  varifyPassword,
  goNextStep,
  goNextNth,
} from '@api/eduadmin/systemConfig';

// IP Input 초기값
const initialSystemInfo = {
  nth: '',
  year: '',
  step: '',
};

//* [ Main ]
export default React.memo(function AdmEduSystemSchedule(props) {
  //* #################################################################
  //* [ States ]
  //* #################################################################

  // 시스템 정보 ( 년도, 차수 )
  const [systemInfo, setSystemInfo] = useState(initialSystemInfo);

  // 인증
  const [passwordInput, setPasswordInput] = useState(''); // 비밀번호
  const [isVarified, setIsVarified] = useState(null);
  const [checkedAgree, setCheckedAgree] = useState(false); // 2. 동의체크

  //* #################################################################
  //* [ Modal ]
  //* #################################################################
  // 모달 - 다음
  const [enterPwdModalShow, setEnterPwdModalShow] = useState(false);

  const openEnterPwdModal = () => {
    setIsVarified(null);
    setEnterPwdModalShow(true);
  };

  const closeEnterPwdModal = () => {
    setEnterPwdModalShow(false);
    // reset
    setTimeout(() => {
      setPasswordInput('');
      setIsVarified(false);
      setCheckedAgree(false);
    }, 200);
  };

  //* #################################################################
  //* [ Utils ]
  //* #################################################################
  // 비밀번호 입력 후 다음
  const goNextEnterPwdModal = async step => {
    //* [ API ] 비밀번호 인증
    if (step === 1) {
      // Axios - 비밀번호 인증
      await varifyPassword({
        pw: passwordInput,
      })
        .then(result => {
          // 인증 성공
          if (result.data.code === 0) {
            setIsVarified(true);
          }
          // 실패
          else {
            setIsVarified(false);
          }
        })
        .catch(error => {
          setIsVarified(false);
        });

      return;
    }
    //* [ API ] Step 전환
    if (step === 2) {
      // 비밀번호 인증 완료 확인
      if (!isVarified) {
        Swal.fire({
          text: '비밀번호 인증이 완료되지 않았습니다.',
          confirmButtonText: '확인',
        });
        return;
      }

      // Axios - 비밀번호 인증
      await goNextStep(systemInfo.step + 1)
        .then(result => {
          // 전환 성공
          if (result.data.code === 0) {
            Swal.fire({
              text: '스텝이 갱신되었습니다.',
              confirmButtonText: '확인',
            });

            getStepInfo();
          }
        })
        .catch(error => {});

      closeEnterPwdModal();
    }
  };

  // 다음
  const nextStep = step => {
    openEnterPwdModal();
  };

  //* #################################################################
  //* [ API ] Step 정보 가져오기
  //* #################################################################
  const getStepInfo = async () => {
    // Axios
    try {
      const { data } = await getStep();

      if (data.code === 0) {
        const { year, nth, step } = data.data;

        setSystemInfo({
          year,
          nth,
          step,
        });
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 차수 전환
  //* #################################################################
  const patchNextNth = async () => {
    // 시스템 차수 확인
    if (systemInfo.step !== 7) {
      Swal.fire({
        text: '잘못된 접근입니다.',
        confirmButtonText: '확인',
      });
      return;
    }

    // Axios
    try {
      const { data } = await goNextNth();

      if (data.code === 0) {
        Swal.fire({
          text: '차수 전환이 완료되었습니다.',
          confirmButtonText: '확인',
        });

        getStepInfo();
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ useEffect ]
  //* #################################################################
  useEffect(() => {
    getStepInfo();
  }, []);

  // Step 컨텐츠 리스트
  const stepData = useMemo(
    () => [
      {
        idx: 1,
        title: '기본정보 등록',
        contents: [
          '학교별 학교관리자 학생 등록',
          '학교별 학교관리자 학생 정보 확정',
          '교육청(관리자) 신규 학생 확정',
        ],
      },
      {
        idx: 2,
        title: '등록정보 변경',
        contents: [
          '학교별 참여교사 학생 정보 수정',
          '학교 자율프로그램명 수정(1차)',
          '교육청(관리자) 리서치 정보 수정, 바우처 점수 관리 수정(1차)',
        ],
      },
      {
        idx: 3,
        title: '협력은행 데이터 전달',
        contents: [
          '교육청(관리자) 학생정보 확인 후 파일 다운로드',
          '부산은행 1차 학생 명단 전달',
        ],
      },
      {
        idx: 4,
        title: '학교별 바우처 운영 기간',
        contents: [
          '학생별 바우처 교육프로그램 입력 및 수정',
          '학교별 참여교사 바우처 교육프로그램 승인 및 반려',
        ],
      },
      {
        idx: 5,
        title: '학교별 바우처 할당 기간',
        contents: ['교육청(관리자) 학교별 바우처 할당 '],
      },
      {
        idx: 6,
        title: '학년별 바우처 확인',
        contents: ['각 학생마다 바우처 금액 확인', '학교별 바우처 일괄 전환'],
      },
      {
        idx: 7,
        title: '바우처 후처리',
        contents: [
          '교육청(관리자) 바우처 후처리 진행',
          '부산은행 바우처 내역 전달',
        ],
      },
    ],
    [],
  );

  //* #################################################################
  //* [ return ]
  //* #################################################################
  return (
    <main id="admEdu-system-schedule" className="type-02">
      <Container>
        <article className="content py">
          <section>
            <div className="title">
              <h5>일정관리</h5>
            </div>
          </section>

          <Card>
            {/* 년도, 차수 */}
            <Card.Header>
              <div className="sub-title">
                <h5>
                  {systemInfo.year}년 {systemInfo.nth}차
                </h5>
              </div>
            </Card.Header>
            {/* Step 진행 상황 */}
            <Card.Body className="pt-0">
              {stepData.map(step => {
                return (
                  <article
                    key={`step-${step.idx}`}
                    className={(step.idx > systemInfo.step && 'disabled') || ''}
                  >
                    <div className="step-title">
                      <Badge>Step {step.idx}</Badge>
                      <h6>{step.title}</h6>
                    </div>
                    <ul>
                      {step.contents.map((content, idx) => {
                        return <li key={`content-${idx}`}>{content}</li>;
                      })}
                    </ul>
                    {step.idx !== 7 && (
                      <div className="btn-area">
                        {systemInfo.step > step.idx ? (
                          <Button variant="ts" disabled>
                            완료
                          </Button>
                        ) : (
                          <Button
                            variant="outline-primary"
                            onClick={() => nextStep(step.idx)}
                          >
                            다음
                          </Button>
                        )}
                      </div>
                    )}
                  </article>
                );
              })}
              <div className="btn-area">
                <Button
                  disabled={systemInfo.step < stepData.length}
                  onClick={patchNextNth}
                >
                  차수 전환
                </Button>
              </div>
            </Card.Body>
          </Card>
        </article>
      </Container>

      <CustomModal
        title="알림"
        show={enterPwdModalShow}
        onHide={closeEnterPwdModal}
        size="sm"
      >
        {!isVarified ? (
          <>
            <Modal.Body className="border-0">
              <strong className="d-block text-center">
                다음 스텝으로 진행하기 위해
                <br />
                비밀번호 인증절차가 필요합니다.
              </strong>
              <Form.Control
                type="password"
                placeholder="비밀번호를 입력해주세요."
                className="mt-4"
                value={passwordInput}
                onChange={e => setPasswordInput(e.target.value)}
                isValid={isVarified}
                isInvalid={isVarified === false}
              />
              <Form.Control.Feedback type="invalid">
                비밀번호를 잘못 입력했습니다.
                <br />
                입력하신 내용을 다시 확인해주세요.
              </Form.Control.Feedback>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-primary" onClick={closeEnterPwdModal}>
                취소
              </Button>
              <Button onClick={() => goNextEnterPwdModal(1)}>다음</Button>
            </Modal.Footer>
          </>
        ) : (
          <>
            <Modal.Body className="border-0">
              <strong className="d-block text-center">
                다음 스텝으로 진행 전 확인하세요.
              </strong>
              <p className="mt-3 text-center">
                다음 스텝으로 진행하면 이전 스텝으로 되돌릴 수 없으니
                <br />
                다시 한번 더 확인해주세요.
              </p>
              <div className="bg-primary-light my-3 p-3">
                <ul className="list-circle">
                  <li>이전 스텝의 모든 관리가 불가능합니다.</li>
                  <li>이전 스텝으로 되돌릴 수 없습니다.</li>
                </ul>
              </div>
              <Form.Check
                type="checkbox"
                label="안내사항을 모두 확인하였으며, 이에 동의합니다."
                id="agree-nextStep"
                value="agree"
                onChange={e => setCheckedAgree(e.target.checked)}
                checked={checkedAgree}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-primary" onClick={closeEnterPwdModal}>
                취소
              </Button>
              <Button
                onClick={() => goNextEnterPwdModal(2)}
                disabled={!checkedAgree || !isVarified}
              >
                다음
              </Button>
            </Modal.Footer>
          </>
        )}
      </CustomModal>
    </main>
  );
});
