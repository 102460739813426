/**
 *   [ 진로설계 API ]
 *
 */
import Axios from '@api/axios';

const API_PROGRAM = 'api/v1/teacher';

const SELECT_CAREER = `${API_PROGRAM}/career-info`;
const UPDATE_CAREER = `${API_PROGRAM}/update-status-career-design`;

// 진로설계 > 정보조회
export const selectFormCareer = param => {
  return Axios.post(`${SELECT_CAREER}`, param);
};

// 진로설계 > 신규등록, 저장, 취소, 승인요청, 삭제
export const updateStatusFormCareer = param => {
  return Axios.post(`${UPDATE_CAREER}`, param);
};
