/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { NavLink, Link, useLocation } from 'react-router-dom';
import { Container, Button, Image, Nav } from 'react-bootstrap';
import { images } from '@assets';
import { Utils } from '@common';
import { Timer } from '@components';
import classNames from 'classnames';

//* API
import { getStep } from '@api/eduadmin/systemConfig';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

// 시스템 정보 초기값
const initialSystemInfo = {
  nth: '',
  year: '',
  step: '',
};

//* [ Main ]
export default React.memo(function TeacherHeader({
  userInfo,
  menu = {},
  timerOption,
  tokenHandler,
  logoutHandler,
}) {
  const history = useHistory();
  const navi = useLocation();

  //* 담당 학급
  // const curGrade = userInfo.grade;

  //* 세부 권한
  const curRoleDetail = userInfo.roleDetail;

  //* 메뉴 필터링 (본인 학년이 아닌 메뉴 제거)
  Object.keys(menu).forEach(item => {
    // 하위 메뉴 검색
    const isEmptyMenu =
      menu[item].filter(subItem => {
        if (subItem.roleDetail) {
          return subItem.roleDetail.split(';').includes(curRoleDetail);
        }
        return false;
      }).length === 0;

    if (isEmptyMenu) {
      // eslint-disable-next-line no-param-reassign
      delete menu[item];
    }
  });

  //* #################################################################
  //* [ State ]
  //* #################################################################
  // 시스템 정보
  const [systemInfo, setSystemInfo] = useState(initialSystemInfo);

  //* #################################################################
  //* [ API ] 현재 Step 정보
  //* #################################################################
  const getCurrentStepInfo = async () => {
    // Axios
    try {
      const { data } = await getStep();

      if (data.code === 0) {
        const { year, nth, step } = data.data;

        setSystemInfo({
          year,
          nth,
          step,
        });
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ useEffect ]
  //* #################################################################
  useEffect(() => {
    getCurrentStepInfo();
    return () => {};
  }, []);

  //* #################################################################
  //* [ Return ]
  //* #################################################################
  return (
    <header>
      <section className="top-area">
        <Container>
          <h1 className="logo-container">
            <Link to="/teacher/studentMgmt/studentMgmt">
              <Image src={images.logo} />
            </Link>
          </h1>
          <div className="flex-start">
            <div className="step-box">
              {systemInfo.nth}차수 스텝{systemInfo.step} 진행중
            </div>
            <Timer mm="60" ss="00" reset={timerOption} />
            <Button
              size="sm"
              variant="outline-primary"
              className="ms-3"
              onClick={tokenHandler}
            >
              시간연장
            </Button>
            <Button
              size="sm"
              variant="primary"
              className="ms-3"
              onClick={() => history.push('/teacher/studentMgmt/studentMgmt')}
            >
              메인으로
            </Button>
            <div className="user-menu">
              <p>
                <span>{Utils.decrypt(userInfo.name)}</span>&nbsp;님
              </p>
              <ul>
                <li>
                  <NavLink to="/teacher/mypage">마이페이지</NavLink>
                </li>
                <li>
                  <NavLink to="/student/service/notice">공지사항</NavLink>
                </li>
                <li>
                  <NavLink to="/student/service/archive">교육 자료실</NavLink>
                </li>
                <li>
                  <NavLink to="/" onClick={logoutHandler}>
                    로그아웃
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </Container>
      </section>

      <section className="nav-bar type-left">
        <Container>
          {menu ? (
            <Nav>
              {Object.keys(menu).map((mainTitle, i) => {
                return (
                  <Nav.Item key={i}>
                    {/* 메인 메뉴 */}
                    <NavLink
                      className={classNames({
                        selected: navi.pathname.includes(
                          `${menu[mainTitle][0].url.split('/')[1]}`,
                        ),
                      })}
                      to={`/teacher${
                        menu[mainTitle][
                          menu[mainTitle].findIndex(item =>
                            item.roleDetail.includes(userInfo.roleDetail),
                          )
                        ].url
                      }`}
                    >
                      {mainTitle}
                    </NavLink>
                    {/* 서브 메뉴 - 드랍다운 */}
                    <ul className="sub-menu">
                      {menu[mainTitle].map((subMenu, ii) => {
                        return subMenu.roleDetail
                          .split(';')
                          .includes(userInfo.roleDetail) ? (
                          <li key={ii}>
                            <NavLink
                              activeClassName="sub-selected"
                              to={`/teacher${subMenu.url}`}
                            >
                              {subMenu.title}
                            </NavLink>
                          </li>
                        ) : null;
                      })}
                    </ul>
                  </Nav.Item>
                );
              })}
            </Nav>
          ) : (
            <Nav>
              {/* ↓ 학생 관리 */}
              <Nav.Item>
                <NavLink
                  className="menu"
                  activeClassName="selected"
                  to="/teacher/studentMgmt"
                >
                  학생 관리
                </NavLink>
                <ul className="sub-menu">
                  <li>
                    <NavLink
                      activeClassName="sub-selected"
                      to="/teacher/studentMgmt"
                    >
                      학생관리
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="sub-selected"
                      to="/teacher/studentMgmt/statusList"
                    >
                      학생현황
                    </NavLink>
                  </li>
                </ul>
              </Nav.Item>

              {/* ↓ 교사관리 */}
              <Nav.Item>
                <NavLink
                  className="menu"
                  activeClassName="selected"
                  to="/teacher/teacherMgmt"
                >
                  교사관리
                </NavLink>
              </Nav.Item>

              {/* ↓ 프로그램 관리 */}
              <Nav.Item>
                <NavLink
                  className="menu"
                  activeClassName="selected"
                  to="/teacher/programMgmt"
                >
                  프로그램 관리
                </NavLink>
              </Nav.Item>

              {/* ↓ 바우처 일괄 전환 */}
              <Nav.Item>
                <NavLink
                  className="menu"
                  activeClassName="selected"
                  to="/teacher/voucherBatch"
                >
                  바우처 일괄 전환
                </NavLink>
              </Nav.Item>

              {/* ↓ 바우처 조회 */}
              <Nav.Item>
                <NavLink
                  className="menu"
                  activeClassName="selected"
                  to="/teacher/voucherLookup"
                >
                  바우처 조회
                </NavLink>
              </Nav.Item>
            </Nav>
          )}
        </Container>
      </section>
    </header>
  );
});
