import Axios from '@api/axios';

const API_ARCHIVE = 'api/v1/board/archive';
const API_NOTICE = 'api/v1/board/notice';

// [메인] 교육자료 리스트 조회
export const getMainArchiveList = () => {
  return Axios.get(`${API_ARCHIVE}/list-main`);
};

// [메인] 공지사항 리스트 조회
export const getMainNoticeList = () => {
  return Axios.get(`${API_NOTICE}/list-main`);
};
