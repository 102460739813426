/**
 *   [ 전공 자격증 API ]
 *
 */
import Axios from '@api/axios';

const API_PROGRAM = 'api/v1/student';

const SELECT_LIST_CERTIFICATE_INFO = `${API_PROGRAM}/certificate-info`;
const SELECT_LIST_CERTIFICATE = `${API_PROGRAM}/certificate-list`;
const INSERT_UPDATE_CERTIFICATE = `${API_PROGRAM}/certificate`;

const formDataConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

// 전공직무 능력 > 전공 자격증 리스트 조회 (활동내역, 결과보고서)
export const selectCertificateInfoList = () => {
  return Axios.get(`${SELECT_LIST_CERTIFICATE_INFO}`);
};

// 전공직무 능력 > 전공 자격증 리스트 조회 (활동내역, 결과보고서)
export const selectCertificateList = param => {
  return Axios.post(`${SELECT_LIST_CERTIFICATE}`, param);
};

// 전공직무 능력 > 전공 자격증 신규등록
export const insertUpdateCertificate = param => {
  return Axios.post(`${INSERT_UPDATE_CERTIFICATE}`, param, formDataConfig);
};
