/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Form, Button, Tab, Nav } from 'react-bootstrap';
import Swal from 'sweetalert2';

//* Util
import Utils from '../../common/Utils';

//* API
import { login } from '../../api/user';
import { ROLE } from '../../common/consts';

export default React.memo(function SignIn(props) {
  const history = useHistory();

  //* 로그인 Input
  const [id, setId] = useState();
  const [pw, setPw] = useState();
  const [loginType, setLoginType] = useState(0);
  const [isValid, setIsValid] = useState(true);

  //* [ API ] 로그인
  const loginHandler = async () => {
    if (!Utils.checkNull(id) || !Utils.checkNull(pw)) {
      setIsValid(false);
      Swal.fire({
        text: '아이디 또는 비밀번호를 잘못 입력했습니다.',
        confirmButtonText: '확인',
      });
      return;
    }

    const trimmedUsername = id.trim();
    const trimmedPassword = pw.trim();

    const paramMap = {
      mb_id: trimmedUsername,
      password: trimmedPassword,
      login_type: loginType,
    };

    try {
      const { data } = await login(paramMap); // Axios Post Request

      if (data.code === 0) {
        // 토큰 주입
        localStorage.setItem('access_token', data.data.token.accessToken);
        localStorage.setItem('refresh_token', data.data.token.refreshToken);

        // 메인 이동
        switch (data.data.role) {
          case ROLE.STUDENT:
            window.location.href = '/home';
            break;
          case ROLE.TEACHER:
            window.location.href = '/teacher/studentMgmt/studentMgmt';
            break;
          case ROLE.ADMIN:
            window.location.href = '/admEdu/student/status';
            break;
          case ROLE.ADMIN_SYSTEM:
            window.location.href = '/admSystem/code/main';
            break;
          default:
            break;
        }
      } else {
        setIsValid(false);
      }
    } catch (e) {
      setIsValid(false);
      // alert(e.response.data.message);
    }
  };

  //* 이벤트
  // Tab 변경 (학생, 선생/관리자)
  const changeTabHandler = type => {
    setId('');
    setPw('');
    setLoginType(type === 'S' ? 0 : 1);
  };

  //* return
  return (
    <main id="sign-in">
      <div className="auth-wrap">
        <Card>
          <div className="title">
            <h5>로그인</h5>
          </div>
          <Tab.Container defaultActiveKey="student" id="signin-tab">
            <Nav className="border-tabs">
              <Nav.Item>
                <Nav.Link
                  eventKey="student"
                  onClick={() => changeTabHandler('S')}
                >
                  학생
                </Nav.Link>
                <Nav.Link
                  eventKey="teacher"
                  onClick={() => changeTabHandler('TA')}
                >
                  교사(관리자)
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              {/* ↓ 학생 로그인 */}
              <Tab.Pane eventKey="student">
                <Form>
                  <Form.Control
                    type="text"
                    placeholder="아이디를 입력해주세요."
                    className="my-4"
                    value={id || ''}
                    onChange={e => setId(e.target.value)}
                  />
                  <Form.Control
                    type="password"
                    placeholder="비밀번호를 입력해주세요."
                    className="mb-4"
                    value={pw || ''}
                    onChange={e => setPw(e.target.value)}
                  />
                  {!isValid && (
                    <div className="mb-4">
                      <Form.Text className="text-danger">
                        아이디 또는 비밀번호를 잘못 입력했습니다. <br />
                        입력하신 내용을 다시 확인해주세요.
                      </Form.Text>
                    </div>
                  )}
                  <Button
                    onClick={loginHandler}
                    size="lg"
                    variant="primary"
                    className="mt-2 w-100"
                  >
                    로그인
                  </Button>
                </Form>
              </Tab.Pane>
              {/* ↓ 교사(관리자) 로그인 */}
              <Tab.Pane eventKey="teacher">
                <Form>
                  <Form.Control
                    type="text"
                    placeholder="아이디를 입력해주세요."
                    className="my-4"
                    value={id || ''}
                    onChange={e => setId(e.target.value)}
                  />
                  <Form.Control
                    type="password"
                    placeholder="비밀번호를 입력해주세요."
                    className="mb-4"
                    value={pw || ''}
                    onChange={e => setPw(e.target.value)}
                  />
                  {!isValid && (
                    <div className="mb-4">
                      <Form.Text className="text-danger">
                        아이디 또는 비밀번호를 잘못 입력했습니다. <br />
                        입력하신 내용을 다시 확인해주세요.
                      </Form.Text>
                    </div>
                  )}
                  <Button
                    onClick={loginHandler}
                    size="lg"
                    variant="primary"
                    className="mt-2 w-100"
                  >
                    로그인
                  </Button>
                </Form>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Card>
      </div>
    </main>
  );
});
