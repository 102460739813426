/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import {
  Container,
  Table,
  Card,
  Button,
  Form,
  Row,
  Col,
} from 'react-bootstrap';
import { CustomSelect } from '@components';

//* API
import {
  getSubCode,
  postSubCode,
  patchSubCode,
  deleteSubCode,
} from '@api/sysadmin/code';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';

// 사용 여부 드랍다운
const USE_YN_OPTIONS = [
  { label: '사용', value: 'Y' },
  { label: '미사용', value: 'N' },
];

// 추가, 수정 객체
const initialInput = {
  maCd: '',
  msCd: '',
  msNm: '',
  mappginCd: '',
  useYn: 'Y',
  option: USE_YN_OPTIONS[0],
};

//* [ Main ]
export default React.memo(function AdmSystemCodeDetail() {
  //* #################################################################
  //* [ States ]
  //* #################################################################

  const { maCd = '' } = useParams();

  // 서브코드 리스트
  const [subCodeList, setSubCodeList] = useState([]);

  // 신규등록 Input
  const [addInfo, setAddInfo] = useState(initialInput);

  // 수정 Input
  const [editInfo, setEditInfo] = useState(initialInput);

  //* #################################################################
  //* [ Utils ]
  //* #################################################################
  // 신규등록 > 신규등록 정보입력
  const addCodeHandler = (type, value) => {
    switch (type) {
      // 상세코드
      case 'msCd':
        addInfo.msCd = value;
        setAddInfo({ ...addInfo });
        break;
      // 상세코드명
      case 'msNm':
        addInfo.msNm = value;
        setAddInfo({ ...addInfo });
        break;
      // 사용여부 ( 드랍다운 )
      case 'useYn':
        addInfo.useYn = value.value; // 값
        addInfo.option = value; // 드랍다운
        setAddInfo({ ...addInfo });
        break;
      // 매핑코드
      case 'mappingCd':
        addInfo.mappingCd = value;
        setAddInfo({ ...addInfo });
        break;
      default:
        break;
    }
  };

  // 수정 > 코드 수정 모드 전환
  const editModeHandler = (index, flag) => {
    // 수정 화면 초기화
    const tempArray = [...subCodeList].map(code => {
      const temp = { ...code };
      return { ...temp, isEditing: false };
    });

    // 수정 flag 갱신
    tempArray[index].isEditing = flag;
    setSubCodeList([...tempArray]);

    // 수정 입력값 주입
    if (flag === true) {
      const copyCode = tempArray[index];

      const tempEditInfo = {
        maCd: copyCode.maCd,
        msCd: copyCode.msCd,
        msNm: copyCode.msNm,
        useYn: copyCode.useYn,
        mappingCd: copyCode.mappingCd,
      };

      setEditInfo(tempEditInfo);
    }
  };

  // 수정 > 대분류 코드명 변경
  const codeEditHandler = (type, value) => {
    switch (type) {
      // 상세코드
      // case 'msCd':
      //   editInfo.msCd = value;
      //   setEditInfo({ ...editInfo });
      //   break;
      // 상세코드명
      case 'msNm':
        editInfo.msNm = value;
        setEditInfo({ ...editInfo });
        break;
      // 사용여부
      case 'useYn':
        editInfo.useYn = value.value;
        setEditInfo({ ...editInfo });
        break;
      // 매핑코드
      // case 'mappingCd':
      //   editInfo.mappingCd = value;
      //   setEditInfo({ ...editInfo });
      //   break;
      default:
        break;
    }
  };

  //* #################################################################
  //* [ API ] 서브코드 목록 가져오기
  //* #################################################################
  const getSubCodeList = async () => {
    // Axios
    try {
      const { data } = await getSubCode({
        maCd,
      });

      if (data.code === 0) {
        const list = data.data;

        // 메인코드 리스트
        setSubCodeList([...list]);
      } else {
        setSubCodeList([]);
      }

      // Input 초기화
      setAddInfo({
        maCd,
        msCd: '',
        msNm: '',
        mappginCd: '',
        useYn: 'Y',
        option: USE_YN_OPTIONS[0],
      });
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 신규등록
  //* #################################################################
  const addMainCode = async () => {
    // Null 체크 ( msCd, msNm, mappingCd )
    if (!addInfo.msCd || !addInfo.msNm || !addInfo.mappingCd) {
      Swal.fire({
        text: '입력을 확인해주세요.',
        confirmButtonText: '확인',
      });
      return;
    }

    // 중복체크 ( msCd, msNm, mappingCd )
    if (
      subCodeList.filter(
        code =>
          code.msCd == addInfo.msCd &&
          code.msNm == addInfo.msNm &&
          code.mappingCd == addInfo.mappingCd,
      ).length > 0
    ) {
      Swal.fire({
        text: '중복된 코드입니다.',
        confirmButtonText: '확인',
      });
      return;
    }

    // JSON 파라미터
    const param = {
      maCd,
      msCd: addInfo.msCd,
      msNm: addInfo.msNm,
      useYn: addInfo.useYn,
      mappingCd: addInfo.mappingCd,
    };

    // Axios
    try {
      const { data } = await postSubCode(param);

      if (data.code === 0) {
        Swal.fire({
          text: '코드가 등록되었습니다.',
          confirmButtonText: '확인',
        }).then(async result => {
          if (result.isConfirmed) {
            // 리스트 갱신
            getSubCodeList();
          }
        });
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 수정
  //* #################################################################
  const editMainCode = async code => {
    // 수정 확인 팝업
    if (
      !(await Swal.fire({
        text: '해당 코드를 수정하시겠습니까?',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        showCancelButton: true,
        reverseButtons: true,
      }).then(result => !!result.isConfirmed))
    ) {
      return;
    }

    // 동일값 확인 ( 상세코드명, 사용여부 )
    if (code.msNm === editInfo.msNm && code.useYn === editInfo.useYn) {
      Swal.fire({
        text: '수정된 내용이 없습니다.',
        confirmButtonText: '확인',
      });
      return;
    }

    // JSON 파라미터
    const param = {
      maCd,
      msCd: editInfo.msCd,
      msNm: editInfo.msNm,
      useYn: editInfo.useYn,
      mappingCd: editInfo.mappingCd,
    };

    // Axios
    try {
      const { data } = await patchSubCode(param);

      if (data.code === 0) {
        Swal.fire({
          text: '코드가 수정되었습니다.',
          confirmButtonText: '확인',
        });

        // 리스트 갱신
        getSubCodeList();
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 삭제
  //* #################################################################
  const removeMainCode = async code => {
    // 삭제 확인 팝업
    if (
      !(await Swal.fire({
        text: '해당 코드를 삭제하시겠습니까?',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        showCancelButton: true,
        reverseButtons: true,
      }).then(result => !!result.isConfirmed))
    ) {
      return;
    }

    // Axios
    try {
      const { data } = await deleteSubCode({
        maCd: code.maCd,
        msCd: code.msCd,
        msNm: code.msNm,
        mappingCd: code.mappingCd,
      });

      if (data.code === 0) {
        Swal.fire({
          text: '코드가 삭제되었습니다.',
          confirmButtonText: '확인',
        });

        // 리스트 갱신
        getSubCodeList();
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ useEffect ]
  //* #################################################################
  useEffect(() => {
    getSubCodeList();
  }, []);

  //* #################################################################
  //* [ Components ]
  //* #################################################################
  // 수정 중
  const onEdit = (code, index) => {
    return (
      <Table className="bt-none edit-table" key={`on_${index}`}>
        <tbody>
          <tr>
            {/* 대분류 코드 */}
            <td>대분류 코드</td>
            <td colSpan={3}>
              <Form.Control readOnly defaultValue={code.maCd || ''} />
            </td>
            {/* 버튼 ( 완료, 취소 ) */}
            <td rowSpan={5}>
              <Button
                size="sm"
                style={{ margin: '0 0.3rem' }}
                onClick={() => editMainCode(code)}
              >
                완료
              </Button>
              <Button
                size="sm"
                variant="outline-primary"
                onClick={() => editModeHandler(index, false, code.maCd)}
              >
                취소
              </Button>
            </td>
          </tr>
          {/* 상세코드 */}
          <tr>
            <td>상세코드</td>
            <td colSpan={3}>
              <Form.Control
                defaultValue={code.msCd || ''}
                readOnly
                // value={editInfo.msCd}
                // onChange={e => codeEditHandler('msCd', e.target.value)}
              />
            </td>
          </tr>
          {/* 상세코드명 */}
          <tr>
            <td>상세코드명</td>
            <td colSpan={3}>
              <Form.Control
                value={editInfo.msNm}
                onChange={e => codeEditHandler('msNm', e.target.value)}
              />
            </td>
          </tr>
          {/* 사용여부 */}
          <tr>
            <td>사용여부</td>
            <td colSpan={3}>
              <CustomSelect
                options={USE_YN_OPTIONS}
                value={USE_YN_OPTIONS.filter(
                  option => option.value === editInfo.useYn,
                )}
                onChange={option => codeEditHandler('useYn', option)}
              />
            </td>
          </tr>
          {/* 매핑코드 */}
          <tr>
            <td>매핑코드</td>
            <td colSpan={3}>
              <Form.Control
                defaultValue={code.mappingCd || ''}
                readOnly
                // value={editInfo.mappingCd}
                // onChange={e => codeEditHandler('mappingCd', e.target.value)}
              />
            </td>
          </tr>
        </tbody>
      </Table>
    );
  };

  // 수정 전
  const offEdit = (code, index) => {
    return (
      <Row key={`off_${index}`}>
        <Col>{code.maCd}</Col>
        <Col>{code.msCd}</Col>
        <Col>{code.msNm}</Col>
        <Col>{code.useYn}</Col>
        <Col>
          <Button size="sm" onClick={() => editModeHandler(index, true)}>
            수정
          </Button>
          <Button
            size="sm"
            variant="outline-primary"
            onClick={() => removeMainCode(code)}
          >
            삭제
          </Button>
        </Col>
      </Row>
    );
  };

  //* #################################################################
  //* [ return ]
  //* #################################################################
  return (
    <main id="admSystem-code-detail" className="type-02 admSystem">
      <Container>
        <article className="content py">
          {/* 세션 (1) - 신규등록 */}
          <section>
            <Card>
              <Card.Body>
                <div className="sub-title">
                  <h5>상세코드</h5>
                </div>
                <Table className="bt-none">
                  <tbody>
                    <tr>
                      <th>대분류 코드</th>
                      <td>
                        <Form.Control defaultValue={maCd || ''} readOnly />
                      </td>
                    </tr>
                    <tr>
                      <th>상세코드</th>
                      <td>
                        <Form.Control
                          value={addInfo.msCd || ''}
                          onChange={e => addCodeHandler('msCd', e.target.value)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>상세코드명</th>
                      <td>
                        <Form.Control
                          value={addInfo.msNm || ''}
                          onChange={e => addCodeHandler('msNm', e.target.value)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>사용여부</th>
                      <td>
                        <CustomSelect
                          options={USE_YN_OPTIONS}
                          value={addInfo.option || ''}
                          onChange={option => addCodeHandler('useYn', option)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>매핑코드</th>
                      <td>
                        <Form.Control
                          value={addInfo.mappingCd || ''}
                          onChange={e =>
                            addCodeHandler('mappingCd', e.target.value)
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <div className="btn-area">
                  <Button onClick={addMainCode}>신규등록</Button>
                </div>
              </Card.Body>
            </Card>
          </section>

          {/* 세션 (2) - 리스트 */}
          <section className="mt-3">
            <Card>
              <Card.Body>
                {/* 상세코드테이블 */}
                <Table className="bt-none">
                  <thead>
                    <tr>
                      <th>대분류 코드</th>
                      <th>상세 코드</th>
                      <th>상세 코드명</th>
                      <th>사용여부</th>
                      <th> </th>
                    </tr>
                  </thead>
                </Table>
                {/* 상세코드목록 */}
                <div className="row-table detail">
                  {subCodeList.length > 0 &&
                    subCodeList.map((code, index) => {
                      return !code.isEditing ? (
                        <>{offEdit(code, index)}</>
                      ) : (
                        <>
                          {offEdit(code, index)}
                          {onEdit(code, index)}
                        </>
                      );
                    })}
                </div>
              </Card.Body>
            </Card>
          </section>
        </article>
      </Container>
    </main>
  );
});
