/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useState, useRef, useEffect } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import { Container, Button, Table, Modal, Form, Image } from 'react-bootstrap';
import { CustomModal, CustomSelect } from '@components';
import { images } from '@assets';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Swal from 'sweetalert2';

//* Utils
import Utils from '@common/Utils';

//* Consts
import { FormStatus, CERT_GUBUN, CERT_TYPE } from '@common/consts'; // Form 상태 text, class

//* API
import {
  selectCertificateList,
  insertUpdateCertificate,
} from '@api/student/majorJobCert';
import { LOGIN_TYPE, ROLE } from '../../../common/consts';

//* [ Main ]
export default React.memo(function MajorJob(props) {
  //* #################################################################
  //* [ States ]
  //* #################################################################
  const history = useHistory();

  const { loginType, studentIdx } = useParams();

  const authType = props.userInfo.type;

  if (loginType !== LOGIN_TYPE.STUDENT && loginType !== LOGIN_TYPE.TEACHER) {
    Swal.fire({
      text: '잘못된 접근입니다.',
      confirmButtonText: '확인',
    });
    history.goBack();
  }

  if (loginType === LOGIN_TYPE.TEACHER && !window.parseInt(studentIdx)) {
    Swal.fire({
      text: '잘못된 접근입니다.',
      confirmButtonText: '확인',
    });
    history.goBack();
  }

  const today = new Date();
  // const yesterday = new Date(today.setDate(today.getDate() - 1));

  // 결과 보고서
  const [certForm, setCertForm] = useState({});

  // 신규등록 변수
  const [certDate, setCertDate] = useState(today); // 취득일
  const [certGubun, setCertGubun] = useState(null); // 자격증 구분
  const [certType, setCertType] = useState(null); // 자격증 종류
  const [certName, setCertName] = useState(); // 자격증 이름
  const [certNumber, setCertNumber] = useState(); // 자격증 번호
  const [certGov, setCertGov] = useState(); // 발급 기관

  const [files, setFiles] = useState([]); // 증빙자료 (복수)
  const fileRef = useRef(''); // 증빙자료

  // 중복요청 방지
  const [isSentRequest, setIsSentRequest] = useState(false);

  //* #################################################################
  //* [ Modal ]
  //* #################################################################

  const [showWriteCertModal, setShowWriteCertModal] = useState(false);

  // 모달 Handler
  const handleShowWriteCertModal = () => {
    // Input 초기화
    setCertDate(today);
    setCertGubun(null);
    setCertType(null);
    setCertName();
    setCertNumber();
    setCertGov();
    setFiles([]);

    // Open
    setShowWriteCertModal(true);
  };

  //* #################################################################
  //* [ Utils ]
  //* #################################################################
  // 파일 첨부
  const addFile = e => {
    if (files.length >= 2) {
      Swal.fire({
        text: '첨부 파일은 2개까지 등록할 수 있습니다.',
        confirmButtonText: '확인',
      });
      return;
    }

    if (e.target.files[0]) setFiles([...files, e.target.files[0]]);
    fileRef.current.value = null;
  };

  // 첨부 파일 제거
  const removeFile = idx => {
    const filteredList = files.filter((file, index) => index !== idx);
    setFiles([...filteredList]);
  };

  //* #################################################################
  //* [ API ] 전공 자격증 리스트 - SELECT
  //* #################################################################
  const [firstCallDone, setFirstCallDone] = useState(false);
  const selectCertificateSummary = async () => {
    // Axios
    try {
      const { data } = await selectCertificateList();

      if (data.code === 0) {
        setFirstCallDone(true);
        // 리스트 데이터 후처리
        const list = data.data;

        if (list.length > 0) {
          const formMap = {};

          // 전공 자격증 이름 ( Null > default )
          let defaultName = '전공 자격증';

          // 데이터 후처리
          for (let i = 0; i < list.length; i += 1) {
            const { grade, point, status, certificate_name: name } = list[i];

            if (name) {
              defaultName = name;
            }

            // 기존
            if (formMap && formMap[grade] !== undefined) {
              const prevStatus = formMap[grade].status;
              const sortedStatus = Utils.sortFormStatus(prevStatus, status);

              formMap[grade].titleName = defaultName;
              formMap[grade].point += parseFloat(point);
              formMap[grade].status = sortedStatus;
              formMap[grade].class = FormStatus[sortedStatus].class;
              formMap[grade].count += 1;
            }
            // 신규
            else {
              formMap[grade] = {
                titleName: defaultName,
                status,
                point,
                class: FormStatus[status].class,
                count: 0,
              };
            }
          }

          // Form 데이터 갱신
          setCertForm(formMap);
        }
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 전공 자격증 신규등록 - INSERT
  //* #################################################################
  const insertForm = async reqStatus => {
    // 중복요청 확인
    if (isSentRequest) {
      return;
    }

    // 필수값 체크
    if (!certGubun || !certType) {
      Swal.fire({
        text: '자격증 구분 및 유형을 확인해주세요.',
        confirmButtonText: '확인',
      });
      return;
    }

    if (reqStatus === 'ING') {
      // 파라미터 설정
      const param = {
        status: reqStatus,
        certificate_date: certDate,
        certificate_gubun: certGubun.value,
        certificate_type: certType.value,
        certificate_name: certName,
        certificate_number: certNumber,
        certificate_gov: certGov,
      };

      // FormData
      const formData = new FormData();

      // File append
      for (let i = 0; i < files.length; i += 1) {
        formData.append('files', files[i]);
      }

      // JSON append
      formData.append(
        'param',
        new Blob([JSON.stringify(param)], { type: 'application/json' }),
      );

      // 중복요청 방지
      setIsSentRequest(true);

      // Axios
      try {
        const { data } = await insertUpdateCertificate(formData);

        if (data.code === 0) {
          // 리스트 갱신
          selectCertificateSummary();

          // 모달 닫기
          setShowWriteCertModal(false);

          // 중복요청 방지 해제
          setIsSentRequest(false);
        }
      } catch (e) {
        // 중복요청 방지 해제
        setIsSentRequest(false);

        // alert(e.response.data.message);
      }
    } else {
      Swal.fire({
        text: '입력을 확인해주세요.',
        confirmButtonText: '확인',
      });
    }
  };

  //* #################################################################
  //* [ useEffect ]
  //* #################################################################
  useEffect(() => {
    if (loginType === LOGIN_TYPE.STUDENT) {
      selectCertificateSummary();
    }
    if (loginType === LOGIN_TYPE.TEACHER) {
      // selectCertificateSummary();
    }

    return () => {
      setCertForm({});
    };
  }, [loginType]);

  const [linkList, setLinkList] = useState([]);

  useEffect(() => {
    switch (authType) {
      case ROLE.ADMIN:
        setLinkList([
          {
            name: '프로그램 현황',
            path: '/admEdu/student/program',
          },
        ]);
        break;
      case ROLE.TEACHER:
        setLinkList([
          {
            name: '학생 관리',
            path: '/teacher/studentMgmt/studentMgmt',
          },
        ]);
        break;
      case ROLE.STUDENT:
        setLinkList([
          {
            name: 'HOME',
            path: '/',
          },
        ]);
        break;
      default:
        break;
    }
  }, [authType]);

  //* #################################################################
  //* [ return ]
  //* #################################################################
  return (
    <main id="student-majorJob">
      <Container>
        <article className="content">
          <ul className="path">
            {linkList &&
              linkList.length > 0 &&
              linkList.map((v, i) => {
                return (
                  <li key={`link_${i}`}>
                    <Link to={v.path}>{v.name}</Link>
                  </li>
                );
              })}
            <li>
              <Link to="#none">전공 자격증 취득</Link>
            </li>
          </ul>

          {/* ////////// ↓ 상단 타이틀영역 ////////// */}
          <section>
            <div className="title">
              <h5>전공 자격증 취득</h5>
              <Button
                // onClick={() => handleShowWriteCertModal()}
                onClick={() => history.push('/student/majorJobCert/register')}
                variant="primary"
              >
                신규작성
              </Button>
            </div>
          </section>

          {/* ////////// ↓ 컨텐츠 ////////// */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>결과 보고서</h5>
            </div>
            <Table>
              <colgroup>
                <col width={200} />
                <col width="*" />
                <col width={150} />
                <col width={200} />
              </colgroup>
              <thead>
                <tr>
                  <th>구분</th>
                  <th>자격증</th>
                  <th>상태</th>
                  <th>점수</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(certForm).length > 0
                  ? Object.keys(certForm).map((grade, idx) => {
                      return (
                        <tr
                          key={idx}
                          className="go-detail"
                          onClick={() =>
                            props.history.push(
                              `/student/majorJobCert/report/${grade}`,
                            )
                          }
                        >
                          {/* 학년 */}
                          <td>{`${grade}학년`}</td>
                          {/* 자격증 */}
                          <td>
                            {certForm[grade].count === 0
                              ? certForm[grade].titleName
                              : `${certForm[grade].titleName} 외 ${certForm[grade].count}개`}
                          </td>
                          {/* 승인 최소 상태 */}
                          <td>
                            <strong className={certForm[grade].class}>
                              {FormStatus[certForm[grade].status].text}
                            </strong>
                          </td>
                          {/* 승인 점수 총계 */}
                          <td>{certForm[grade].point}</td>
                        </tr>
                      );
                    })
                  : firstCallDone && (
                      <tr>
                        <td colSpan="4" className="no-data">
                          작성한 결과 보고서가 없습니다.
                        </td>
                      </tr>
                    )}
              </tbody>
            </Table>
          </section>
        </article>
      </Container>

      {/* 자격증 작성 모달 */}
      <CustomModal
        id="writeCertModal"
        title="취득 자격증"
        show={showWriteCertModal}
        onHide={() => setShowWriteCertModal(false)}
        size="sm"
      >
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>취득일</Form.Label>
            <div className="w-100">
              <DatePicker
                className="input-datepicker"
                name="acquisition-date"
                dateFormat="yyyy.MM.dd"
                selected={certDate}
                onChange={date => setCertDate(date)}
                // maxDate={today}
                customInput={<Form.Control style={{ width: '100%' }} />}
                showPopperArrow={false}
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>구분</Form.Label>
            <CustomSelect
              options={CERT_GUBUN}
              value={certGubun}
              onChange={setCertGubun}
              placeholder="자격증 구분값을 선택하세요."
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>종류</Form.Label>
            <CustomSelect
              options={CERT_TYPE}
              value={certType}
              onChange={setCertType}
              placeholder="자격증 타입을 선택하세요."
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>자격증명</Form.Label>
            <Form.Control
              type="text"
              placeholder="자격증명을 입력하세요."
              onChange={e => setCertName(e.target.value)}
              maxLength={20}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>자격증 번호</Form.Label>
            <Form.Control
              type="text"
              placeholder="자격증 번호를 입력하세요."
              onChange={e =>
                setCertNumber(
                  e.target.value.replace(/[^a-zA-Z0-9-]/g, '').toUpperCase(),
                )
              }
              value={certNumber}
              maxLength={30}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>발급기관</Form.Label>
            <Form.Control
              type="text"
              placeholder="발급기관을 입력하세요."
              onChange={e => setCertGov(e.target.value)}
              maxLength={20}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>증빙자료</Form.Label>
            {/* ↓ 첨부된 파일 */}
            <ul className="drag-attachment-list sm mb-3">
              {files.map((file, idx) => {
                return (
                  <li key={idx}>
                    <p className="text-primary">{file.name}</p>
                    <Button variant="iconOnly">
                      <Image
                        src={images.icCancel}
                        onClick={() => removeFile(idx)}
                      />
                    </Button>
                  </li>
                );
              })}
            </ul>
            {/* ↓ 파일 첨부 */}
            <Button
              onClick={() => fileRef.current.click()}
              variant="outline-primary"
              className="d-block"
            >
              파일첨부
            </Button>
            <Form.Control
              ref={fileRef}
              type="file"
              // accept="image/*, .pdf"
              style={{ display: 'none' }}
              onChange={addFile}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => insertForm('ING')}>
            저장
          </Button>
        </Modal.Footer>
      </CustomModal>
    </main>
  );
});
