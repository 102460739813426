import Axios from '@api/axios';

const API_PROGRAM = 'api/v1/edu/teacher';

const TEACHERS = `${API_PROGRAM}/list`;
const RESET_TEACHER_PASSWORD = `${API_PROGRAM}/reset-teacher-password`;
const TEACHER_UPDATE = `${API_PROGRAM}/teacher`;

// [교사관리] 교사 리스트 조회
export const getTeacherList = data => {
  return Axios.post(`${TEACHERS}`, data);
};

// [교사관리] 학교 관리자 비밀번호 초기화
export const resetPw = id => {
  return Axios.post(`${RESET_TEACHER_PASSWORD}/${id}`);
};

// [교사관리] 학교 관리자 수정
export const patchTeacher = data => {
  return Axios.patch(`${TEACHER_UPDATE}`, data);
};

// [교사관리] 학교 관리자 등록
export const putTeacher = data => {
  return Axios.put(`${TEACHER_UPDATE}`, data);
};
