/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Container, Button, Row, Col, Table } from 'react-bootstrap';

//* API
import { selectDashboardInfo } from '@api/student/home';

export default React.memo(function UseInfo(props) {
  const history = useHistory();
  const pageRefresh =
    (history.location.state && history.location.state.pageRefresh) || false;

  const [selectedGrade, setSelectedGrade] = useState(null);

  //* #################################################################
  //* [ API ] 개인정보 조회
  //* #################################################################
  const getBoardInfo = async () => {
    // Axios
    try {
      const { data } = await selectDashboardInfo({});

      if (data.code === 0) {
        const {
          userName = '',
          schoolName = '',
          userMajor = '',
          userGrade = '',
          userBan = '',
          userNum = '',
          pointSum = 0,
          voucherSum = 0,
        } = data.data;

        // 선택 학년
        setSelectedGrade(+userGrade);
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  useEffect(() => {
    getBoardInfo();
  }, [pageRefresh]);

  // useEffect(() => {
  //   console.log('your grade :::: ', selectedGrade);
  // }, [selectedGrade]);

  return (
    <main id="service-archive">
      <Container>
        <article className="content">
          <ul className="path">
            <li>
              <Link to="/">HOME</Link>
            </li>
            <li>
              <Link to="#none">이용 안내 영상</Link>
            </li>
          </ul>

          {/* ////////// ↓ 상단 타이틀영역 ////////// */}
          <section>
            <div className="title">
              <h5>이용 안내 영상</h5>
            </div>
          </section>

          <section>
            {selectedGrade === 1 && (
              <>
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <video
                  src="/videos/grade_1_240710.mp4"
                  controls
                  width="1280"
                  height="720"
                >
                  video is not loaded
                </video>
              </>
            )}
            {selectedGrade === 2 && (
              <>
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <video
                  src="/videos/grade_2_240710.mp4"
                  controls
                  width="1280"
                  height="720"
                >
                  video is not loaded
                </video>
              </>
            )}
            {selectedGrade === 3 && (
              <>
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <video
                  src="/videos/grade_3_240710.mp4"
                  controls
                  width="1280"
                  height="720"
                >
                  video is not loaded
                </video>
              </>
            )}
          </section>
        </article>
      </Container>
    </main>
  );
});
