import React from 'react';
import Router from './router/Router';

function App() {
  //* JWT 토큰 (Access Token, Refresh Token 확인)
  const isAuthorized =
    localStorage.getItem('access_token') != null &&
    localStorage.getItem('refresh_token');

  //* return
  return (
    <div className="App">
      <Router isAuthorized={isAuthorized} />
    </div>
  );
}

export default React.memo(App);
