import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Table,
  Card,
  Form,
  Button,
  Row,
  Col,
} from 'react-bootstrap';
import { CustomSelect } from '@components';
import Swal from 'sweetalert2';

//* API
import {
  getMainCode,
  searchCode,
  putRedis,
  postMainCode,
  patchMainCode,
  deleteMainCode,
} from '@api/sysadmin/code';

// 사용 여부 드랍다운
const USE_YN_OPTIONS = [
  { label: '사용', value: 'Y' },
  { label: '미사용', value: 'N' },
];

// 검색 객체
const initialSearchInfo = {
  filter: 'maCd',
  keyword: '',
};

// 추가, 수정 객체
const initialInput = {
  maCd: '',
  maNm: '',
  useYn: 'Y',
  memo: 'N',
  option: USE_YN_OPTIONS[0],
};

//* [ Main ]
export default React.memo(function AdmSystemCodeMain() {
  //* #################################################################
  //* [ States ]
  //* #################################################################

  // 메인코드 리스트
  const [mainCodeList, setMainCodeList] = useState([]);

  // 검색 옵션
  const [searchInfo, setSearchInfo] = useState(initialSearchInfo);

  // 신규등록 Input
  const [addInfo, setAddInfo] = useState(initialInput);

  // 수정 Input
  const [editInfo, setEditInfo] = useState(initialInput);

  //* #################################################################
  //* [ Utils ]
  //* #################################################################
  // 신규등록 > 신규등록 정보입력
  const addCodeHandler = (type, value) => {
    switch (type) {
      // 대분류 코드 ( 텍스트 )
      case 'maCd':
        addInfo[type] = value;
        setAddInfo({ ...addInfo });
        break;
      // 대분류 코드명 ( 텍스트 )
      case 'maNm':
        addInfo[type] = value;
        setAddInfo({ ...addInfo });
        break;
      // 사용여부 ( 드랍다운 )
      case 'useYn':
        addInfo[type] = value.value; // 값
        addInfo.option = value; // 드랍다운
        setAddInfo({ ...addInfo });
        break;
      // 메모 ( 체크박스 )
      case 'memo':
        addInfo[type] = value ? 'Y' : 'N';
        setAddInfo({ ...addInfo });
        break;
      default:
        break;
    }
  };

  // 수정 > 코드 수정 모드 전환
  const editModeHandler = (index, flag) => {
    // 수정 화면 초기화
    const tempArray = [...mainCodeList].map(code => {
      const temp = { ...code };
      return { ...temp, isEditing: false };
    });

    // 수정 flag 갱신
    tempArray[index].isEditing = flag;
    setMainCodeList([...tempArray]);

    // 수정 입력값 주입
    if (flag === true) {
      const copyCode = tempArray[index];

      const tempEditInfo = {
        maCd: copyCode.maCd,
        maNm: copyCode.maNm,
        useYn: copyCode.useYn,
        memo: copyCode.memo,
      };

      setEditInfo(tempEditInfo);
    }
  };

  // 수정 > 사용 여부 체크
  const useYnHandler = option => {
    editInfo.useYn = option.value;
    setEditInfo({ ...editInfo });
  };

  // 수정 > 대분류 코드명 변경
  const codeNameHandler = value => {
    editInfo.maNm = value;
    setEditInfo({ ...editInfo });
  };

  // 수정 > 메모 변경 ( 테이블 메핑 정보 )
  const memoHandler = isChecked => {
    editInfo.memo = isChecked ? 'Y' : 'N';
    setEditInfo({ ...editInfo });
  };

  // 검색 > 검색 옵션 지정 ( 필터 - filter , 검색어 - input )
  const searchHandler = (type, value) => {
    // 필터 선택
    if (type === 'filter') {
      searchInfo.filter = value;

      setSearchInfo({
        ...searchInfo,
      });
    }
    // 검색어 입력
    else if (type === 'input') {
      searchInfo.keyword = value;

      setSearchInfo({
        ...searchInfo,
      });
    }
  };

  //* #################################################################
  //* [ API ] 신규등록
  //* #################################################################
  const addMainCode = async () => {
    // 중복체크 ( maCd )
    if (mainCodeList.filter(code => code.maCd === addInfo.maCd).length > 0) {
      Swal.fire({
        text: '중복된 대분류 코드입니다.',
        confirmButtonText: '확인',
      });
      return;
    }

    // JSON 파라미터
    const param = {
      maCd: addInfo.maCd,
      maNm: addInfo.maNm,
      useYn: addInfo.useYn,
      memo: addInfo.memo === 'Y' ? 'TABLE' : '',
    };

    // Axios
    try {
      const { data } = await postMainCode(param);

      if (data.code === 0) {
        Swal.fire({
          text: '코드가 등록되었습니다.',
          confirmButtonText: '확인',
        });

        // 리스트 갱신
        getMainCodeList();
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 메인코드 목록 가져오기
  //* #################################################################
  const getMainCodeList = async () => {
    // Axios
    try {
      const { data } = await getMainCode();

      if (data.code === 0) {
        const list = data.data;

        // 메인코드 리스트
        setMainCodeList([...list]);
      } else {
        setMainCodeList([]);
      }

      // Input 초기화
      setAddInfo({
        maCd: '',
        maNm: '',
        useYn: 'Y',
        memo: 'N',
        option: USE_YN_OPTIONS[0],
      });
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 검색
  //* #################################################################
  const searchMainCodeList = async () => {
    // Axios
    try {
      const { data } = await searchCode({
        filter: searchInfo.filter,
        keyword: searchInfo.keyword,
      });

      if (data.code === 0) {
        const list = data.data;

        // 메인코드 리스트
        setMainCodeList([...list]);
      } else {
        setMainCodeList([]);
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] Redis 업데이트
  //* #################################################################
  const updateRedisData = async () => {
    // Axios
    try {
      const { data } = await putRedis();

      if (data.code === 0) {
        Swal.fire({
          text: '레디스 데이터가 업데이트되었습니다.',
          confirmButtonText: '확인',
        });
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 수정
  //* #################################################################
  const editMainCode = async () => {
    // 수정 확인 팝업
    if (
      !(await Swal.fire({
        text: '해당 코드를 수정하시겠습니까?',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        showCancelButton: true,
        reverseButtons: true,
      }).then(result => !!result.isConfirmed))
    ) {
      return;
    }

    // JSON 파라미터
    const param = {
      maCd: editInfo.maCd,
      maNm: editInfo.maNm,
      useYn: editInfo.useYn,
      memo: editInfo.memo === 'Y' ? 'TABLE' : '',
    };

    // Axios
    try {
      const { data } = await patchMainCode(param);

      if (data.code === 0) {
        Swal.fire({
          text: '코드가 수정되었습니다.',
          confirmButtonText: '확인',
        });

        // 리스트 갱신
        getMainCodeList();
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 삭제
  //* #################################################################
  const removeMainCode = async code => {
    // 삭제 확인 팝업
    if (
      !(await Swal.fire({
        text: '해당 코드를 삭제하시겠습니까?',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        showCancelButton: true,
        reverseButtons: true,
      }).then(result => !!result.isConfirmed))
    ) {
      return;
    }

    // Axios
    try {
      const { data } = await deleteMainCode({ maCd: code.maCd });

      if (data.code === 0) {
        Swal.fire({
          text: '코드가 삭제되었습니다.',
          confirmButtonText: '확인',
        });

        // 리스트 갱신
        getMainCodeList();
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ useEffect ]
  //* #################################################################
  useEffect(() => {
    getMainCodeList();
  }, []);

  //* #################################################################
  //* [ Components ]
  //* #################################################################
  // 수정 중
  const onEdit = (code, index) => {
    return (
      <Table className="bt-none edit-table" key={`on_${index}`}>
        <tbody>
          <tr>
            {/* 대분류 코드 */}
            <td>대분류 코드</td>
            <td colSpan={2}>
              <Form.Control readOnly defaultValue={code.maCd} />
            </td>
            {/* 버튼 ( 완료, 취소 ) */}
            <td rowSpan={4}>
              <Button
                size="sm"
                style={{ margin: '0 0.3rem' }}
                onClick={editMainCode}
              >
                완료
              </Button>
              <Button
                size="sm"
                variant="outline-primary"
                onClick={() => editModeHandler(index, false, code.maCd)}
              >
                취소
              </Button>
            </td>
          </tr>
          {/* 대분류 코드명 */}
          <tr>
            <td>대분류 코드명</td>
            <td colSpan={2}>
              <Form.Control
                value={editInfo.maNm}
                onChange={e => codeNameHandler(e.target.value)}
              />
            </td>
          </tr>
          {/* 사용여부 */}
          <tr>
            <td>사용여부</td>
            <td colSpan={2}>
              <CustomSelect
                options={USE_YN_OPTIONS}
                value={USE_YN_OPTIONS.filter(
                  option => option.value === editInfo.useYn,
                )}
                onChange={option => useYnHandler(option)}
              />
            </td>
          </tr>
          {/* 메모 */}
          <tr>
            <td>메모</td>
            <td colSpan={2}>
              <Form.Check
                type="checkbox"
                label="테이블 매핑 유무"
                checked={editInfo.memo === 'Y'}
                onChange={e => memoHandler(e.target.checked)}
              />
            </td>
          </tr>
        </tbody>
      </Table>
    );
  };

  // 수정 전
  const offEdit = (code, index) => {
    return (
      <Row key={`off_${index}`}>
        <Col>{code.maCd}</Col>
        <Col>
          <Link to={`/admSystem/code/detail/${code.maCd}`}>{code.maNm}</Link>
        </Col>
        <Col>{code.useYn}</Col>
        <Col>
          <Button size="sm" onClick={() => editModeHandler(index, true)}>
            수정
          </Button>
          <Button
            size="sm"
            variant="outline-primary"
            onClick={() => removeMainCode(code)}
          >
            삭제
          </Button>
        </Col>
      </Row>
    );
  };

  //* #################################################################
  //* [ return ]
  //* #################################################################
  return (
    <main id="admSystem-code-main" className="type-02 admSystem">
      <Container>
        <article className="content py">
          {/* 세션 (1) - 신규등록 */}
          <section>
            <Card>
              <Card.Body>
                <div className="sub-title">
                  <h5>코드추가</h5>
                </div>
                <Table className="bt-none">
                  <colgroup>
                    <col width={100} />
                    <col width="*" />
                    <col width={100} />
                    <col width="*" />
                    <col width={100} />
                    <col width="*" />
                    <col width={100} />
                    <col width="*" />
                  </colgroup>
                  <tbody>
                    <tr>
                      {/* 코드 추가 - 대분류 코드명 */}
                      <th>대분류 코드</th>
                      <td>
                        <Form.Control
                          value={addInfo.maCd}
                          onChange={e => addCodeHandler('maCd', e.target.value)}
                        />
                      </td>
                      {/* 코드 추가 - 대분류 코드 */}
                      <th>대분류 코드명</th>
                      <td>
                        <Form.Control
                          value={addInfo.maNm}
                          onChange={e => addCodeHandler('maNm', e.target.value)}
                        />
                      </td>
                      {/* 코드 추가 - 사용여부 */}
                      <th>사용여부</th>
                      <td>
                        <CustomSelect
                          options={USE_YN_OPTIONS}
                          value={addInfo.option}
                          onChange={option => addCodeHandler('useYn', option)}
                        />
                      </td>
                      {/* 코드 추가 - 메모 */}
                      <th>메모</th>
                      <td>
                        {/* <Form.Control /> */}
                        <Form.Check
                          type="checkbox"
                          label="테이블 매핑 유무"
                          checked={addInfo.memo === 'Y'}
                          onChange={e =>
                            addCodeHandler('memo', e.target.checked)
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <div className="btn-area">
                  <Button onClick={addMainCode}>신규등록</Button>
                </div>
              </Card.Body>
            </Card>
          </section>
          {/* 세션 (2) - 검색, 리스트 */}
          <section className="mt-3">
            <Card>
              <Card.Body>
                {/* 검색 */}
                <div className="dl-box">
                  <dl>
                    <dt>코드검색</dt>
                    <dd className="flex-start">
                      <Form.Check
                        type="radio"
                        label="코드"
                        name="filter"
                        id="searchFilter-code"
                        value="maCd"
                        onChange={e =>
                          searchHandler(e.target.name, e.target.value)
                        }
                        checked={searchInfo.filter === 'maCd'}
                      />
                      <Form.Check
                        type="radio"
                        label="코드명"
                        name="filter"
                        id="searchFilter-codeName"
                        value="maNm"
                        onChange={e =>
                          searchHandler(e.target.name, e.target.value)
                        }
                        checked={searchInfo.filter === 'maNm'}
                      />
                      <Form.Check
                        type="radio"
                        label="사용여부"
                        name="filter"
                        id="searchFilter-isUsed"
                        value="useYn"
                        onChange={e =>
                          searchHandler(e.target.name, e.target.value)
                        }
                        checked={searchInfo.filter === 'useYn'}
                      />
                    </dd>
                  </dl>
                  {/* 검색어 입력 */}
                  <dl className="mt-2">
                    <dt>검색어</dt>
                    <dd className="flex-start">
                      <Form.Control
                        className="input-search"
                        placeholder="검색할 내용을 입력하세요."
                        name="input"
                        value={searchInfo.keyword}
                        onChange={e =>
                          searchHandler(e.target.name, e.target.value)
                        }
                      />
                      <div className="btn-area d-inline-flex m-0">
                        <Button onClick={searchMainCodeList}>검색</Button>
                        <Button onClick={updateRedisData}>
                          Redis 업데이트
                        </Button>
                      </div>
                    </dd>
                  </dl>
                </div>

                {/* 코드 목록 - 헤더 */}
                <Table className="bt-none mt-5">
                  <thead>
                    <tr>
                      <th>대분류 코드</th>
                      <th>대분류 코드명</th>
                      <th>사용여부</th>
                      <th> </th>
                    </tr>
                  </thead>
                </Table>

                {/* 코드 목록 - 컨텐츠 ( 수전 전, 수정 중 ) */}
                <div className="row-table main">
                  {mainCodeList.length > 0 &&
                    mainCodeList.map((code, index) => {
                      return !code.isEditing ? (
                        <>{offEdit(code, index)}</>
                      ) : (
                        <>
                          {offEdit(code, index)}
                          {onEdit(code, index)}
                        </>
                      );
                    })}
                </div>
              </Card.Body>
            </Card>
          </section>
        </article>
      </Container>
    </main>
  );
});
