import Axios from '@api/axios';

const API_ARCHIVE = 'api/v1/board/archive';

const formDataConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

// [게시물 관리 > 교육자료실] 교육자료 리스트 조회
export const getArchiveList = data => {
  return Axios.get(`${API_ARCHIVE}/list`, { params: data });
};

// [게시물 관리 > 교육자료실] 교육자료 상세 조회
export const getArchiveDetail = seq => {
  return Axios.get(`${API_ARCHIVE}/${seq}`);
};

// [게시물 관리 > 교육자료실] 교육자료 등록
export const putArchive = data => {
  return Axios.put(`${API_ARCHIVE}`, data, formDataConfig);
};
// [게시물 관리 > 교육자료실] 교육자료 수정
export const patchArchive = data => {
  return Axios.patch(`${API_ARCHIVE}`, data, formDataConfig);
};

// [게시물 관리 > 교육자료실] 교육자료 삭제
export const deleteArchive = seq => {
  return Axios.delete(`${API_ARCHIVE}/${seq}`);
};

// [게시물 관리 > 교육자료실] 교육자료 메인 등록/등록취소
export const patchArchiveMainYn = data => {
  return Axios.patch(`${API_ARCHIVE}/update-main`, data);
};
