import Axios from '@api/axios';
import axios from 'axios';

const API_PROGRAM = 'api/v1/edu/research';

const RESEARCHS = `${API_PROGRAM}/list`;
const RESEARCH = `${API_PROGRAM}/research`;

const formDataConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

// [만족도 조사 관리] 만족도 조사 리스트 조회
export const getResearchList = data => {
  return Axios.get(`${RESEARCHS}`, { params: data });
};

// [만족도 조사 관리] 만족도 조사 추가
export const postResearch = data => {
  return Axios.post(`${RESEARCH}`, data);
};

// [만족도 조사 관리] 만족도 조사 수정
export const putResearch = data => {
  return Axios.put(`${RESEARCH}`, data);
};
