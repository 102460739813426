/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import { Container, Button, Table } from 'react-bootstrap';

//* API
import {
  selectEduProgramList,
  insertUpdateEduProgram,
} from '@api/student/selfEduProgram';

//* CONSTS
import { FormStatus } from '@common/consts';
import { LOGIN_TYPE, ROLE } from '../../../common/consts';
import { selectEduProgramListByTeacher } from '../../../api/teacher/selfEduProgram';

//* Utils
import Utils from '@common/Utils';
import Swal from 'sweetalert2';

//* Main
export default React.memo(function SelfEduProgram(props) {
  //* #################################################################
  //* [ States ]
  //* #################################################################
  const history = useHistory();

  const { loginType, studentIdx, grade } = useParams();

  const authType = props.userInfo.type;

  if (loginType !== LOGIN_TYPE.STUDENT && loginType !== LOGIN_TYPE.TEACHER) {
    Swal.fire({
      text: '잘못된 접근입니다.',
      confirmButtonText: '확인',
    });
    history.goBack();
  }

  if (loginType === LOGIN_TYPE.TEACHER && !window.parseInt(studentIdx)) {
    Swal.fire({
      text: '잘못된 접근입니다.',
      confirmButtonText: '확인',
    });
    history.goBack();
  }

  //* 리스트
  const [programList, setProgramList] = useState([]);

  // 중복요청 방지
  const [isSentRequest, setIsSentRequest] = useState(false);

  // type
  // const [gradeType, setGradeType] = useState('freshman'); // freshman/junior/senior

  //* #################################################################
  //* [ API ] 직업기초 능력평가 리스트 조회
  //* #################################################################
  const [firstCallDone, setFirstCallDone] = useState(false);
  const getProgramList = async () => {
    // 파라미터 설정
    const param = {
      grade,
    };

    // Axios
    try {
      const { data } = await selectEduProgramList(param);

      if (data.code === 0) {
        setFirstCallDone(true);
        const list = data.data;

        if (list.length > 0) {
          setProgramList(list);
        }
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  const getProgramListByTeacher = async () => {
    // 파라미터 설정
    const param = {
      studentIdx,
    };

    // Axios
    try {
      const { data } = await selectEduProgramListByTeacher(param);

      if (data.code === 0) {
        setFirstCallDone(true);
        const list = data.data;

        if (list.length > 0) {
          setProgramList(list);
        }
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 요청 취소, 삭제
  //* #################################################################
  const updateRequest = async (formSeq, reqStatus) => {
    // 중복요청 확인
    if (isSentRequest) {
      return;
    }

    // Confirm 메세지
    const confirmMessage =
      reqStatus === 'ING'
        ? '승인요청을 취소하시겠습니까?'
        : '삭제하시겠습니까?';

    // eslint-disable-next-line no-restricted-globals
    if (
      await Swal.fire({
        text: confirmMessage,
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        showCancelButton: true,
        reverseButtons: true,
      }).then(result => !!result.isConfirmed)
    ) {
      // 파라미터 설정
      const param = {
        formSeq,
        status: reqStatus !== 'CAN' ? reqStatus : 'ING',
      };

      // FormData
      const formData = new FormData();

      // JSON append
      formData.append(
        'param',
        new Blob([JSON.stringify(param)], { type: 'application/json' }),
      );

      // 중복요청 방지
      setIsSentRequest(true);

      // Axios
      try {
        const { data } = await insertUpdateEduProgram(formData);

        if (data.code === 0) {
          // 프로그램 리스트 갱신
          getProgramList();

          // 중복요청 방지 해제
          setIsSentRequest(false);
        }
      } catch (e) {
        // 중복요청 방지 해제
        setIsSentRequest(false);

        // alert(e.response.data.message);
      }
    }
  };

  //* #################################################################
  //* [ useEffect ]
  //* #################################################################
  useEffect(() => {
    // 페이지 접근 검증 (학년)
    const validGrades = ['1', '2', '3'];

    if (!validGrades.includes(grade)) {
      Swal.fire({
        text: '잘못된 접근입니다.',
        confirmButtonText: '확인',
      }).then(result => {
        if (result.isConfirmed) {
          history.goBack();
        }
      });
    }

    // 리스트 출력
    if (loginType === LOGIN_TYPE.STUDENT) {
      getProgramList();
    }
    if (loginType === LOGIN_TYPE.TEACHER) {
      getProgramListByTeacher();
    }

    // 초기화 옵션
    return () => {
      setProgramList([]);
    };
  }, [grade, loginType]);

  //* #################################################################
  //* [ Components ]
  //* #################################################################
  // 버튼 (삭제, 수정, 취소)
  const customButton = (type, seq) => {
    let variant;
    let event;
    let text;

    switch (type) {
      case 'delete':
        variant = 'outline-primary';
        event = e => {
          e.stopPropagation();

          updateRequest(seq, 'DEL');
        };
        text = '삭제';
        break;
      case 'edit':
        variant = 'primary';
        event = e => {
          e.stopPropagation();

          history.push({
            pathname: `/student/selfEduProgram/write/${grade}`,
            state: { formSeq: seq },
          });
        };
        text = '수정';
        break;
      case 'cancel':
        variant = 'primary';
        event = e => {
          e.stopPropagation();

          updateRequest(seq, 'CAN');
        };
        text = '승인요청 취소';
        break;
      default:
        break;
    }

    return (
      <Button size="sm" variant={variant} onClick={event}>
        {text}
      </Button>
    );
  };

  const [linkList, setLinkList] = useState([]);

  useEffect(() => {
    switch (authType) {
      case ROLE.ADMIN:
        setLinkList([
          {
            name: '프로그램 현황',
            path: '/admEdu/student/program',
          },
        ]);
        break;
      case ROLE.TEACHER:
        setLinkList([
          {
            name: '학생 관리',
            path: '/teacher/studentMgmt/studentMgmt',
          },
        ]);
        break;
      case ROLE.STUDENT:
        setLinkList([
          {
            name: 'HOME',
            path: '/',
          },
        ]);
        break;
      default:
        break;
    }
  }, [authType]);

  //* #################################################################
  //* [ return ]
  //* #################################################################
  return (
    <main id="student-selfEduProgram">
      <Container>
        <article className="content">
          <ul className="path">
            {linkList &&
              linkList.length > 0 &&
              linkList.map((v, i) => {
                return (
                  <li key={`link_${i}`}>
                    <Link to={v.path}>{v.name}</Link>
                  </li>
                );
              })}
            <li>
              <Link to="#none">{`${grade}학년 자율 교육 프로그램`}</Link>
            </li>
          </ul>

          {/* ////////// ↓ 상단 타이틀영역 ////////// */}
          <section>
            <div className="title">
              <h5>{`${grade}학년 자율 교육 프로그램`}</h5>
              {loginType === LOGIN_TYPE.STUDENT && (
                <Button
                  onClick={() =>
                    history.push(`/student/selfEduProgram/write/${grade}`)
                  }
                  variant="primary"
                >
                  신규작성
                </Button>
              )}
            </div>
          </section>

          {/* ////////// ↓ 컨텐츠 ////////// */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>결과 보고서</h5>
            </div>
            <Table>
              <colgroup>
                <col width={220} />
                <col width={220} />
                <col width="*" />
                <col width={150} />
                <col width={150} />
                {loginType === LOGIN_TYPE.STUDENT && <col width={150} />}
              </colgroup>
              <thead>
                <tr>
                  <th>교육 프로그램</th>
                  <th>교육일</th>
                  <th>교육명</th>
                  <th>상태</th>
                  <th>점수</th>
                  {loginType === LOGIN_TYPE.STUDENT && <th>관리</th>}
                </tr>
              </thead>
              <tbody>
                {programList.length > 0
                  ? programList.map((program, idx) => {
                      return (
                        <tr
                          key={idx}
                          className="go-detail"
                          onClick={e =>
                            loginType === LOGIN_TYPE.STUDENT
                              ? history.push(
                                  `/student/selfEduProgram/report/${program.seq}`,
                                )
                              : history.push(
                                  `/teacher/studentMgmt/selfEduProgram/comment/${program.seq}/${studentIdx}`,
                                )
                          }
                        >
                          {/* 교육 프로그램 */}
                          <td>{program.program_name}</td>
                          {/* 교육일 */}
                          <td>{program.program_date}</td>
                          {/* 교육명 */}
                          <td className="text-start">
                            {program.program_title}
                          </td>
                          {/* 상태 */}
                          <td className="status-text">
                            <strong
                              className={
                                FormStatus[program.status || 'NONE'].class
                              }
                            >
                              {FormStatus[program.status || 'NONE'].text}
                              <br/>
                              {loginType === LOGIN_TYPE.TEACHER && program.status === 'CON' && 
                                <small>{Utils.getJustDateString(program.confirm_date)}</small>
                              }
                            </strong>
                          </td>
                          {/* 점수 */}
                          <td>{program.point}</td>
                          {/* 관리 - Button */}
                          {loginType === LOGIN_TYPE.STUDENT && (
                            <td>
                              <div className="btn-area">
                                {/* 승인 */}
                                {program.status === 'CON'}
                                {/* 승인요청 */}
                                {program.status === 'REQ' && (
                                  <>{customButton('cancel', program.seq)}</>
                                )}
                                {/* 보완 */}
                                {program.status === 'REJ' && (
                                  <>{customButton('edit', program.seq)}</>
                                )}
                                {/* 임시저장 */}
                                {program.status === 'ING' && (
                                  <>
                                    {customButton('delete', program.seq)}
                                    {customButton('edit', program.seq)}
                                  </>
                                )}
                              </div>
                            </td>
                          )}
                        </tr>
                      );
                    })
                  : firstCallDone && (
                      <tr>
                        <td
                          colSpan={loginType === LOGIN_TYPE.STUDENT ? '6' : '5'}
                          className="no-data"
                        >
                          작성한 결과 보고서가 없습니다.
                        </td>
                      </tr>
                    )}
              </tbody>
            </Table>
          </section>

          {/* ////////// ↓ 하단 버튼 //////////  */}
          {loginType === LOGIN_TYPE.TEACHER && <div className="btn-area mb-0">
            <Button
              onClick={() => {
                history.goBack();
              }}
              variant="outline-primary"
              size="lg"
            >
              목록
            </Button>
          </div>}          
        </article>
      </Container>
    </main>
  );
});
