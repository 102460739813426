import React, { useEffect, useState } from 'react';
import {
  Container,
  Form,
  Button,
  Card,
  Table,
  Tab,
  Nav,
  Modal,
} from 'react-bootstrap';
import { getPoints, patchPoints } from '@api/eduadmin/voucher';
import Swal from 'sweetalert2';

export default React.memo(function AdmEduVoucherScoreMgmt(props) {
  const [systemStatus, setSystemStatus] = useState({});
  const [maxPoint1, setMaxPoint1] = useState(0);
  const [maxPoint2, setMaxPoint2] = useState(0);
  const [maxPoint3, setMaxPoint3] = useState(0);

  const getData = async () => {
    const { data } = await getPoints();
    if (data.code === 0) {
      setSystemStatus(data.data);
      setMaxPoint3(data.data.maxPoint3);
      setMaxPoint2(data.data.maxPoint2);
      setMaxPoint1(data.data.maxPoint1);
    }
  };

  const update = async event => {
    const target = event.currentTarget;
    target.disabled = true;
    try {
      const param = {
        grade1: maxPoint1,
        grade2: maxPoint2,
        grade3: maxPoint3,
      };
      const { data } = await patchPoints(param);
      if (data.code === 0) {
        Swal.fire({
          text: '정상적으로 수정 되었습니다.',
          confirmButtonText: '확인',
        }).finally(() => {
          getData();
        });
      }
    } catch (error) {
      target.disabled = false;
    }
    target.disabled = false;
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <main id="admEdu-voucher-scoreMgmt" className="type-02">
      <Container>
        <article className="content py">
          <section>
            <div className="title">
              <h5>바우처 점수 관리</h5>
            </div>

            <Card>
              <Card.Body>
                <Table className="bt-none">
                  <colgroup>
                    <col width={120} />
                    <col width="*" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>학년</th>
                      <th>바우처 대상 점수</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>
                        <Form.Control
                          placeholder="0"
                          value={maxPoint1}
                          onChange={e => {
                            if (
                              Number(e.target.value) ||
                              e.target.value === ''
                            ) {
                              setMaxPoint1(e.target.value);
                            }
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>
                        <Form.Control
                          placeholder="0"
                          value={maxPoint2}
                          onChange={e => {
                            if (
                              Number(e.target.value) ||
                              e.target.value === ''
                            ) {
                              setMaxPoint2(e.target.value);
                            }
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>
                        <Form.Control
                          placeholder="0"
                          value={maxPoint3}
                          onChange={e => {
                            if (
                              Number(e.target.value) ||
                              e.target.value === ''
                            ) {
                              setMaxPoint3(e.target.value);
                            }
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <div className="btn-area justify-content-end m-0">
                  <Button
                    variant="primary"
                    onClick={e => {
                      update(e);
                    }}
                  >
                    저장
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </section>
        </article>
      </Container>
    </main>
  );
});
