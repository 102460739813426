/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import { Container, Table, Row, Col, Button, Card } from 'react-bootstrap';
import { Utils } from '@common';
import { FormStatus } from '@common/consts';
import { selectStudentDetail } from '@api/teacher/studentMgmt';
import Moment from 'react-moment';

export default React.memo(function StudentDetail(props) {
  const history = useHistory();
  const { seq } = useParams();

  // 제대로 된 페이지로 들어왔는지 확인
  if (!window.parseInt(seq)) {
    history.replace('/teacher/studentMgmt/studentMgmt');
  }

  // 직전 페이지 리스트 조회 정보
  const { state } = history.location;

  /** form state */
  // 학생 정보
  const [studentInfo, setStudentInfo] = useState({});

  // 프로그램 리스트
  const [programs, setPrograms] = useState([]);

  /** API */
  const getStudentInfo = async () => {
    const paramMap = {
      student_idx: seq,
    };

    // Axios
    try {
      const { data } = await selectStudentDetail(paramMap);

      if (data.code === 0) {
        setStudentInfo(data.data.studentInfo);
        // 프로그램  리스트 갱신
        setPrograms(data.data.programList);
      }
    } catch (e) {
      history.goBack();
      // alert(e.response.data.message);
    }
  };

  /** useEffect */
  useEffect(() => {
    getStudentInfo();
  }, []);

  return (
    <main id="teacher-studentStatus-detail" className="type-02">
      <Container>
        <article className="content">
          <ul className="path">
            <li>
              <Link to="/teacher/studentMgmt/statusList">학생현황</Link>
            </li>
            <li>
              <Link to="#none">학생정보</Link>
            </li>
          </ul>

          {/* ////////// ↓ 상단 타이틀영역 ////////// */}
          <section>
            <div className="title">
              <h5>학생정보</h5>
            </div>
          </section>

          {/* ////////// ↓ 컨텐츠 ////////// */}
          {/* 학교정보 */}
          {studentInfo.seq && (
            <Card>
              <Card.Header>
                <h5>{Utils.decrypt(studentInfo.student_name)}</h5>
              </Card.Header>
              <Card.Body>
                <section className="mb-5">
                  <div className="sub-title">
                    <h5>학교정보</h5>
                  </div>
                  <div className="split-table type-simple">
                    <Row className="table-row">
                      <Col className="th col-2">학교</Col>
                      <Col className="td col-10">{studentInfo.school_name}</Col>
                    </Row>
                    <Row className="table-row">
                      <Col className="th col-2">전공</Col>
                      <Col className="td col-10">
                        {studentInfo.student_depart}
                      </Col>
                    </Row>
                    <Row className="table-row">
                      <Col className="th col-2">학생 ID</Col>
                      <Col className="td col-10">{studentInfo.student_id}</Col>
                    </Row>
                    <Row className="table-row">
                      <Col className="th col-2">학년/반/번호</Col>
                      <Col className="td col-10">
                        {' '}
                        {`${studentInfo.grade}학년 ${
                          studentInfo.ban_no || '-'
                        }반 ${studentInfo.student_no || '-'}번`}
                      </Col>
                    </Row>
                  </div>
                </section>
                <section className="mb-5">
                  <div className="sub-title">
                    <h5>기본정보</h5>
                  </div>
                  <div className="split-table type-simple">
                    <Row className="table-row">
                      <Col className="th col-2">생년월일</Col>
                      <Col className="td col-10">
                        {studentInfo.student_birth ? (
                          <Moment
                            date={Utils.decrypt(studentInfo.student_birth)}
                            format="YYYY-MM-DD"
                            interval={0}
                          />
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>
                    <Row className="table-row">
                      <Col className="th col-2">성별</Col>
                      <Col className="td col-10">{studentInfo.sex}</Col>
                    </Row>
                    <Row className="table-row">
                      <Col className="th col-2">주소</Col>
                      <Col className="td col-10">
                        {`${Utils.decrypt(studentInfo.addr1) || ''} ${
                          Utils.decrypt(studentInfo.addr2) || ''
                        }`}
                      </Col>
                    </Row>
                    <Row className="table-row">
                      <Col className="th col-2">연락처</Col>
                      <Col className="td col-10">
                        {studentInfo.phone_no &&
                          Utils.phoneNumberHypenFormat(
                            Utils.decrypt(studentInfo.phone_no),
                          )}
                      </Col>
                    </Row>
                    <Row className="table-row">
                      <Col className="th col-2">이메일</Col>
                      <Col className="td col-10">
                        {Utils.decrypt(studentInfo.e_mail)}
                      </Col>
                    </Row>
                  </div>
                </section>

                <section className="mb-5">
                  <div className="sub-title">
                    <h5>바우처/점수현황</h5>
                  </div>
                  <Table className="type-narrow">
                    <colgroup>
                      {programs.map((program, idx) => {
                        return <col key={idx} width="*" />;
                      })}
                      <col width={80} />
                      {/* <col width={80} /> */}
                      <col width={80} />
                    </colgroup>
                    <thead>
                      <tr>
                        {/* 학년별 컬럼 (프로그램) */}
                        {programs.map((program, idx) => {
                          return <th key={idx}>{program.program_name}</th>;
                        })}
                        {/* 바우처 대상점수 */}
                        <th>
                          바우처
                          <br />
                          대상점수
                        </th>
                        {/* 누계점수 */}
                        {/* <th> */}
                        {/*  누계 */}
                        {/*  <br /> */}
                        {/*  점수 */}
                        {/* </th> */}
                        {/* 바우처 */}
                        <th className="text-end">바우처</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {/* 학년별 프로그램 */}
                        {programs.map((program, i) => {
                          // 상태 변수
                          const status =
                            studentInfo[
                              Utils.convertProgramData(
                                program.program_id,
                                studentInfo.grade,
                                studentInfo.seq,
                              ).status
                            ];

                          // 점수 변수
                          const point =
                            studentInfo[
                              Utils.convertProgramData(
                                program.program_id,
                                studentInfo.grade,
                                studentInfo.seq,
                              ).point
                            ];

                          // return
                          return (
                            <td
                              key={i}
                              className="go-detail"
                              aria-hidden="true"
                              onClick={() =>
                                props.history.push(
                                  Utils.convertProgramData(
                                    program.program_id,
                                    studentInfo.grade,
                                    studentInfo.seq,
                                  ).url,
                                )
                              }
                            >
                              {/* 상태 */}
                              <strong className={FormStatus[status].class}>
                                {FormStatus[status].text}
                              </strong>
                              <br />
                              {/* 점수 */}
                              {status === 'CON' && <small>점수: {point}</small>}
                            </td>
                          );
                        })}

                        {/* 바우처 대상점수 */}
                        <td>{studentInfo.point_sum}</td>

                        {/* 누계 점수 */}
                        {/* <td>{studentInfo.total_point}</td> */}

                        {/* 바우처 */}
                        <td className="text-end">
                          {Utils.numberComma(studentInfo.voucher_sum)}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </section>

                <section className="mb-5">
                  <div className="sub-title">
                    <h5>학생관리</h5>
                  </div>
                  <div className="split-table type-simple">
                    <Row className="table-row">
                      <Col className="th col-2">상태</Col>
                      <Col className="td col-10">{studentInfo.status_han}</Col>
                    </Row>
                    {/* <Row className="table-row">
                    <Col className="th col-2">비밀번호</Col>
                    <Col className="td col-10">
                      <Button variant="outline-primary">비밀번호 초기화</Button>
                    </Col>
                  </Row> */}
                  </div>
                </section>

                {/* ////////// ↓ 하단 버튼 //////////  */}
                <div className="btn-area mb-0">
                  <Button
                    onClick={() => {
                      props.history.push({
                        pathname: `/teacher/studentMgmt/statusList`,
                        state,
                      });
                    }}
                    variant="primary"
                    size="lg"
                  >
                    목록
                  </Button>
                  <Button
                    onClick={() =>
                      props.history.push(
                        `/teacher/studentMgmt/statusList/edit/${seq}`,
                      )
                    }
                    size="lg"
                    variant="outline-primary"
                  >
                    수정
                  </Button>
                </div>
              </Card.Body>
            </Card>
          )}
        </article>
      </Container>
    </main>
  );
});
