import React, { useEffect, useState } from 'react';
import { Container, Form, Button, Card, Table, Modal } from 'react-bootstrap';
import { SearchSchool, CustomModal } from '@components';
import { useHistory } from 'react-router-dom';
import { Utils } from '@common';
import Swal from 'sweetalert2';
import { getTeacherList, resetPw } from '../../../api/eduadmin/teacher';

export default React.memo(function AdmEduTeacher(props) {
  const history = useHistory();

  const goRegister = () => {
    history.push({
      pathname: '/admEdu/teacher/register',
      state: { type: 'register' },
    });
  };
  const goDetail = e => {
    e.stopPropagation();
    history.history.push('/admEdu/teacher/detail');
  };

  const school =
    (history.location.state && history.location.state.school) || {};

  const teacherName =
    (history.location.state && history.location.state.teacherName) || '';

  const pageRefresh =
    (history.location.state && history.location.state.pageRefresh) || false;

  const [selectedSchool, setSelectedSchool] = useState(school);

  const [searchedTeacherName, setSearchedTeacherName] = useState(teacherName);

  const [totalPages, setTotalPages] = useState();

  const [size, setSize] = useState(10);

  const [seq, setSeq] = useState(0);

  /** modal */
  // 비밀번호 초기화
  const [pwdInitModalShow, setPwdInitModalShow] = useState(false);
  const openPwdInitModal = (e, idx) => {
    e.stopPropagation();
    setSeq(idx);
    setPwdInitModalShow(true);
  };
  const closePwdInitModal = () => {
    setSeq();
    setPwdInitModalShow(false);
  };
  const submitPwdInitModal = e => {
    resetTeacherPw(e);
  };

  // 선생 비밀번호 초기화
  const resetTeacherPw = async event => {
    const target = event.currentTarget;
    target.disabled = true;
    try {
      const { data } = await resetPw(seq);
      if (data.code === 0) {
        Swal.fire({
          html: '비밀번호가 초기화되었습니다.<br/>초기화된 비밀번호는 계정 아이디와 동일합니다.',
          confirmButtonText: '확인',
        });
        closePwdInitModal();
      }
    } catch (e) {
      target.disabled = false;
      closePwdInitModal();
    }
    target.disabled = false;
  };

  const [teacherList, setTeacherList] = useState([]);

  const getTeachers = async () => {
    const params = {
      schoolIdx: school.value === 'all' ? null : school.value,
      teacherName: searchedTeacherName,
    };
    const { data } = await getTeacherList(params);
    if (data.code === 0) {
      setTeacherList(data.data);
    }
  };

  const movePage = (pageNum, isSearch) => {
    const state = {
      teacherName: isSearch ? searchedTeacherName : teacherName,
      school: isSearch ? selectedSchool : school,
      pageRefresh: !pageRefresh,
    };

    history.push({
      pathname: `/admEdu/teacher`,
      state,
    });
  };

  useEffect(() => {
    getTeachers();
  }, [pageRefresh]);

  return (
    <main id="admEdu-teacher" className="type-02">
      <Container>
        <article className="content py">
          <section>
            <div className="title">
              <h5>학교 관리자 관리</h5>
            </div>
          </section>

          <Card>
            <Card.Header className="flex-between">
              <div className="flex-start">
                <SearchSchool
                  selectedSchool={selectedSchool}
                  setSelectedSchool={item => {
                    setSelectedSchool(item);
                  }}
                />
                <div className="input-has-btn ms-2">
                  <Form.Control
                    type="text"
                    placeholder="교사 이름을 검색하세요."
                    className="input-search"
                    value={searchedTeacherName}
                    onChange={e => {
                      setSearchedTeacherName(e.target.value);
                    }}
                  />
                  <Button
                    size="sm"
                    variant="primary"
                    onClick={() => {
                      movePage(1, true);
                    }}
                  >
                    검색
                  </Button>
                </div>
              </div>
              <Button variant="primary" onClick={goRegister}>
                학교 관리자 등록
              </Button>
            </Card.Header>
            <Card.Body className="pt-0">
              <Table className="bt-none tr-even-bg">
                <colgroup>
                  <col width={280} />
                  <col width={200} />
                  <col width="*" />
                  <col width={200} />
                </colgroup>
                <thead>
                  <tr>
                    <th>학교</th>
                    <th>이름</th>
                    <th>아이디</th>
                    <th>관리</th>
                  </tr>
                </thead>
                <tbody>
                  {teacherList.length > 0 ? (
                    teacherList.map(item => {
                      return (
                        <tr
                          key={`teacher-${item.seq}`}
                          className="go-detail"
                          onClick={() =>
                            history.push({
                              pathname: `/admEdu/teacher/detail/${item.seq}`,
                              state: item,
                            })
                          }
                        >
                          <td>{item.schoolName}</td>
                          <td>{Utils.decrypt(item.teacherName)}</td>
                          <td>{item.teacherId}</td>
                          <td>
                            <Button
                              variant="outline-primary"
                              onClick={e => openPwdInitModal(e, item.seq)}
                            >
                              비밀번호 초기화
                            </Button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={4}>
                        <p className="no-data">데이터가 없습니다.</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </article>
      </Container>

      <CustomModal
        title="알림"
        show={pwdInitModalShow}
        onHide={closePwdInitModal}
        size="sm"
      >
        <Modal.Body className="border-0">
          <p className="text-center">비밀번호를 초기화 하시겠습니까?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={closePwdInitModal}>
            취소
          </Button>
          <Button onClick={submitPwdInitModal}>초기화</Button>
        </Modal.Footer>
      </CustomModal>
    </main>
  );
});

const dataDummy = [
  {
    school: '부산국제영화고등학교',
    name: '신용훈',
    id: 'ET50101',
  },
  {
    school: '부산국제고등학교',
    name: '신용훈',
    id: 'ET50101',
  },
  {
    school: '유피고등학교',
    name: '신용훈',
    id: 'ET50101',
  },
];
