/**
 *   [ 인성 자존감 API ]
 *
 */
import Axios from '@api/axios';

const API_PROGRAM = 'api/v1/student';

const SELECT_LIST_INSUNG_ALL = `${API_PROGRAM}/insung-list-all`;
const SELECT_LIST_INSUNG_GRADE = `${API_PROGRAM}/insung-list-by-grade`;
const INSERT_UPDATE_INSUNG = `${API_PROGRAM}/insung`;

const formDataConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

// 직업기초 능력 > 인성 자존감 활동 리스트 조회 (활동내역)
export const selectFormInsungGrade = param => {
  return Axios.post(`${SELECT_LIST_INSUNG_GRADE}`, param);
};

// 직업기초 능력 > 인성 자존감 활동 리스트 조회 (결과 보고서)
export const selectFormInsungAll = () => {
  return Axios.post(`${SELECT_LIST_INSUNG_ALL}`);
};

// 직업기초 능력 > 인성 자존감 활동 신규등록
export const insertUpdateInsung = param => {
  return Axios.post(`${INSERT_UPDATE_INSUNG}`, param, formDataConfig);
};
