/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useState, useRef, useEffect } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import { Container, Button, Table, Modal, Form, Image } from 'react-bootstrap';
import { CustomModal } from '@components';
import { images } from '@assets';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Swal from 'sweetalert2';

//* Utils
import Utils from '@common/Utils';

//* Consts
import { FormStatus } from '@common/consts'; // Form 상태 text, class

//* API
import { selectFormInsungAll, insertUpdateInsung } from '@api/student/basicJob';
import { COMPLETE_TIME, LOGIN_TYPE, ROLE } from '../../../../common/consts';
import { CustomSelect } from '../../../../components';

//* [ Main ]
export default React.memo(function TenacityAction(props) {
  //* #################################################################
  //* [ States ]
  //* #################################################################
  const history = useHistory();
  const { studentIdx, loginType } = useParams();

  const authType = props.userInfo.type;

  // const { loginType } = props.match.params;
  if (loginType !== LOGIN_TYPE.STUDENT && loginType !== LOGIN_TYPE.TEACHER) {
    Swal.fire({
      text: '잘못된 접근입니다.',
      confirmButtonText: '확인',
    });
    history.goBack();
  }

  // const { studentIdx } = props.match.params;
  if (loginType === LOGIN_TYPE.TEACHER && !window.parseInt(studentIdx)) {
    Swal.fire({
      text: '잘못된 접근입니다.',
      confirmButtonText: '확인',
    });
    history.goBack();
  }

  // 결과 보고서
  const [insungForm, setInsungForm] = useState({});

  // 신규등록 변수
  const [actGov, setActGov] = useState(''); // 활동일
  const [actTime, setActTime] = useState(''); // 활동시간
  const [actTitle, setActTitle] = useState(''); // 활동내용
  const [actDate, setActDate] = useState(new Date()); // 증빙기관
  const [files, setFiles] = useState([]); // 증빙자료 (복수)
  const fileRef = useRef(''); // 증빙자료

  //* #################################################################
  //* [ Modal ] 신규등록
  //* #################################################################
  const [showWriteActivityModal, setShowWriteActivityModal] = useState(false);
  const handleShowWriteActivityModal = () => {
    // 기존 입력 초기화
    setActGov('');
    setActTime('');
    setActTitle('');
    setActDate(new Date());
    setFiles([]);

    setShowWriteActivityModal(true);
  };

  //* #################################################################
  //* Utils > 유효성 검증 (활동일, 활동시간, 활동내용, 증빙기관), 파일 첨부
  //* #################################################################
  // 활동 시간 입력
  const actDateHandler = date => {
    setActDate(date);
  };

  // 활동 시간 입력
  const actTimeHandler = e => {
    setActTime(e.target.value.replace(/[^0-9]/g, ''));
  };

  // 활동 내용 입력
  const actTitleHandler = e => {
    setActTitle(e.target.value);
  };

  // 활동 기관 입력
  const actGovHandler = e => {
    setActGov(e.target.value);
  };

  // 파일 첨부
  const addFile = e => {
    if (files.length >= 2) {
      Swal.fire({
        text: '첨부 파일은 2개까지 등록할 수 있습니다.',
        confirmButtonText: '확인',
      });
      return;
    }

    if (e.target.files[0]) setFiles([...files, e.target.files[0]]);
    fileRef.current.value = null;
  };

  // 첨부 파일 제거
  const removeFile = idx => {
    const filteredList = files.filter((file, index) => index !== idx);
    setFiles([...filteredList]);
  };

  //* #################################################################
  //* [ API ] 인성 자존감 활동 리스트 (결과 보고서)
  //* #################################################################
  const [firstCallDone, setFirstCallDone] = useState(false);
  const selectInsungList = async () => {
    // Axios
    try {
      const { data } = await selectFormInsungAll();

      if (data.code === 0) {
        setFirstCallDone(true);
        // 리스트 데이터 후처리
        const {
          list,
          point_1: point1,
          point_2: point2,
          point_3: point3,
        } = data.data;

        if (list.length > 0) {
          // 기존 데이터 초기화
          setInsungForm({});

          // 데이터 후처리
          for (let i = 0; i < list.length; i += 1) {
            const { grade, status } = list[i];

            // 신규
            if (insungForm && insungForm[grade] !== undefined) {
              const prevStatus = insungForm[grade].status;
              const sortedStatus = Utils.sortFormStatus(prevStatus, status);

              insungForm[grade].programName = '인성 자존감 활동';
              // insungForm[grade].point += parseFloat(point);
              insungForm[grade].status = sortedStatus;
              insungForm[grade].class = FormStatus[sortedStatus].class;
            }
            // 기존
            else {
              insungForm[grade] = {
                programName: '인성 자존감 활동',
                status,
                // point,
                class: FormStatus[status].class,
              };
            }
          }

          // 학년별 총 점수
          const gradeList = Object.keys(insungForm);

          for (let i = 0; i < gradeList.length; i += 1) {
            const grade = gradeList[i];
            switch (+grade) {
              case 1:
                insungForm[grade].point = point1;
                break;
              case 2:
                insungForm[grade].point = point2;
                break;
              case 3:
                insungForm[grade].point = point3;
                break;
              default:
                break;
            }
          }

          setInsungForm({ ...insungForm });
        }
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 인성 자존감 활동 신규등록
  //* #################################################################
  const insertForm = async reqStatus => {
    if (reqStatus === 'ING') {
      // 파라미터 설정
      const param = {
        status: reqStatus,
        act_title: actTitle,
        act_date: actDate,
        act_time: actTime.value,
        act_gov: actGov,
      };

      const formData = new FormData();

      // 파일 append
      for (let i = 0; i < files.length; i += 1) {
        formData.append('files', files[i]);
      }

      // JSON append
      formData.append(
        'param',
        new Blob([JSON.stringify(param)], { type: 'application/json' }),
      );

      // Axios
      try {
        const { data } = await insertUpdateInsung(formData);

        if (data.code === 0) {
          // 리스트 갱신
          selectInsungList();

          // 모달 닫기
          setShowWriteActivityModal(false);
        }
      } catch (e) {
        // alert(e.response.data.message);
      }
    } else {
      Swal.fire({
        text: '입력을 확인해주세요.',
        confirmButtonText: '확인',
      });
    }
  };

  //* #################################################################
  //* [ useEffect ]
  //* #################################################################
  useEffect(() => {
    if (loginType === LOGIN_TYPE.STUDENT) {
      selectInsungList();
    }
    if (loginType === LOGIN_TYPE.TEACHER) {
      // selectInsungList();
    }
  }, [loginType]);

  const [linkList, setLinkList] = useState([]);

  useEffect(() => {
    switch (authType) {
      case ROLE.ADMIN:
        setLinkList([
          {
            name: '프로그램 현황',
            path: '/admEdu/student/program',
          },
        ]);
        break;
      case ROLE.TEACHER:
        setLinkList([
          {
            name: '학생 관리',
            path: '/teacher/studentMgmt/studentMgmt',
          },
        ]);
        break;
      case ROLE.STUDENT:
        setLinkList([
          {
            name: 'HOME',
            path: '/',
          },
        ]);
        break;
      default:
        break;
    }
  }, [authType]);

  //* #################################################################
  //* [ return ]
  //* #################################################################
  return (
    <main id="student-tenacityAction">
      <Container>
        <article className="content">
          <ul className="path">
            {linkList &&
              linkList.length > 0 &&
              linkList.map((v, i) => {
                return (
                  <li key={`link_${i}`}>
                    <Link to={v.path}>{v.name}</Link>
                  </li>
                );
              })}
            <li>
              <Link to="#none">인성자존감 활동</Link>
            </li>
          </ul>

          {/* ////////// ↓ 상단 타이틀영역 ////////// */}
          <section>
            <div className="title">
              <h5>인성자존감 활동</h5>
              <Button
                onClick={() => handleShowWriteActivityModal()}
                variant="primary"
              >
                신규작성
              </Button>
            </div>
          </section>

          {/* ////////// ↓ 컨텐츠 ////////// */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>결과 보고서</h5>
            </div>
            <Table>
              <colgroup>
                <col width={200} />
                <col width="*" />
                <col width={150} />
                <col width={200} />
              </colgroup>
              <thead>
                <tr>
                  <th>구분</th>
                  <th>제목</th>
                  <th>상태</th>
                  <th>점수</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(insungForm).length > 0
                  ? Object.keys(insungForm).map((grade, idx) => {
                      return (
                        <tr
                          key={idx}
                          className="go-detail"
                          onClick={() =>
                            history.push(
                              `/student/basicJob/tenacityAction/report/${grade}`,
                            )
                          }
                        >
                          {/* 학년 */}
                          <td>{`${grade}학년`}</td>
                          {/* 학년 프로그램 이름 */}
                          <td>{insungForm[grade].programName}</td>
                          {/* 학년 최소 상태 */}
                          <td>
                            <strong className={insungForm[grade].class}>
                              {FormStatus[insungForm[grade].status].text}
                            </strong>
                          </td>
                          {/* 학년 총 포인트 */}
                          <td>{insungForm[grade].point}</td>
                        </tr>
                      );
                    })
                  : firstCallDone && (
                      <tr>
                        <td colSpan="4" className="no-data">
                          작성한 결과 보고서가 없습니다.
                        </td>
                      </tr>
                    )}
              </tbody>
            </Table>
          </section>
        </article>
      </Container>

      {/* 인성 자존감 활동 작성 모달 */}
      <CustomModal
        id="writeActivityModal"
        title="활동내역"
        show={showWriteActivityModal}
        onHide={() => setShowWriteActivityModal(false)}
        size="sm"
      >
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>활동일</Form.Label>
            <div className="w-100">
              <DatePicker
                className="input-datepicker"
                name="activity-date"
                dateFormat="yyyy.MM.dd"
                selected={actDate}
                onChange={actDateHandler}
                // maxDate={new Date()}
                customInput={<Form.Control style={{ width: '100%' }} />}
                showPopperArrow={false}
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>활동시간</Form.Label>
            <div className="mw-input">
              <CustomSelect
                options={COMPLETE_TIME}
                value={actTime}
                onChange={setActTime}
                placeholder="선택하세요."
              />
            </div>
            {/* <Form.Control */}
            {/*  type="text" */}
            {/*  placeholder="활동시간을 숫자로 입력하세요." */}
            {/*  maxLength={4} */}
            {/*  value={actTime} */}
            {/*  onChange={actTimeHandler} */}
            {/* /> */}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>활동내용</Form.Label>
            <Form.Control
              type="text"
              placeholder="활동대상을 입력하세요."
              maxLength={50}
              onChange={actTitleHandler}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>증빙기관</Form.Label>
            <Form.Control
              type="text"
              placeholder="증빙기관을 입력하세요."
              maxLength={50}
              onChange={actGovHandler}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>증빙자료</Form.Label>
            {/* ↓ 첨부된 파일 */}
            {files.length > 0 && (
              <ul className="drag-attachment-list sm mb-3">
                {files.map((file, idx) => {
                  return (
                    <li key={idx}>
                      <p className="text-primary">{file.name}</p>
                      <Button variant="iconOnly" size="sm">
                        <Image
                          src={images.icCancel}
                          onClick={() => removeFile(idx)}
                        />
                      </Button>
                    </li>
                  );
                })}
              </ul>
            )}
            {/* ↓ 파일 첨부 Button */}
            <Button
              onClick={() => fileRef.current.click()}
              variant="outline-primary"
              className="d-block"
            >
              파일첨부
            </Button>
            {/* ↓ 파일 첨부 Input */}
            <Form.Control
              ref={fileRef}
              onChange={addFile}
              type="file"
              // accept="image/*, .pdf"
              style={{ display: 'none' }}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => insertForm('ING')}>
            임시저장
          </Button>
        </Modal.Footer>
      </CustomModal>
    </main>
  );
});
