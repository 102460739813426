/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import {
  Container,
  Form,
  Row,
  Col,
  Button,
  Card,
  FormText,
  Modal,
} from 'react-bootstrap';
import { Utils } from '@common';
import { TEACHER_ROLE_DETAIL_HAN, TEACHER_ROLE_DETAIL } from '@common/consts';
import { CustomModal } from '@components';
import {
  selectTeacherInfo,
  updateTeacherPassword,
  updateTeacherStaticIpYn,
} from '@api/teacher/myPage';
import classNames from 'classnames';
import Swal from 'sweetalert2';

export default React.memo(function TeacherMypage(props) {
  const history = useHistory();

  // type
  const [teacherType, setTeacherType] = useState('basic'); // basic/director

  // checkbox
  const [unfreezeCheck, setUnfreezeCheck] = useState(false);

  const [wrongPw, setWrongPw] = useState(false);
  const [isSamePw, setIsSamePw] = useState(false);
  const [pwFormCheck, setPwFormCheck] = useState(true);
  const [changePwView, setChangePwView] = useState(false);

  /** modal */
  const [changedModalShow, setChangedModalShow] = useState(false);
  const openChangedModal = () => {
    setChangedModalShow(true);
  };
  const closeChangedModal = () => {
    setChangedModalShow(false);
  };

  /** form state */
  const [teacherInfo, setTeacherInfo] = useState({});
  const [pw, setPw] = useState();
  const [newPw, setNewPw] = useState();
  const [pwCheck, setPwCheck] = useState();

  /** API */
  // 선생 정보 조회
  const getTeacherInfo = async () => {
    try {
      const { data } = await selectTeacherInfo();
      if (data.code === 0) {
        setTeacherInfo(data.data);
        setUnfreezeCheck(data.data.static_yn === 'N');
      }
    } catch (e) {
      // console.log(e);
    }
  };

  // IP 고정 해제
  const changeStaticYn = async unfreeze => {
    setUnfreezeCheck(unfreeze);
    const params = {
      static_yn: unfreeze ? 'N' : 'Y',
    };
    try {
      const { data } = await updateTeacherStaticIpYn(params);
      if (data.code === 0) {
        if (unfreeze) {
          Swal.fire({
            text: '로그인 가능 IP가 고정해제되었습니다.',
            confirmButtonText: '확인',
          });
        } else {
          Swal.fire({
            text: '로그인 가능 IP가 고정되었습니다.',
            confirmButtonText: '확인',
          });
        }
      }
    } catch (e) {
      // console.log('eeeee', e.response.data);
      if (e.response.data.code === 2009) {
        setWrongPw(true);
      }
    }
  };

  // 비밀번호 변경
  const changePw = async event => {
    const target = event.currentTarget;
    target.disabled = true;

    if (!pw) {
      Swal.fire({
        text: '현재 비밀번호를 입력해주시기 바랍니다.',
        confirmButtonText: '확인',
      });
      target.disabled = false;
      return;
    }

    if (!newPw) {
      Swal.fire({
        text: '새 비밀번호를 입력해주시기 바랍니다.',
        confirmButtonText: '확인',
      });
      target.disabled = false;
      return;
    }

    if (!pwCheck) {
      Swal.fire({
        text: '비밀번호 확인을 입력해주시기 바랍니다.',
        confirmButtonText: '확인',
      });
      target.disabled = false;
      return;
    }

    const params = {
      pw: Utils.encrypt(pw),
      newPw: Utils.encrypt(newPw),
    };
    try {
      const { data } = await updateTeacherPassword(params);
      if (data.code === 0) {
        // 재로그인 요청
        openChangedModal();
      }
    } catch (e) {
      // console.log('eeeee', e.response.data);
      if (e.response.data.code === 2009) {
        setWrongPw(true);
      }
      closeChangedModal();
      target.disabled = false;
    }
    target.disabled = false;
  };

  /** help fucntion */
  const passwordCheck = (password, pwChk) => {
    if (!pwChk) return;
    if (password === pwChk) {
      setIsSamePw(true);
    } else {
      setIsSamePw(false);
    }
  };

  const newPwFormCheck = newPassword => {
    setPwFormCheck(Utils.pwRuleCheck(newPassword));
  };

  const logOut = () => {
    closeChangedModal();
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    window.location.href = '/'; // 로그인 화면 이동
  };

  /** useEffect */
  useEffect(() => {
    getTeacherInfo();
  }, []);

  // 패스워드 일치 여부 확인
  useEffect(() => {
    newPwFormCheck(newPw);
    passwordCheck(newPw, pwCheck);
  }, [newPw, pwCheck]);

  return (
    <main id="teacher-mypage" className="type-02">
      <Container>
        <article className="content">
          <ul className="path">
            <li>
              <Link to="/teacher/studentMgmt/studentMgmt">HOME</Link>
            </li>
            <li>
              <Link to="#none">마이페이지</Link>
            </li>
          </ul>

          {/* ////////// ↓ 상단 타이틀영역 ////////// */}
          <section>
            <div className="title">
              <h5>마이페이지</h5>
            </div>
          </section>

          {/* ////////// ↓ 컨텐츠 ////////// */}
          {/* 학교정보 */}
          <Card>
            <Card.Body>
              <section className="mb-5">
                <div className="sub-title">
                  <h5>학교정보</h5>
                </div>
                <div className="split-table type-simple">
                  <Row className="table-row">
                    <Col className="th col-2">학교</Col>
                    <Col className="td col-10">{teacherInfo.school_name}</Col>
                  </Row>
                  {teacherInfo.role_detail !==
                    TEACHER_ROLE_DETAIL.SCHOOL_HEAD && (
                    <Row className="table-row">
                      <Col className="th col-2">담당</Col>
                      <Col className="td col-10">
                        {teacherInfo.grade &&
                          `${
                            +teacherInfo.grade === 0
                              ? '전체'
                              : teacherInfo.grade
                          }학년`}
                        {teacherInfo.ban_no &&
                          `${window.parseInt(teacherInfo.ban_no)}반`}
                        {!teacherInfo.grade && !teacherInfo.ban_no && '-'}
                      </Col>
                    </Row>
                  )}
                  {teacherType === 'director' && (
                    <Row className="table-row">
                      <Col className="th col-2">권한</Col>
                      <Col className="td col-10">
                        {teacherInfo.role_detail
                          ? TEACHER_ROLE_DETAIL_HAN[teacherInfo.role_detail]
                          : '-'}
                      </Col>
                    </Row>
                  )}
                </div>
              </section>

              {/* 기본정보 */}
              <section className="mb-5">
                <div className="sub-title">
                  <h5>기본정보</h5>
                </div>
                <div className="split-table type-simple">
                  <Row className="table-row">
                    <Col className="th col-2">이름</Col>
                    <Col className="td col-10">
                      {Utils.decrypt(teacherInfo.teacher_name)}
                    </Col>
                  </Row>
                  <Row className="table-row">
                    <Col className="th col-2">아이디</Col>
                    <Col className="td col-10">{teacherInfo.teacher_id}</Col>
                  </Row>
                </div>
              </section>

              {/* 고정 IP 확인 */}
              <section className="mb-5">
                <div className="sub-title">
                  <h5>고정 IP 확인</h5>
                </div>
                <div className="split-table type-simple">
                  <Row className="table-row">
                    <Col className="th col-2">고정 IP 주소</Col>
                    <Col className="td col-10">
                      <div className="w-100 flex-between">
                        <p>{teacherInfo.allow_ip}</p>
                        <Form.Check
                          type="checkbox"
                          label="고정해제"
                          id="student-saveId-checked"
                          name="student-saveId-checked"
                          checked={unfreezeCheck}
                          onChange={e => {
                            changeStaticYn(e.target.checked);
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </section>

              {/* 비밀번호 */}
              <section>
                <div className="sub-title">
                  <h5>비밀번호</h5>
                </div>
                {!changePwView ? (
                  <div className="split-table type-simple">
                    <Row className="table-row">
                      <Col className="th col-2">비밀번호</Col>
                      <Col className="td col-10">
                        **********
                        <Button
                          variant="primary"
                          className="ms-3"
                          onClick={() => {
                            setChangePwView(true);
                          }}
                        >
                          비밀번호 변경
                        </Button>
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <div className="split-table type-simple">
                    <Row className="table-row">
                      <Col className="th col-2">현재 비밀번호</Col>
                      <Col className="td col-10">
                        <div className="w-100">
                          <Form.Control
                            className={classNames({ 'is-invalid': wrongPw })}
                            type="password"
                            placeholder="현재 비밀번호를 입력하세요."
                            onChange={e => {
                              setPw(e.target.value);
                              setWrongPw(false);
                            }}
                            value={pw || ''}
                          />
                          {wrongPw && (
                            <FormText className="mt-2 d-block invalid-feedback">
                              현재 비밀번호가 일치하지 않습니다.
                            </FormText>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Row className="table-row">
                      <Col className="th col-2">새 비밀번호</Col>
                      <Col className="td col-10">
                        <div className="w-100">
                          <Form.Control
                            className={classNames({
                              'is-invalid': newPw && !pwFormCheck,
                            })}
                            type="password"
                            placeholder="새 비밀번호를 입력하세요. (비밀번호는 대소문자, 특수문자, 숫자 포함 8 ~ 20자리 문자열입니다.)"
                            onChange={e => {
                              setNewPw(e.target.value);
                            }}
                            value={newPw || ''}
                          />
                          {newPw && !pwFormCheck && (
                            <FormText className="mt-2 d-block invalid-feedback">
                              비밀번호 형식을 확인해주시기 바랍니다. 비밀번호는
                              대소문자, 특수문자, 숫자 포함 8 ~ 20자리
                              문자열입니다.
                            </FormText>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Row className="table-row">
                      <Col className="th col-2">비밀번호 확인</Col>
                      <Col className="td col-10">
                        <div className="w-100">
                          <Form.Control
                            className={classNames({
                              'is-invalid': pwCheck && !isSamePw,
                            })}
                            type="password"
                            placeholder="새 비밀번호를 다시 입력하여 비밀번호를 확인해주세요."
                            onChange={e => {
                              setPwCheck(e.target.value);
                            }}
                            value={pwCheck || ''}
                          />
                          {pwCheck && !isSamePw && (
                            <FormText className="mt-2 d-block invalid-feedback">
                              비밀번호가 일치하지 않습니다.
                            </FormText>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Row className="table-row">
                      <Col className="th col-2">&nbsp;</Col>
                      <Col className="td col-10">
                        <Button
                          variant="primary"
                          onClick={changePw}
                          disabled={
                            !(pw && isSamePw && !wrongPw && pwFormCheck)
                          }
                        >
                          비밀번호 변경
                        </Button>
                      </Col>
                    </Row>
                  </div>
                )}
              </section>
            </Card.Body>
          </Card>
        </article>
      </Container>
      <CustomModal
        show={changedModalShow}
        onHide={closeChangedModal}
        title="알림"
      >
        <Modal.Body className="border-0">
          <p className="text-center">
            변경된 사항을 저장하였습니다. <br />
            재로그인해주시기 바랍니다.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              logOut();
            }}
          >
            확인
          </Button>
        </Modal.Footer>
      </CustomModal>
    </main>
  );
});
