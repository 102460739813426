/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Utils } from '@common';
import { Container, Form, Button, Card, Table } from 'react-bootstrap';
import { CustomSelect } from '@components';
import {
  TEACHER_ROLE_DETAIL,
  FILE_FORM_TYPE,
  STATUS_TYPE_LIST,
} from '@common/consts';
import {
  selectTempStudentList,
  insertTempStudent,
  confirmTempStudent,
} from '@api/teacher/studentMgmt';
import { getFilePathFromRedis } from '@api/file';
import ReactPaginate from 'react-paginate';
import LoadingSpinner from '@components/LoadingSpinner';
import classNames from 'classnames';
import Swal from 'sweetalert2';

export default React.memo(function TempStuList(props) {
  const history = useHistory();

  const historyState = history.location.state;
  let page = historyState && historyState.page;
  if (!(page === 0 || page)) {
    page = 1;
  }
  const statusType = (historyState && historyState.statusType) || {
    label: '모두',
    value: 'ALL',
  };

  const excelUpload = useRef('');

  /** loading spinner */
  const [showSpinner, setShowSpinner] = useState(false);

  /** paging state */
  const [size, setSize] = useState(10);
  const [totalPageCnt, setTotalPageCnt] = useState(0);
  const pageRefresh =
    historyState && historyState.pageRefresh ? historyState.pageRefresh : null;

  /** form state */
  const [schoolInfo, setSchoolInfo] = useState({});
  const [tempStudentList, setTempStudentList] = useState([]); // 임시 등록된 학생 리스트

  /** API */
  // 임시 등록 학생 리스트
  const [firstCallDone, setFirstCallDone] = useState(false);
  const getTempStudentList = async status => {
    const params = {
      page,
      size,
      upload_status: status.value,
    };
    try {
      const { data } = await selectTempStudentList(params);
      if (data.code === 0) {
        setFirstCallDone(true);
        setSchoolInfo(data.data.schoolInfo);
        setTotalPageCnt(data.data.pageResponse.totalPages);
        setTempStudentList(data.data.pageResponse.list);
      }
    } catch (e) {
      // console.log(e);
    }
  };
  // 확정하기
  const tempStudentConfirm = async event => {
    const target = event.currentTarget;
    target.disabled = true;
    // eslint-disable-next-line no-restricted-globals
    if (
      // eslint-disable-next-line no-restricted-globals
      !(await Swal.fire({
        html: '임시 등록된 학생 정보를 확정하시겠습니까?<br />확정 후에는 취소가 불가능합니다.',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        showCancelButton: true,
        reverseButtons: true,
      }).then(result => !!result.isConfirmed))
    ) {
      target.disabled = false;
      return;
    }
    try {
      const { data } = await confirmTempStudent();
      if (data.code === 0) {
        window.location.reload();
      }
    } catch (e) {
      // console.log(e);
      target.disabled = false;
    }
    target.disabled = false;
  };

  // 학생 등록
  const [throttle, setThrottle] = useState();
  const registTempStudent = async event => {
    if (throttle) return;
    setThrottle(true);
    setShowSpinner(true);
    if (!event.target.files || !event.target.files[0]) {
      Swal.fire({
        text: '파일이 없습니다.',
        confirmButtonText: '확인',
      });
      return;
    }

    // FormData
    const formData = new FormData();

    // JSON append
    formData.append('file', event.target.files[0]);

    try {
      const { data } = await insertTempStudent(formData);
      if (data.code === 0) {
        movePage(1, STATUS_TYPE_LIST[0]);
      }
    } catch (e) {
      // console.log(e);
      setThrottle(false);
      setShowSpinner(false);
    }
    setThrottle(false);
    setShowSpinner(false);
  };

  // 양식 다운로드
  const openFile = async () => {
    // API 파라미터
    const paramMap = {
      formType: FILE_FORM_TYPE.FILE_STATIC_TEMP_STUDENT_EXCEL,
    };

    // Axios
    try {
      const { data } = await getFilePathFromRedis(paramMap);

      if (data.code === 0) {
        const redisKey = data.data;
        const hostName = window.location.hostname;

        const url = Utils.getFileOpenUrl(hostName, redisKey);

        window.open(url);
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  /** paging */

  // 페이지 이동
  const movePage = (pageNum, status) => {
    const state = {
      page: pageNum,
      statusType: status,
      pageRefresh: page === 1 ? !pageRefresh : pageRefresh,
    };
    history.push({
      pathname: `/teacher/studentMgmt/studentTemp`,
      state,
    });
  };

  /** useEffect */
  useEffect(() => {
    return () => {
      setFirstCallDone(false);
      setSchoolInfo({});
      setTotalPageCnt(0);
      setTempStudentList([]);
    };
  }, []);

  useEffect(() => {
    setTempStudentList([]);
    setFirstCallDone(false);
    getTempStudentList(statusType);
  }, [page, pageRefresh]);

  const handleUploadState = state => {
    switch (state) {
      case 'NORMAL':
        return (
          <div className="text-center">
            <i className="material-icons text-success pe-1">check_circle</i>
            <strong>정상</strong>
          </div>
        );
      case 'WARNING':
        return (
          <div className="text-center">
            <i className="material-icons text-warning pe-1">warning</i>
            <strong>경고</strong>
          </div>
        );
      case 'ERROR':
        return (
          <div className="text-center">
            <i className="material-icons text-danger pe-1">error</i>
            <strong>오류</strong>
          </div>
        );
      default:
        return '';
    }
  };
  return (
    <main id="teacher-studentStatus" className="type-02">
      <Container>
        {/* ////////// ↓ 컨텐츠 ////////// */}
        {firstCallDone && (
          <article className="content">
            {/* ////////// ↓ 상단 타이틀영역 ////////// */}
            <section>
              <div className="title">
                <h5>학생 임시현황</h5>
              </div>
            </section>
            {showSpinner ? (
              <LoadingSpinner
                show={showSpinner}
                message="학생 임시등록 중입니다."
              />
            ) : (
              <Card>
                <Card.Header>
                  <div className="table-title mt-4">
                    <h5>{schoolInfo.school_name}</h5>
                    <div className="confirmation-area">
                      <div className="btn-area m-0">
                        <Button
                          variant="outline-primary"
                          disabled={schoolInfo.confirm_yn === 'Y'} // 이미 확정 했을 때
                          onClick={e => {
                            tempStudentConfirm(e);
                          }}
                        >
                          확정하기
                        </Button>
                        <Button
                          variant="outline-primary"
                          onClick={() => {
                            openFile();
                          }}
                        >
                          양식 다운로드
                        </Button>
                        <Button
                          variant="primary"
                          onClick={e => {
                            excelUpload.current.click(e);
                          }}
                        >
                          학생등록
                        </Button>
                        <Form.Control
                          type="file"
                          accept=".xls, .xlsx"
                          style={{ display: 'none' }}
                          ref={excelUpload}
                          onChange={registTempStudent}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex-between mt-3">
                    <div className="sw-input">
                      <CustomSelect
                        options={STATUS_TYPE_LIST}
                        value={statusType}
                        onChange={e => {
                          movePage(1, e);
                        }}
                      />
                    </div>
                    <strong
                      className={classNames('fs-18', {
                        'text-blue': schoolInfo.confirm_yn === 'Y',
                        'text-gray-dark': schoolInfo.confirm_yn === 'N',
                      })}
                    >
                      {schoolInfo.confirm_yn === 'Y' ? '확정완료' : '미확정'}
                    </strong>
                  </div>
                </Card.Header>
                <Card.Body className="pt-0">
                  <Table className="bt-none tr-even-bg">
                    <colgroup>
                      <col width={62} />
                      <col width={62} />
                      <col width={62} />
                      <col width={270} />
                      <col width={134} />
                      <col width={164} />
                      <col width={150} />
                      <col width="*" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>학년</th>
                        <th>반</th>
                        <th>번호</th>
                        <th className="text-start">이름</th>
                        <th>상태</th>
                        <th>연락처</th>
                        <th>업로드</th>
                        <th>메시지</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tempStudentList && tempStudentList.length > 0 ? (
                        tempStudentList
                          // .slice(startIndex, endIndex)
                          .map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{item.grade}</td>
                                <td>
                                  {item.ban_no && window.parseInt(item.ban_no)}
                                </td>
                                <td>
                                  {item.student_no &&
                                    window.parseInt(item.student_no)}
                                </td>
                                <td className="text-start">
                                  {item.student_name &&
                                    Utils.decrypt(item.student_name)}
                                </td>
                                <td>{item.status_han}</td>
                                <td>
                                  {item.phone_no &&
                                    Utils.decrypt(item.phone_no)}
                                </td>
                                <td>{handleUploadState(item.upload_status)}</td>
                                <td
                                  className="text-start"
                                  style={{ whiteSpace: 'pre-line' }}
                                >
                                  {item.error_message &&
                                    item.error_message.replace(/],/g, ']\n\n')}
                                </td>
                              </tr>
                            );
                          })
                      ) : (
                        <tr>
                          <td colSpan={8} className="no-data">
                            등록된 학생이 없습니다.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {/* 페이징 */}
                  <ReactPaginate
                    pageRangeDisplayed={3}
                    nextLabel=""
                    previousLabel=""
                    breakLabel="..."
                    pageCount={totalPageCnt || page || 1}
                    renderOnZeroPageCount={null}
                    containerClassName="pagination mt-4"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    activeClassName="active"
                    onPageChange={e => {
                      movePage(e.selected + 1, statusType);
                    }}
                    forcePage={page ? page - 1 : 0}
                  />
                </Card.Body>
              </Card>
            )}
          </article>
        )}
      </Container>
    </main>
  );
});
