/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import { Container, Button, Row, Col, Form } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';
import Utils from '@common/Utils';
import {
  FormStatus,
  PROGRAM_ID,
  RESEARCH_POINT_TYPE_LIST,
  FILE_FORM_TYPE,
} from '@common/consts';
import {
  selectFormPracticeAbilityInfo,
  upsertFormPracticeAbility,
} from '@api/student/practiceAbility';
import { getFilePathFromRedis } from '@api/file';
import Moment from 'react-moment';
import Swal from 'sweetalert2';

export default React.memo(function PracticeAbilityReport(props) {
  const history = useHistory();
  const [showFileList, setShowFileList] = useState([1]);

  const { type: typeParam, pageType, seq } = useParams();

  // type
  const [viewType, setViewType] = useState(typeParam);
  const viewTypes = ['student', 'teacher', 'eduAdm'];
  const pageTypes = ['excursions', 'experience', 'training'];

  // 제대로 된 페이지로 들어왔는지 확인
  if (!viewTypes.includes(viewType)) {
    history.replace('/home');
    return false;
  }
  if (!pageTypes.includes(pageType)) {
    history.replace('/home');
    return false;
  }
  if (!window.parseInt(seq)) {
    history.replace('/home');
  }

  // radio
  const [researchRadioResult, setResearchRadioResult] = useState({});

  // 학생 정보, 보고서 정보
  const [reportInfo, setReportInfo] = useState({});

  // 파일 리스트
  const [files, setFiles] = useState([]);

  // 교사 코멘트
  const [teacherCommentList, setTeacherCommentList] = useState([]);

  // 리서치 정보
  const [researchList, setResearchList] = useState([]);

  // 페이지별 학년 구분
  const getPageGrade = type => {
    switch (type) {
      case 'excursions':
        return 1;
      case 'experience':
        return 2;
      case 'training':
        return 3;
      default:
        return null;
    }
  };

  // 프로그램 ID 획득
  const getProgramId = type => {
    switch (getPageGrade(type)) {
      case 1:
        return PROGRAM_ID.PROGRAM_ID_FI_VISIT;
      case 2:
        return PROGRAM_ID.PROGRAM_ID_FI_EXPERIENCE;
      case 3:
        return PROGRAM_ID.PROGRAM_ID_FI_TRAINING;
      default:
        return null;
    }
  };

  // 프로그램 ID
  const programId = getProgramId(pageType);

  /** API */
  // 정보 조회
  const getInfo = async () => {
    // 파라미터
    const param = {
      formSeq: seq,
      program_id: programId,
      isUpsert: false,
    };

    // Axios
    try {
      const { data } = await selectFormPracticeAbilityInfo(param);

      if (data.code === 0) {
        // 학생 정보, 보고서 정보
        const { info } = data.data;

        setReportInfo(info);

        // 파일 리스트
        setFiles(data.data.files);

        // 교사 코멘트 리스트
        setTeacherCommentList(data.data.teacherCommentList);

        // 리서치 목록 구성
        setResearchList(data.data.researchList);

        // 리서치 값 세팅
        settingResearchValue(info.research_idx, info.research_response);
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  // 승인요청, 취소, 삭제
  const updateRequest = async (event, reqStatus) => {
    const target = event.currentTarget;
    target.disabled = true;

    if (
      reqStatus === FormStatus.DEL.value &&
      // eslint-disable-next-line no-restricted-globals
      !(await Swal.fire({
        text: '삭제하시겠습니까?',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        showCancelButton: true,
        reverseButtons: true,
      }).then(result => !!result.isConfirmed))
    ) {
      target.disabled = false;
      return;
    }

    if (
      reqStatus === FormStatus.ING.value &&
      // eslint-disable-next-line no-restricted-globals
      !(await Swal.fire({
        text: '승인요청을 취소하시겠습니까?',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        showCancelButton: true,
        reverseButtons: true,
      }).then(result => !!result.isConfirmed))
    ) {
      target.disabled = false;
      return;
    }

    if (
      reqStatus === FormStatus.REQ.value &&
      // eslint-disable-next-line no-restricted-globals
      !(await Swal.fire({
        text: '승인요청하시겠습니까?',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        showCancelButton: true,
        reverseButtons: true,
      }).then(result => !!result.isConfirmed))
    ) {
      target.disabled = false;
      return;
    }

    // API 파라미터
    let params = {
      formSeq: seq,
      status: reqStatus,
      program_id: reportInfo.program_id,
    };

    if (reqStatus === FormStatus.REQ.value) {
      if (!inputConditionCheck(reportInfo)) {
        target.disabled = false;
        return;
      }
      params = { ...reportInfo, ...params };
    }

    const formData = new FormData();

    // JSON append
    formData.append(
      'param',
      new Blob([JSON.stringify(params)], { type: 'application/json' }),
    );

    // Axios
    try {
      const { data } = await upsertFormPracticeAbility(formData);

      if (data.code === 0) {
        history.push(`/student/practiceAbility/${pageType}`);
      }
    } catch (e) {
      // alert(e.response.data.message);
      target.disabled = false;
    }

    target.disabled = false;
  };

  // API 파일 조회
  const openFile = async fileSeq => {
    // API 파라미터
    const paramMap = {
      formType: FILE_FORM_TYPE.FILE_INDUSTRY,
      fileSeq,
    };

    // Axios
    try {
      const { data } = await getFilePathFromRedis(paramMap);

      if (data.code === 0) {
        const redisKey = data.data;
        const hostName = window.location.hostname;

        const url = Utils.getFileOpenUrl(hostName, redisKey);

        window.open(url);
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  /** API helper function */
  // 설문조사 값 설정
  const settingResearchValue = (researchIdx, researchResponse) => {
    if (researchIdx && researchResponse) {
      const idxs = researchIdx.split(';');
      const responses = researchResponse.split(';');
      const researchObject = {};
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const i in idxs) {
        researchObject[`${idxs[i]}`] = responses[i];
      }
      setResearchRadioResult(researchObject);
    }
  };

  // 승인 요청 가능 여부 체크
  const inputConditionCheck = item => {
    // 산업체 실습의 경우 지원주체, 종료사유 체크
    if (programId === PROGRAM_ID.PROGRAM_ID_FI_TRAINING) {
      if (!item.support_group_gubun) {
        Swal.fire({
          text: '지원주체가 선택되지 않았습니다.',
          confirmButtonText: '확인',
        });
        return false;
      }

      if (!item.termination_reason_gubun) {
        Swal.fire({
          text: '종료사유가 선택되지 않았습니다.',
          confirmButtonText: '확인',
        });
        return false;
      }
    }

    if (!item.visit_company) {
      Swal.fire({
        text: '방문업체가 입력되지 않았습니다.',
        confirmButtonText: '확인',
      });
      return false;
    }

    if (!item.visit_address) {
      Swal.fire({
        text: '방문업체주소가 입력되지 않았습니다.',
        confirmButtonText: '확인',
      });
      return false;
    }

    // if (files.length === 0) {
    //   Swal.fire({
    //     text: '업로드 된 파일이 존재하지 않습니다.',
    //     confirmButtonText: '확인',
    //   });
    //   return false;
    // }
    // 입력 제한 1 ~ 1000
    if (!Utils.limitCheck(item.visit_contents, 1, 1000)) {
      Swal.fire({
        text: '참여활동 내용 최소 1자 이상 최대 1000자여야 합니다.',
        confirmButtonText: '확인',
      });
      return false;
    }
    if (!Utils.limitCheck(item.visit_review, 1, 1000)) {
      Swal.fire({
        text: '참여 후 느낀점은 최소 1자 이상 최대 1000자여야 합니다.',
        confirmButtonText: '확인',
      });
      return false;
    }
    // 범위 제한 1 ~ 5
    // 리서치 조사를 했는지 체크
    const researchKeys = Object.keys(researchRadioResult);
    if (researchKeys.length !== researchList.length) {
      Swal.fire({
        text: '응답되지 않은 만족도 조사가 존재합니다.',
        confirmButtonText: '확인',
      });
      return false;
    }
    if (researchKeys.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const key of researchKeys) {
        if (
          researchRadioResult[key] &&
          !Utils.rangeCheck(researchRadioResult[key], 1, 5)
        ) {
          Swal.fire({
            text: '만족도 조사에 응답된 값이 잘못되었습니다.',
            confirmButtonText: '확인',
          });
          return false;
        }
      }
    }
    return true;
  };

  /** print function */
  // 인쇄
  const printWindowRef = useRef();
  const handlePrint = useReactToPrint({
    onBeforeGetContent: () => {
      printWindowRef.current.classList.add('print');
    },
    onAfterPrint: () => {
      printWindowRef.current.classList.remove('print');
    },
    onPrintError: () => {
      printWindowRef.current.classList.remove('print');
    },
    content: () => {
      return printWindowRef.current;
    },
  });

  /** useEffect */
  useEffect(() => {
    getInfo();
    return () => {
      setReportInfo({});
      setFiles([]);
      setTeacherCommentList([]);
      setResearchList([]);
      setResearchRadioResult({});
    };
  }, []);

  return (
    <main id="program-practiceAbility-report">
      <Container>
        <article className="content" ref={printWindowRef}>
          {viewType === 'student' && (
            <ul className="path">
              <li>
                <Link to="/">HOME</Link>
              </li>
              <li>
                <Link to={`/student/practiceAbility/${pageType}`}>
                  {pageType === 'excursions' && '산업체 현장견학'}
                  {pageType === 'experience' && '산업체 현장체험'}
                  {pageType === 'training' && '산업체 현장실습'}
                </Link>
              </li>
              <li>
                <Link to="#none">결과 보고서</Link>
              </li>
            </ul>
          )}

          {/* ////////// ↓ 상단 타이틀영역 ////////// */}
          <section>
            <div className="title">
              <h5>
                {pageType === 'excursions' && '산업체 현장견학'}
                {pageType === 'experience' && '산업체 현장체험'}
                {pageType === 'training' && '산업체 현장실습'}
                &nbsp;결과 보고서
              </h5>
              <div className="dl-box type-bar lg">
                <dl>
                  <strong
                    className={
                      FormStatus[reportInfo.status] &&
                      FormStatus[reportInfo.status].class
                    }
                  >
                    {FormStatus[reportInfo.status] &&
                      FormStatus[reportInfo.status].text}
                  </strong>
                </dl>
                <dl>
                  <dd>
                    <strong>{reportInfo.point}</strong>
                  </dd>
                </dl>
              </div>
            </div>
          </section>
          {/* ////////// ↓ 컨텐츠 ////////// */}
          {/* 기본정보 */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>기본정보</h5>
            </div>
            <div className="split-table">
              <Row className="table-row">
                <Col className="th col-2">학번</Col>
                <Col className="td col-10">{reportInfo.student_id}</Col>
              </Row>
              <Row className="table-row">
                <Col className="th col-2">이름</Col>
                <Col className="td col-10">
                  {Utils.decrypt(reportInfo.student_name)}
                </Col>
              </Row>
            </div>
          </section>
          {/* 산업체 참여 정보 */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>산업체 참여 정보</h5>
            </div>
            {pageType !== 'training' ? (
              <div className="split-table">
                <Row className="table-row">
                  <Col className="th col-2">방문일</Col>
                  <Col className="td col-10">
                    {reportInfo.visit_date && (
                      <Moment
                        format="YYYY-MM-DD"
                        date={reportInfo.visit_date}
                      />
                    )}
                  </Col>
                </Row>
                <Row className="table-row">
                  <Col className="th col-2">방문업체명</Col>
                  <Col className="td col-10">{reportInfo.visit_company}</Col>
                </Row>
                <Row className="table-row">
                  <Col className="th col-2">방문업체주소</Col>
                  <Col className="td col-10">{reportInfo.visit_address}</Col>
                </Row>
              </div>
            ) : (
              <div className="split-table">
                <Row className="table-row">
                  <Col className="th col-2">실습일</Col>
                  <Col className="td col-10">
                    {reportInfo.visit_date && (
                      <Moment
                        format="YYYY-MM-DD"
                        date={reportInfo.visit_date}
                      />
                    )}
                    &nbsp; ~ &nbsp;
                    {reportInfo.training_end_date && (
                      <Moment
                        format="YYYY-MM-DD"
                        date={reportInfo.training_end_date}
                      />
                    )}
                  </Col>
                </Row>
                <Row className="table-row">
                  <Col className="th col-2">이수시간</Col>
                  <Col className="td col-10">
                    {reportInfo && reportInfo.complete_time
                      ? `${reportInfo.complete_time} 시간`
                      : ''}
                  </Col>
                </Row>
                <Row className="table-row">
                  <Col className="th col-2">지원주체</Col>
                  <Col className="td col-10">
                    {reportInfo.support_group_gubun_han === '결과없음'
                      ? ''
                      : reportInfo.support_group_gubun_han}
                  </Col>
                </Row>

                <Row className="table-row">
                  <Col className="th col-2">종료사유</Col>
                  <Col className="td col-10">
                    {reportInfo.termination_reason_gubun_han === '결과없음'
                      ? ''
                      : reportInfo.termination_reason_gubun_han}
                  </Col>
                </Row>
                <Row className="table-row">
                  <Col className="th col-2">방문업체명</Col>
                  <Col className="td col-10">{reportInfo.visit_company}</Col>
                </Row>
                <Row className="table-row">
                  <Col className="th col-2">방문업체주소</Col>
                  <Col className="td col-10">{reportInfo.visit_address}</Col>
                </Row>
              </div>
            )}
          </section>

          {/* 참여활동 내용 */}
          <section className="mb-5">
            <div className="sub-title flex-start">
              <h5>참여활동 내용</h5>
            </div>
            <div className="comment-box">
              <ul className="comment-list">
                <li>
                  <p className="text">{reportInfo.visit_contents}</p>
                </li>
              </ul>
            </div>
          </section>

          {/* 참여 후 느낀점 */}
          <section className="mb-5">
            <div className="sub-title flex-start">
              <h5>참여 후 느낀점</h5>
            </div>
            <div className="comment-box">
              <ul className="comment-list">
                <li>
                  <p className="text">{reportInfo.visit_review}</p>
                </li>
              </ul>
            </div>
          </section>

          {/* 만족도 조사 */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>만족도 조사</h5>
            </div>
            <div className="split-table h-56">
              <Row className="table-row">
                <Col className="th col-2 text-gray">조사내용</Col>
                <Col className="th col-4 text-center text-gray">질문</Col>
                <Col className="th col-6">
                  <ul className="type-flex-1">
                    <li>매우 그렇다</li>
                    <li>그렇다</li>
                    <li>보통</li>
                    <li>그렇지 않다</li>
                    <li>매우 그렇지 않다</li>
                  </ul>
                </Col>
              </Row>
              {researchList &&
                researchList.length > 0 &&
                researchList.map((item, index) => {
                  return (
                    <Row className="table-row" key={`research-${index}`}>
                      <Col className="th col-2 text-gray">
                        {item.research_contents}
                      </Col>
                      <Col className="td col-4">{item.research_question}</Col>
                      <Col className="td col-6 flex-between">
                        <div className="radio-group type-flex-1">
                          {RESEARCH_POINT_TYPE_LIST.map(pointItem => {
                            return (
                              <Form.Check
                                key={`${index}-${pointItem.value}`}
                                name={`${index}`}
                                type="radio"
                                label=""
                                value={pointItem.value}
                                id={pointItem.value}
                                onChange={e => {
                                  setResearchRadioResult({
                                    ...researchRadioResult,
                                    [item.seq]: e.target.value,
                                  });
                                }}
                                checked={
                                  researchRadioResult[item.seq] ===
                                  pointItem.value
                                }
                                disabled
                              />
                            );
                          })}
                        </div>
                      </Col>
                    </Row>
                  );
                })}
            </div>
          </section>

          {/* 파일 첨부 */}
          <section className="mb-5">
            <div className="sub-title flex-start">
              <h5>파일 첨부</h5>
            </div>
            {showFileList.length > 0 ? (
              <ul className="attachment-list">
                {files.map((file, idx) => {
                  return (
                    <li key={idx}>
                      <Button
                        className="btn-icon ic-down"
                        onClick={() => openFile(file.file_seq)}
                      >
                        {file.file_name_org}
                      </Button>
                    </li>
                  );
                })}
              </ul>
            ) : (
              <ul className="attachment-list">
                <li>없음</li>
              </ul>
            )}
          </section>

          {/* 교사 코멘트 */}
          {(reportInfo.status === FormStatus.REJ.value ||
            reportInfo.status === FormStatus.CON.value) && (
            <section className="mb-5">
              <div className="sub-title flex-start">
                <h5 className="text-primary">교사 코멘트</h5>
              </div>
              <div className="comment-box type-primary">
                <ul className="comment-list">
                  {teacherCommentList.length > 0 &&
                    teacherCommentList.map((item, index) => {
                      return (
                        <li key={`comment-${index}`}>
                          <p className="date">
                            {item.reg_date && (
                              <Moment
                                format="YYYY.MM.DD"
                                date={item.reg_date}
                              />
                            )}
                          </p>
                          <p className="text">{item.comment}</p>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </section>
          )}

          {/* ////////// ↓ 하단 버튼 //////////  */}
          {viewType === 'student' &&
            reportInfo.status === FormStatus.CON.value && (
              <div className="btn-area">
                <Button
                  size="lg"
                  variant="outline-primary"
                  onClick={e => handlePrint(e)}
                >
                  인쇄
                </Button>
                <Button
                  onClick={() => {
                    history.push(`/student/practiceAbility/${pageType}`);
                  }}
                  variant="primary"
                  size="lg"
                >
                  목록으로
                </Button>
              </div>
            )}
          {viewType === 'student' &&
            reportInfo.status === FormStatus.REQ.value && (
              <div className="btn-area">
                <Button
                  size="lg"
                  variant="outline-primary"
                  onClick={e => handlePrint(e)}
                >
                  인쇄
                </Button>
                <Button
                  size="lg"
                  variant="outline-primary"
                  onClick={e => {
                    updateRequest(e, FormStatus.ING.value);
                  }}
                >
                  승인요청 취소
                </Button>
                <Button
                  onClick={() => {
                    history.push(`/student/practiceAbility/${pageType}`);
                  }}
                  variant="primary"
                  size="lg"
                >
                  목록으로
                </Button>
              </div>
            )}
          {viewType === 'student' &&
            reportInfo.status === FormStatus.ING.value && (
              <div className="btn-area">
                <Button
                  size="lg"
                  variant="outline-primary"
                  onClick={e => handlePrint(e)}
                >
                  인쇄
                </Button>
                <Button
                  size="lg"
                  variant="outline-primary"
                  onClick={e => {
                    updateRequest(e, FormStatus.DEL.value);
                  }}
                >
                  삭제
                </Button>
                <Button
                  size="lg"
                  variant="primary"
                  onClick={e => {
                    history.push({
                      pathname: `/student/practiceAbility/write/${pageType}/${seq}`,
                    });
                  }}
                >
                  수정
                </Button>
                <Button
                  size="lg"
                  variant="primary"
                  onClick={e => {
                    updateRequest(e, FormStatus.REQ.value);
                  }}
                >
                  승인요청
                </Button>
                <Button
                  onClick={() => {
                    history.push(`/student/practiceAbility/${pageType}`);
                  }}
                  variant="primary"
                  size="lg"
                >
                  목록으로
                </Button>
              </div>
            )}
          {viewType === 'student' &&
            reportInfo.status === FormStatus.REJ.value && (
              <div className="btn-area">
                <Button
                  size="lg"
                  variant="outline-primary"
                  onClick={e => handlePrint(e)}
                >
                  인쇄
                </Button>
                {/* <Button */}
                {/*  size="lg" */}
                {/*  variant="outline-primary" */}
                {/*  onClick={e => { */}
                {/*    updateRequest(e, FormStatus.DEL.value); */}
                {/*  }} */}
                {/* > */}
                {/*  삭제 */}
                {/* </Button> */}
                <Button
                  size="lg"
                  variant="primary"
                  onClick={e => {
                    history.push({
                      pathname: `/student/practiceAbility/write/${pageType}/${seq}`,
                    });
                  }}
                >
                  수정
                </Button>
                <Button
                  onClick={() => {
                    history.push(`/student/practiceAbility/${pageType}`);
                  }}
                  variant="primary"
                  size="lg"
                >
                  목록으로
                </Button>
              </div>
            )}
          {viewType !== 'student' && (
            <div className="btn-area">
              <Button
                onClick={() => {
                  history.goBack();
                }}
                variant="primary"
                size="lg"
              >
                목록으로
              </Button>
            </div>
          )}
        </article>
      </Container>
    </main>
  );
});
