/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Container, Button, Row, Col, Table } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import Moment from 'react-moment';
import classNames from 'classnames';
import { getNoticeList } from '../../../api/board/notice';

export default React.memo(function Notice(props) {
  const history = useHistory();
  const pageRefresh =
    (history.location.state && history.location.state.pageRefresh) || false;

  const page = (history.location.state && history.location.state.page) || 1;

  const [showNoticeList, setShowNoticeList] = useState([1]);
  const [noticeList, setNoticeList] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [size, setSize] = useState(10);
  const [firstCallDone, setFirstCallDone] = useState(true);

  const getNotices = async () => {
    const params = {
      page,
      size,
    };
    setFirstCallDone(false);
    const { data } = await getNoticeList(params);
    if (data.code === 0) {
      setTotalPages(data.data.totalPages);
      setNoticeList(data.data.list);
      setFirstCallDone(true);
    }
  };

  const movePage = (pageNum, isSearch) => {
    const state = {
      page: isSearch ? 1 : pageNum,
      pageRefresh: !pageRefresh,
    };

    history.push({
      pathname: `/student/service/notice`,
      state,
    });
  };

  useEffect(() => {
    getNotices();
  }, [pageRefresh]);

  return (
    <main id="service-notice">
      <Container>
        <article className="content">
          <ul className="path">
            <li>
              <Link to="/">HOME</Link>
            </li>
            <li>
              <Link to="#none">공지사항</Link>
            </li>
          </ul>

          {/* ////////// ↓ 상단 타이틀영역 ////////// */}
          <section>
            <div className="title">
              <h5>공지사항</h5>
            </div>
          </section>

          {/* ////////// ↓ 컨텐츠 ////////// */}
          {firstCallDone && (
            <section>
              <Table>
                <colgroup>
                  <col width={160} />
                  <col width="*" />
                  <col width={150} />
                  <col width={180} />
                  <col width={150} />
                </colgroup>
                <thead>
                  <tr>
                    <th>번호</th>
                    <th>제목</th>
                    <th>작성자</th>
                    <th>작성일</th>
                    <th>조회수</th>
                  </tr>
                </thead>
                <tbody>
                  {noticeList && noticeList.length > 0 ? (
                    noticeList.map((v, i) => {
                      return (
                        <tr
                          key={`notice_${i}`}
                          className="go-detail"
                          onClick={() =>
                            props.history.push(
                              `/student/service/notice/detail/${v.seq}`,
                            )
                          }
                        >
                          <td>{v.seq}</td>
                          <td className="table-ellipsis text-start">
                            <p
                              className={classNames('ellipsis', {
                                attatchment: v.fileCnt && v.fileCnt > 0,
                              })}
                            >
                              {v.title}
                            </p>
                            {/* {v.title} */}
                          </td>
                          <td>{v.writer}</td>
                          <td>
                            <Moment date={v.regDate} format="YYYY-MM-DD" />
                          </td>
                          <td>{v.viewCnt}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="5" className="no-data">
                        공지사항이 없습니다.
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <ReactPaginate
                // pageRangeDisplayed={5}
                nextLabel=""
                previousLabel=""
                pageCount={totalPages}
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakClassName="page-item"
                breakLinkClassName="page-link"
                activeClassName="active"
                onPageChange={e => {
                  movePage(e.selected + 1);
                }}
                forcePage={page ? page - 1 : 0}
              />
            </section>
          )}
        </article>
      </Container>
    </main>
  );
});
