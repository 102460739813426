/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { NavLink, Link, useLocation, useHistory } from 'react-router-dom';
import { Container, Button, Image, Nav } from 'react-bootstrap';
import { images } from '@assets';
import { Timer } from '@components';
import classNames from 'classnames';

export default React.memo(function HeaderAdmSystem({
  timerOption,
  tokenHandler,
  logoutHandler,
}) {
  const history = useHistory();
  const navi = useLocation();

  //* #################################################################
  //* [ Return ]
  //* #################################################################
  return (
    <header>
      <section className="top-area">
        <Container>
          <h1 className="logo-container">
            <Link to="/admSystem/code/main">
              <Image src={images.logo} />
            </Link>
          </h1>
          <div className="flex-start">
            <Timer mm="60" ss="00" reset={timerOption} />
            <Button
              size="sm"
              variant="outline-primary"
              className="ms-3"
              onClick={tokenHandler}
            >
              시간연장
            </Button>
            <Button
              size="sm"
              variant="primary"
              className="ms-3"
              onClick={() => history.push('/admSystem/code/main')}
            >
              메인으로
            </Button>
            <div className="user-menu">
              <p>
                <span>관리자</span>&nbsp;님
              </p>
              <ul>
                <li>
                  <NavLink to="/" onClick={logoutHandler}>
                    로그아웃
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </Container>
      </section>

      <section className="nav-bar type-left">
        <Container>
          <Nav>
            {/* ↓ 코드관리 */}
            <Nav.Item>
              <Link
                to="/admSystem/code/main"
                className={classNames({
                  selected: navi.pathname.includes('/admSystem/code'),
                })}
              >
                코드관리
              </Link>
            </Nav.Item>

            {/* ↓ 차수관리  */}
            <Nav.Item>
              <NavLink to="/admSystem/order" activeClassName="selected">
                차수관리
              </NavLink>
            </Nav.Item>

            {/* ↓ 메뉴관리  */}
            <Nav.Item>
              <NavLink to="/admSystem/menu" activeClassName="selected">
                메뉴관리
              </NavLink>
            </Nav.Item>
          </Nav>
        </Container>
      </section>
    </header>
  );
});
