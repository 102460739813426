/**
 *   [ 기초직업 능력 API ]
 *
 */
import Axios from '@api/axios';

const API_PROGRAM = 'api/v1/teacher';

const SELECT_ABILITY_BASIC = `${API_PROGRAM}/ability-test-basic-info`;
const SELECT_ABILITY_FORM = `${API_PROGRAM}/ability-test-info`;
const SELECT_ABILITY_LIST = `${API_PROGRAM}/ability-test-list`;
const INSERT_UPDATE_ABILITY = `${API_PROGRAM}/ability-test`;
const UPDATE_STATUS = `${API_PROGRAM}/update-status-form-ability-test`;

const formDataConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

// 직업기초 능력평가 > [ SELECT ] 개인정보
export const selectFormAbilityBasicInfoByTeacher = data => {
  return Axios.get(`${SELECT_ABILITY_BASIC}`, { params: data });
};

// 직업기초 능력평가 > [ SELECT ] 개인정보 & Form 데이터
export const selectFormAbilityInfoByTeacher = data => {
  return Axios.get(`${SELECT_ABILITY_FORM}`, { params: data });
};

// 직업기초 능력평가 > [ SELECT ] 리스트
export const selectFormAbilityListByTeacher = data => {
  return Axios.get(`${SELECT_ABILITY_LIST}`, { params: data });
};

// 신규등록, 승인요청, 승인요청 취소, 삭제
export const updateStatusFormAbilityTestByTeacher = param => {
  return Axios.post(`${UPDATE_STATUS}`, param);
};
