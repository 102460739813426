import React, { useEffect, useRef, useState } from 'react';
import { Container, Table, Card, Button, Form } from 'react-bootstrap';
import { CheckboxSelect, CustomSelect } from '@components';
import { getMenuAdnOptions, postMenu, patchMenu } from '@api/sysadmin/menu';
import { ROLE } from '@common/consts';
import Swal from 'sweetalert2';
import { deleteMenu, putAllWebMenu } from '../../../api/sysadmin/menu';

function CategoryTr({ item, roleList, roleDetailOptions, sortOptions }) {
  const roleOptions = roleList || [];
  const detailOptions = roleDetailOptions || {};
  const sortOptionList = sortOptions || [];

  const [role, setRole] = useState({});
  const [roleDetail, setRoleDetail] = useState([]);
  const [sort, setSort] = useState({});
  const [isUsed, setIsUsed] = useState(item.menuUse === 'Y');

  const [modifyMenuName, setModifyMenuName] = useState(item.menuName); // 메뉴명
  const [modifyMenuUrl, setModifyMenuUrl] = useState(item.menuUrl); //  URL

  /** API */
  // 메뉴 수정
  const updateMenu = async event => {
    const target = event.currentTarget;
    target.disabled = true;
    const params = {
      seq: item.seq,
      menuCateIdx: item.menuCateIdx,
      menuName: modifyMenuName,
      menuUrl: modifyMenuUrl,
      menuRole: role.value,
      menuRoleDetail: getMenuDetailString(roleDetail),
      menuSort: sort.value,
      menuUse: isUsed ? 'Y' : 'N',
    };
    try {
      const { data } = await patchMenu(params);
      if (data.code === 0) {
        Swal.fire({
          text: '메뉴가 수정되었습니다.',
          confirmButtonText: '확인',
        }).then(result => {
          if (result.isConfirmed) window.location.reload();
        });
      }
    } catch (e) {
      // console.log(e);
    }
    target.disabled = false;
  };

  // 메뉴 삭제
  const removeMenu = async event => {
    const target = event.currentTarget;
    target.disabled = true;
    if (
      !(await Swal.fire({
        text: '해당 메뉴를 삭제하시겠습니까?',
        showCancelButton: true,
        confirmButtonText: '확인',
        cancelButtonText: '취소',
      }).then(result => !!result.isConfirmed))
    ) {
      target.disabled = false;
      return;
    }
    try {
      const { data } = await deleteMenu(item.seq);
      if (data.code === 0) {
        Swal.fire({
          text: '메뉴가 삭제되었습니다.',
          confirmButtonText: '확인',
        }).then(result => {
          if (result.isConfirmed) window.location.reload();
        });
      }
    } catch (e) {
      // console.log(e);
    }
    target.disabled = false;
  };

  /** help function */
  const getMenuDetailString = list => {
    let result = null;
    if (list && list.length > 0) {
      const valueList = list.map(i => i.value);
      if (valueList && valueList.length > 0) {
        result = `${valueList.join(';')};`;
      }
    }
    return result;
  };

  /** useEffect */
  // 메뉴 권한 설정
  useEffect(() => {
    if (roleOptions && roleOptions.length > 0) {
      const findItem = roleOptions.find(i => i.value === item.menuRole);
      if (findItem) setRole(findItem);
    }
  }, [item, roleOptions]);

  // 상세권한 설정
  useEffect(() => {
    if (
      role.value !== ROLE.ADMIN &&
      role.value !== ROLE.ADMIN_SYSTEM &&
      item.menuRoleDetail
    ) {
      const roleDetilaList = item.menuRoleDetail
        .split(';')
        .filter(i => i != null && i !== '');
      const list = [];
      if (detailOptions && detailOptions[role.value]) {
        detailOptions[role.value].forEach(i => {
          if (roleDetilaList.includes(i.value)) list.push(i);
        });
      }
      setRoleDetail(list);
    }
  }, [role, detailOptions]);

  // 순서 설정
  useEffect(() => {
    if (sortOptionList && sortOptionList.length > 0) {
      const findItem = sortOptionList.find(i => {
        return +i.value === +item.menuSort;
      });
      if (findItem) setSort(findItem);
    }
  }, [sortOptionList, item]);

  return (
    <tr>
      {/* 카테고리 */}
      <td>{item.cateName}</td>
      {/* 메뉴명 */}
      <td>
        <Form.Control
          onChange={e => {
            setModifyMenuName(e.target.value);
          }}
          value={modifyMenuName}
        />
      </td>
      {/* URL */}
      <td>
        <Form.Control
          onChange={e => {
            setModifyMenuUrl(e.target.value);
          }}
          value={modifyMenuUrl}
        />
      </td>
      {/* 메뉴권한 */}
      <td>
        <CustomSelect
          options={roleOptions}
          value={role}
          onChange={setRole}
          placeholder="선택"
        />
      </td>
      {/* 상세권한 */}
      <td>
        <CheckboxSelect
          options={detailOptions[role.value]}
          value={roleDetail}
          onChange={e => setRoleDetail(e)}
          placeholder="선택"
          isDisabled={!role}
        />
      </td>
      {/* 순서 */}
      <td>
        <CustomSelect
          options={sortOptionList}
          value={sort}
          onChange={setSort}
          placeholder="선택"
        />
      </td>
      {/* 사용여부 */}
      <td>
        <Form.Check
          type="switch"
          id="isUsed"
          value={isUsed}
          onChange={() => setIsUsed(!isUsed)}
          checked={isUsed === true}
        />
      </td>
      {/* 관리 */}
      <td>
        <div className="btn-area">
          <Button
            size="sm"
            onClick={updateMenu}
            disabled={
              !(
                item.menuCateIdx &&
                modifyMenuName &&
                modifyMenuUrl &&
                role.value &&
                sort.value
              )
            }
          >
            수정
          </Button>
          <Button size="sm" variant="outline-primary" onClick={removeMenu}>
            삭제
          </Button>
        </div>
      </td>
    </tr>
  );
}

export default React.memo(function AdmSystemMenu() {
  const [regIsUsed, setRegIsUsed] = useState(isUsedOptions[0]);

  /** form state */
  const [menuList, setMenuList] = useState([]); // 메뉴 리스트
  const [roleList, setRoleList] = useState([]); // 메뉴권한 리스트
  const [sortOptionList, setSortOptionList] = useState([]); // 등록 :: 순서 옵션
  const [roleDetailOptions, setRoleDetailOptions] = useState({}); // 상세권한 옵션
  const [categoryOptions, setCategoryOptions] = useState([]); // 카테고리 옵션 리스트

  // 등록
  const [regCategory, setRegCategory] = useState({}); // 등록 :: 선택된 카테고리
  const [regRole, setRegRole] = useState({}); // 등록 :: 선택된 권한
  const [regRoleDetail, setRegRoleDetail] = useState([]); // 등록 :: 선택된 상세 권한
  const [sortNo, setSortNo] = useState({}); // 등록 :: 순서

  const [regMenuName, setRegMenuName] = useState(); // 등록 :: 메뉴명
  const [regMenuUrl, setRegMenuUrl] = useState(); // 등록 :: URL

  /** API */
  const [firstCallDone, setFirstCallDone] = useState(false);
  const getMenuList = async () => {
    try {
      const { data } = await getMenuAdnOptions();
      if (data.code === 0) {
        setFirstCallDone(true);

        // 메뉴 리스트
        setMenuList(data.data.webMenuList);

        // 메뉴권한 리스트 설정
        setRoleList(data.data.roleList);
        if (data.data.roleList && data.data.roleList.length > 0) {
          setRegRole(data.data.roleList[0]);
        }

        // 카테고리 옵션 리스트
        setCategoryOptions(data.data.webMenuCategoryList);
        if (
          data.data.webMenuCategoryList &&
          data.data.webMenuCategoryList.length > 0
        ) {
          setRegCategory(data.data.webMenuCategoryList[0]);
        }

        // 상세권한 옵션 설정
        setRoleDetailOptions({
          [ROLE.ADMIN_SYSTEM]: data.data.systemAdminRoleDetailList,
          [ROLE.ADMIN]: data.data.adminRoleDetailList,
          [ROLE.TEACHER]: data.data.teacherRoleDetailList,
          [ROLE.STUDENT]: data.data.studentRoleDetailList,
        });
      }
    } catch (e) {
      // console.log(e);
    }
  };

  // 메뉴 등록
  const registMenu = async event => {
    const target = event.currentTarget;
    target.disabled = true;
    const params = {
      menuCateIdx: regCategory.value,
      menuName: regMenuName,
      menuUrl: regMenuUrl,
      menuRole: regRole.value,
      menuRoleDetail: getMenuDetailString(regRoleDetail),
      menuSort: sortNo.value,
      menuUse: regIsUsed.value,
    };
    try {
      const { data } = await postMenu(params);
      if (data.code === 0) {
        Swal.fire({
          text: '메뉴가 추가되었습니다.',
          confirmButtonText: '확인',
        }).then(result => {
          if (result.isConfirmed) window.location.reload();
        });
      }
    } catch (e) {
      // console.log(e);
    }
    target.disabled = false;
  };

  // Redis 업데이트
  const redisUpdate = async event => {
    const target = event.currentTarget;
    target.disabled = true;
    try {
      const { data } = await putAllWebMenu();
      if (data.code === 0) {
        Swal.fire({
          text: 'Redis 업데이트가 완료되었습니다.',
          confirmButtonText: '확인',
        });
      }
    } catch (e) {
      // console.log(e);
    }
    target.disabled = false;
  };

  /** help function */
  const getSortOptions = (list, categoryIdx, isRegist) => {
    const options = [];
    if (list && list.length > 0 && categoryIdx) {
      const { length } = list.filter(
        item => +item.menuCateIdx === +categoryIdx,
      );
      if (length > 0) {
        for (let i = 1; i <= length; i += 1) {
          options.push({ label: i, value: i });
        }
      }
    }
    if (isRegist) {
      options.push({ label: options.length + 1, value: options.length + 1 });
    }
    return options;
  };

  const getMenuDetailString = list => {
    let result = null;
    if (list && list.length > 0) {
      const valueList = list.map(item => item.value);
      if (valueList && valueList.length > 0) {
        result = `${valueList.join(';')};`;
      }
    }
    return result;
  };

  /** useEffect */
  useEffect(() => {
    getMenuList();
  }, []);

  // 등록 :: 메뉴권한 변경시, 상세권한 체크 해제
  useEffect(() => {
    setRegRoleDetail([]);
  }, [regRole]);

  // 등록 :: 카테고리 변경시 순서 리셋
  useEffect(() => {
    const list = getSortOptions(menuList, regCategory.value, true);
    setSortOptionList(list);
    if (list && list.length > 0) {
      setSortNo(list[list.length - 1]);
    }
  }, [regCategory, menuList]);

  return (
    <main id="admSystem-menu" className="type-02 admSystem">
      <Container>
        <article className="content py">
          <section>
            <Card>
              <Card.Body>
                <div className="sub-title">
                  <h5>메뉴 생성</h5>
                </div>
                <Table className="bt-none">
                  <colgroup>
                    <col width={1} />
                    <col width={3} />
                    <col width={1} />
                    <col width={3} />
                    <col width={1} />
                    <col width={1} />
                    <col width={1} />
                    <col width={1} />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>카테고리</th>
                      <td>
                        <CustomSelect
                          options={categoryOptions}
                          value={regCategory}
                          onChange={setRegCategory}
                          placeholder="선택"
                        />
                      </td>
                      <th>메뉴명</th>
                      <td>
                        <Form.Control
                          onChange={e => {
                            setRegMenuName(e.target.value);
                          }}
                          value={regMenuName || ''}
                        />
                      </td>
                      <th>URL</th>
                      <td colSpan={3}>
                        <Form.Control
                          onChange={e => {
                            setRegMenuUrl(e.target.value);
                          }}
                          value={regMenuUrl || ''}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>메뉴권한</th>
                      <td>
                        <CustomSelect
                          options={roleList}
                          value={regRole}
                          onChange={setRegRole}
                          placeholder="선택"
                        />
                      </td>
                      <th>상세권한</th>
                      <td>
                        {regRole.value !== ROLE.ADMIN &&
                        regRole.value !== ROLE.ADMIN_SYSTEM ? (
                          <CheckboxSelect
                            onChange={e => setRegRoleDetail(e)}
                            options={roleDetailOptions[regRole.value]}
                            value={regRoleDetail}
                            placeholder="선택"
                          />
                        ) : (
                          <div>
                            시스템관리자, 교육청관리자는 <br />
                            권한 상세가 존재하지 않습니다.
                          </div>
                        )}
                      </td>
                      <th>순서</th>
                      <td>
                        <CustomSelect
                          options={sortOptionList}
                          value={sortNo}
                          onChange={setSortNo}
                          placeholder="선택"
                        />
                      </td>
                      <th>사용여부</th>
                      <td>
                        <CustomSelect
                          options={isUsedOptions}
                          value={regIsUsed}
                          onChange={setRegIsUsed}
                          defaultValue={isUsedOptions[0]}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <div className="btn-area">
                  <Button
                    onClick={registMenu}
                    disabled={
                      !(
                        regCategory.value &&
                        regMenuName &&
                        regMenuUrl &&
                        regRole.value &&
                        sortNo.value &&
                        regIsUsed.value
                      )
                    }
                  >
                    신규등록
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </section>
          <section className="mt-3">
            <Card>
              <Card.Body>
                <div className="btn-area justify-content-end mt-0 mb-3">
                  <Button onClick={redisUpdate}>Redis 업데이트</Button>
                </div>
                {/* 상세코드테이블 */}
                <Table className="bt-none">
                  <colgroup>
                    <col width={120} />
                    <col width={120} />
                    <col width={120} />
                    <col width={110} />
                    <col width={150} />
                    <col width={80} />
                    <col width={80} />
                    <col width={160} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>카테고리</th>
                      <th>메뉴명</th>
                      <th>URL</th>
                      <th>메뉴권한</th>
                      <th>상세권한</th>
                      <th>순서</th>
                      <th>사용여부</th>
                      <th>관리</th>
                    </tr>
                  </thead>
                  <tbody>
                    {menuList.length > 0
                      ? menuList.map((item, index) => {
                          return (
                            <CategoryTr
                              // eslint-disable-next-line react/no-array-index-key
                              key={`menu-idx-${index}`}
                              item={item}
                              roleList={roleList}
                              roleDetailOptions={roleDetailOptions}
                              sortOptions={getSortOptions(
                                menuList,
                                item.menuCateIdx,
                              )}
                            />
                          );
                        })
                      : firstCallDone && (
                          <tr>
                            <td colSpan={7}>
                              <p className="no-data">데이터가 없습니다.</p>
                            </td>
                          </tr>
                        )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </section>
        </article>
      </Container>
    </main>
  );
});

const isUsedOptions = [
  { label: '사용', value: 'Y' },
  { label: '미사용', value: 'N' },
];
