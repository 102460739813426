import axios from 'axios';
import Axios from './axios';

const baseURL = process.env.REACT_APP_API_URL;

const API_INFO_OPEN = 'api/v1/info/open';
const API_INFO_AUTH = 'api/v1/auth';
const API_AUTH_OPEN = `${baseURL}api/v1/auth/open`;

const MENU_LIST = `${API_INFO_OPEN}/webMenuList`;
const MEMBER_INFO = `${API_INFO_AUTH}/getMemberInfo`;
const ACCESS_TOKEN = `${API_AUTH_OPEN}/getAccessToken`;

//* 회원 기본 정보
export const getMemberInfo = () => {
  return Axios.get(`${MEMBER_INFO}`);
};

//* 메뉴 리스트
export const getMenuList = userType => {
  return Axios.get(`${MENU_LIST}/${userType}`);
};

//* 기본 Axios > baseUrl 재설정
export const getAccessToken = refreshToken => {
  // Refresh Token 주입
  axios.defaults.headers.common.Authorization = `Bearer ${refreshToken}`;
  return axios.get(`${ACCESS_TOKEN}`);
};
