/**
 *   [ 바우처 조회 API ]
 *
 */
import Axios from '@api/axios';

const API_TEACHER = 'api/v1/teacher';

const SELECT_SCHOOL_VOUCHER = `${API_TEACHER}/school-voucher`;

// 바우처 조회 > 학교별 바우처 리스트
export const selectSchoolVoucher = () => {
  return Axios.get(`${SELECT_SCHOOL_VOUCHER}`);
};
