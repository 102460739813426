/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { Container, Form, Button, Card, Table } from 'react-bootstrap';
import Moment from 'react-moment';
import { useHistory, useParams } from 'react-router-dom';
import { FormStatus, FILE_FORM_TYPE } from '@common/consts';
import { publicDownload } from '@api/file';
import Utils from '@common/Utils';
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import {
  deleteNotice,
  getNoticeDetail,
  patchNoticeMainYn,
} from '../../../api/board/notice';
import {
  deleteArchive,
  getArchiveDetail,
  patchArchiveMainYn,
} from '../../../api/board/archive';

export default React.memo(function AdmEduPostDetail(props) {
  const history = useHistory();
  // 게시판 확인
  const { boardId, seq } = useParams();

  const [thisPost, setThisPost] = useState({});

  const getNotice = async () => {
    const { data } = await getNoticeDetail(seq);
    if (data.code === 0) {
      setThisPost(data.data);
    }
  };

  const getArchive = async () => {
    const { data } = await getArchiveDetail(seq);
    if (data.code === 0) {
      setThisPost(data.data);
    }
  };

  const [thisBoard, setThisBoard] = useState({});
  const handleBoard = id => {
    switch (id) {
      case 'notice':
        setThisBoard({
          name: '공지사항',
        });
        getNotice();
        break;
      case 'eduArchive':
        setThisBoard({
          name: '교육자료실',
        });
        getArchive();
        break;
      default:
        break;
    }
  };

  const removeNotice = async () => {
    const { data } = await deleteNotice(seq);
    if (data.code === 0) {
      Swal.fire({
        text: '정상적으로 삭제 되었습니다.',
        confirmButtonText: '확인',
      }).finally(() => {
        history.push(`/admEdu/post/${boardId}/list`);
      });
    }
  };

  const removeArchive = async () => {
    const { data } = await deleteArchive(seq);
    if (data.code === 0) {
      Swal.fire({
        text: '정상적으로 삭제 되었습니다.',
        confirmButtonText: '확인',
      }).finally(() => {
        history.push(`/admEdu/post/${boardId}/list`);
      });
    }
  };

  const updateMainNotice = async () => {
    const params = {
      seq,
      mainYn: thisPost.mainYn === 'Y' ? 'N' : 'Y',
    };
    const { data } = await patchNoticeMainYn(params);
    if (data.code === 0) {
      Swal.fire({
        text: '정상적으로 수정 되었습니다.',
        confirmButtonText: '확인',
      }).finally(() => {
        handleBoard(boardId);
      });
    }
  };

  const updateMainArchive = async () => {
    const params = {
      seq,
      mainYn: thisPost.mainYn === 'Y' ? 'N' : 'Y',
    };
    const { data } = await patchArchiveMainYn(params);
    if (data.code === 0) {
      Swal.fire({
        text: '정상적으로 수정 되었습니다.',
        confirmButtonText: '확인',
      }).finally(() => {
        handleBoard(boardId);
      });
    }
  };

  const openFile = async (fileSeq, fileName) => {
    const { data } = await publicDownload(fileSeq);
    const blob = new Blob([data]);
    const fileUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName;
    link.style.display = 'none';
    link.click();
    link.remove();
  };

  useEffect(() => {
    handleBoard(boardId);
  }, [boardId]);

  return (
    <main id="admEdu-post-detail" className="type-02">
      <Container>
        <article className="content py">
          <section>
            <div className="title">
              <h5>{thisBoard.name}</h5>
            </div>

            <Card>
              <Card.Body>
                <div className="sub-title justify-content-end">
                  <Button
                    variant="primary"
                    onClick={() => {
                      if (boardId === 'notice') {
                        updateMainNotice();
                      } else {
                        updateMainArchive();
                      }
                    }}
                  >
                    {thisPost.mainYn === 'N' ? '메인등록' : '메인등록해지'}
                  </Button>
                </div>
                <Table>
                  <colgroup>
                    <col width={160} />
                    <col width="*" />
                    <col width={160} />
                    <col width="*" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>순번</th>
                      <td>{thisPost.seq}</td>
                      <th>작성자</th>
                      <td>{thisPost.writer}</td>
                    </tr>
                    <tr>
                      <th>공개여부</th>
                      <td>{thisPost.showYn === 'Y' ? '공개' : '비공개'}</td>
                      <th>공개대상</th>
                      <td>
                        {thisPost.publicYn === 'Y'
                          ? '학생'
                          : thisPost.publicYn === 'N'
                          ? '학교'
                          : '전체'}
                      </td>
                    </tr>
                    <tr>
                      <th>작성일</th>
                      <td>
                        <Moment date={thisPost.regDate} format="YYYY.MM.DD" />
                      </td>
                      <th>조회수</th>
                      <td>{thisPost.viewCnt}</td>
                    </tr>
                    <tr>
                      <th>제목</th>
                      <td colSpan={3} className="text-start">
                        {thisPost.title}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={4}>
                        <div
                          className="post-content"
                          dangerouslySetInnerHTML={{ __html: thisPost.content }}
                        />
                      </td>
                    </tr>
                    <tr>
                      {thisPost.files && thisPost.files.length > 0 && (
                        <td colSpan={4}>
                          <ul className="attatchment-box">
                            {thisPost.files.map((v, i) => {
                              return (
                                <li key={`file_${i}`}>
                                  <a
                                    href="#none"
                                    onClick={() => {
                                      openFile(v.seq, v.fileName);
                                    }}
                                  >
                                    {v.fileName}
                                    {v.fileSize && (
                                      <span>{`(${Utils.formatFileSize(
                                        v.size || v.fileSize,
                                      )})`}</span>
                                    )}
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </td>
                      )}
                    </tr>
                  </tbody>
                </Table>
                <div className="flex-between my-3">
                  <Button
                    variant="primary"
                    onClick={() => {
                      // history.push(`/admEdu/post/${boardId}/list`);
                      history.goBack();
                    }}
                  >
                    목록
                  </Button>
                  <div className="btn-area m-0">
                    <Button
                      variant="outline-primary"
                      onClick={() => {
                        if (boardId === 'notice') {
                          removeNotice();
                        } else {
                          removeArchive();
                        }
                      }}
                    >
                      삭제
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => {
                        props.history.push({
                          pathname: `/admEdu/post/${boardId}/write`,
                          state: {
                            thisBoard,
                            writeType: 'modify',
                            boardSeq: seq,
                          },
                        });
                      }}
                    >
                      수정
                    </Button>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </section>
        </article>
      </Container>
    </main>
  );
});
