/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import { Container, Button, Table, Modal } from 'react-bootstrap';
import { PdfPreview, PreviewModal } from '@components';
import Swal from 'sweetalert2';

//* Utils
import Utils from '@common/Utils';

//* Consts
import { ROLE, FormStatus, FILE_FORM_TYPE } from '@common/consts'; // Form 상태 text, class

//* API
import {
  selectCertificateList,
  updateStatusCertificate,
} from '@api/teacher/majorJobCert';

import { getFilePathFromRedis } from '@api/file';

// 유저 정보 초기값
const initialStudentInfo = {
  name: '',
  grade: '',
  summaryPoint: 0,
  summaryStatus: 'NONE',
};

//* [ Main ]
export default React.memo(function MajorJobComment(props) {
  //* #################################################################
  //* [ States ]
  //* #################################################################
  const history = useHistory();

  // 유저 권한 타입
  const authType = props.userInfo.type;

  //* 조회 학년
  const { studentIdx } = useParams();

  //* 학생 정보
  const [studentInfo, setStudentInfo] = useState(initialStudentInfo);

  //* 활동내역 리스트
  const [certList, setCertList] = useState([]);

  // 중복요청 방지
  const [isSentRequest, setIsSentRequest] = useState(false);

  // 페이지 로딩 완료 Flag
  const [firstCallDone, setFirstCallDone] = useState(false);

  // PDF 미리보기 ( react-pdf )
  const [fileUrl, setFileUrl] = useState(null);
  const [pdfPageNum, setPdfPageNum] = useState(1);
  const [pdfTotalPages, setPdfTotalPages] = useState(1);

  //* #################################################################
  //* [ Utils ]
  //* #################################################################
  // PDF 파일 로딩 완료
  const onDocumentLoadSuccess = ({ numPages }) => {
    setPdfPageNum(1);
    setPdfTotalPages(numPages);
  };

  //* #################################################################
  //* [ Preview Modal ]
  //* #################################################################
  // Flag
  const [previewModalShow, setPreviewModalShow] = useState(false);

  // Open
  const openPreviewModal = () => {
    openFile({ fileSeq: null });
    setPreviewModalShow(true);
  };

  // Close
  const closePreviewModal = () => {
    setPreviewModalShow(false);
  };

  //* #################################################################
  //* [ API ] 파일 조회 ( 첨부파일, 승인이수 )
  //* #################################################################
  const openFile = async ({ fileSeq }) => {
    let paramMap;

    // [ CASE 1 ] 첨부파일 조회
    if (fileSeq) {
      paramMap = {
        formType: FILE_FORM_TYPE.FILE_CERTIFICATE,
        fileSeq,
      };
    }
    // [ CASE 2 ] 승인이수 조회
    else {
      let formType;

      switch (+studentInfo.grade) {
        case 1:
          formType = FILE_FORM_TYPE.FILE_STATIC_FORM_GUIDE_CERTIFICATE_1;
          break;
        case 2:
          formType = FILE_FORM_TYPE.FILE_STATIC_FORM_GUIDE_CERTIFICATE_2;
          break;
        case 3:
          formType = FILE_FORM_TYPE.FILE_STATIC_FORM_GUIDE_CERTIFICATE_3;
          break;
        default:
          Swal.fire({
            text: '잘못된 접근입니다.',
            confirmButtonText: '확인',
          });
          return;
      }

      paramMap = {
        formType,
        fileSeq,
      };
    }

    // Axios
    try {
      const { data } = await getFilePathFromRedis(paramMap);

      if (data.code === 0) {
        const redisKey = data.data;
        const hostName = window.location.hostname;

        const url = Utils.getFileOpenUrl(hostName, redisKey);

        // 파일 다운로드 (첨부파일)
        if (fileSeq) {
          window.open(url);
        }
        // PDF 미리보기 (이수기준)
        else {
          setFileUrl(url);
        }
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 학년별 전공 자격증 리스트 - SELECT
  //* #################################################################
  const selectCertificateListByGrade = async () => {
    // 파라미터 설정
    const paramMap = {
      studentIdx,
    };

    // Axios
    try {
      const { data } = await selectCertificateList(paramMap);

      if (data.code === 0) {
        setFirstCallDone(true);

        // 리스트 데이터 후처리
        const { studentInfo: student, list } = data.data;

        // 학생 정보 갱신
        setStudentInfo({
          name: student.student_name,
          grade: student.grade,
          summaryPoint: student.summary_point,
          summaryStatus: student.summary_status,
        });

        // 자격증 정보
        list.forEach(form => {
          const isValid =
            // Utils.checkDateTime(new Date(form.certificate_date)) &&
            Utils.checkNumber(form.certificate_gubun) &&
            Utils.checkNumber(form.certificate_type) &&
            Utils.checkNull(form.certificate_name) &&
            Utils.checkNull(form.certificate_number) &&
            Utils.checkNull(form.certificate_gov) &&
            form.files.length > 0;

          form.isValid = isValid || isValid !== undefined;
        });

        setCertList(list);
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 전공 자격증 승인, 보완, 승인취소 - UPDATE
  //* #################################################################
  const updateRequest = async (formSeq, reqStatus) => {
    // 중복요청 확인
    if (isSentRequest) {
      return;
    }

    // 승인 = CON
    // 승인취소, 보완 = REJ
    const status = reqStatus === 'CON' ? 'CON' : 'REJ';

    // JSON 파라미터
    const param = {
      formSeq,
      status,
    };

    // Axios
    try {
      const { data } = await updateStatusCertificate(param);

      if (data.code === 0) {
        // 승인 완료
        if (reqStatus === 'CON') {
          Swal.fire({
            text: '승인 되었습니다.',
            confirmButtonText: '확인',
          });
        }

        // 승인취소 완료
        else if (reqStatus === 'CAN') {
          Swal.fire({
            text: '승인취소 되었습니다.',
            confirmButtonText: '확인',
          });
        }

        // 보완 완료
        else if (reqStatus === 'REJ') {
          Swal.fire({
            text: '보완 되었습니다.',
            confirmButtonText: '확인',
          });
        }

        // 리스트 갱신
        selectCertificateListByGrade();

        // 중복요청 방지 해제
        setIsSentRequest(false);
      }
    } catch (e) {
      // 중복요청 방지 해제
      setIsSentRequest(false);

      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ useEffect ]
  //* #################################################################
  useEffect(() => {
    selectCertificateListByGrade();
  }, []);

  //* #################################################################
  //* [ Components ]
  //* #################################################################
  // 버튼 (인쇄, 목록, 삭제, 수정, 취소)
  const customButton = (type, seq) => {
    let variant;
    let event;
    let text;

    switch (type) {
      // 보완
      case 'reject':
        variant = 'outline-primary';
        event = () =>
          Swal.fire({
            title: '알림',
            text: '보완 처리 하시겠습니까?',
            confirmButtonText: '확인',
            cancelButtonText: '취소',
            showCancelButton: true,
            reverseButtons: true,
          }).then(result => {
            if (result.isConfirmed) {
              updateRequest(seq, 'REJ');
            }
          });
        text = '보완';
        break;
      // 승인취소
      case 'cancel':
        variant = 'outline-primary';
        event = () =>
          Swal.fire({
            title: '알림',
            text: '승인취소 하시겠습니까?',
            confirmButtonText: '확인',
            cancelButtonText: '취소',
            showCancelButton: true,
            reverseButtons: true,
          }).then(result => {
            if (result.isConfirmed) {
              updateRequest(seq, 'CAN');
            }
          });
        text = '승인취소';
        break;
      // 승인
      case 'confirm':
        variant = 'outline-primary';
        event = () =>
          Swal.fire({
            title: '알림',
            text: '승인 처리 하시겠습니까?',
            confirmButtonText: '확인',
            cancelButtonText: '취소',
            showCancelButton: true,
            reverseButtons: true,
          }).then(result => {
            if (result.isConfirmed) {
              updateRequest(seq, 'CON');
            }
          });
        text = '승인';
        break;
      default:
        break;
    }

    return (
      <Button size="sm" variant={variant} onClick={event}>
        {text}
      </Button>
    );
  };

  const [linkList, setLinkList] = useState([]);

  useEffect(() => {
    switch (authType) {
      case ROLE.ADMIN:
        setLinkList([
          {
            name: '프로그램 현황',
            path: '/admEdu/student/program',
          },
        ]);
        break;
      case ROLE.TEACHER:
        setLinkList([
          {
            name: '학생 관리',
            path: '/teacher/studentMgmt/studentMgmt',
          },
        ]);
        break;
      case ROLE.STUDENT:
        setLinkList([
          {
            name: 'HOME',
            path: '/',
          },
        ]);
        break;
      default:
        break;
    }
  }, [authType]);

  //* #################################################################
  //* [ return ]
  //* #################################################################
  return (
    <main id="program-majorJob-comment">
      <Container>
        <article className="content">
          <ul className="path">
            {linkList &&
              linkList.length > 0 &&
              linkList.map((v, i) => {
                return (
                  <li key={`link_${i}`}>
                    <Link to={v.path}>{v.name}</Link>
                  </li>
                );
              })}
            <li>
              <Link to="#none">전공자격증취득</Link>
            </li>
          </ul>

          {/* ////////// ↓ 상단 타이틀영역 ////////// */}
          <section>
            <div className="title mb-60">
              <h5>결과 보고서</h5>
            </div>
          </section>

          {/* ////////// ↓ 컨텐츠 ////////// */}
          <section className="mb-5">
            {/* 학년 정보 */}
            <div className="sub-title">
              <h5>{studentInfo.grade}학년 취득 자격증</h5>
            </div>
            <div className="mb-4">
              <Button variant="text-underline" onClick={openPreviewModal}>
                승인이수 보기
              </Button>
            </div>
            <Table>
              <colgroup>
                <col width={110} />
                <col width={110} />
                <col width={140} />
                <col width="*" />
                <col width={120} />
                <col width={120} />
                <col width={65} />
                <col width={120} />
                {authType !== ROLE.ADMIN && <col width={180} />}
              </colgroup>
              <thead>
                <tr>
                  <th>취득일</th>
                  <th>구분</th>
                  <th>종류</th>
                  <th className="text-start">자격증</th>
                  <th>발급기관</th>
                  <th>증빙자료</th>
                  <th>점수</th>
                  <th>상태</th>
                  {authType !== ROLE.ADMIN && <th>관리</th>}
                </tr>
              </thead>
              {/* 자격증 리스트 */}
              <tbody>
                {certList.length > 0 ? (
                  <>
                    {certList.map((form, idx) => (
                      <tr key={idx}>
                        {/* 취득일 */}
                        <td>{form.certificate_date}</td>
                        {/* 자격증 구분 HAN */}
                        <td>{form.certificate_gubun_han}</td>
                        {/* 자격증 종류 HAN */}
                        <td>
                          {`${form.certificate_assess_type_han}`}
                          <br />
                          {`(${form.certificate_type_han})`}
                        </td>
                        {/* 자격증 번호 & 이름 */}
                        <td className="text-start">
                          <small className="text-gray">
                            자격증 번호 : {form.certificate_number}
                          </small>
                          <p>{form.certificate_name}</p>
                        </td>
                        {/* 자격증 발급기관 */}
                        <td>{form.certificate_gov}</td>
                        {/* 첨부 파일 */}
                        <td>
                          {form.files &&
                            form.files.length > 0 &&
                            form.files.map((file, fileIdx) => {
                              return (
                                <Button
                                  key={fileIdx}
                                  className="btn-icon ic-down"
                                  onClick={() =>
                                    openFile({ fileSeq: file.file_seq })
                                  }
                                >
                                  {file.file_name_org}
                                </Button>
                              );
                            })}
                        </td>
                        {/* 바우처 점수 */}
                        <td>{form.point}</td>
                        {/* 승인 상태 */}
                        <td className="status-text">
                          <strong className={FormStatus[form.status].class}>
                            {FormStatus[form.status].text}
                            <br />
                            {form.status === 'CON' && (
                              <small>
                                {Utils.getJustDateString(form.confirm_date)}
                              </small>
                            )}
                          </strong>
                        </td>
                        {/* 관리 (버튼) */}
                        {authType !== ROLE.ADMIN && (
                          <td>
                            <div className="btn-area">
                              {/* 승인 */}
                              {form.status === 'CON' && (
                                <>{customButton('cancel', form.seq)}</>
                              )}
                              {/* 승인요청 */}
                              {form.status === 'REQ' && (
                                <>
                                  {customButton('reject', form.seq)}
                                  {customButton('confirm', form.seq)}
                                </>
                              )}
                            </div>
                          </td>
                        )}
                      </tr>
                    ))}
                  </>
                ) : (
                  firstCallDone && (
                    <tr>
                      <td
                        colSpan={authType !== ROLE.ADMIN ? '9' : '8'}
                        className="no-data"
                      >
                        등록된 자격증이 없습니다.
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          </section>

          {/* ////////// ↓ 하단 버튼 //////////  */}
          <div className="btn-area mb-0">
            <Button
              onClick={() => {
                history.goBack();
              }}
              variant="outline-primary"
              size="lg"
            >
              목록
            </Button>
          </div>
        </article>
      </Container>

      {/* PDF 미리보기 모달 */}
      <PreviewModal
        className="preview-modal"
        title="승인이수"
        show={previewModalShow}
        onHide={closePreviewModal}
      >
        <Modal.Body className="border-0 text-center">
          <PdfPreview
            fileUrl={fileUrl}
            loadHandler={onDocumentLoadSuccess}
            pageNum={pdfPageNum}
            totalPages={pdfTotalPages}
            isPageDisabled={false}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={closePreviewModal}>
            닫기
          </Button>
        </Modal.Footer>
      </PreviewModal>
    </main>
  );
});
