/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useState, useRef, useEffect } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import { Container, Button, Form, Card, Image } from 'react-bootstrap';
import { Utils } from '@common';
import { CustomSelect } from '@components';
import { images } from '@assets';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Swal from 'sweetalert2';

//* Api
import {
  selectFormAbilityBasicInfo,
  selectFormAbilityInfo,
  insertUpdateAbilityTest,
} from '@api/student/jobAssess';

//* Consts
import { RANK_MENU } from '@common/consts';

// 유저 정보
const initialUserInfo = {
  name: '',
  id: '',
};

// 등급정보
const initialRankInfo = {
  KOR: null,
  ENG: null,
  MATH: null,
  SOL: null,
};

//* [ Main ]
export default React.memo(function JobAssessWrite(props) {
  //* #################################################################
  //* [ State ]
  //* #################################################################
  const history = useHistory();

  // URL Param
  const { seq: formSeq } = useParams();

  // 개인정보
  const [userInfo, setUserInfo] = useState(initialUserInfo);

  // Form
  const [testDate, setTestDate] = useState(new Date()); // datepicker
  const [testTitle, setTestTitle] = useState(''); // 평가명
  const [rankMap, setRankMap] = useState(initialRankInfo); // 국어, 영어, 수리, 문제해결
  const [avgRank, setAvgRank] = useState(1.0);

  // 파일
  const [files, setFiles] = useState([]);
  const [initialFiles, setInitalFiles] = useState([]);
  const selectFile = useRef('');

  // 중복요청 방지
  // const [isSentRequest, setIsSentRequest] = useState(false);

  //* #################################################################
  //* [ Utils ]
  //* #################################################################
  // 평가명 입력
  const testTitleHandler = e => {
    const title = e.target.value;
    let isValidRank = true;

    setTestTitle(title);

    // 유효성 검증
    Object.keys(rankMap).forEach(rankType => {
      if (Number.isNaN(rankMap[rankType]) || !title) {
        isValidRank = false;
      }
    });
  };

  // 과목 등급 드랍다운
  const changeHandler = (type, item) => {
    const { value } = item;
    // 등급 갱신
    setRankMap(prev => ({
      ...prev,
      [type]: item,
    }));
  };

  useEffect(() => {
    // 평균 계산 & 유효성 검증
    let total = 0;
    let count = 0;
    let isValidRank = true;

    Object.keys(rankMap).forEach(rankType => {
      if (rankMap[rankType] && rankMap[rankType].value) {
        const eachValue = Number(rankMap[rankType].value);
        total += !Number.isNaN(eachValue) ? eachValue : 0;
        count += eachValue ? 1 : 0;

        if (Number.isNaN(eachValue) || !testTitle) {
          isValidRank = false;
        }
      }
    });

    if (count === 0) {
      count = 1;
    }

    setAvgRank((total / count).toFixed(2)); // 평균 등급 갱신
  }, [rankMap]);

  // 파일 첨부
  const addFile = e => {
    if (files.length >= 2) {
      Swal.fire({
        text: '첨부 파일은 2개까지 등록할 수 있습니다.',
        confirmButtonText: '확인',
      });
      return;
    }
    if (e.target.files[0]) setFiles([...files, e.target.files[0]]);
    selectFile.current.value = null;
  };

  // 파일 삭제
  const removeFile = idx => {
    const filteredList = files.filter((file, index) => index !== idx);
    setFiles([...filteredList]);
  };

  //* #################################################################
  //* [ API ] 기본 정보 가져오기
  //* #################################################################
  const getBasicInfo = async () => {
    // Axios
    try {
      // [ CASE 1 ] - 신규작성
      if (!formSeq) {
        const { data } = await selectFormAbilityBasicInfo();

        if (data.code === 0) {
          const userData = data.data;

          // 유저 정보
          setUserInfo({
            name: userData.name,
            id: userData.id,
          });
        }
      }
      // [ CASE 2 ] - 수정
      else {
        // 파라미터 설정
        const param = {
          formSeq,
        };

        const { data } = await selectFormAbilityInfo(param);

        if (data.code === 0) {
          const formData = data.data;
          // 유저 정보
          setUserInfo({
            name: formData.name,
            id: formData.id,
          });

          const formStatus = formData.status;

          if (formStatus !== 'ING' && formStatus !== 'REJ') {
            Swal.fire({
              text: '잘못된 접근입니다.',
              confirmButtonText: '확인',
            });
            history.goBack();
          }

          // Form 정보
          setTestDate(new Date(formData.test_date));
          setTestTitle(formData.test_title);
          setRankMap({
            KOR: RANK_MENU.KOR.find(
              el => Number(el.value) === formData.test_korean,
            ),
            ENG: RANK_MENU.ENG.find(
              el => Number(el.value) === formData.test_english,
            ),
            MATH: RANK_MENU.MATH.find(
              el => Number(el.value) === formData.test_math,
            ),
            SOL: RANK_MENU.SOL.find(
              el => Number(el.value) === formData.test_solve,
            ),
          });
          setAvgRank(formData.test_average);
          setFiles([...formData.files]);
          setInitalFiles([...formData.files]);
        }
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 임시저장 ( INSERT, UPDATE )
  //* #################################################################
  const insertUpdateForm = async (event, reqStatus) => {
    // // 중복요청 확인
    // if (isSentRequest) {
    //   return;
    // }
    const target = event.currentTarget;
    target.disabled = true;

    // 임시저장
    if (reqStatus === 'ING') {
      // 필수값 체크 (등급)
      if (!rankMap.KOR || !rankMap.ENG || !rankMap.MATH || !rankMap.SOL) {
        Swal.fire({
          text: '평가결과를 확인해주세요.',
          confirmButtonText: '확인',
        });
        target.disabled = false;
        return;
      }

      const allFileSeq = [...initialFiles.map(file => file.file_seq)];
      const saveFileSeq = [];
      const deleteFileSeq = [];

      // FormData
      const formData = new FormData();

      // 파일 append (File 타입 Only)
      for (let i = 0; i < files.length; i += 1) {
        // File 타입
        if (Object.getPrototypeOf(files[i]) === File.prototype) {
          formData.append('files', files[i]);
        }
        // Object 타입
        else {
          saveFileSeq.push(files[i].file_seq);
        }
      }

      // 삭제 파일 Seq 추출 (기존파일)
      allFileSeq.forEach(seq => {
        if (saveFileSeq.indexOf(seq) === -1) deleteFileSeq.push(seq);
      });

      // JSON 파라미터
      const param = {
        formSeq,
        status: reqStatus,
        test_date: testDate,
        test_title: testTitle,
        rankMap: {
          KOR: rankMap.KOR.value,
          ENG: rankMap.ENG.value,
          MATH: rankMap.MATH.value,
          SOL: rankMap.SOL.value,
        },
        test_average: avgRank,
        deleteFileSeq,
      };

      // JSON append
      formData.append(
        'param',
        new Blob([JSON.stringify(param)], { type: 'application/json' }),
      );

      // 중복요청 방지
      // setIsSentRequest(true);

      // Axios
      try {
        const { data } = await insertUpdateAbilityTest(formData);
        if (data.code === 0) {
          const upsertFormSeq = data.data;

          if (upsertFormSeq && upsertFormSeq !== 0) {
            history.push(
              `/student/employAbility/jobAssess/report/${upsertFormSeq}`,
            );
          }
        }
      } catch (e) {
        target.disabled = false;
        // 중복요청 방지 해제
        // setIsSentRequest(false);
        // alert(e.response.data.message);
      }
    } else {
      Swal.fire({
        text: '입력을 확인해주세요.',
        confirmButtonText: '확인',
      });
      target.disabled = false;
    }
    target.disabled = false;
  };

  //* #################################################################
  //* [ useEffect ]
  //* #################################################################
  useEffect(() => {
    getBasicInfo();
  }, []);

  //* #################################################################
  //* [ return ]
  //* #################################################################
  return (
    <main id="student-jobAssess-write">
      <Container>
        <article className="content">
          <ul className="path">
            <li>
              <Link to="/">HOME</Link>
            </li>
            <li>
              <Link to="/student/employAbility/jobAssess">
                직업기초능력평가
              </Link>
            </li>
            <li>
              <Link to="#none">작성 페이지</Link>
            </li>
          </ul>

          {/* ////////// ↓ 상단 타이틀영역 ////////// */}
          <section>
            <div className="title">
              <h5>직업기초능력평가 결과 보고서</h5>
            </div>
          </section>

          {/* ////////// ↓ 컨텐츠 ////////// */}
          {/* 기본정보 */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>기본정보</h5>
            </div>
            <Card>
              <ul className="form-list">
                <li>
                  <h5>학번</h5>
                  <Form.Control type="text" value={userInfo.id} readOnly />
                </li>
                <li>
                  <h5>이름</h5>
                  <Form.Control
                    type="text"
                    value={Utils.decrypt(userInfo.name)}
                    readOnly
                  />
                </li>
              </ul>
            </Card>
          </section>

          {/* 평가정보 */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>평가정보</h5>
            </div>
            <Card>
              <ul className="form-list">
                <li>
                  <h5>평가일</h5>
                  <div className="mw-input">
                    <DatePicker
                      className="input-datepicker"
                      name="assess-date"
                      dateFormat="yyyy.MM.dd"
                      selected={testDate}
                      onChange={date => setTestDate(date)}
                      // maxDate={new Date()}
                      customInput={<Form.Control style={{ width: '100%' }} />}
                      showPopperArrow={false}
                    />
                  </div>
                </li>
                <li>
                  <h5>평가명</h5>
                  <div className="mw-input">
                    <Form.Control
                      type="text"
                      placeholder="평가명을 입력하세요."
                      maxLength={20}
                      onChange={testTitleHandler}
                      value={testTitle}
                    />
                  </div>
                </li>
              </ul>
            </Card>
          </section>

          {/* 직업기초능력평가 결과  */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>직업기초능력평가 결과</h5>
            </div>
            <Card>
              <ul className="form-list">
                <li>
                  <h5>의사소통 국어</h5>
                  <div className="lw-input">
                    <CustomSelect
                      onChange={data => {
                        changeHandler('KOR', data);
                      }}
                      options={RANK_MENU.KOR}
                      value={rankMap.KOR}
                      placeholder="등급을 선택하세요."
                    />
                  </div>
                </li>
                <li>
                  <h5>의사소통 영어</h5>
                  <div className="lw-input">
                    <CustomSelect
                      onChange={data => changeHandler('ENG', data)}
                      options={RANK_MENU.ENG}
                      value={rankMap.ENG}
                      placeholder="등급을 선택하세요."
                    />
                  </div>
                </li>
                <li>
                  <h5>수리활용</h5>
                  <div className="lw-input">
                    <CustomSelect
                      onChange={data => changeHandler('MATH', data)}
                      options={RANK_MENU.MATH}
                      value={rankMap.MATH}
                      placeholder="등급을 선택하세요."
                    />
                  </div>
                </li>
                <li>
                  <h5>문제해결</h5>
                  <div className="lw-input">
                    <CustomSelect
                      onChange={data => changeHandler('SOL', data)}
                      options={RANK_MENU.SOL}
                      value={rankMap.SOL}
                      placeholder="등급을 선택하세요."
                    />
                  </div>
                </li>
                <li style={{ marginTop: 30 }}>
                  <h5>평균</h5>
                  <strong className="ms-3">{+avgRank}</strong>
                </li>
              </ul>
            </Card>
          </section>

          {/* 파일 첨부 */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>파일 첨부</h5>
              <Button
                onClick={() => selectFile.current.click()}
                variant="outline-primary"
                size="md"
              >
                파일첨부
              </Button>
              <Form.Control
                ref={selectFile}
                onChange={addFile}
                type="file"
                // accept="image/*, .pdf"
                style={{ display: 'none' }}
              />
            </div>
            <Card>
              {files.length === 0 ? (
                // ↓ 파일 첨부
                <div
                  className="drag-box"
                  onDrop={e => {
                    console.log(e);
                  }}
                  onDrag={e => {
                    console.log(e);
                  }}
                >
                  <p>파일을 첨부해주세요.</p>
                </div>
              ) : (
                // ↓ 첨부된 파일
                <ul className="drag-attachment-list">
                  {files.map((file, idx) => {
                    return (
                      <li key={idx}>
                        <p>{file.name || file.file_name_org}</p>
                        <Button variant="iconOnly">
                          <Image
                            src={images.icCancel}
                            onClick={() => removeFile(idx)}
                          />
                        </Button>
                      </li>
                    );
                  })}
                </ul>
              )}
            </Card>
          </section>

          {/* ////////// ↓ 하단 버튼(신규작성/임시저장) //////////  */}
          <div className="btn-area">
            <Button
              size="lg"
              variant="outline-primary"
              onClick={e => insertUpdateForm(e, 'ING')}
            >
              저장
            </Button>
          </div>
        </article>
      </Container>
    </main>
  );
});
