/**
 *   [ 직무기초 포트폴리오 API ]
 *
 */
import Axios from '../axios';

const API_TEACHER = 'api/v1/teacher';

const PORTFOLIO_INFO = `${API_TEACHER}/portfolio-info`;
const PORTFOLIO = `${API_TEACHER}/portfolio`;
const CERTIFICATE_LIST = `${API_TEACHER}/certificate-list`;

const formDataConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

// 직무기초 포트폴리오 정보 조회
export const selectPortfolioInfoByTeacher = param => {
  return Axios.post(`${PORTFOLIO_INFO}`, param);
};

// 자격증 리스트 조회
export const selectCertificateListByTeacher = param => {
  return Axios.post(`${CERTIFICATE_LIST}`, param);
};

// 직무기초 포트폴리오 임시저장, 승인요청, 승인요청 취소, 삭제
export const upsertFormPortfolioByTeacher = param => {
  return Axios.post(`${PORTFOLIO}`, param);
};
