/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useState, useRef, useEffect } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import { Container, Button, Table, Modal, Form, Image } from 'react-bootstrap';
import { CustomModal, CustomSelect } from '@components';
import { images } from '@assets';
import DatePicker from 'react-datepicker';
import { useReactToPrint } from 'react-to-print';
import 'react-datepicker/dist/react-datepicker.css';
import Swal from 'sweetalert2';

//* Utils
import Utils from '@common/Utils';

//* Consts
import {
  FormStatus,
  CERT_GUBUN,
  FILE_FORM_TYPE,
  CERT_PROCESS_TYPE,
  CERT_PROVE_TYPE,
} from '@common/consts'; // Form 상태 text, class

//* API
import {
  selectCertificateList,
  insertUpdateCertificate,
} from '@api/student/majorJobCert';
import { getFilePathFromRedis } from '@api/file';

//* [ Main ]
export default React.memo(function MajorJobReport(props) {
  //* #################################################################
  //* [ States ]
  //* #################################################################
  const history = useHistory();
  const { type: typeParam, grade } = useParams();

  // const [showReportList, setShowReportList] = useState([1]);
  const [viewType, setViewType] = useState(typeParam);

  //* 조회 학년
  const validGrades = ['1', '2', '3'];

  //* 활동내역 리스트
  const [certList, setCertList] = useState([]);

  //* 자격증 타입 드랍다운
  const [certTypeList, setCertTypeList] = useState(CERT_PROCESS_TYPE);

  // 신규등록 변수
  const [formSeq, setFormSeq] = useState(); // SEQ
  const [certDate, setCertDate] = useState(new Date()); // 취득일
  const [certGubun, setCertGubun] = useState(''); // 자격증 구분
  const [certType, setCertType] = useState(''); // 자격증 종류
  const [certName, setCertName] = useState(''); // 자격증 이름
  const [certNumber, setCertNumber] = useState(''); // 자격증 번호
  const [certGov, setCertGov] = useState(''); // 발급 기관

  const [files, setFiles] = useState([]); // 증빙자료 (복수)
  const fileRef = useRef(''); // 증빙자료

  // 중복요청 방지
  const [isSentRequest, setIsSentRequest] = useState(false);

  //* #################################################################
  //* [ Modal ]
  //* #################################################################
  const [showWriteCertModal, setShowWriteCertModal] = useState(false);

  // 모달 Handler
  const handleShowWriteCertModal = seq => {
    // 입력값 업데이트
    const editForm = certList.filter(form => form.seq === seq)[0];

    setFormSeq(seq);
    setCertDate(new Date(editForm.certificate_date));
    setCertGubun(
      CERT_GUBUN.find(el => el.value === editForm.certificate_gubun),
    );
    setCertName(editForm.certificate_name);
    setCertNumber(editForm.certificate_number);
    setCertGov(editForm.certificate_gov);
    setFiles(editForm.files);

    // 자격증 타입 드랍다운 리스트 분기
    if (editForm.certificate_assess_type === 'PROCESS') {
      setCertTypeList(CERT_PROCESS_TYPE);
      setCertType(
        CERT_PROCESS_TYPE.find(el => el.value === editForm.certificate_type),
      );
    } else if (editForm.certificate_assess_type === 'PROVE') {
      setCertTypeList(CERT_PROVE_TYPE);
      setCertType(
        CERT_PROVE_TYPE.find(el => el.value === editForm.certificate_type),
      );
    }

    // Open
    setShowWriteCertModal(true);
  };

  //* #################################################################
  //* [ Utils ]
  //* #################################################################
  // 파일 첨부
  const addFile = e => {
    if (files.length >= 2) {
      Swal.fire({
        text: '첨부 파일은 2개까지 등록할 수 있습니다.',
        confirmButtonText: '확인',
      });
      return;
    }

    if (e.target.files[0]) setFiles([...files, e.target.files[0]]);
    fileRef.current.value = null;
  };

  // 첨부 파일 삭제
  const removeFile = (seq, idx) => {
    const filteredList = files.filter((file, index) => index !== idx);
    setFiles([...filteredList]);
  };

  // 인쇄
  const printWindowRef = useRef();

  const handlePrint = useReactToPrint({
    onBeforeGetContent: () => {
      printWindowRef.current.classList.add('print');
    },
    onAfterPrint: () => {
      printWindowRef.current.classList.remove('print');
    },
    onPrintError: () => {
      printWindowRef.current.classList.remove('print');
    },
    content: () => {
      return printWindowRef.current;
    },
  });

  //* #################################################################
  //* [ API ] 인성 자존감 활동 파일 조회
  //* #################################################################
  const openFile = async fileSeq => {
    // API 파라미터
    const paramMap = {
      formType: FILE_FORM_TYPE.FILE_CERTIFICATE,
      fileSeq,
    };

    // Axios
    try {
      const { data } = await getFilePathFromRedis(paramMap);

      if (data.code === 0) {
        const redisKey = data.data;
        const hostName = window.location.hostname;

        const url = Utils.getFileOpenUrl(hostName, redisKey);

        window.open(url);
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 학년별 전공 자격증 리스트 - SELECT
  //* #################################################################
  const [firstCallDone, setFirstCallDone] = useState(false);
  const selectCertificateListByGrade = async () => {
    // 조회 학년 확인
    if (!validGrades.includes(grade)) {
      Swal.fire({
        text: '잘못된 접근입니다.',
        confirmButtonText: '확인',
      });
      history.goBack();
    }

    // 파라미터 설정
    const paramMap = {
      grade,
    };

    // Axios
    try {
      const { data } = await selectCertificateList(paramMap);

      if (data.code === 0) {
        setFirstCallDone(true);
        // 리스트 데이터 후처리
        const list = data.data;

        list.forEach(form => {
          const isValid =
            // Utils.checkDateTime(new Date(form.certificate_date)) &&
            Utils.checkNumber(form.certificate_gubun) &&
            Utils.checkNumber(form.certificate_type) &&
            Utils.checkNull(form.certificate_name) &&
            Utils.checkNull(form.certificate_number) &&
            Utils.checkNull(form.certificate_gov) &&
            form.files.length > 0;

          form.isValid = isValid || isValid !== undefined;
        });

        setCertList(list);
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 전공 자격증 수정, 삭제, 취소, 승인요청 - UPDATE
  //* #################################################################
  const updateRequest = async (seq, reqStatus) => {
    // 중복요청 확인
    if (isSentRequest) {
      return;
    }

    // 승인요청 > 입력 검증
    if (reqStatus === 'REQ') {
      const reqForm = certList.filter(cert => cert.seq === seq)[0];

      // if (!Utils.checkDateTime(new Date(reqForm.certificate_date))) {
      //   Swal.fire({
      //     text: '취득일자를 확인해주세요.',
      //     confirmButtonText: '확인',
      //   });
      //   return;
      // }

      if (!reqForm.certificate_name) {
        Swal.fire({
          text: '자격증명이 입력되지 않았습니다.',
          confirmButtonText: '확인',
        });
        return;
      }

      //* 선택 옵션으로 변경 (23.02.27)
      // if (!reqForm.certificate_number) {
      //   Swal.fire({
      //     text: '자격증번호가 입력되지 않았습니다.',
      //     confirmButtonText: '확인',
      //   });
      //   return;
      // }

      if (!reqForm.certificate_gov) {
        Swal.fire({
          text: '발급기관이 입력되지 않았습니다.',
          confirmButtonText: '확인',
        });
        return;
      }

      // if (!reqForm.files || reqForm.files.length < 1) {
      //   Swal.fire({
      //     text: '증빙자료가 등록되지 않았습니다.',
      //     confirmButtonText: '확인',
      //   });
      //   return;
      // }
    }

    // eslint-disable-next-line no-restricted-globals
    if (
      reqStatus === 'CAN' &&
      !(await Swal.fire({
        text: '승인요청을 취소하시겠습니까?',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        showCancelButton: true,
        reverseButtons: true,
      }).then(result => !!result.isConfirmed))
    ) {
      return;
    }

    // eslint-disable-next-line no-restricted-globals
    if (
      reqStatus === 'DEL' &&
      !(await Swal.fire({
        text: '삭제하시겠습니까',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        showCancelButton: true,
        reverseButtons: true,
      }).then(result => !!result.isConfirmed))
    ) {
      return;
    }

    // eslint-disable-next-line no-restricted-globals
    if (
      reqStatus === 'REQ' &&
      !(await Swal.fire({
        text: '승인요청하시겠습니까?',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        showCancelButton: true,
        reverseButtons: true,
      }).then(result => !!result.isConfirmed))
    ) {
      return;
    }

    // FormData
    const formData = new FormData();

    // 수정 요청 > 파일 검증
    const saveFileSeq = [];
    const deleteFileSeq = [];

    if (reqStatus === 'ING') {
      // 모든 파일 Seq 추출 (기존파일)
      const { files: allFiles } = certList.filter(
        form => form.seq === formSeq,
      )[0];

      const allFileSeq = allFiles.map(file => file.file_seq);

      // 파일 append (File 타입 Only)
      for (let i = 0; i < files.length; i += 1) {
        // File 타입
        if (Object.getPrototypeOf(files[i]) === File.prototype) {
          formData.append('files', files[i]);
        }
        // Object 타입
        else {
          saveFileSeq.push(files[i].file_seq);
        }
      }

      // 삭제 파일 Seq 추출
      allFileSeq.forEach(fileSeq => {
        if (saveFileSeq.indexOf(fileSeq) === -1) deleteFileSeq.push(fileSeq);
      });
    }

    // JSON 파라미터 설정
    // [ CASE 1 ] 삭제, 승인요청 ( default )
    const param = {
      formSeq: seq,
      status: reqStatus,
    };

    // [ CASE 2 ] 수정 > 임시저장
    if (reqStatus === 'ING') {
      param.certificate_date = certDate;
      param.certificate_gubun = certGubun.value;
      param.certificate_type = certType.value;
      param.certificate_name = certName;
      param.certificate_number = certNumber;
      param.certificate_gov = certGov;
      param.deleteFileSeq = deleteFileSeq;
    }

    // [ CASE 3 ] 취소
    else if (reqStatus === 'CAN') {
      param.status = 'ING';
    }

    // JSON append
    formData.append(
      'param',
      new Blob([JSON.stringify(param)], { type: 'application/json' }),
    );

    // 중복요청 방지
    setIsSentRequest(true);

    // Axios
    try {
      const { data } = await insertUpdateCertificate(formData);

      if (data.code === 0) {
        // 리스트 갱신
        selectCertificateListByGrade();

        // 모달 Close
        setShowWriteCertModal(false);

        // 중복요청 방지 해제
        setIsSentRequest(false);
      }
    } catch (e) {
      // 중복요청 방지 해제
      setIsSentRequest(false);

      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ useEffect ]
  //* #################################################################
  useEffect(() => {
    selectCertificateListByGrade();
  }, []);

  //* #################################################################
  //* [ Components ]
  //* #################################################################
  // 버튼 (인쇄, 목록, 삭제, 수정, 취소)
  const customButton = (type, seq, isValid = true) => {
    let variant;
    let event;
    let text;

    switch (type) {
      case 'delete':
        variant = 'outline-primary';
        event = () => updateRequest(seq, 'DEL');
        text = '삭제';
        break;
      case 'edit':
        variant = 'primary';
        event = () => handleShowWriteCertModal(seq);
        text = '수정';
        break;
      case 'cancel':
        variant = 'primary';
        event = () => updateRequest(seq, 'CAN');
        text = '승인요청 취소';
        break;
      case 'request':
        variant = 'primary';
        event = () => updateRequest(seq, 'REQ');
        text = '승인요청';
        break;
      default:
        break;
    }

    return (
      <Button size="sm" variant={variant} onClick={event} disabled={!isValid}>
        {text}
      </Button>
    );
  };

  //* #################################################################
  //* [ return ]
  //* #################################################################
  return (
    <main id="program-majorJob-report">
      <Container>
        <article className="content" ref={printWindowRef}>
          {viewType === 'student' && (
            <ul className="path">
              <li>
                <Link to="/">HOME</Link>
              </li>
              <li>
                <Link to="#none">전공 자격증 취득</Link>
              </li>
              <li>
                <Link to="#none">활동내역</Link>
              </li>
            </ul>
          )}

          {/* ////////// ↓ 상단 타이틀영역 ////////// */}
          <section>
            <div className="title">
              <h5>활동내역</h5>
            </div>
          </section>

          {/* ////////// ↓ 컨텐츠 ////////// */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>{`${grade}학년 취득 자격증`}</h5>
            </div>
            <Table className="type-narrow print-remove-col">
              <colgroup>
                <col width={100} />
                <col width={100} />
                <col width={140} />
                <col width="*" />
                <col width={120} />
                <col width={120} />
                <col width={65} />
                <col width={90} />
                <col width={220} />
              </colgroup>
              <thead>
                <tr>
                  <th>취득일</th>
                  <th>구분</th>
                  <th>종류</th>
                  <th className="text-start">자격증</th>
                  <th>발급기관</th>
                  <th>증빙자료</th>
                  <th>점수</th>
                  <th>상태</th>
                  <th>관리</th>
                </tr>
              </thead>
              {/* 자격증 리스트 */}
              <tbody>
                {certList.length > 0 ? (
                  <>
                    {certList.map((form, idx) => (
                      <tr key={idx}>
                        {/* 취득일 */}
                        <td>{form.certificate_date}</td>
                        {/* 자격증 구분 HAN */}
                        <td>{form.certificate_gubun_han}</td>
                        {/* 자격증 종류 HAN */}
                        <td>
                          {`${form.certificate_assess_type_han}`}
                          <br />
                          {`(${form.certificate_type_han})`}
                        </td>
                        {/* 자격증 번호 & 이름 */}
                        <td className="text-start">
                          <small className="text-gray">
                            자격증 번호 : {form.certificate_number}
                          </small>
                          <p>{form.certificate_name}</p>
                        </td>
                        {/* 자격증 발급기관 */}
                        <td>{form.certificate_gov}</td>
                        {/* 첨부 파일 */}
                        <td className="text-start">
                          {form.files.length > 0 &&
                            form.files.map((file, fileIdx) => {
                              return (
                                <Button
                                  key={fileIdx}
                                  className="btn-icon ic-down"
                                  onClick={() => openFile(file.file_seq)}
                                >
                                  {file.file_name_org}
                                </Button>
                              );
                            })}
                        </td>
                        {/* 바우처 점수 */}
                        <td>{form.point}</td>
                        {/* 승인 상태 */}
                        <td>
                          <strong className={FormStatus[form.status].class}>
                            {FormStatus[form.status].text}
                          </strong>
                        </td>
                        {/* 관리 (버튼) */}
                        <td>
                          <div className="btn-area">
                            {/* 승인 */}
                            {form.status === 'CON'}
                            {/* 승인요청 */}
                            {form.status === 'REQ' && (
                              <>{customButton('cancel', form.seq)}</>
                            )}
                            {/* 보완 */}
                            {form.status === 'REJ' && (
                              <>{customButton('edit', form.seq)}</>
                            )}
                            {/* 임시저장 */}
                            {form.status === 'ING' && (
                              <>
                                {customButton('delete', form.seq)}
                                {customButton('edit', form.seq)}
                                {customButton(
                                  'request',
                                  form.seq,
                                  form.isValid,
                                )}
                              </>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  firstCallDone && (
                    <tr>
                      <td colSpan="9" className="no-data">
                        등록된 자격증이 없습니다.
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          </section>

          {/* ////////// ↓ 하단 버튼 //////////  */}
          <div className="btn-area">
            <Button
              size="lg"
              variant="outline-primary"
              onClick={() => handlePrint()}
            >
              인쇄
            </Button>
            <Button
              size="lg"
              variant="primary"
              onClick={() => history.push('/student/majorJobCert')}
            >
              목록으로
            </Button>
          </div>
        </article>
      </Container>

      {/* 자격증 작성 모달 */}
      <CustomModal
        id="writeCertModal"
        title="취득 자격증"
        show={showWriteCertModal}
        onHide={() => setShowWriteCertModal(false)}
        size="sm"
      >
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>취득일</Form.Label>
            <div className="w-100">
              <DatePicker
                className="input-datepicker"
                name="acquisition-date"
                dateFormat="yyyy.MM.dd"
                selected={certDate}
                onChange={date => setCertDate(date)}
                // maxDate={new Date()}
                customInput={<Form.Control style={{ width: '100%' }} />}
                showPopperArrow={false}
              />
            </div>
          </Form.Group>
          {/* 자격증 구분 */}
          <Form.Group className="mb-3">
            <Form.Label>구분</Form.Label>
            <CustomSelect
              value={certGubun}
              onChange={setCertGubun}
              options={CERT_GUBUN}
              placeholder="자격증 구분값을 선택하세요."
            />
          </Form.Group>
          {/* 자격증 종류 */}
          <Form.Group className="mb-3">
            <Form.Label>종류</Form.Label>
            <CustomSelect
              options={certTypeList}
              value={certType}
              onChange={setCertType}
              placeholder="자격증 타입을 선택하세요."
              isDisabled
            />
          </Form.Group>
          {/* 자격증 이름 */}
          <Form.Group className="mb-3">
            <Form.Label>자격증명</Form.Label>
            <Form.Control
              type="text"
              value={certName}
              onChange={e => setCertName(e.target.value)}
              placeholder="자격증명을 입력하세요."
              maxLength={20}
              disabled
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>자격증 번호</Form.Label>
            <Form.Control
              type="text"
              placeholder="자격증 번호를 입력하세요."
              onChange={e =>
                setCertNumber(
                  e.target.value.replace(/[^a-zA-Z0-9-]/g, '').toUpperCase(),
                )
              }
              value={certNumber}
              maxLength={30}
            />
          </Form.Group>
          {/* 자격증 발급기관 */}
          <Form.Group className="mb-3">
            <Form.Label>발급기관</Form.Label>
            <Form.Control
              type="text"
              placeholder="발급기관을 입력하세요."
              onChange={e => setCertGov(e.target.value)}
              value={certGov}
              maxLength={20}
              disabled
            />
          </Form.Group>
          {/* 첨부 파일 */}
          <Form.Group className="mb-3">
            <Form.Label>증빙자료</Form.Label>
            {/* ↓ 첨부된 파일 */}
            <ul className="drag-attachment-list sm mb-3">
              {files.length > 0 &&
                files.map((file, idx) => {
                  return (
                    <li key={idx}>
                      <p className="text-primary">
                        {file.file_name_org || file.name}
                      </p>
                      <Button variant="iconOnly" size="sm">
                        <Image
                          src={images.icCancel}
                          onClick={() => removeFile(file.file_seq, idx)}
                        />
                      </Button>
                    </li>
                  );
                })}
            </ul>
            {/* ↓ 파일 첨부 */}
            <Button
              onClick={() => fileRef.current.click()}
              variant="outline-primary"
              className="d-block"
            >
              파일첨부
            </Button>
            <Form.Control
              ref={fileRef}
              type="file"
              // accept="image/*, .pdf"
              style={{ display: 'none' }}
              onChange={addFile}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-primary"
            onClick={() => updateRequest(formSeq, 'ING')}
          >
            저장
          </Button>
        </Modal.Footer>
      </CustomModal>
    </main>
  );
});
