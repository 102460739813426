/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import {
  Container,
  Button,
  Card,
  Table,
  Nav,
  Tab,
  Modal,
  Form,
} from 'react-bootstrap';
import { CustomModal, CustomSelect } from '@components';
import Moment from 'react-moment';
import {
  getResearchList,
  postResearch,
  putResearch,
} from '@api/eduadmin/research';
import { RESEARCH_GUBUN } from '@common/consts';
import { useHistory } from 'react-router-dom';

export default React.memo(function AdmEduSatisfactionSurvey(props) {
  const history = useHistory();
  const historyState = history.location.state;

  /** tab key */
  const activeTabKey =
    (historyState && historyState.activeTabKey) || RESEARCH_GUBUN.PROGRAM;

  const refresh = (historyState && historyState.activeTabKey) || false;

  /** modal */
  // 질문추가
  const [addModalShow, setAddModalShow] = useState(false);
  const [addItem, setAddItem] = useState({
    researchGubun: RESEARCH_GUBUN.PROGRAM,
    researchContents: '',
    researchQuestion: '',
    researchSort: null,
    useYn: 'Y',
  });
  const openAddModal = () => {
    setAddModalShow(true);
  };
  const closeAddModal = () => {
    setAddModalShow(false);
    setAddItem({
      researchGubun: RESEARCH_GUBUN.PROGRAM,
      researchContents: '',
      researchQuestion: '',
      researchSort: null,
      useYn: 'Y',
    });
  };
  const submitAddModal = e => {
    registResearch(e);
  };

  // 질문수정
  const [modifyModalShow, setModifyModalShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const openModifyModal = item => {
    setModifyModalShow(true);
    setSelectedItem(item);
    setModifySort(getModifySortNumber(item.researchGubun, item.researchSort));
  };
  const closeModifyModal = () => {
    setModifyModalShow(false);
  };
  const submitModifyModal = e => {
    modifyResearch(e);
  };

  /** form state */
  // 조사 리스트
  const [researchList, setResearchList] = useState([]);

  // 질문 추가 순서 옵션
  const [addSortOptions, setAddSortOptions] = useState({});
  const [addSort, setAddSort] = useState({});

  // 질문 수정 순서 옵션
  const [modifySortOptions, setModifySortOptions] = useState({});
  const [modifySort, setModifySort] = useState({});

  /** API */
  const [firstCallDone, setFirstCallDone] = useState(false);
  const getResearchs = async () => {
    const params = {
      researchGubun: activeTabKey,
    };
    try {
      const { data } = await getResearchList(params);
      if (data.code === 0) {
        setFirstCallDone(true);
        setResearchList(data.data.researchList);

        const { programResearchCount } = data.data;
        const { industryResearchCount } = data.data;

        /* 추가모달 선택 옵션 */
        const addSortOptionObj = {};
        const addSortObj = {};
        const modifySortOptionObj = {};
        const programSortOptionList = [];
        const industrySortOptionList = [];
        // PROGRAM
        for (let i = 1; i <= programResearchCount + 1; i += 1) {
          programSortOptionList.push({ label: i, value: i });
        }
        // INDUSTRY
        for (let i = 1; i <= industryResearchCount + 1; i += 1) {
          industrySortOptionList.push({ label: i, value: i });
        }

        // 추가모달 옵션 리스트 설정
        addSortOptionObj[RESEARCH_GUBUN.PROGRAM] = programSortOptionList;
        addSortOptionObj[RESEARCH_GUBUN.INDUSTRY] = industrySortOptionList;

        setAddSortOptions(addSortOptionObj);

        // 추가모달 선택된 옵션 설정
        addSortObj[RESEARCH_GUBUN.PROGRAM] =
          programSortOptionList[programSortOptionList.length - 1];
        addSortObj[RESEARCH_GUBUN.INDUSTRY] =
          industrySortOptionList[industrySortOptionList.length - 1];

        setAddSort(addSortObj);

        /* 수정모달 선택 옵션 */
        // PROGRAM
        modifySortOptionObj[RESEARCH_GUBUN.PROGRAM] = [
          ...programSortOptionList.slice(0, programSortOptionList.length - 1),
        ];

        // INDUSTRY
        modifySortOptionObj[RESEARCH_GUBUN.INDUSTRY] = [
          ...industrySortOptionList.slice(0, industrySortOptionList.length - 1),
        ];
        // 수정모달 옵션 리스트 설정
        setModifySortOptions({ ...modifySortOptionObj });
      }
    } catch (e) {
      // console.log(e);
    }
  };

  const registResearch = async event => {
    const target = event.currentTarget;
    target.disabled = true;

    const params = {
      researchGubun: addItem.researchGubun,
      researchContents: addItem.researchContents,
      researchQuestion: addItem.researchQuestion,
      researchSort: addSort[addItem.researchGubun].value,
      useYn: addItem.useYn,
    };
    try {
      const { data } = await postResearch(params);
      if (data.code === 0) {
        if (activeTabKey === addItem.researchGubun) {
          window.location.reload();
        } else {
          changeTab(addItem.researchGubun);
        }
      }
    } catch (e) {
      // console.log(e);
    }
    target.disabled = false;
    closeAddModal();
  };

  const modifyResearch = async event => {
    const target = event.currentTarget;
    target.disabled = true;
    const params = {
      seq: selectedItem.seq,
      researchGubun: selectedItem.researchGubun,
      researchContents: selectedItem.researchContents,
      researchQuestion: selectedItem.researchQuestion,
      researchSort: modifySort[selectedItem.researchGubun].value,
      useYn: selectedItem.useYn,
    };
    try {
      const { data } = await putResearch(params);
      if (data.code === 0) {
        if (activeTabKey === selectedItem.researchGubun) {
          window.location.reload();
        } else {
          changeTab(selectedItem.researchGubun);
        }
      }
    } catch (e) {
      // console.log(e);
    }
    target.disabled = false;
    closeModifyModal();
  };

  /** API help function */
  const getModifySortNumber = (gubun, sortNumber) => {
    let result = {};
    if (modifySortOptions && modifySortOptions[gubun]) {
      const findItem = modifySortOptions[gubun].find(item => {
        return item.value === sortNumber;
      });
      if (findItem)
        result = {
          [RESEARCH_GUBUN.PROGRAM]: {
            label: '순서를 선택해주세요',
            value: null,
          },
          [RESEARCH_GUBUN.INDUSTRY]: {
            label: '순서를 선택해주세요',
            value: null,
          },
          [gubun]: findItem,
        };
    }

    return result;
  };

  /** changeTab */
  const changeTab = tabKey => {
    setFirstCallDone(false);
    history.push({
      pathname: '/admEdu/satisfactionSurvey',
      state: {
        activeTabKey: tabKey,
        refresh: !refresh,
      },
    });
  };

  /** useEffect */
  useEffect(() => {
    setFirstCallDone(false);
    getResearchs();
    return () => {
      setResearchList([]);
    };
  }, [activeTabKey, refresh]);

  return (
    <main id="admEdu-satisfactionSurvey" className="type-02">
      <Container>
        <article className="content py">
          <section>
            <div className="title">
              <h5>만족도 조사 관리</h5>
            </div>
          </section>

          <Card>
            <Card.Body>
              <Tab.Container
                activeKey={activeTabKey}
                onSelect={key => {
                  changeTab(key);
                }}
              >
                <div className="flex-between mb-3">
                  <Nav className="text-tabs border-bottom flex-grow-1">
                    <Nav.Item>
                      <Nav.Link eventKey={RESEARCH_GUBUN.PROGRAM}>
                        프로그램
                      </Nav.Link>
                      <Nav.Link eventKey={RESEARCH_GUBUN.INDUSTRY}>
                        산업체 체험
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Button className="ms-3" onClick={() => openAddModal()}>
                    질문추가
                  </Button>
                </div>
                <Tab.Content>
                  {/* ↓ 프로그램 탭 */}
                  <Tab.Pane eventKey={RESEARCH_GUBUN.PROGRAM}>
                    <Table className="bt-none">
                      <colgroup>
                        <col width={160} />
                        <col width="*" />
                        <col width={120} />
                        <col width={80} />
                        <col width={100} />
                        <col width={120} />
                        <col width={120} />
                      </colgroup>
                      <thead>
                        <tr>
                          <th>조사내용</th>
                          <th>질문</th>
                          <th>작성자</th>
                          <th>순서</th>
                          <th>사용여부</th>
                          <th>작성일</th>
                          <th>관리</th>
                        </tr>
                      </thead>
                      <tbody>
                        {researchList.length > 0
                          ? researchList.map((item, idx) => {
                              return (
                                <tr key={`program-${idx}`}>
                                  <td>{item.researchContents}</td>
                                  <td>{item.researchQuestion}</td>
                                  <td>관리자</td>
                                  <td>{item.researchSort}</td>
                                  <td>{item.useYn}</td>
                                  <td>
                                    <Moment
                                      date={item.regDate}
                                      format="YYYY.MM.DD"
                                    />
                                  </td>
                                  <td>
                                    <Button
                                      variant="primary"
                                      onClick={() => openModifyModal(item)}
                                    >
                                      수정
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })
                          : firstCallDone && (
                              <tr>
                                <td colSpan={7}>
                                  <p className="no-data">데이터가 없습니다.</p>
                                </td>
                              </tr>
                            )}
                      </tbody>
                    </Table>
                  </Tab.Pane>
                  {/* ↓ 산업체 체험 탭 */}
                  <Tab.Pane eventKey={RESEARCH_GUBUN.INDUSTRY}>
                    <Table className="bt-none">
                      <colgroup>
                        <col width={160} />
                        <col width="*" />
                        <col width={120} />
                        <col width={80} />
                        <col width={100} />
                        <col width={120} />
                        <col width={120} />
                      </colgroup>
                      <thead>
                        <tr>
                          <th>조사내용</th>
                          <th>질문</th>
                          <th>작성자</th>
                          <th>순서</th>
                          <th>사용여부</th>
                          <th>작성일</th>
                          <th>관리</th>
                        </tr>
                      </thead>
                      <tbody>
                        {researchList.length > 0
                          ? researchList.map((item, idx) => {
                              return (
                                <tr key={`industry-${idx}`}>
                                  <td>{item.researchContents}</td>
                                  <td>{item.researchQuestion}</td>
                                  <td>관리자</td>
                                  <td>{item.researchSort}</td>
                                  <td>{item.useYn}</td>
                                  <td>
                                    <Moment
                                      date={item.regDate}
                                      format="YYYY.MM.DD"
                                    />
                                  </td>
                                  <td>
                                    <Button
                                      variant="primary"
                                      onClick={() => openModifyModal(item)}
                                    >
                                      수정
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })
                          : firstCallDone && (
                              <tr>
                                <td colSpan={7}>
                                  <p className="no-data">데이터가 없습니다.</p>
                                </td>
                              </tr>
                            )}
                      </tbody>
                    </Table>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Card.Body>
          </Card>
        </article>
      </Container>

      {/* 질문추가 */}
      <CustomModal
        show={addModalShow}
        onHide={closeAddModal}
        title="질문추가"
        className="modal-reg-question"
      >
        <Modal.Body>
          <ul className="form-list">
            <li>
              <h5>분류</h5>
              <div className="flex-start">
                <Form.Check
                  type="radio"
                  label="프로그램"
                  name="addSurveyType"
                  id="addSurveyType-program"
                  value={RESEARCH_GUBUN.PROGRAM}
                  onChange={e =>
                    setAddItem(prevState => ({
                      ...prevState,
                      researchGubun: e.target.value,
                    }))
                  }
                  checked={addItem.researchGubun === RESEARCH_GUBUN.PROGRAM}
                />
                <Form.Check
                  type="radio"
                  label="산업체 체험"
                  name="addSurveyType"
                  id="addSurveyType-industry"
                  value={RESEARCH_GUBUN.INDUSTRY}
                  onChange={e =>
                    setAddItem(prevState => ({
                      ...prevState,
                      researchGubun: e.target.value,
                    }))
                  }
                  checked={addItem.researchGubun === RESEARCH_GUBUN.INDUSTRY}
                />
              </div>
            </li>
            <li>
              <h5>조사내용</h5>
              <Form.Control
                placeholder="조사내용을 입력해주세요."
                onChange={e => {
                  setAddItem(prevState => ({
                    ...prevState,
                    researchContents: e.target.value,
                  }));
                }}
                value={addItem.researchContents || ''}
              />
            </li>
            <li>
              <h5>질문</h5>
              <Form.Control
                placeholder="질문을 입력해주세요."
                onChange={e => {
                  setAddItem(prevState => ({
                    ...prevState,
                    researchQuestion: e.target.value,
                  }));
                }}
                value={addItem.researchQuestion || ''}
              />
            </li>
            <li>
              <h5>순서</h5>
              <div className="w-100">
                <CustomSelect
                  options={addSortOptions[addItem.researchGubun]}
                  value={addSort[addItem.researchGubun]}
                  onChange={e => {
                    setAddSort({ ...addSort, [addItem.researchGubun]: e });
                  }}
                />
              </div>
            </li>
            <li>
              <h5>사용여부</h5>
              <div className="flex-start">
                <Form.Check
                  type="radio"
                  label="사용"
                  name="addSurveyIsUsed"
                  id="addSurveyIsUsed-true"
                  value="Y"
                  onChange={e =>
                    setAddItem(prevState => ({
                      ...prevState,
                      useYn: e.target.value,
                    }))
                  }
                  checked={addItem.useYn === 'Y'}
                />
                <Form.Check
                  type="radio"
                  label="미사용"
                  name="addSurveyIsUsed"
                  id="addSurveyIsUsed-false"
                  value="N"
                  onChange={e =>
                    setAddItem(prevState => ({
                      ...prevState,
                      useYn: e.target.value,
                    }))
                  }
                  checked={addItem.useYn === 'N'}
                />
              </div>
            </li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={closeAddModal}>
            닫기
          </Button>
          <Button
            variant="primary"
            onClick={submitAddModal}
            disabled={
              !(
                addItem &&
                addItem.researchGubun &&
                addItem.researchContents &&
                addItem.researchQuestion &&
                addSort[addItem.researchGubun] &&
                addSort[addItem.researchGubun].value &&
                addItem.useYn
              )
            }
          >
            추가
          </Button>
        </Modal.Footer>
      </CustomModal>

      {/* 질문수정 */}
      <CustomModal
        show={modifyModalShow}
        onHide={closeModifyModal}
        title="질문수정"
        className="modal-reg-question"
      >
        <Modal.Body>
          {selectedItem && (
            <ul className="form-list">
              <li>
                <h5>조사유형</h5>
                <div className="flex-start">
                  <Form.Check
                    type="radio"
                    label="프로그램"
                    name="modifySurveyType"
                    id="modifySurveyType-program"
                    value={RESEARCH_GUBUN.PROGRAM}
                    onChange={e =>
                      setSelectedItem(prevState => ({
                        ...prevState,
                        researchGubun: e.target.value,
                      }))
                    }
                    checked={
                      selectedItem.researchGubun === RESEARCH_GUBUN.PROGRAM
                    }
                  />
                  <Form.Check
                    type="radio"
                    label="산업체 체험"
                    name="modifySurveyType"
                    id="modifySurveyType-industry"
                    value={RESEARCH_GUBUN.INDUSTRY}
                    onChange={e =>
                      setSelectedItem(prevState => ({
                        ...prevState,
                        researchGubun: e.target.value,
                      }))
                    }
                    checked={
                      selectedItem.researchGubun === RESEARCH_GUBUN.INDUSTRY
                    }
                  />
                </div>
              </li>
              <li>
                <h5>조사내용</h5>
                <Form.Control
                  placeholder="조사내용을 입력해주세요."
                  onChange={e => {
                    setSelectedItem(prevState => ({
                      ...prevState,
                      researchContents: e.target.value,
                    }));
                  }}
                  value={selectedItem.researchContents || ''}
                />
              </li>
              <li>
                <h5>질문</h5>
                <Form.Control
                  placeholder="질문을 입력해주세요."
                  onChange={e => {
                    setSelectedItem(prevState => ({
                      ...prevState,
                      researchQuestion: e.target.value,
                    }));
                  }}
                  value={selectedItem.researchQuestion || ''}
                />
              </li>
              <li>
                <h5>순서</h5>
                <div className="w-100">
                  <CustomSelect
                    options={modifySortOptions[selectedItem.researchGubun]}
                    value={modifySort[selectedItem.researchGubun]}
                    onChange={e => {
                      setModifySort({
                        ...modifySort,
                        [selectedItem.researchGubun]: e,
                      });
                    }}
                  />
                </div>
              </li>
              <li>
                <h5>사용여부</h5>
                <div className="flex-start">
                  <Form.Check
                    type="radio"
                    label="사용"
                    name="modifySurveyIsUsed"
                    id="modifySurveyIsUsed-true"
                    value="Y"
                    onChange={e =>
                      setSelectedItem(prevState => ({
                        ...prevState,
                        useYn: e.target.value,
                      }))
                    }
                    checked={selectedItem.useYn === 'Y'}
                  />
                  <Form.Check
                    type="radio"
                    label="미사용"
                    name="modifySurveyIsUsed"
                    id="modifySurveyIsUsed-false"
                    value="N"
                    onChange={e =>
                      setSelectedItem(prevState => ({
                        ...prevState,
                        useYn: e.target.value,
                      }))
                    }
                    checked={selectedItem.useYn === 'N'}
                  />
                </div>
              </li>
            </ul>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={closeModifyModal}>
            닫기
          </Button>
          <Button
            variant="primary"
            onClick={submitModifyModal}
            disabled={
              !(
                selectedItem &&
                selectedItem.researchGubun &&
                selectedItem.researchContents &&
                selectedItem.researchQuestion &&
                modifySort[selectedItem.researchGubun] &&
                modifySort[selectedItem.researchGubun].value &&
                selectedItem.useYn
              )
            }
          >
            수정
          </Button>
        </Modal.Footer>
      </CustomModal>
    </main>
  );
});
