/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useState, useRef, useEffect } from 'react';
import { useHistory, Link, useLocation, useParams } from 'react-router-dom';
import {
  Container,
  Button,
  Form,
  Image,
  Card,
  Row,
  Col,
} from 'react-bootstrap';
import { Utils } from '@common';
import { CustomSelect } from '@components';
import { images } from '@assets';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Swal from 'sweetalert2';

//* CONSTS
import {
  FREE_PROGRAM_DROPDOWN,
  RESEARCH_POINT_TYPE_LIST,
} from '@common/consts';

//* API
import {
  selectEduProgramInfo,
  insertUpdateEduProgram,
} from '@api/student/selfEduProgram';

// 유저 정보
const initialUserInfo = {
  name: '',
  id: '',
};

//* Main
export default React.memo(function SelfEduProgramWrite(props) {
  //* #################################################################
  //* [ State ]
  //* #################################################################
  const history = useHistory();
  const location = useLocation();

  // URL Param
  const { grade } = useParams();

  // State Param
  const formSeq = location.state ? location.state.formSeq : undefined;

  // Date
  const today = new Date();
  // const yesterday = new Date(today.setDate(today.getDate() - 1));

  // Dropdown > API 에서 명칭 갱신
  const [eduDropdown, setEduDropdown] = useState(FREE_PROGRAM_DROPDOWN);

  // 학생 정보
  const [userInfo, setUserInfo] = useState(initialUserInfo);

  // 프로그램 드람다운 개수

  // Form Input
  // const [isSentRequest, setIsSentRequest] = useState(false); // 중복요청 방지
  const [eduProgram, setEduProgram] = useState(0); // 교육 프로그램
  const [eduDate, setEduDate] = useState(today); // 교육일
  const [eduTitle, setEduTitle] = useState(''); // 교육 프로그램 이름
  const [eduDetail, setEduDetail] = useState(''); // 교육 프로그램 내용

  // 만족도
  const [researchFormList, setResearchFormList] = useState([]);
  const [researchResult, setResearchResult] = useState({});

  // 파일
  const [files, setFiles] = useState([]); // 첨부 파일
  const [initialFiles, setInitalFiles] = useState([]); // 첨부 파일 초기값 (수정 시, 사용)
  const selectFile = useRef('');

  //* #################################################################
  //* [ Utils ]
  //* #################################################################
  // 파일 첨부
  const addFile = e => {
    if (files.length >= 2) {
      Swal.fire({
        text: '첨부 파일은 2개까지 등록할 수 있습니다.',
        confirmButtonText: '확인',
      });
      return;
    }
    if (e.target.files[0]) setFiles([...files, e.target.files[0]]);
    selectFile.current.value = null;
  };

  // 파일 삭제
  const removeFile = idx => {
    const filteredList = files.filter((file, index) => index !== idx);
    setFiles([...filteredList]);
  };

  // 설문조사 문자열 파라미터 ( research_idx, research_response 생성 )
  const getResearchResponse = () => {
    const result = {};

    const researchKeys = Object.keys(researchResult);

    if (researchKeys.length > 0) {
      result.research_idx = researchKeys.join(';');

      const resultValueList = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const key of researchKeys) {
        resultValueList.push(researchResult[key]);
      }

      result.research_response = resultValueList.join(';');
    }
    return result;
  };

  // 파일 드래그 앤 드랍 :: 파일 클래스명 처리
  const fileDragHover = e => {
    e.stopPropagation();
    e.preventDefault();
    e.currentTarget.className =
      e.type === 'dragover' ? 'drag-box hover' : 'drag-box';
  };

  // 파일 드래그 앤 드랍 :: 파일 처리
  const fileSelectHandler = e => {
    // cancel event and hover styling
    fileDragHover(e);

    // fetch FileList object
    const insertFiles = e.target.files || e.dataTransfer.files;
    const totlaFilesLength = files.length;
    const insertFilesLength = insertFiles.length;

    if (totlaFilesLength >= 2) {
      Swal.fire({
        text: '첨부 파일은 2개까지 등록할 수 있습니다.',
        confirmButtonText: '확인',
      });
      return;
    }
    if (totlaFilesLength + insertFilesLength > 2) {
      Swal.fire({
        text: '첨부 파일은 2개까지 등록할 수 있습니다.',
        confirmButtonText: '확인',
      });
      return;
    }

    setFiles([...files, ...insertFiles]);
  };

  //* #################################################################
  //* [ API ] 기본 정보 가져오기 ( 신규작성, 수정 )
  //* #################################################################
  const getInfo = async () => {
    // Axios
    try {
      // 파라미터 설정
      const param = {
        formSeq,
      };

      const { data } = await selectEduProgramInfo(param);

      if (data.code === 0) {
        const formData = data.data;
        const { name = '', id = '', researchList = [] } = formData;

        // 유저 정보
        setUserInfo({
          name,
          id,
        });

        // 프로그램 목록
        const {
          free_program_1: option1,
          free_program_2: option2,
          free_program_3: option3,
          free_program_4: option4,
          free_program_5: option5,
          free_program_6: option6,
          programMax,
          isExist, // Form Data 유무 확인 ( formSeq 참조 ) - 수정 Only
        } = formData;

        // 프로그램 목록 갱신 ( 학교별 등록 프로그램 이름 )
        eduDropdown[0] = { label: option1, value: 1 };
        eduDropdown[1] = { label: option2, value: 2 };
        eduDropdown[2] = { label: option3, value: 3 };
        eduDropdown[3] = { label: option4, value: 4 };
        eduDropdown[4] = { label: option5, value: 5 };
        eduDropdown[5] = { label: option6, value: 6 };

        // 등록 제한 적용
        eduDropdown.length = programMax;

        // 등록 정보 NULL 필터
        const filteredDropdown = eduDropdown.filter(
          item => item.label !== 'NONE',
        );

        setEduDropdown(filteredDropdown);

        // 설문조사 목록
        setResearchFormList(researchList);

        // 수정 폼
        if (isExist === 1) {
          // 접근 유효성 확인
          if (formData.status !== 'ING' && formData.status !== 'REJ') {
            Swal.fire({
              text: '잘못된 접근입니다.',
              confirmButtonText: '확인',
            });
            history.goBack();
          }

          // Form 정보 갱신
          setEduProgram(
            eduDropdown.find(el => el.value === formData.program_gubun),
          );
          setEduDate(new Date(formData.program_date));
          setEduTitle(formData.program_title);
          setEduDetail(formData.program_contents);

          // 설문조사 갱신
          const {
            research_idx: researchIdx = '',
            research_response: resarchResponse = '',
          } = formData;

          if (researchIdx && resarchResponse) {
            const updateMap = {};

            const idxs = researchIdx.split(';'); // 설문 항목
            const responses = resarchResponse.split(';'); // 설문 항목별 입력값

            // eslint-disable-next-line guard-for-in,no-restricted-syntax
            for (const i in idxs) {
              updateMap[`${idxs[i]}`] = responses[i];
            }

            setResearchResult(updateMap);
          }

          // 파일 갱신
          setFiles([...formData.files]);
          setInitalFiles([...formData.files]);
        }
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 승인요청, 임시저장 ( INSERT, UPDATE )
  //* #################################################################
  const insertUpdateForm = async (event, reqStatus) => {
    // // 중복요청 확인
    // if (isSentRequest) {
    //   return;
    // }
    const target = event.currentTarget;
    target.disabled = true;

    // 필수값 체크
    if (!eduProgram) {
      Swal.fire({
        text: '교육 프로그램을 확인해주세요.',
        confirmButtonText: '확인',
      });
      target.disabled = false;
      return;
    }

    // 임시저장
    if (reqStatus === 'ING') {
      const allFileSeq = [...initialFiles.map(file => file.file_seq)];
      const saveFileSeq = [];
      const deleteFileSeq = [];

      // FormData
      const formData = new FormData();

      // 파일 append (File 타입 Only)
      for (let i = 0; i < files.length; i += 1) {
        // File 타입
        if (Object.getPrototypeOf(files[i]) === File.prototype) {
          formData.append('files', files[i]);
        }
        // Object 타입
        else {
          saveFileSeq.push(files[i].file_seq);
        }
      }

      // 삭제 파일 Seq 추출 (기존파일)
      allFileSeq.forEach(seq => {
        if (saveFileSeq.indexOf(seq) === -1) deleteFileSeq.push(seq);
      });

      // JSON 파라미터
      const param = {
        formSeq,
        reqGrade: grade,
        status: reqStatus,
        program_gubun: eduProgram.value,
        program_date: eduDate,
        program_title: eduTitle,
        program_contents: eduDetail,
        deleteFileSeq,
        ...getResearchResponse(),
      };

      // JSON append
      formData.append(
        'param',
        new Blob([JSON.stringify(param)], { type: 'application/json' }),
      );

      // 중복 요청 방지
      // setIsSentRequest(true);

      // Axios
      try {
        const { data } = await insertUpdateEduProgram(formData);

        if (data.code === 0) {
          const upsertFormSeq = data.data;

          if (upsertFormSeq && upsertFormSeq !== 0) {
            history.push(`/student/selfEduProgram/report/${upsertFormSeq}`);
          }
        }
      } catch (e) {
        target.disabled = false;
        // 중복 요청 방지 해제
        // setIsSentRequest(false);
        // alert(e.response.data.message);
      }
    } else {
      Swal.fire({
        text: '입력을 확인해주세요.',
        confirmButtonText: '확인',
      });
      target.disabled = false;
    }
    target.disabled = false;
  };

  //* #################################################################
  //* [ useEffect ]
  //* #################################################################
  useEffect(() => {
    // 페이지 접근 검증 (학년)
    const validGrades = ['1', '2', '3'];

    if (!validGrades.includes(grade)) {
      Swal.fire({
        text: '잘못된 접근입니다.',
        confirmButtonText: '확인',
      });
      history.goBack();
    }

    // Form 정보 조회
    getInfo();

    return () => {};
  }, []);

  //* #################################################################
  //* [ return ]
  //* #################################################################
  return (
    <main id="student-selfEduProgram-write">
      <Container>
        <article className="content">
          <ul className="path">
            <li>
              <Link to="/">HOME</Link>
            </li>
            <li>
              <Link
                to={`/student/selfEduProgram/${grade}`}
              >{`${grade}학년 자율 교육 프로그램`}</Link>
            </li>
            <li>
              <Link to="#none">작성페이지</Link>
            </li>
          </ul>
          {/* ////////// ↓ 상단 타이틀영역 ////////// */}
          <section>
            <div className="title">
              <h5>
                <Link
                  to={`/student/selfEduProgram/${grade}`}
                >{`${grade}학년 자율 교육 프로그램`}</Link>
              </h5>
            </div>
          </section>

          {/* ////////// ↓ 컨텐츠 ////////// */}
          {/* 기본정보 */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>기본정보</h5>
            </div>
            <Card>
              <ul className="form-list">
                <li>
                  <h5>학번</h5>
                  <Form.Control type="text" value={userInfo.id} readOnly />
                </li>
                <li>
                  <h5>이름</h5>
                  <Form.Control
                    type="text"
                    value={Utils.decrypt(userInfo.name)}
                    readOnly
                  />
                </li>
              </ul>
            </Card>
          </section>

          {/* 교육정보 */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>교육정보</h5>
            </div>
            <Card>
              <ul className="form-list">
                {/* 교육 프로그램 - Dropdown */}
                <li>
                  <h5>교육 프로그램</h5>
                  <div className="lw-input w-100">
                    <CustomSelect
                      options={eduDropdown}
                      value={eduProgram}
                      onChange={setEduProgram}
                      placeholder="교육 프로그램을 선택해주세요."
                    />
                  </div>
                </li>
                {/* 교육 일자 - Date Picker */}
                <li>
                  <h5>교육일</h5>
                  <div className="mw-input">
                    <DatePicker
                      className="input-datepicker"
                      name="edu-date"
                      dateFormat="yyyy.MM.dd"
                      selected={eduDate}
                      onChange={date => setEduDate(date)}
                      // maxDate={today}
                      customInput={<Form.Control style={{ width: '100%' }} />}
                      showPopperArrow={false}
                    />
                  </div>
                </li>
              </ul>
            </Card>
          </section>

          {/* 교육 프로그램명 */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>교육 프로그램명</h5>
            </div>
            <Card>
              <Form.Control
                type="text"
                placeholder="프로그램 주제를 입력하세요."
                maxLength={50}
                onChange={e => setEduTitle(e.target.value)}
                value={eduTitle}
              />
            </Card>
          </section>

          {/* 교육 내용 요약 및 정리 */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>교육 내용 요약 및 정리</h5>
            </div>
            <Card>
              <Form.Control
                as="textarea"
                rows={5}
                placeholder="최소 1자이상 최대 1000자 이내로 입력하세요."
                maxLength={1000}
                // minLength={1}
                onChange={e => setEduDetail(e.target.value)}
                value={eduDetail}
              />
            </Card>
          </section>

          {/* 만족도 조사 */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>만족도 조사</h5>
            </div>
            <div className="split-table bt-none h-56">
              <Row className="table-row">
                <Col className="th col-2">조사내용</Col>
                <Col className="th col-4 text-center">질문</Col>
                <Col className="th col-6">
                  <ul className="type-flex-1">
                    <li>매우 그렇다</li>
                    <li>그렇다</li>
                    <li>보통</li>
                    <li>그렇지 않다</li>
                    <li>매우 그렇지 않다</li>
                  </ul>
                </Col>
              </Row>
              {researchFormList.length > 0 ? (
                researchFormList.map((item, idx) => {
                  return (
                    <Row className="table-row" key={idx}>
                      {/* 조사내용 */}
                      <Col className="th col-2">{item.research_contents}</Col>
                      {/* 질문 */}
                      <Col className="td col-4">{item.research_question}</Col>
                      {/* 만족도 입력 */}
                      <Col className="td col-6 flex-between">
                        <div className="radio-group type-flex-1">
                          {RESEARCH_POINT_TYPE_LIST.map((pointItem, index) => {
                            return (
                              <Form.Check
                                key={`${index}-${pointItem.value}`}
                                name={idx}
                                type="radio"
                                label=""
                                value={pointItem.value}
                                id={pointItem.value}
                                onChange={e => {
                                  setResearchResult({
                                    ...researchResult,
                                    [item.seq]: e.target.value,
                                  });
                                }}
                                checked={
                                  researchResult[item.seq] === pointItem.value
                                }
                              />
                            );
                          })}
                        </div>
                      </Col>
                    </Row>
                  );
                })
              ) : (
                <Row className="table-row">
                  <Col className="td col-12 text-center">
                    등록된 설문이 없습니다.
                  </Col>
                </Row>
              )}
            </div>
          </section>

          {/* 파일 첨부 */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>파일 첨부</h5>
              <Button
                onClick={() => selectFile.current.click()}
                variant="outline-primary"
                size="md"
              >
                파일첨부
              </Button>
              <Form.Control
                ref={selectFile}
                onChange={addFile}
                type="file"
                // accept="image/*, .pdf"
                style={{ display: 'none' }}
              />
            </div>
            <Card>
              {files && files.length > 0 ? (
                // ↓ 첨부된 파일
                <ul className="drag-attachment-list">
                  {files.map((file, idx) => {
                    return (
                      <li key={idx}>
                        <p>{file.name || file.file_name_org}</p>
                        <Button variant="iconOnly">
                          <Image
                            src={images.icCancel}
                            onClick={() => removeFile(idx)}
                          />
                        </Button>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                // ↓ 파일 첨부
                <div
                  className="drag-box"
                  draggable
                  onDragOver={e => {
                    fileDragHover(e);
                  }}
                  onDragLeave={e => {
                    fileDragHover(e);
                  }}
                  onDrop={e => {
                    fileSelectHandler(e);
                  }}
                >
                  <p>파일을 마우스로 끌어 오세요.</p>
                </div>
              )}
            </Card>
          </section>

          {/* ////////// ↓ 하단 버튼(신규작성/임시저장) //////////  */}
          <div className="btn-area">
            <Button
              size="lg"
              variant="outline-primary"
              onClick={() => history.push(`/student/selfEduProgram/${grade}`)}
            >
              목록으로
            </Button>
            <Button
              size="lg"
              variant="outline-primary"
              onClick={e => insertUpdateForm(e, 'ING')}
              // disabled={isSentRequest}
            >
              저장
            </Button>
          </div>
        </article>
      </Container>
    </main>
  );
});
