import React, { useEffect, useLayoutEffect } from 'react';
import { Route, useLocation, Link } from 'react-router-dom';
import { Container, Image } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Footer } from '@components';
import { images } from '@assets';

//* API
import { getUserTypeFromToken } from '@api/user';

//* CONSTS
import { STUDENT, TEACHER, ADMIN, ADMIN_SYSTEM } from '@common/consts';

//* [ Main ]
function Layout({ component: Component, render, ...rest }) {
  Layout.propTypes = {
    component: PropTypes.elementType,
  };
  Layout.defaultProps = {
    component: PropTypes.element,
  };

  const navi = useLocation();
  useLayoutEffect(() => {
    // go scroll top
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
  }, [navi.pathname]);

  //* #################################################################
  //* [ API ]
  //* #################################################################
  const fetchUserType = async () => {
    const { data } = await getUserTypeFromToken();

    try {
      if (data.code === 0) {
        const userType = data.data.replace('ROLE_', '');
        window.location.href = urlHandler(userType);
      } else {
        removeToken();
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ Utils ]
  //* #################################################################
  // 로그인 회원 Default URL 설정
  const urlHandler = type => {
    let url = '/';

    switch (type) {
      case STUDENT:
        url = '/home';
        break;
      case TEACHER:
        url = '/teacher/studentMgmt/studentMgmt';
        break;
      case ADMIN:
        url = '/admEdu/student/status';
        break;
      case ADMIN_SYSTEM:
        url = '/admSystem/code/main';
        break;
      default:
        removeToken();
        break;
    }

    return url;
  };

  // 유효하지 않은 토큰 후처리 > 로그인
  const removeToken = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
  };

  //* #################################################################
  //* [ useEffect ]
  //* #################################################################
  useEffect(() => {
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');

    if (accessToken && refreshToken) {
      fetchUserType();
    } else {
      removeToken();
    }
  }, []);

  //* #################################################################
  //* [ return ]
  //* #################################################################
  return (
    <>
      <header>
        <section className="top-area">
          <Container>
            <h1 className="logo-container">
              <Link to="/">
                <Image src={images.logo} />
              </Link>
            </h1>
          </Container>
        </section>
      </header>
      <Container fluid className="app-container">
        <div className="app-contents">
          <Route
            {...rest}
            render={routeProps =>
              render ? render(routeProps) : <Component {...routeProps} />
            }
          />
        </div>
      </Container>
      <Footer />
    </>
  );
}

export default React.memo(Layout);
