import Axios from './axios';

const API_AUTH_OPEN = 'api/v1/auth/open';
const API_AUTH = 'api/v1/auth';

const LOGIN = `${API_AUTH_OPEN}/loginUser`;
const GET_USER_TYPE_FROM_TOKEN = `${API_AUTH}/getUserTypeFromToken`;

export const login = data => {
  return Axios.post(`${LOGIN}`, data);
};

export const getUserTypeFromToken = () => {
  return Axios.get(`${GET_USER_TYPE_FROM_TOKEN}`);
};
