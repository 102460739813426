/**
 *   [ 바우처 일괄 전환 API ]
 *
 */
import Axios from '@api/axios';

const API_TEACHER = 'api/v1/teacher';

const SELECT_SCHOOL_VOUCHER_BATCH = `${API_TEACHER}/school-voucher-batch`;
const UPDATE_SCHOOL_VOUCHER_BATCH_PRE = `${API_TEACHER}/pre-confirm-school-voucher-batch`;
const UPDATE_SCHOOL_VOUCHER_BATCH_CONFIRM = `${API_TEACHER}/confirm-school-voucher-batch`;

// 바우처 일괄 전환 > 학년별 점수 조회
export const selectSchoolVoucherBatch = () => {
  return Axios.get(`${SELECT_SCHOOL_VOUCHER_BATCH}`);
};

// 바우처 일괄 전환 > 일괄전환
// export const preConfirmSchoolVoucherBatch = param => {
//   return Axios.post(`${UPDATE_SCHOOL_VOUCHER_BATCH_PRE}`, param);
// };

// 바우처 일괄 전환 > 확정하기
export const confirmSchoolVoucherBatch = param => {
  return Axios.post(`${UPDATE_SCHOOL_VOUCHER_BATCH_CONFIRM}`, param);
};
