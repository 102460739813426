/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from 'react';
import { Container, Form, Button, Card, Row, Col } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Utils } from '@common';
import Swal from 'sweetalert2';
import {
  getNoticeDetail,
  patchNotice,
  putNotice,
} from '../../../api/board/notice';
import {
  getArchiveDetail,
  patchArchive,
  putArchive,
} from '../../../api/board/archive';

export default React.memo(function AdmEduPostWrite(props) {
  const history = useHistory();
  // 게시판 확인
  const { boardId } = useParams();

  const { thisBoard, writeType, boardSeq } = props.location.state;

  const [showYn, setShowYn] = useState('Y');
  const [mainYn, setMainYn] = useState('Y');
  const [publicYn, setPublicYn] = useState('P');
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  const [files, setFiles] = useState([]);
  const [initialFiles, setInitalFiles] = useState([]);
  const selectFile = useRef('');

  const createUpdateNotice = async (event, isUpdate) => {
    const target = event.currentTarget;
    target.disabled = true;
    try {
      if (!title) {
        Swal.fire({
          text: '제목을 입력 해주세요.',
          confirmButtonText: '확인',
        });
        target.disabled = false;
        return;
      }
      if (!content) {
        Swal.fire({
          text: '내용을 입력 해주세요.',
          confirmButtonText: '확인',
        });
        target.disabled = false;
        return;
      }

      const formData = new FormData();
      const allFileSeq = [...initialFiles.map(file => file.seq)];
      const saveFileSeq = [];
      const deleteFiles = [];
      for (let i = 0; i < files.length; i += 1) {
        // File 타입
        if (Object.getPrototypeOf(files[i]) === File.prototype) {
          formData.append('files', files[i]);
        }
        // Object 타입
        else {
          saveFileSeq.push(files[i].seq);
        }
      }
      // 삭제 파일 Seq 추출 (기존파일)
      allFileSeq.forEach(seq => {
        if (saveFileSeq.indexOf(seq) === -1) deleteFiles.push(seq);
      });
      const params = {
        title,
        content,
        mainYn,
        showYn,
        publicYn,
        deleteFiles,
      };

      if (isUpdate) {
        params.seq = boardSeq;
      }

      formData.append(
        'dto',
        new Blob([JSON.stringify(params)], { type: 'application/json' }),
      );
      if (!isUpdate) {
        const { data } = await putNotice(formData);
        if (data.code === 0) {
          Swal.fire({
            text: '정상적으로 등록 되었습니다.',
            confirmButtonText: '확인',
          }).finally(() => {
            history.push(`/admEdu/post/${boardId}/list`);
          });
        }
      } else {
        const { data } = await patchNotice(formData);
        if (data.code === 0) {
          Swal.fire({
            text: '정상적으로 수정 되었습니다.',
            confirmButtonText: '확인',
          }).finally(() => {
            if (showYn === 'N') {
              history.push(`/admEdu/post/${boardId}/list`);
            } else {
              history.goBack();
            }
          });
        }
      }
    } catch (error) {
      target.disabled = false;
    }
    target.disabled = false;
  };

  const createUpdateArchive = async (event, isUpdate) => {
    const target = event.currentTarget;
    target.disabled = true;
    try {
      if (!title) {
        Swal.fire({
          text: '제목을 입력 해주세요.',
          confirmButtonText: '확인',
        });
        target.disabled = false;
        return;
      }
      if (!content) {
        Swal.fire({
          text: '내용을 입력 해주세요.',
          confirmButtonText: '확인',
        });
        target.disabled = false;
        return;
      }
      const formData = new FormData();
      const allFileSeq = [...initialFiles.map(file => file.seq)];
      const saveFileSeq = [];
      const deleteFiles = [];
      for (let i = 0; i < files.length; i += 1) {
        // File 타입
        if (Object.getPrototypeOf(files[i]) === File.prototype) {
          formData.append('files', files[i]);
        }
        // Object 타입
        else {
          saveFileSeq.push(files[i].seq);
        }
      }
      // 삭제 파일 Seq 추출 (기존파일)
      allFileSeq.forEach(seq => {
        if (saveFileSeq.indexOf(seq) === -1) deleteFiles.push(seq);
      });
      const params = {
        title,
        content,
        mainYn,
        showYn,
        publicYn,
        deleteFiles,
      };

      if (isUpdate) {
        params.seq = boardSeq;
      }

      formData.append(
        'dto',
        new Blob([JSON.stringify(params)], { type: 'application/json' }),
      );
      if (!isUpdate) {
        const { data } = await putArchive(formData);
        if (data.code === 0) {
          Swal.fire({
            text: '정상적으로 등록 되었습니다.',
            confirmButtonText: '확인',
          }).finally(() => {
            history.push(`/admEdu/post/${boardId}/list`);
          });
        }
      } else {
        const { data } = await patchArchive(formData);
        if (data.code === 0) {
          Swal.fire({
            text: '정상적으로 수정 되었습니다.',
            confirmButtonText: '확인',
          }).finally(() => {
            if (showYn === 'N') {
              history.push(`/admEdu/post/${boardId}/list`);
            } else {
              history.goBack();
            }
          });
        }
      }
    } catch (error) {
      target.disabled = false;
    }
    target.disabled = false;
  };

  // 파일 첨부
  const addFile = e => {
    if (e.target.files[0]) setFiles([...files, e.target.files[0]]);
    selectFile.current.value = null;
  };

  // 파일 삭제
  const removeFile = idx => {
    const filteredList = files.filter((file, index) => index !== idx);
    setFiles([...filteredList]);
  };

  const getNotice = async () => {
    const { data } = await getNoticeDetail(boardSeq);
    if (data.code === 0) {
      setShowYn(data.data.showYn);
      setMainYn(data.data.mainYn);
      setPublicYn(data.data.publicYn);
      setTitle(data.data.title);
      setContent(data.data.content);
      setFiles([...data.data.files]);
      setInitalFiles([...data.data.files]);
    }
  };

  const getArchive = async () => {
    const { data } = await getArchiveDetail(boardSeq);
    if (data.code === 0) {
      setShowYn(data.data.showYn);
      setMainYn(data.data.mainYn);
      setPublicYn(data.data.publicYn);
      setTitle(data.data.title);
      setContent(data.data.content);
      setFiles([...data.data.files]);
      setInitalFiles([...data.data.files]);
    }
  };

  const handleBoard = id => {
    switch (id) {
      case 'notice':
        getNotice();
        break;
      case 'eduArchive':
        getArchive();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (writeType === 'modify') {
      if (!boardSeq) {
        Swal.fire({
          text: '잘못된 접근입니다.',
          confirmButtonText: '확인',
        });
        history.goBack();
      } else {
        handleBoard(boardId);
      }
    }
  }, []);

  return (
    <main id="admEdu-post-write" className="type-02">
      <Container>
        <article className="content py">
          <section>
            <div className="title">
              <h5>{thisBoard.name}</h5>
            </div>

            <Card>
              <Card.Body>
                <div className="sub-title mb-3">
                  <h5>{writeType === 'reg' ? '게시글 작성' : '게시글 수정'}</h5>
                </div>
                <Form className="write-form">
                  <Form.Group className="form-group">
                    <Form.Label>공개여부</Form.Label>
                    <div className="flex-start">
                      <Form.Check
                        type="radio"
                        label="공개"
                        name="release"
                        id="release-true"
                        value="Y"
                        onChange={e => setShowYn(e.target.value)}
                        checked={showYn === 'Y'}
                      />
                      <Form.Check
                        type="radio"
                        label="비공개"
                        name="release"
                        id="release-false"
                        value="N"
                        onChange={e => setShowYn(e.target.value)}
                        checked={showYn === 'N'}
                      />
                    </div>
                  </Form.Group>
                  <Form.Group className="form-group mt-2">
                    <Form.Label>메인등록</Form.Label>
                    <div className="flex-start">
                      <Form.Check
                        type="radio"
                        label="등록"
                        name="regMain"
                        id="regMain-true"
                        value="Y"
                        onChange={e => setMainYn(e.target.value)}
                        checked={mainYn === 'Y'}
                      />
                      <Form.Check
                        type="radio"
                        label="해제"
                        name="regMain"
                        id="regMain-false"
                        value="N"
                        onChange={e => setMainYn(e.target.value)}
                        checked={mainYn === 'N'}
                      />
                    </div>
                  </Form.Group>
                  <Form.Group className="form-group mt-2">
                    <Form.Label>공개대상</Form.Label>
                    <div className="flex-start">
                      <Form.Check
                        type="radio"
                        label="전체"
                        name="publicFor"
                        id="publicFor-public"
                        value="P"
                        onChange={e => setPublicYn(e.target.value)}
                        checked={publicYn === 'P'}
                      />
                      <Form.Check
                        type="radio"
                        label="학생"
                        name="publicFor"
                        id="publicFor-student"
                        value="Y"
                        onChange={e => setPublicYn(e.target.value)}
                        checked={publicYn === 'Y'}
                      />
                      <Form.Check
                        type="radio"
                        label="학교"
                        name="publicFor"
                        id="publicFor-teacher"
                        value="N"
                        onChange={e => setPublicYn(e.target.value)}
                        checked={publicYn === 'N'}
                      />
                    </div>
                  </Form.Group>
                  <Form.Group className="form-group mt-2">
                    <Form.Label>제목</Form.Label>
                    <Form.Control
                      placeholder="제목을 입력하세요"
                      value={title}
                      onChange={e => {
                        setTitle(e.target.value);
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="form-group mt-2">
                    <Form.Label>내용</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={10}
                      value={content}
                      onChange={e => {
                        setContent(e.target.value);
                      }}
                      placeholder="내용을 입력하세요"
                    />
                  </Form.Group>
                  <Form.Group className="form-group mt-5">
                    <Form.Label>첨부파일</Form.Label>
                    <div>
                      {/* <Button variant="primary">파일선택</Button> */}
                      <Button
                        onClick={() => selectFile.current.click()}
                        variant="primary"
                      >
                        파일선택
                      </Button>
                      <Form.Control
                        ref={selectFile}
                        onChange={addFile}
                        type="file"
                        accept="image/*, .pdf"
                        style={{ display: 'none' }}
                      />

                      {files && files.length > 0 && (
                        <ul className="attatchment-box">
                          {files.map((v, i) => {
                            return (
                              <li key={`file_${i}`}>
                                <span>
                                  <p>{`${
                                    v.name || v.fileName
                                  }(${Utils.formatFileSize(
                                    v.size || v.fileSize,
                                  )})`}</p>
                                </span>
                                <Button
                                  variant="icon"
                                  className="btn-delete"
                                  onClick={() => removeFile(i)}
                                />
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </div>
                  </Form.Group>
                </Form>
                <div className="btn-area justify-content-end my-3">
                  <Button
                    variant="outline-primary"
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    취소
                  </Button>
                  {writeType === 'reg' ? (
                    <Button
                      onClick={e => {
                        if (boardId === 'notice') {
                          createUpdateNotice(e);
                        } else {
                          createUpdateArchive(e);
                        }
                      }}
                    >
                      등록
                    </Button>
                  ) : (
                    <Button
                      variant="primary"
                      onClick={e => {
                        if (boardId === 'notice') {
                          createUpdateNotice(e, true);
                        } else {
                          createUpdateArchive(e, true);
                        }
                      }}
                    >
                      수정
                    </Button>
                  )}
                </div>
              </Card.Body>
            </Card>
          </section>
        </article>
      </Container>
    </main>
  );
});
