/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import { Container, Button, Table, Row, Col } from 'react-bootstrap';

//* CONSTS
import { FormStatus, FILE_FORM_TYPE } from '@common/consts';

//* Utils
import { useReactToPrint } from 'react-to-print';
import Utils from '@common/Utils';
import Moment from 'react-moment';
import Swal from 'sweetalert2';

//* API
import {
  selectPortfolioInfo,
  upsertFormPortfolio,
} from '@api/student/Portfolio';
import { getFilePathFromRedis } from '@api/file';

//* [ Main ]
export default React.memo(function Portfolio(props) {
  const history = useHistory();

  const { type: typeParam } = useParams();

  // type
  const [viewType, setViewType] = useState(typeParam); // viewType (student, teacher, eduAdm)
  const { grade } = props.userInfo;

  //* #################################################################
  //* [ States ]
  //* #################################################################
  // 학생 정보, 포트폴리오 정보
  const [portfolioInfo, setPortfolioInfo] = useState({});
  const [profileUrl, setProfileUrl] = useState();

  // 자격증
  const [certList, setCertList] = useState([]);

  // 수상경력
  const [awardList, setAwardList] = useState([]);

  // 경력 및 활동사항
  const [careerList, setCareerList] = useState([]);

  // 교사 코멘트
  const [teacherCommentList, setTeacherCommentList] = useState([]);

  //* #################################################################
  //* [ Utils ]
  //* #################################################################

  // 입력 제한 조건 체크
  const inputConditionCheck = () => {
    /* 인적사항 체크 */
    if (!portfolioInfo.student_name_hanja) {
      Swal.fire({
        text: '한자이름이 존재하지 않습니다.',
        confirmButtonText: '확인',
      });
      return false;
    }
    // if (!portfolioInfo.student_birth) {
    //   Swal.fire({
    //     text: '생년월일이 존재하지 않습니다.',
    //     confirmButtonText: '확인',
    //   });
    //   return false;
    // }
    // if (!portfolioInfo.sex) {
    //   Swal.fire({
    //     text: '성별 값이 존재하지 않습니다.',
    //     confirmButtonText: '확인',
    //   });
    //   return false;
    // }
    // if (!portfolioInfo.student_phone_no) {
    //   Swal.fire({
    //     text: '연락처가 존재하지 않습니다.',
    //     confirmButtonText: '확인',
    //   });
    //   return false;
    // }
    // if (!portfolioInfo.student_e_mail) {
    //   Swal.fire({
    //     text: '이메일이 존재하지 않습니다.',
    //     confirmButtonText: '확인',
    //   });
    //   return false;
    // }
    // if (!portfolioInfo.student_addr) {
    //   Swal.fire({
    //     text: '주소가 존재하지 않습니다.',
    //     confirmButtonText: '확인',
    //   });
    //   return false;
    // }
    // if (portfolioInfo.isExistsPhoto !== 'Y') {
    //   Swal.fire({
    //     text: '프로필 사진이 존재하지 않습니다.',
    //     confirmButtonText: '확인',
    //   });
    //   return false;
    // }
    // phone_no check
    // if (
    //   portfolioInfo.student_phone_no &&
    //   !Utils.phoneNumberTest(portfolioInfo.student_phone_no)
    // ) {
    //   Swal.fire({
    //     text: '휴대폰 번호 형식을 확인해주시기 바랍니다.',
    //     confirmButtonText: '확인',
    //   });
    //   return false;
    // }
    // // email check
    // if (
    //   portfolioInfo.student_e_mail &&
    //   !Utils.emailTest(portfolioInfo.student_e_mail)
    // ) {
    //   Swal.fire({
    //     text: '이메일 형식을 확인해주시기 바랍니다.',
    //     confirmButtonText: '확인',
    //   });
    //   return false;
    // }

    /* 학력사항 input check */
    if (!portfolioInfo.high_start_date) {
      Swal.fire({
        text: '고등학교 기간 중 시작 기간이 존재하지 않습니다.',
        confirmButtonText: '확인',
      });
      return false;
    }
    if (!portfolioInfo.high_end_date) {
      Swal.fire({
        text: '고등학교 기간 중 종료 시간이 존재하지 않습니다.',
        confirmButtonText: '확인',
      });
      return false;
    }
    if (!portfolioInfo.high_school_name) {
      Swal.fire({
        text: '고등학교 학교명이 존재하지 않습니다.',
        confirmButtonText: '확인',
      });
      return false;
    }
    if (!portfolioInfo.high_depart_name) {
      Swal.fire({
        text: '고등학교 학과(전공)이 존재하지 않습니다.',
        confirmButtonText: '확인',
      });
      return false;
    }
    if (!portfolioInfo.high_status) {
      Swal.fire({
        text: '고등학교 재적여부에 대한 값이 존재하지 않습니다.',
        confirmButtonText: '확인',
      });
      return false;
    }

    if (!portfolioInfo.mid_start_date) {
      Swal.fire({
        text: '중학교 기간 중 시작 기간이 존재하지 않습니다.',
        confirmButtonText: '확인',
      });
      return false;
    }
    if (!portfolioInfo.mid_end_date) {
      Swal.fire({
        text: '중학교 기간 중 종료 시간이 존재하지 않습니다.',
        confirmButtonText: '확인',
      });
      return false;
    }
    if (!portfolioInfo.mid_school_name) {
      Swal.fire({
        text: '중학교 학교명이 존재하지 않습니다.',
        confirmButtonText: '확인',
      });
      return false;
    }
    if (!portfolioInfo.mid_depart_name) {
      Swal.fire({
        text: '중학교 학과(전공)이 존재하지 않습니다.',
        confirmButtonText: '확인',
      });
      return false;
    }
    if (!portfolioInfo.mid_status) {
      Swal.fire({
        text: '중학교 재적여부에 대한 값이 존재하지 않습니다.',
        confirmButtonText: '확인',
      });
      return false;
    }

    /* 수상경력 input check */
    if (awardList && awardList.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const item of awardList) {
        if (!item.award_date) {
          Swal.fire({
            text: '수상일이 존재하지 않는 수상내역이 존재합니다.',
            confirmButtonText: '확인',
          });
          return false;
        }

        if (!item.award_name) {
          Swal.fire({
            text: '수상명이 존재하지 않는 수상내역이 존재합니다.',
            confirmButtonText: '확인',
          });
          return false;
        }

        if (!item.award_number) {
          Swal.fire({
            text: '등급(위)가 존재하지 않는 수상내역이 존재합니다.',
            confirmButtonText: '확인',
          });
          return false;
        }

        if (!item.award_gov) {
          Swal.fire({
            text: '수여기관이 존재하지 않는 수상내역이 존재합니다.',
            confirmButtonText: '확인',
          });
          return false;
        }
      }
    }

    /* 경력 및 활동사항 input check */
    if (careerList && careerList.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const item of careerList) {
        if (!item.program_gubun) {
          Swal.fire({
            text: '구분이 존재하지 않는 경력 및 활동사항 내역이 존재합니다.',
            confirmButtonText: '확인',
          });
          return false;
        }

        if (!item.career_start_date) {
          Swal.fire({
            text: '활동시작일이 존재하지 않는 경력 및 활동사항 내역이 존재합니다.',
            confirmButtonText: '확인',
          });
          return false;
        }

        if (!item.career_end_date) {
          Swal.fire({
            text: '활동종료일이 존재하지 않는 경력 및 활동사항 내역이 존재합니다.',
            confirmButtonText: '확인',
          });
          return false;
        }

        if (!item.career_title) {
          Swal.fire({
            text: '활동명이 존재하지 않는 경력 및 활동사항 내역이 존재합니다.',
            confirmButtonText: '확인',
          });
          return false;
        }

        if (!item.career_contents) {
          Swal.fire({
            text: '주요활동내용(수행역할)이 존재하지 않는 경력 및 활동사항 내역이 존재합니다.',
            confirmButtonText: '확인',
          });
          return false;
        }
      }
    }

    /* 참여활동 내용 input 길이 체크 */
    if (!Utils.limitCheck(portfolioInfo.my_growth_proceed, 1, 1000)) {
      Swal.fire({
        text: '성장과정은 최소 1자 이상 최대 1000자여야 합니다.',
        confirmButtonText: '확인',
      });
      return false;
    }
    if (!Utils.limitCheck(portfolioInfo.my_sinjo, 1, 1000)) {
      Swal.fire({
        text: '성격 및 생활신조는 최소 1자 이상 최대 1000자여야 합니다.',
        confirmButtonText: '확인',
      });
      return false;
    }
    if (!Utils.limitCheck(portfolioInfo.my_career, 1, 1000)) {
      Swal.fire({
        text: '학교 및 경력사항은 최소 1자 이상 최대 1000자여야 합니다.',
        confirmButtonText: '확인',
      });
      return false;
    }
    if (!Utils.limitCheck(portfolioInfo.my_jiwon, 1, 1000)) {
      Swal.fire({
        text: '지원동기 및 입사 후 포부는 최소 1자 이상 최대 1000자여야 합니다.',
        confirmButtonText: '확인',
      });
      return false;
    }

    return true;
  };

  /** print function */
  const printWindowRef = useRef();
  const handlePrint = useReactToPrint({
    onBeforeGetContent: () => {
      printWindowRef.current.classList.add('print');
    },
    onAfterPrint: () => {
      printWindowRef.current.classList.remove('print');
    },
    onPrintError: () => {
      printWindowRef.current.classList.remove('print');
    },
    content: () => {
      return printWindowRef.current;
    },
  });

  //* #################################################################
  //* [ API ] 포트폴리오 정보 조회
  //* #################################################################
  const [firstCallDone, setFirstCallDone] = useState(false);
  const getPortfolioInfo = async () => {
    const params = {
      isUpsert: false,
    };
    try {
      const { data } = await selectPortfolioInfo(params);
      if (data.code === 0) {
        setFirstCallDone(true);
        const resultData = data.data;
        const { info } = data.data;

        setPortfolioInfo(info);
        if (info.seq) {
          if (info.isExistsPhoto === 'Y') {
            const url = await openFile(info.seq);
            if (url) {
              setProfileUrl(url);
            }
          }
        }
        setCertList(resultData.certificateList);
        setAwardList(resultData.awardList);
        setCareerList(resultData.careerList);
        // 교사 코멘트
        setTeacherCommentList(resultData.teacherCommentList);
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 파일조회 ( 프로필 )
  //* #################################################################
  const openFile = async fileSeq => {
    // API 파라미터
    const paramMap = {
      formType: FILE_FORM_TYPE.FILE_PORTFOLIO,
      fileSeq,
    };

    // Axios
    try {
      const { data } = await getFilePathFromRedis(paramMap);

      if (data.code === 0) {
        const redisKey = data.data;
        const hostName = window.location.hostname;

        const url = Utils.getFileOpenUrl(hostName, redisKey);

        return url;
      }
    } catch (e) {
      return null;
    }
    return null;
  };

  //* #################################################################
  //* [ API ] 포트폴리오 저장/수정
  //* #################################################################
  const updatePortfolio = async (target, status) => {
    target.disabled = true;

    // input check
    if (status === FormStatus.REQ.value && !inputConditionCheck()) {
      target.disabled = false;
      return;
    }

    // FormData
    const formData = new FormData();

    // JSON 파라미터
    const params = {
      status,
    };

    // JSON append
    formData.append(
      'param',
      new Blob([JSON.stringify(params)], { type: 'application/json' }),
    );

    try {
      const { data } = await upsertFormPortfolio(formData);
      if (data.code === 0) {
        window.location.reload();
      }
    } catch (e) {
      // alert(e.response.data.message);
      target.disabled = false;
    }
    target.disabled = false;
  };

  //* #################################################################
  //* [ useEffect ]
  //* #################################################################
  useEffect(() => {
    getPortfolioInfo();
    return () => {
      setPortfolioInfo({});
      setCertList([]);
      setAwardList([]);
      setCareerList([]);
      setTeacherCommentList([]);
    };
  }, []);

  //* #################################################################
  //* [ return ]
  //* #################################################################
  return (
    <main id="program-resume-report">
      <Container>
        {portfolioInfo && portfolioInfo.seq ? (
          <article className="content" ref={printWindowRef}>
            {viewType === 'student' && (
              <ul className="path">
                <li>
                  <Link to="/">HOME</Link>
                </li>
                <li>
                  <Link to="#none">직무기초 포트폴리오</Link>
                </li>
              </ul>
            )}

            {/* ////////// ↓ 상단 타이틀영역 ////////// */}
            <section>
              <div className="title">
                <h5>직무기초 포트폴리오</h5>
                <div className="dl-box type-bar lg">
                  <dl>
                    <strong
                      className={
                        FormStatus[portfolioInfo.status] &&
                        FormStatus[portfolioInfo.status].class
                      }
                    >
                      {FormStatus[portfolioInfo.status] &&
                        FormStatus[portfolioInfo.status].text}
                    </strong>
                  </dl>
                  <dl>
                    <dd>
                      <strong>{portfolioInfo.point}</strong>
                    </dd>
                  </dl>
                </div>
              </div>
            </section>

            {/* ////////// ↓ 컨텐츠 ////////// */}
            {/* 인적사항 */}
            <section className="mb-5">
              <div className="sub-title">
                <h5>인적사항</h5>
              </div>
              <div className="split-table has-img">
                <div className="profile">
                  {profileUrl ? (
                    <div
                      className="img-box"
                      style={{
                        backgroundImage: `url(${profileUrl})`,
                      }}
                    />
                  ) : (
                    <div className="no-data" />
                  )}
                </div>
                <div className="table-row-wrap">
                  <Row className="table-row">
                    <Col className="th col-2">이름</Col>
                    <Col className="td col-4">
                      {Utils.decrypt(portfolioInfo.student_name)}
                    </Col>
                    <Col className="td col-6">
                      {portfolioInfo.student_name_hanja}
                    </Col>
                  </Row>
                  <Row className="table-row">
                    <Col className="th col-2">생년월일</Col>
                    <Col className="td col-4">
                      {portfolioInfo.student_birth ? (
                        <Moment
                          date={Utils.decrypt(portfolioInfo.student_birth)}
                          format="YYYY-MM-DD"
                          interval={0}
                        />
                      ) : (
                        ''
                      )}
                      {portfolioInfo.student_birth &&
                        ` (만 ${Utils.getFullAge(
                          Utils.decrypt(portfolioInfo.student_birth),
                        )}세)`}
                    </Col>
                    <Col className="th col-2">성별</Col>
                    <Col className="td col-4">{portfolioInfo.sex}</Col>
                  </Row>
                  <Row className="table-row">
                    <Col className="th col-2">연락처</Col>
                    <Col className="td col-4">
                      {portfolioInfo.phone_no &&
                        Utils.phoneNumberHypenFormat(
                          Utils.decrypt(portfolioInfo.phone_no),
                        )}
                    </Col>
                    <Col className="th col-2">이메일</Col>
                    <Col className="td col-4">
                      {portfolioInfo.e_mail &&
                        Utils.decrypt(portfolioInfo.e_mail)}
                    </Col>
                  </Row>
                  <Row className="table-row">
                    <Col className="th col-2">주소</Col>
                    <Col className="td col-10">
                      {portfolioInfo.addr1
                        ? Utils.decrypt(portfolioInfo.addr1)
                        : ''}{' '}
                      {portfolioInfo.addr2
                        ? ` ${Utils.decrypt(portfolioInfo.addr2)}`
                        : ''}
                    </Col>
                  </Row>
                </div>
              </div>
            </section>

            {/* 학력사항 */}
            <section className="mb-5">
              <div className="sub-title">
                <h5>학력사항</h5>
              </div>
              <Table>
                <colgroup>
                  <col width={140} />
                  <col width={270} />
                  <col width="*" />
                  <col width="*" />
                  <col width="*" />
                </colgroup>
                <thead>
                  <tr>
                    <th>구분</th>
                    <th>기간</th>
                    <th>학교명</th>
                    <th>학과(전공)</th>
                    <th>재적여부(졸업/예정/수료/재학)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>고등학교</td>
                    <td>
                      {portfolioInfo.high_start_date && (
                        <Moment
                          format="YYYY-MM-DD"
                          date={portfolioInfo.high_start_date}
                        />
                      )}
                      &nbsp; ~ &nbsp;
                      {portfolioInfo.high_end_date && (
                        <Moment
                          format="YYYY-MM-DD"
                          date={portfolioInfo.high_end_date}
                        />
                      )}
                    </td>
                    <td className="text-start">
                      {portfolioInfo.high_school_name}
                    </td>
                    <td className="text-start">
                      {portfolioInfo.high_depart_name}
                    </td>
                    <td>{portfolioInfo.high_status_han}</td>
                  </tr>
                  <tr>
                    <td>중학교</td>
                    <td>
                      {portfolioInfo.mid_start_date && (
                        <Moment
                          format="YYYY-MM-DD"
                          date={portfolioInfo.mid_start_date}
                        />
                      )}
                      &nbsp; ~ &nbsp;
                      {portfolioInfo.mid_end_date && (
                        <Moment
                          format="YYYY-MM-DD"
                          date={portfolioInfo.mid_end_date}
                        />
                      )}
                    </td>
                    <td className="text-start">
                      {portfolioInfo.mid_school_name}
                    </td>
                    <td className="text-start">
                      {portfolioInfo.mid_depart_name}
                    </td>
                    <td>{portfolioInfo.mid_status_han}</td>
                  </tr>
                </tbody>
              </Table>
            </section>

            {/* 자격증 */}
            <section className="mb-5">
              <div className="sub-title">
                <h5>자격증</h5>
              </div>
              <Table>
                <colgroup>
                  <col width={120} />
                  <col width={120} />
                  <col width={120} />
                  <col width="*" />
                  <col width={180} />
                </colgroup>
                <thead>
                  <tr>
                    <th>취득일</th>
                    <th>구분</th>
                    <th>종류</th>
                    <th className="text-start">자격증</th>
                    <th>발급기관</th>
                  </tr>
                </thead>
                <tbody>
                  {certList && certList.length > 0 ? (
                    certList.map((item, index) => {
                      return (
                        <tr key={`cert-${index}`}>
                          <td>
                            {item.certificate_date ? (
                              <Moment
                                date={item.certificate_date}
                                format="YYYY-MM-DD"
                                interval={0}
                              />
                            ) : (
                              ''
                            )}
                          </td>
                          <td>
                            {' '}
                            {item.certificate_gubun_han === '결과없음'
                              ? ''
                              : item.certificate_gubun_han}
                          </td>
                          <td>
                            {item.certificate_type_han === '결과없음'
                              ? ''
                              : item.certificate_type_han}
                          </td>
                          <td className="text-start">
                            {item.certificate_number && (
                              <small className="text-gray">
                                자격증 번호 : {item.certificate_number}
                              </small>
                            )}
                            <p className="table-ellipsis">
                              {item.certificate_name}
                            </p>
                          </td>
                          <td>{item.certificate_gov}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={5}>등록된 자격증이 없습니다.</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </section>

            {/* 수상경력 */}
            <section className="mb-5">
              <div className="sub-title">
                <h5>수상경력</h5>
              </div>
              <Table>
                <colgroup>
                  <col width={120} />
                  <col width="*" />
                  <col width={120} />
                  <col width={180} />
                </colgroup>
                <thead>
                  <tr>
                    <th>수상일</th>
                    <th>수상명</th>
                    <th>등급(위)</th>
                    <th>수여기관</th>
                  </tr>
                </thead>
                <tbody>
                  {awardList && awardList.length > 0 ? (
                    awardList.map((item, index) => {
                      return (
                        <tr key={`award-${index}`}>
                          <td>
                            {item.award_date && (
                              <Moment
                                date={portfolioInfo.award_date}
                                format="YYYY-MM-DD"
                                interval={0}
                              />
                            )}
                          </td>
                          <td className="text-start">{item.award_name}</td>
                          <td>{item.award_number}</td>
                          <td>{item.award_gov}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={4}>수상경력이 없습니다.</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </section>

            {/* 경력 및 활동사항 */}
            <section>
              <div className="sub-title">
                <h5>경력 및 활동사항</h5>
              </div>
              <Table>
                <colgroup>
                  <col width={120} />
                  <col width={140} />
                  <col width="*" />
                  <col width={300} />
                </colgroup>
                <thead>
                  <tr>
                    <th>구분</th>
                    <th>활동기간</th>
                    <th>활동명</th>
                    <th>주요활동내용(수행역할)</th>
                  </tr>
                </thead>
                <tbody>
                  {careerList && careerList.length > 0 ? (
                    careerList.map((item, index) => {
                      return (
                        <tr key={`career-${index}`}>
                          <td>{item.program_gubun_han}</td>
                          <td>
                            {item.career_start_date && (
                              <Moment
                                format="YYYY-MM-DD"
                                date={item.career_start_date}
                              />
                            )}
                            &nbsp; ~ &nbsp;
                            {item.career_end_date && (
                              <Moment
                                format="YYYY-MM-DD"
                                date={item.career_end_date}
                              />
                            )}
                          </td>
                          <td className="text-start">{item.career_title}</td>
                          <td>{item.career_contents}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={4}>등록된 경력 및 활동사항이 없습니다.</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </section>

            {/* 서명 */}
            <section className="signature-area">
              <h5>
                위와 같이 지원서를 제출하며 일체 허위 기재 사실이 없음을
                확인합니다.
              </h5>
              <p>
                <Moment format="YYYY년 MM월 DD일" />
              </p>
              <p>
                지원자 :{' '}
                <span>{Utils.decrypt(portfolioInfo.student_name)}</span> (서명)
              </p>
            </section>

            {/* 자기소개서 */}
            <section className="mb-5">
              <div className="sub-title">
                <h5>자기소개서</h5>
              </div>
              <div className="split-table type-unsplit">
                <Row className="table-row">
                  {/* 성장과정 */}
                  <Col className="th col-12">성장과정</Col>
                  <Col className="td col-12">
                    {portfolioInfo.my_growth_proceed}
                  </Col>
                  {/* 성격 및 생활신조 */}
                  <Col className="th col-12">성격 및 생활신조</Col>
                  <Col className="td col-12">{portfolioInfo.my_sinjo}</Col>
                  {/* 학교 및 경력사항 */}
                  <Col className="th col-12">학교 및 경력사항</Col>
                  <Col className="td col-12">{portfolioInfo.my_career}</Col>
                  {/* 지원동기 및 입사 후 포부 */}
                  <Col className="th col-12">지원동기 및 입사 후 포부</Col>
                  <Col className="td col-12">{portfolioInfo.my_jiwon}</Col>
                </Row>
              </div>
            </section>

            {/* 교사 코멘트 */}
            {(portfolioInfo.status === FormStatus.CON.value ||
              portfolioInfo.status === FormStatus.REJ.value) && (
              <section className="mb-5">
                <div className="sub-title flex-start">
                  <h5 className="text-primary">교사 코멘트</h5>
                </div>
                <div className="comment-box type-primary">
                  <ul className="comment-list">
                    {teacherCommentList.length > 0 &&
                      teacherCommentList.map((item, index) => {
                        return (
                          <li key={`comment-${index}`}>
                            <p className="date">
                              {item.reg_date && (
                                <Moment
                                  format="YYYY.MM.DD"
                                  date={item.reg_date}
                                />
                              )}
                            </p>
                            <p className="text">{item.comment}</p>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </section>
            )}

            {/* ////////// ↓ 하단 버튼 //////////  */}
            {viewType === 'student' && grade === '3' && (
              <div className="btn-area">
                <Button
                  size="lg"
                  variant="outline-primary"
                  onClick={e => handlePrint(e)}
                >
                  인쇄
                </Button>
                <Button
                  size="lg"
                  variant="primary"
                  onClick={e => {
                    history.push({
                      pathname: `/student/indivAbility/portfolio/write`,
                    });
                  }}
                >
                  수정
                </Button>
              </div>
            )}
            {viewType === 'student' &&
              grade === '2' &&
              portfolioInfo.status === FormStatus.CON.value && (
                <div className="btn-area">
                  <Button
                    size="lg"
                    variant="outline-primary"
                    onClick={e => handlePrint(e)}
                  >
                    인쇄
                  </Button>
                </div>
              )}
            {viewType === 'student' &&
              grade === '2' &&
              portfolioInfo.status === FormStatus.REQ.value && (
                <div className="btn-area">
                  <Button
                    size="lg"
                    variant="outline-primary"
                    onClick={e => handlePrint(e)}
                  >
                    인쇄
                  </Button>
                  <Button
                    size="lg"
                    variant="primary"
                    onClick={e => {
                      const target = e.currentTarget;

                      Swal.fire({
                        title: '알림',
                        text: '승인요청을 취소하시겠습니까?',
                        confirmButtonText: '확인',
                        cancelButtonText: '취소',
                        showCancelButton: true,
                        reverseButtons: true,
                      }).then(result => {
                        if (result.isConfirmed) {
                          updatePortfolio(target, FormStatus.ING.value);
                        }
                      });
                    }}
                  >
                    승인요청 취소
                  </Button>
                </div>
              )}
            {viewType === 'student' &&
              grade === '2' &&
              portfolioInfo.status === FormStatus.ING.value && (
                <div className="btn-area">
                  <Button
                    size="lg"
                    variant="outline-primary"
                    onClick={e => handlePrint(e)}
                  >
                    인쇄
                  </Button>
                  <Button
                    size="lg"
                    variant="outline-primary"
                    onClick={e => {
                      const target = e.currentTarget;

                      Swal.fire({
                        title: '알림',
                        text: '삭제하시겠습니까?',
                        confirmButtonText: '확인',
                        cancelButtonText: '취소',
                        showCancelButton: true,
                        reverseButtons: true,
                      }).then(result => {
                        if (result.isConfirmed) {
                          updatePortfolio(target, FormStatus.DEL.value);
                        }
                      });
                    }}
                  >
                    삭제
                  </Button>
                  <Button
                    size="lg"
                    variant="primary"
                    onClick={e => {
                      history.push({
                        pathname: `/student/indivAbility/portfolio/write`,
                      });
                    }}
                  >
                    수정
                  </Button>
                  <Button
                    size="lg"
                    variant="primary"
                    onClick={e => {
                      const target = e.currentTarget;

                      Swal.fire({
                        title: '알림',
                        text: '승인요청하시겠습니까?',
                        confirmButtonText: '확인',
                        cancelButtonText: '취소',
                        showCancelButton: true,
                        reverseButtons: true,
                      }).then(result => {
                        if (result.isConfirmed) {
                          updatePortfolio(target, FormStatus.REQ.value);
                        }
                      });
                    }}
                  >
                    승인요청
                  </Button>
                </div>
              )}
            {viewType === 'student' &&
              grade === '2' &&
              portfolioInfo.status === FormStatus.REJ.value && (
                <div className="btn-area">
                  <Button
                    size="lg"
                    variant="outline-primary"
                    onClick={e => handlePrint(e)}
                  >
                    인쇄
                  </Button>
                  <Button
                    size="lg"
                    variant="primary"
                    onClick={e => {
                      history.replace({
                        pathname: `/student/indivAbility/portfolio/write`,
                      });
                    }}
                  >
                    수정
                  </Button>
                </div>
              )}
            {viewType !== 'student' && (
              <div className="btn-area">
                <Button
                  onClick={() => {
                    history.goBack();
                  }}
                  variant="primary"
                  size="lg"
                >
                  목록으로
                </Button>
              </div>
            )}
          </article>
        ) : (
          firstCallDone && (
            // 포트폴리오 내용이 없을 시
            <article className="content">
              <ul className="path">
                <li>
                  <Link to="/">HOME</Link>
                </li>
                <li>
                  <Link to="#none">직무기초 포트폴리오</Link>
                </li>
              </ul>

              {/* ////////// ↓ 상단 타이틀영역 ////////// */}
              <section>
                <div className="title">
                  <h5 style={{ margin: '0' }}>
                    직무기초 포트폴리오{' '}
                    <span
                      style={{
                        fontSize: 'var(--fs-14)',
                        color: 'blue',
                        fontWeight: 'bold',
                        marginLeft: '5px',
                      }}
                    >
                      (포트폴리오 입력은 2학년 및 3학년에 해당되나 바우처 점수는
                      2학년만 해당됩니다.)
                    </span>
                  </h5>
                  <div className="btn-area m-0">
                    <Button
                      onClick={() =>
                        props.history.push(
                          '/student/indivAbility/portfolio/write',
                        )
                      }
                      variant="primary"
                    >
                      신규작성
                    </Button>
                  </div>
                </div>
              </section>

              {/* ////////// ↓ 컨텐츠 ////////// */}

              <section className="no-data">
                작성한 학업계획서가 없습니다.
              </section>
            </article>
          )
        )}
      </Container>
    </main>
  );
});
