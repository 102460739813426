import Axios from '@api/axios';

/**
 *   [ 시스템 관리자 > 코드관리 API ]
 *
 */

// const API_SYSTEM_CODE = 'api/v1/system/config';
const API_ADMIN_SYSTEM_CODE = 'api/v1/adminSystem';

// ---------------------------------------------------------------
// 메인코드
// ---------------------------------------------------------------
// [코드관리 > 코드관리 > 메인코드] 목록 가져오기
export const getMainCode = () => {
  return Axios.get(`${API_ADMIN_SYSTEM_CODE}/main-code-list`);
};

// [코드관리 > 코드관리 > 메인코드] 검색
export const searchCode = ({ filter, keyword }) => {
  return Axios.get(`${API_ADMIN_SYSTEM_CODE}/search-code/${filter}/${keyword}`);
};

// [코드관리 > 코드관리 > 메인코드] Redis 업데이트
export const putRedis = () => {
  return Axios.put(`${API_ADMIN_SYSTEM_CODE}/all-common-code`);
};

// [코드관리 > 코드관리 > 메인코드] 신규등록
export const postMainCode = param => {
  return Axios.post(`${API_ADMIN_SYSTEM_CODE}/add-main-code`, param);
};

// [코드관리 > 코드관리 > 메인코드] 삭제
export const deleteMainCode = ({ maCd }) => {
  return Axios.delete(`${API_ADMIN_SYSTEM_CODE}/delete-main-code/${maCd}`);
};

// [코드관리 > 코드관리 > 메인코드] 수정
export const patchMainCode = param => {
  return Axios.patch(`${API_ADMIN_SYSTEM_CODE}/patch-main-code`, param);
};

// ---------------------------------------------------------------
// 서브코드
// ---------------------------------------------------------------
// [코드관리 > 코드관리 > 서브코드] 목록 가져오기
export const getSubCode = ({ maCd }) => {
  return Axios.get(`${API_ADMIN_SYSTEM_CODE}/sub-code-list/${maCd}`);
};
// [코드관리 > 코드관리 > 서브코드 신규등록
export const postSubCode = param => {
  return Axios.post(`${API_ADMIN_SYSTEM_CODE}/add-sub-code`, param);
};

// [코드관리 > 코드관리 > 서브코드 삭제
export const deleteSubCode = ({ maCd, msCd, msNm, mappingCd }) => {
  return Axios.delete(
    `${API_ADMIN_SYSTEM_CODE}/delete-sub-code/${maCd}/${msCd}/${msNm}/${mappingCd}`,
  );
};

// [코드관리 > 코드관리 > 서브코드 수정
export const patchSubCode = param => {
  return Axios.patch(`${API_ADMIN_SYSTEM_CODE}/patch-sub-code`, param);
};
