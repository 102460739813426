/**
 *   [ 프로그램 관리 API ]
 *
 */
import Axios from '@api/axios';

const API_TEACHER = 'api/v1/teacher';

const SELECT_FREE_PROGRAM = `${API_TEACHER}/free-program`;
const UPDATE_FREE_PROGRAM = `${API_TEACHER}/update-free-program`;

// 프로그램 관리 > 자율교육 프로그램 조회
export const selectFreeProgram = () => {
  return Axios.post(`${SELECT_FREE_PROGRAM}`);
};

// 프로그램 관리 > 자율교육 프로그램 업데이트
export const updateFreeProgram = param => {
  return Axios.post(`${UPDATE_FREE_PROGRAM}`, param);
};
