/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import {
  Container,
  Button,
  Form,
  Card,
  Row,
  Col,
  Table,
  Modal,
} from 'react-bootstrap';
import { CustomSelect, PdfPreview, PreviewModal } from '@components';

//* Utils
import Utils from '@common/Utils';
import Swal from 'sweetalert2';

//* CONSTS
import {
  ROLE,
  FormStatus,
  SEMESTER_DROPDOWN,
  APPROVE_STATE_TYPE_LIST,
  FILE_FORM_TYPE,
} from '@common/consts';

//* API
import {
  selectFormCareer,
  updateStatusFormCareer,
} from '@api/teacher/careerDesign';
import { getFilePathFromRedis } from '@api/file';

// 인적사항 Form
const initialUserInfo = {
  name: '',
  major: '',
  grade: '',
  class: '',
  num: '',
};

// 취업, 진학 Form
const initialCareerForm = {
  seq: '',
  grade: '',
  status: 'NONE',
  point: '',
  orgPoint: '',
  confirmDate: '',

  hopeDirection: '', // 진로방향 (코드)
  hopeDirectionHan: '', // 진로방향 (한글)

  hopeJobName: '', // 희망 직업
  hopeJobGubun: '', // 기업형태 (코드)
  hopeJobGubunHan: '', // 기업형태 (한글)
  hopeJobIndustry: '', // 희망 산업체
  hopeJobReason: '', // 진로희망 사유
  hopeJobCertificate: '', // 희망직업 관련 자격증
  hopeJobWork: '', // 희망직업 업무 내용
  hopeJobSkill: '', // 필요기술 및 지식

  hopeUnivName: '', // 희망대학
  hopeUnivGubun: '', // 대학유형 (코드)
  hopeUnivGubunHan: '', // 대학유형 (한글)
  hopeUnivApply: '', // 지원형태 (코드)
  hopeUnivApplyHan: '', // 지원형태 (한글)
  hopeUnivMajor: '', // 희망학과
  hopeUnivReason: '', // 진학희망 사유
  hopeUnivGrade: '', // 필요등급
};

// 교과이수 Form
const initialStudyPlan = {
  '1-1': {
    mon: ';;;;;;;',
    tue: ';;;;;;;',
    wed: ';;;;;;;',
    thu: ';;;;;;;',
    fri: ';;;;;;;',
  },
};

// 테이블 행-열
const scheduleDays = ['월', '화', '수', '목', '금']; // 요일
const scheduleClasses = [1, 2, 3, 4, 5, 6, 7]; // 교시

//* [ Main ]
export default React.memo(function CareerDesignReport(props) {
  //* #################################################################
  //* [ States ]
  //* #################################################################
  const history = useHistory();

  // 유저 권한 타입
  const authType = props.userInfo.type;

  // Path 파라미터
  const { seq: studentIdx } = useParams();

  // 중복요청 방지
  const [isSentRequest, setIsSentRequest] = useState(false);

  // 인적사항
  const [userInfo, setUserInfo] = useState(initialUserInfo);

  // 진로방향
  const [careerForm, setCareerForm] = useState(initialCareerForm);

  // 교과이수
  // const [selectedOption, setSelectedOption] = useState(SEMESTER_DROPDOWN[0]); // 선택 학기정보 (옵션)
  // const [semesterDropdown, setSemesterDropdown] = useState(SEMESTER_DROPDOWN); // 학기정보 드랍다운
  // const [studyPlan, setStudyPlan] = useState(initialStudyPlan);

  // 자격증
  const [certList, setCertList] = useState([]);

  // 특별활동
  const [actList, setActList] = useState([]);

  // 교사 코멘트 List
  const [commentList, setCommentList] = useState([]);

  // 교사 코멘트 Input
  const [commentInput, setCommentInput] = useState('');

  // 승인여부 ( 승인 | 보완 )
  const [submitType, setSubmitType] = useState('');

  // PDF 미리보기 ( react-pdf )
  const [fileUrl, setFileUrl] = useState(null);
  const [pdfPageNum, setPdfPageNum] = useState(1);
  const [pdfTotalPages, setPdfTotalPages] = useState(1);

  //* #################################################################
  //* [ Utils ]
  //* #################################################################
  // 학기 선택
  // const semesterHandler = data => {
  //   setSelectedOption(data);
  // };

  // PDF 파일 로딩 완료
  const onDocumentLoadSuccess = ({ numPages }) => {
    setPdfPageNum(1);
    setPdfTotalPages(numPages);
  };

  //* #################################################################
  //* [ Preview Modal ]
  //* #################################################################
  // Flag
  const [previewModalShow, setPreviewModalShow] = useState(false);

  // Open
  const openPreviewModal = () => {
    openFile({ fileSeq: null });
    setPreviewModalShow(true);
  };

  // Close
  const closePreviewModal = () => {
    setPreviewModalShow(false);
  };

  //* #################################################################
  //* [ API ] 이수기준 파일 출력
  //* #################################################################
  // 양식 다운로드
  const openFile = async () => {
    // Axios
    try {
      const { data } = await getFilePathFromRedis({
        formType: FILE_FORM_TYPE.FILE_STATIC_FORM_GUIDE_CAREER,
      });

      if (data.code === 0) {
        const redisKey = data.data;
        const hostName = window.location.hostname;

        const url = Utils.getFileOpenUrl(hostName, redisKey);

        setFileUrl(url);
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 진로설계 정보 가져오기
  //* #################################################################
  const [firstCallDone, setFirstCallDone] = useState(false);

  const selectFormData = async () => {
    // JSON 파라미터
    const param = {
      studentIdx,
    };

    // Axios
    try {
      const { data } = await selectFormCareer(param);

      if (data.code === 0) {
        setFirstCallDone(true);

        const { career_form: career } = data.data;

        // 인적사항
        setUserInfo({
          name: career.name,
          major: career.major,
          grade: career.grade,
          class: career.ban_no,
          num: career.student_no,
        });

        // 진로뱡향, 교과이수, 자격증, 체험활동
        if (career.has_form) {
          const {
            study_plan: study = {},
            certi_list: certi = [],
            act_list: act = [],
            comment_list: comment = [],
          } = data.data;

          // 진로뱡향
          setCareerForm({
            seq: career.form_seq, // SEQ
            status: career.status, // 상태
            point: career.point, // 승인점수
            orgPoint: career.org_point, // 기본점수
            confirmDate: career.confirm_date, // 승인일자

            hopeDirection: career.hope_direction, // 진로유형 (코드)
            hopeDirectionHan: career.hope_direction_han, // 진로유형 (한글)

            hopeJobName: career.hope_job_name, // 희망 직업
            hopeJobGubun: career.hope_job_gubun, // 기업형태 (코드)
            hopeJobGubunHan: career.hope_job_gubun_han, // 기업형태 (한글)
            hopeJobIndustry: career.hope_job_industry, // 희망 산업체
            hopeJobReason: career.hope_job_reason, // 진로희망 사유
            hopeJobCertificate: career.hope_job_certificate, // 희망직업 관련 자격증
            hopeJobWork: career.hope_job_work, // 희망직업 업무 내용
            hopeJobSkill: career.hope_job_skill, // 필요기술 및 지식

            hopeUnivName: career.hope_univ_name, // 희망대학
            hopeUnivGubun: career.hope_univ_gubun, // 대학유형 (코드)
            hopeUnivGubunHan: career.hope_univ_gubun_han, // 대학유형 (한글)
            hopeUnivApply: career.hope_univ_apply, // 지원형태 (코드)
            hopeUnivApplyHan: career.hope_univ_apply_han, // 지원형태 (한글)
            hopeUnivMajor: career.hope_univ_major, // 희망학과
            hopeUnivReason: career.hope_univ_reason, // 진학희망 사유
            hopeUnivGrade: career.hope_univ_grade, // 필요등급
          });

          // 현재 학기
          // setStudyPlan(study);

          // 학기 리스트 필터링
          const storedSemsterList = Object.keys(study);

          const filteredSemesterList = SEMESTER_DROPDOWN.filter(item =>
            storedSemsterList.includes(item.value),
          ).sort(
            (a, b) => b.value.replaceAll('-', '') - a.value.replaceAll('-', ''),
          );

          // setSemesterDropdown(filteredSemesterList);

          // 최신 학기 > default 선택
          // setSelectedOption(filteredSemesterList[0]);

          // 자격증 취득계획
          setCertList([...certi]);

          // 창의적 체험활동
          setActList([...act]);

          // 교사 코멘트
          setCommentList([...comment]);

          // 승인여부
          setSubmitType(APPROVE_STATE_TYPE_LIST[0].value);
        }
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 진로설계 승인, 보완, 승인취소
  //* #################################################################
  const updateRequest = async status => {
    // 중복요청 확인
    if (isSentRequest) {
      return;
    }

    // 승인, 보완 ( CON, REJ )
    if (status === 'CONFIRM') {
      status = submitType;
    }

    // 승인 취소
    else if (status === 'CANCEL') {
      status = 'REJ';
    }

    if (status !== 'CON' && status !== 'REJ') {
      Swal.fire({
        text: '승인여부를 확인해주세요.',
        confirmButtonText: '확인',
      });
      return;
    }

    // JSON 파라미터
    const param = {
      formSeq: careerForm.seq,
      status,
      comment: commentInput,
    };

    // 중복요청 방지 해제
    setIsSentRequest(true);

    // Axios
    try {
      const { data } = await updateStatusFormCareer(param);

      if (data.code === 0) {
        // 새로고침
        window.location.reload();
      }
    } catch (e) {
      // 중복요청 방지 해제
      setIsSentRequest(false);

      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ useEffect ]
  //* #################################################################
  useEffect(() => {
    selectFormData();

    return () => {};
  }, []);

  //* #################################################################
  //* [ Components ]
  //* #################################################################
  // 버튼 (삭제, 수정, 취소)
  const customButton = type => {
    let variant;
    let event;
    let text;

    switch (type) {
      case 'submit':
        variant = 'primary';
        event = e => {
          Swal.fire({
            title: '알림',
            text: '제출 하시겠습니까?',
            confirmButtonText: '확인',
            cancelButtonText: '취소',
            showCancelButton: true,
            reverseButtons: true,
          }).then(result => {
            if (result.isConfirmed) {
              updateRequest('CONFIRM');
            }
          });
        };
        text = '제출';
        break;
      case 'list':
        variant = 'outline-primary';
        event = e => {
          history.goBack();
        };
        text = '목록';
        break;
      case 'cancel':
        variant = 'outline-primary';
        event = e => {
          Swal.fire({
            title: '알림',
            text: '취소 하시겠습니까?',
            confirmButtonText: '확인',
            cancelButtonText: '취소',
            showCancelButton: true,
            reverseButtons: true,
          }).then(result => {
            if (result.isConfirmed) {
              updateRequest('CANCEL');
            }
          });
        };
        text = '승인취소';
        break;
      default:
        break;
    }

    return (
      <Button size="lg" variant={variant} onClick={event}>
        {text}
      </Button>
    );
  };

  const [linkList, setLinkList] = useState([]);

  useEffect(() => {
    switch (authType) {
      case ROLE.ADMIN:
        setLinkList([
          {
            name: '프로그램 현황',
            path: '/admEdu/student/program',
          },
        ]);
        break;
      case ROLE.TEACHER:
        setLinkList([
          {
            name: '학생 관리',
            path: '/teacher/studentMgmt/studentMgmt',
          },
        ]);
        break;
      case ROLE.STUDENT:
        setLinkList([
          {
            name: 'HOME',
            path: '/',
          },
        ]);
        break;
      default:
        break;
    }
  }, [authType]);

  //* #################################################################
  //* [ return ]
  //* #################################################################
  return (
    <main id="program-careerDesign-comment" className="type-02">
      {careerForm.seq ? (
        // 작성된 글이 있을 때
        <Container>
          <ul className="path">
            {linkList &&
              linkList.length > 0 &&
              linkList.map((v, i) => {
                return (
                  <li key={`link_${i}`}>
                    <Link to={v.path}>{v.name}</Link>
                  </li>
                );
              })}
            <li>
              <Link to="#none">결과 보고서</Link>
            </li>
          </ul>

          {/* ////////// ↓ 상단 타이틀영역 ////////// */}
          <section>
            <div className="title mb-60">
              <h5>진로설계</h5>
              <div className="dl-box type-bar lg">
                {/* 승인상태, 승인일자 */}
                <dl className="status-text">
                  <strong className={FormStatus[careerForm.status].class}>
                    {FormStatus[careerForm.status].text}
                    <br />
                    {careerForm.status === 'CON' && (
                      <small>
                        {Utils.getJustDateString(careerForm.confirmDate)}
                      </small>
                    )}
                  </strong>
                </dl>
                {/* 점수 */}
                <dl>
                  <dd>
                    <strong>{careerForm.point || 0}</strong>
                  </dd>
                </dl>
              </div>
            </div>

            <div className="mb-4">
              <Button variant="text-underline" onClick={openPreviewModal}>
                승인이수 보기
              </Button>
            </div>
          </section>

          {/* ////////// ↓ 컨텐츠 ////////// */}
          <Card>
            <Card.Body>
              {/* 인적사항 */}
              <section className="mb-5">
                <div className="sub-title">
                  <h5>인적사항</h5>
                </div>
                <div className="split-table">
                  <Row className="table-row">
                    <Col className="th col-2">이름</Col>
                    <Col className="td col-10">
                      {Utils.decrypt(userInfo.name)}
                    </Col>
                  </Row>
                  <Row className="table-row">
                    <Col className="th col-2">학과</Col>
                    <Col className="td col-10">{userInfo.major}</Col>
                  </Row>
                  <Row className="table-row">
                    <Col className="th col-2">학년/반/번호</Col>
                    <Col className="td col-10">
                      {+userInfo.grade || ''}학년 {+userInfo.class || ''}반{' '}
                      {+userInfo.num || ''}번
                    </Col>
                  </Row>
                </div>
              </section>

              {/* 진로방향 */}
              <section className="mb-5">
                <div className="sub-title">
                  <h5>진로방향</h5>
                </div>
                {/* 취업 */}
                {careerForm.hopeDirection === '1' && (
                  <div className="split-table">
                    <Row className="table-row">
                      <Col className="th col-2">진로방향</Col>
                      <Col className="td col-10">
                        {careerForm.hopeDirection &&
                          careerForm.hopeDirectionHan}
                      </Col>
                    </Row>
                    <Row className="table-row">
                      <Col className="th col-2">희망직업</Col>
                      <Col className="td col-10">
                        {careerForm.hopeJobName || ''}
                      </Col>
                    </Row>
                    <Row className="table-row">
                      <Col className="th col-2">기업형태</Col>
                      <Col className="td col-10">
                        {careerForm.hopeJobGubun && careerForm.hopeJobGubunHan}
                      </Col>
                    </Row>
                    <Row className="table-row">
                      <Col className="th col-2">희망산업체</Col>
                      <Col className="td col-10">
                        {careerForm.hopeJobIndustry || ''}
                      </Col>
                    </Row>
                    <Row className="table-row">
                      <Col className="th col-2">진로희망 사유</Col>
                      <Col className="td col-10">
                        {careerForm.hopeJobReason || ''}
                      </Col>
                    </Row>
                    <Row className="table-row">
                      <Col className="th col-2">희망직업 관련 자격증</Col>
                      <Col className="td col-10">
                        {careerForm.hopeJobCertificate || ''}
                      </Col>
                    </Row>
                    <Row className="table-row">
                      <Col className="th col-2">희망직업이 하는 일</Col>
                      <Col className="td col-10">
                        {careerForm.hopeJobWork || ''}
                      </Col>
                    </Row>
                    <Row className="table-row">
                      <Col className="th col-2">필요기술 및 지식</Col>
                      <Col className="td col-10">
                        {careerForm.hopeJobSkill || ''}
                      </Col>
                    </Row>
                  </div>
                )}
                {/* 진학 */}
                {careerForm.hopeDirection === '2' && (
                  <div className="split-table">
                    <Row className="table-row">
                      <Col className="th col-2">진로방향</Col>
                      <Col className="td col-10">
                        {careerForm.hopeDirection &&
                          careerForm.hopeDirectionHan}
                      </Col>
                    </Row>
                    <Row className="table-row">
                      <Col className="th col-2">희망대학</Col>
                      <Col className="td col-10">
                        {careerForm.hopeUnivName || ''}
                      </Col>
                    </Row>
                    <Row className="table-row">
                      <Col className="th col-2">대학형태</Col>
                      <Col className="td col-10">
                        {careerForm.hopeUnivGubun &&
                          careerForm.hopeUnivGubunHan}
                      </Col>
                    </Row>
                    <Row className="table-row">
                      <Col className="th col-2">지원형태</Col>
                      <Col className="td col-10">
                        {careerForm.hopeUnivApply &&
                          careerForm.hopeUnivApplyHan}
                      </Col>
                    </Row>
                    <Row className="table-row">
                      <Col className="th col-2">희망학과</Col>
                      <Col className="td col-10">
                        {careerForm.hopeUnivMajor || ''}
                      </Col>
                    </Row>
                    <Row className="table-row">
                      <Col className="th col-2">진학희망 사유</Col>
                      <Col className="td col-10">
                        {careerForm.hopeUnivReason || ''}
                      </Col>
                    </Row>
                    <Row className="table-row">
                      <Col className="th col-2">필요등급</Col>
                      <Col className="td col-10">
                        {careerForm.hopeUnivGrade || ''}
                      </Col>
                    </Row>
                  </div>
                )}
              </section>

              {/* 교과 이수 */}
              {/* <section className="mb-5">
                <div className="sub-title">
                  <h5>교과 이수</h5>
                  <div className="sw-input">
                    <CustomSelect
                      options={semesterDropdown}
                      value={selectedOption}
                      onChange={semesterHandler}
                    />
                  </div>
                </div>
                <Table>
                  <colgroup>
                    <col width="*" />
                    <col width="*" />
                    <col width="*" />
                    <col width="*" />
                    <col width="*" />
                    <col width="*" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>구분</th>
                      {scheduleDays.map((day, index) => (
                        <th key={index}>{day}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {scheduleClasses.map((item, index) => {
                      return (
                        <tr key={index}>
                          <th>{item}교시</th>
                          <td>
                            {studyPlan[selectedOption.value] &&
                              studyPlan[selectedOption.value].mon.split(';')[
                                item - 1
                              ]}
                          </td>
                          <td>
                            {studyPlan[selectedOption.value] &&
                              studyPlan[selectedOption.value].tue.split(';')[
                                item - 1
                              ]}
                          </td>
                          <td>
                            {studyPlan[selectedOption.value] &&
                              studyPlan[selectedOption.value].wed.split(';')[
                                item - 1
                              ]}
                          </td>
                          <td>
                            {studyPlan[selectedOption.value] &&
                              studyPlan[selectedOption.value].thu.split(';')[
                                item - 1
                              ]}
                          </td>
                          <td>
                            {studyPlan[selectedOption.value] &&
                              studyPlan[selectedOption.value].fri.split(';')[
                                item - 1
                              ]}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </section> */}

              {/* 자격증 취득계획 */}
              <section className="mb-5">
                <div className="sub-title">
                  <h5>자격증 취득계획</h5>
                </div>
                <Table>
                  <colgroup>
                    <col width={200} />
                    <col width="*" />
                  </colgroup>
                  {/* <thead></thead> */}
                  <tbody>
                    <tr>
                      <th>전공자격증</th>
                      <td className="text-start">
                        {certList
                          .filter(item => +item.certi_gubun === 1)
                          .map(item => item.certi_info)
                          .join(', ')}
                      </td>
                    </tr>
                    <tr>
                      <th>정보화 자격증</th>
                      <td className="text-start">
                        {certList
                          .filter(item => +item.certi_gubun === 2)
                          .map(item => item.certi_info)
                          .join(', ')}
                      </td>
                    </tr>
                    <tr>
                      <th>외국어 능력</th>
                      <td className="text-start">
                        {certList
                          .filter(item => +item.certi_gubun === 3)
                          .map(item => item.certi_info)
                          .join(', ')}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </section>

              {/* 창의적 체험활동 */}
              <section className="mb-5">
                <div className="sub-title">
                  <h5>창의적 체험활동</h5>
                </div>
                <div className="has-bt">
                  {/* 동아리 */}
                  <div className="sub-title type-dot">
                    <h5>동아리</h5>
                  </div>
                  <Table className="bt-none">
                    <colgroup>
                      <col width={400} />
                      <col width="*" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th className="text-start">동아리명</th>
                        <th className="text-start">활동내용</th>
                      </tr>
                    </thead>
                    <tbody>
                      {actList
                        .filter(item => +item.act_gubun === 1)
                        .map((item, index) => {
                          return (
                            <tr key={index}>
                              <td className="text-start">{item.act_info}</td>
                              <td className="text-start">{item.act_detail}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                  {/* 봉사활동 */}
                  <div className="sub-title type-dot">
                    <h5>봉사활동</h5>
                  </div>
                  <Table className="bt-none">
                    <colgroup>
                      <col width="*" />
                      <col width={200} />
                    </colgroup>
                    <thead>
                      <tr>
                        <th className="text-start">봉사활동명</th>
                        <th>봉사시간</th>
                      </tr>
                    </thead>
                    <tbody>
                      {actList
                        .filter(item => +item.act_gubun === 2)
                        .map((item, index) => {
                          return (
                            <tr key={index}>
                              <td className="text-start">{item.act_info}</td>
                              <td className="text-start">{item.act_detail}</td>
                            </tr>
                          );
                        })}
                      {actList.filter(item => +item.act_gubun === 2).length >
                        0 && (
                        <tr>
                          <td className="text-start">
                            <strong>총 봉사시간</strong>
                          </td>
                          <td className="text-start">
                            <strong>
                              {actList
                                .filter(item => +item.act_gubun === 2)
                                .reduce((sum, el) => {
                                  return sum + +el.act_detail;
                                }, 0)}
                            </strong>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {/* 독서계획 */}
                  <div className="sub-title type-dot">
                    <h5>독서계획</h5>
                  </div>
                  <Table className="bt-none">
                    <colgroup>
                      <col width="*" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th className="text-start">도서명</th>
                      </tr>
                    </thead>
                    <tbody>
                      {actList
                        .filter(item => +item.act_gubun === 3)
                        .map((item, index) => {
                          return (
                            <tr key={index}>
                              <td className="text-start">{item.act_info}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                  {/* 기타활동 */}
                  <div className="sub-title type-dot">
                    <h5>기타활동</h5>
                  </div>
                  <Table className="bt-none">
                    <colgroup>
                      <col width={400} />
                      <col width="*" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th className="text-start">활동명</th>
                        <th className="text-start">활동내용</th>
                      </tr>
                    </thead>
                    <tbody>
                      {actList
                        .filter(item => +item.act_gubun === 4)
                        .map((item, index) => {
                          return (
                            <tr key={index}>
                              <td className="text-start">{item.act_info}</td>
                              <td className="text-start">{item.act_detail}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
              </section>

              {/* 승인여부 */}
              {authType !== ROLE.ADMIN && careerForm.status !== 'CON' && (
                <section className="mb-5">
                  <div className="flex-between">
                    <div className="card-radio-group mb-4">
                      <h5>승인여부</h5>
                      {APPROVE_STATE_TYPE_LIST.map((item, index) => {
                        return (
                          <Form.Check
                            key={`approve-state-type-${item.value}`}
                            name="approve-state-type"
                            type="radio"
                            label={item.label}
                            value={item.value}
                            id={item.value}
                            onChange={e => setSubmitType(e.target.value)}
                            checked={submitType === item.value}
                          />
                        );
                      })}
                    </div>
                  </div>
                </section>
              )}

              {/* 교사 코멘트 - 작성하기 */}
              {((authType !== ROLE.ADMIN && careerForm.status === 'REQ') ||
                careerForm.status === 'CON') && (
                <section className="mb-5">
                  <div className="sub-title">
                    <h5>교사 코멘트</h5>
                  </div>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    placeholder="최대 700자 이내로 입력하세요."
                    onChange={e => setCommentInput(e.target.value)}
                    value={commentInput}
                  />
                  <small className="text-gray flex-end mt-2">0/700자</small>
                </section>
              )}

              {/* 교사 코멘트 - 작성된 리스트 */}
              {commentList.length > 0 && (
                <section className="mb-5">
                  <div className="comment-box disabled mt-4">
                    {commentList.map((comment, index) => {
                      return (
                        <ul className="comment-list" key={index}>
                          <li>
                            <p className="date">
                              {
                                new Date(comment.reg_date)
                                  .toISOString()
                                  .split('T')[0]
                              }
                            </p>
                            <p className="text">{comment.comment}</p>
                          </li>
                          <br />
                        </ul>
                      );
                    })}
                  </div>
                </section>
              )}

              {/* ////////// ↓ 하단 버튼 //////////  */}
              {authType !== ROLE.ADMIN && (
                <div className="btn-area">
                  {/* 승인 */}
                  {careerForm.status === 'CON' && (
                    <>
                      {customButton('list')}
                      {customButton('cancel')}
                    </>
                  )}
                  {/* 승인요청 */}
                  {careerForm.status === 'REQ' && (
                    <>
                      {customButton('list')}
                      {customButton('submit')}
                    </>
                  )}
                  {/* 보완 */}
                  {careerForm.status === 'ING' && <>{customButton('list')}</>}
                  {/* 작성중 */}
                  {careerForm.status === 'REJ' && (
                    <>
                      {customButton('list')}
                      {customButton('submit')}
                    </>
                  )}
                </div>
              )}
              {authType === ROLE.ADMIN && (
                <div className="btn-area">{customButton('list')}</div>
              )}
            </Card.Body>
          </Card>
        </Container>
      ) : (
        firstCallDone && (
          // 작성된 글이 없을 때
          <Container>
            <article className="content">
              <ul className="path">
                {linkList &&
                  linkList.length > 0 &&
                  linkList.map((v, i) => {
                    return (
                      <li key={`link_${i}`}>
                        <Link to={v.path}>{v.name}</Link>
                      </li>
                    );
                  })}
                <li>
                  <Link to="#none">결과 보고서</Link>
                </li>
              </ul>

              {/* ////////// ↓ 상단 타이틀영역 ////////// */}
              <section>
                <div className="title">
                  <h5>진로설계</h5>
                  <div className="btn-area m-0">
                    <Button onClick={() => history.goBack()} variant="primary">
                      목록으로
                    </Button>
                  </div>
                </div>
              </section>

              {/* ////////// ↓ 컨텐츠 ////////// */}
              <section className="no-data">작성된 진로설계가 없습니다.</section>
            </article>
          </Container>
        )
      )}

      {/* PDF 미리보기 모달 */}
      <PreviewModal
        className="preview-modal"
        title="승인이수"
        show={previewModalShow}
        onHide={closePreviewModal}
      >
        <Modal.Body className="border-0 text-center">
          <PdfPreview
            fileUrl={fileUrl}
            loadHandler={onDocumentLoadSuccess}
            pageNum={pdfPageNum}
            totalPages={pdfTotalPages}
            isPageDisabled={false}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={closePreviewModal}>
            닫기
          </Button>
        </Modal.Footer>
      </PreviewModal>
    </main>
  );
});
