import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Switch } from 'react-switch-input';
import { Container, Button, Row, Col, Card, Ratio } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';
import Moment from 'react-moment';

//* API
import { getFilePathFromRedis } from '@api/file';
import { selectMatchingSummaryReport } from '@api/student/matching';

//* Consts
import { FILE_FORM_TYPE } from '@common/consts';

//* Utils
import Utils from '@common/Utils';

const initialStudentInfo = {
  grade: '',
  ban_no: '',
  student_no: '',
  stduent_depart: '',
};

//* [ Main ]
export default React.memo(function MatchingInfoReport(props) {
  const printWindowRef = useRef();

  //* #################################################################
  //* [ States ]
  //* #################################################################
  // 학생 정보
  const [studentInfo, setStudentInfo] = useState(initialStudentInfo);

  // 진로설계 (1학년)
  const [careerForm, setCareerForm] = useState({}); // 진로설계 Form

  const [careerMajorCertList, setCareerMajorCertList] = useState([]); // 자격증 취득계획 > 전공
  const [careerItCertList, setCareerItCertList] = useState([]); // 자격증 취득계획 > 정보화
  const [careerLangCertList, setCareerLangCertList] = useState([]); // 자격증 취득계획 > 외국어

  const [careerClubActList, setCareerClubActList] = useState([]); // 체험활동 > 동아리
  const [careerBongActList, setCareerBongActList] = useState([]); // 체험활동 > 봉사
  const [careerReadActList, setCareerReadActList] = useState([]); // 체험활동 > 독서
  const [careerEtcActList, setCareerEtcActList] = useState([]); // 체험활동 > 기타

  // 포트폴리오 (2, 3학년)
  const [portfolioForm, setPortfolioForm] = useState({}); // 포트폴리오 Form
  const [portfolioAwardList, setPortfolioAwardList] = useState([]); // 수상내역
  const [portfolioCareerList, setPortfolioCareerList] = useState([]); // 경력 및 활동내역
  const [portfolioCertList, setPortfolioCertList] = useState([]); // 자격증
  const [profileImageUrl, setProfileImageUrl] = useState(''); // 프로필 이미지 URL

  // 산업체
  const [industryList, setIndustryList] = useState([]);

  // 취업마인드
  const [workList, setWorkList] = useState([]);

  // 인성자존감
  const [insungList, setInsungList] = useState([]);

  // NCS
  const [ncsList, setNcsList] = useState([]);

  // 요약 스위치
  const [isSummary, setIsSummary] = useState(false);

  //* #################################################################
  //* [ Utils ]
  //* #################################################################
  // 인쇄하기
  const handlePrint = useReactToPrint({
    onBeforeGetContent: () => {
      printWindowRef.current.classList.add('report-print');
    },
    onAfterPrint: () => {
      printWindowRef.current.classList.remove('report-print');
    },
    onPrintError: () => {
      printWindowRef.current.classList.remove('report-print');
    },
    content: () => {
      return printWindowRef.current;
    },
  });

  // 요약보고서
  const summaryHandler = e => {
    setIsSummary(e.target.checked);
  };

  //* #################################################################
  //* [ API ] FORM 가져오기 - SELECT
  //* #################################################################
  const getSummaryReport = async () => {
    try {
      const { data } = await selectMatchingSummaryReport();

      if (data.code === 0) {
        const info = data.data;
        const grade = +info.studentMap.grade;

        setStudentInfo({ ...info.studentMap }); // 학생 기본정보

        // 1학년 > 진로설계
        if (grade === 1) {
          setCareerForm({ ...info.careerMap.form }); // 진로설계 > Form

          // 진로설계 > 자격증 취득계획
          if (info.careerMap.certList && info.careerMap.certList.length > 0) {
            // 진로설계 > 자격증 취득계획 > 전공
            setCareerMajorCertList([
              ...info.careerMap.certList.filter(
                item => +item.certi_gubun === 1,
              ),
            ]);
            // 진로설계 > 자격증 취득계획 > 정보화
            setCareerItCertList([
              ...info.careerMap.certList.filter(
                item => +item.certi_gubun === 2,
              ),
            ]);
            // 진로설계 > 자격증 취득계획 > 외국어
            setCareerLangCertList([
              ...info.careerMap.certList.filter(
                item => +item.certi_gubun === 3,
              ),
            ]);
          }

          // 진로설계 > 체험활동
          if (info.careerMap.actList && info.careerMap.actList.length > 0) {
            // 진로설계 > 체험활동 > 동아리
            setCareerClubActList([
              ...info.careerMap.actList.filter(item => +item.act_gubun === 1),
            ]);
            // 진로설계 > 체험활동 > 봉사
            setCareerBongActList([
              ...info.careerMap.actList.filter(item => +item.act_gubun === 2),
            ]);
            // 진로설계 > 체험활동 > 독서
            setCareerReadActList([
              ...info.careerMap.actList.filter(item => +item.act_gubun === 3),
            ]);
            // 진로설계 > 체험활동 > 기타
            setCareerEtcActList([
              ...info.careerMap.actList.filter(item => +item.act_gubun === 4),
            ]);
          }
        }

        // 2, 3학년 > 포트폴리오
        if (grade > 1) {
          setPortfolioForm({ ...info.portfolioMap.form }); // 포트폴리오 > Form
          setPortfolioAwardList([...info.portfolioMap.awardList]); // 포트폴리오 > 수상경력
          setPortfolioCareerList([...info.portfolioMap.careerList]); // 포트폴리오 > 경력 및 활동사항
          setPortfolioCertList([...info.portfolioMap.certList]); // 포트폴리오 > 자격증

          // 포트폴리오 > 이미지
          if (info.portfolioMap.form.student_photo_path) {
            setProfileImageUrl(await openFile(info.portfolioMap.form.seq));
          }
        }

        // 공통
        setIndustryList([...info.industryList]); // 산업체
        setWorkList([...info.workProgramList]); // 취업마인드
        setInsungList([...info.insungList]); // 인성자존감
        setNcsList([...info.ncsList]); // NCS
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 포트폴리오 이미지 가져오기
  //* #################################################################
  // API 파일 조회 :: 학생 프로필 사진 url 조회
  const openFile = async fileSeq => {
    // API 파라미터
    const paramMap = {
      formType: FILE_FORM_TYPE.FILE_PORTFOLIO,
      fileSeq,
    };

    // Axios
    try {
      const { data } = await getFilePathFromRedis(paramMap);

      if (data.code === 0) {
        const redisKey = data.data;
        const hostName = window.location.hostname;

        const url = Utils.getFileOpenUrl(hostName, redisKey);

        return url;
      }
    } catch (e) {
      return null;
    }
    return null;
  };

  //* #################################################################
  //* [ useEffect ]
  //* #################################################################
  useEffect(() => {
    getSummaryReport();

    return () => {};
  }, []);

  //* #################################################################
  //* [ return ]
  //* #################################################################
  return (
    <main id="student-matchingInfo-report">
      <Container>
        <article className="content" ref={printWindowRef}>
          <ul className="path">
            <li>
              <Link to="/">HOME</Link>
            </li>
            <li>
              <Link to="#none">종합보고서</Link>
            </li>
          </ul>

          {/* ////////// ↓ 상단 타이틀영역 ////////// */}
          <section>
            <div className="title">
              <h5>종합보고서</h5>
              <div className="d-flex">
                <p>요약</p>
                <Switch value={isSummary} onChange={summaryHandler} />
              </div>
            </div>
          </section>

          {/* ////////// ↓ 컨텐츠 ////////// */}
          <Card className="mt-4">
            {/* 학적정보 (학과, 학년/반/번호) */}
            <section>
              <div className="dl-box">
                <dl>
                  <dt>학과</dt>
                  <dd>{studentInfo.student_depart}</dd>
                </dl>
                <dl>
                  <dt>학년/반/번호</dt>
                  <dd>
                    {`${studentInfo.grade}학년 ${
                      studentInfo.ban_no ? studentInfo.ban_no : '-'
                    }반 ${
                      studentInfo.student_no ? studentInfo.student_no : '-'
                    }번`}
                  </dd>
                </dl>
              </div>
            </section>

            {/* 포트폴리오 (1학년) */}
            {+studentInfo.grade === 1 && (
              <>
                {/* 진로설계 (1학년) - Form */}
                <section>
                  <div className="dl-box">
                    {/* 진로방향 - 취업 ( Default ) */}
                    {+careerForm.hope_direction !== 2 && (
                      <>
                        <dl>
                          <dt>진로방향</dt>
                          <dd>
                            {careerForm.hope_direction_han !== '결과없음'
                              ? careerForm.hope_direction_han
                              : ''}
                          </dd>
                        </dl>
                        <dl>
                          <dt>희망직업</dt>
                          <dd>{careerForm.hope_job_name}</dd>
                        </dl>
                        <dl>
                          <dt>희망산업체</dt>
                          <dd>{careerForm.hope_job_industry}</dd>
                        </dl>
                        <dl>
                          <dt>기업형태</dt>
                          <dd>
                            {careerForm.hope_job_gubun_han !== '결과없음'
                              ? careerForm.hope_job_gubun_han
                              : ''}
                          </dd>
                        </dl>
                        <dl>
                          <dt>진로희망 사유</dt>
                          <dd>{careerForm.hope_job_reason}</dd>
                        </dl>
                        <dl>
                          <dt>희망직업 관련 자격증</dt>
                          <dd>{careerForm.hope_job_certificate}</dd>
                        </dl>
                        <dl>
                          <dt>희망직업이 하는 일</dt>
                          <dd>{careerForm.hope_job_work}</dd>
                        </dl>
                        <dl>
                          <dt>필요기술 및 지식</dt>
                          <dd>{careerForm.hope_job_skill}</dd>
                        </dl>
                      </>
                    )}

                    {/* 진로방향 - 진학 */}
                    {+careerForm.hope_direction === 2 && (
                      <>
                        <dl>
                          <dt>진로방향</dt>
                          <dd>
                            {careerForm.hope_direction_han !== '결과없음'
                              ? careerForm.hope_direction_han
                              : ''}
                          </dd>
                        </dl>
                        <dl>
                          <dt>희망대학</dt>
                          <dd>{careerForm.hope_univ_name}</dd>
                        </dl>
                        <dl>
                          <dt>대학형태</dt>
                          <dd>
                            {careerForm.hope_univ_gubun_han !== '결과없음'
                              ? careerForm.hope_univ_gubun_han
                              : ''}
                          </dd>
                        </dl>
                        <dl>
                          <dt>지원형태</dt>
                          <dd>
                            {careerForm.hope_univ_apply_han !== '결과없음'
                              ? careerForm.hope_univ_apply_han
                              : ''}
                          </dd>
                        </dl>
                        <dl>
                          <dt>진로희망 사유</dt>
                          <dd>{careerForm.hope_univ_reason}</dd>
                        </dl>
                        <dl>
                          <dt>희망학과</dt>
                          <dd>{careerForm.hope_univ_major}</dd>
                        </dl>
                        <dl>
                          <dt>필요등급</dt>
                          <dd>{careerForm.hope_univ_grade}</dd>
                        </dl>
                      </>
                    )}
                  </div>
                </section>

                {/* 진로설계 (1학년) - 자격증 취득계획 */}
                <section>
                  <div className="dl-box">
                    <dl>
                      <dt className="sub-title">자격증 취득계획</dt>
                      <dd>
                        <ul>
                          {/* 전공 자격증 */}
                          {careerMajorCertList.length > 0 && (
                            <li>
                              <div>
                                <strong>
                                  {careerMajorCertList[0].certi_gubun_han}
                                </strong>
                                {careerMajorCertList.map(item => {
                                  return (
                                    <p key={`career-certi-${item.seq}`}>
                                      {item.certi_info}
                                    </p>
                                  );
                                })}
                              </div>
                            </li>
                          )}

                          {/* 정보화 자격증 */}
                          {careerItCertList.length > 0 && (
                            <li>
                              <div>
                                <strong>
                                  {careerItCertList[0].certi_gubun_han}
                                </strong>
                                {careerItCertList.map(item => {
                                  return (
                                    <p key={`career-certi-${item.seq}`}>
                                      {item.certi_info}
                                    </p>
                                  );
                                })}
                              </div>
                            </li>
                          )}

                          {/* 외국어 자격증 */}
                          {careerLangCertList.length > 0 && (
                            <li>
                              <div>
                                <strong>
                                  {careerLangCertList[0].certi_gubun_han}
                                </strong>
                                {careerLangCertList.map(item => {
                                  return (
                                    <p key={`career-certi-${item.seq}`}>
                                      {item.certi_info}
                                    </p>
                                  );
                                })}
                              </div>
                            </li>
                          )}
                        </ul>
                      </dd>
                    </dl>
                  </div>
                </section>

                {/* 진로설계 (1학년) - 창의적 체험활동 */}
                <section>
                  <div className="dl-box">
                    <dl>
                      <dt>창의적 체험활동</dt>
                      <dd>
                        <ul>
                          {/* 동아리 */}
                          {careerClubActList.length > 0 && (
                            <li>
                              <div>
                                <strong>
                                  {careerClubActList[0].act_gubun_han}
                                </strong>
                                {careerClubActList.map(item => {
                                  return (
                                    <p key={`career-act-${item.seq}`}>
                                      {item.act_info}{' '}
                                      {item.act_detail
                                        ? `[${item.act_detail}]`
                                        : ``}
                                    </p>
                                  );
                                })}
                              </div>
                            </li>
                          )}

                          {/* 봉사 */}
                          {careerBongActList.length > 0 && (
                            <li>
                              <div>
                                <strong>
                                  {careerBongActList[0].act_gubun_han}
                                </strong>
                                {careerBongActList.map(item => {
                                  return (
                                    <p key={`career-act-${item.seq}`}>
                                      {item.act_info}{' '}
                                      {item.act_detail
                                        ? `[${item.act_detail}시간]`
                                        : ``}
                                    </p>
                                  );
                                })}
                              </div>
                            </li>
                          )}

                          {/* 독서 */}
                          {careerReadActList.length > 0 && (
                            <li>
                              <div>
                                <strong>
                                  {careerReadActList[0].act_gubun_han}
                                </strong>
                                {careerReadActList.map(item => {
                                  return (
                                    <p key={`career-act-${item.seq}`}>
                                      {item.act_info}
                                    </p>
                                  );
                                })}
                              </div>
                            </li>
                          )}

                          {/* 기타 */}
                          {careerEtcActList.length > 0 && (
                            <li>
                              <div>
                                <strong>
                                  {careerEtcActList[0].act_gubun_han}
                                </strong>
                                {careerEtcActList.map(item => {
                                  return (
                                    <p key={`career-act-${item.seq}`}>
                                      {item.act_info}{' '}
                                      {item.act_detail
                                        ? `[${item.act_detail}]`
                                        : ``}
                                    </p>
                                  );
                                })}
                              </div>
                            </li>
                          )}
                        </ul>
                      </dd>
                    </dl>
                  </div>
                </section>
              </>
            )}

            {/* 포트폴리오 (2, 3학년) */}
            {+studentInfo.grade > 1 && (
              <>
                {/* 포트폴리오 (2, 3학년) - Form  */}
                <section>
                  <Row>
                    <Col sm={9}>
                      <h6>
                        {Utils.decrypt(studentInfo.student_name)}
                        <span>{portfolioForm.student_name_hanja}</span>
                      </h6>
                      <div className="dl-box mt-5">
                        <dl>
                          <dt>생년월일</dt>
                          <dd>
                            {portfolioForm.student_birth &&
                              Utils.convertBirth(
                                Utils.decrypt(portfolioForm.student_birth),
                              )}
                          </dd>
                          {/* S.student_birth, S.sex, S.phone_no, S.addr1, S.addr2 */}
                        </dl>
                        <dl>
                          <dt>성별</dt>
                          <dd>{portfolioForm.sex}</dd>
                        </dl>
                        <dl>
                          <dt>연락처</dt>
                          <dd>
                            {portfolioForm.phone_no &&
                              Utils.phoneNumberHypenFormat(
                                Utils.decrypt(portfolioForm.phone_no),
                              )}
                          </dd>
                        </dl>
                        <dl>
                          <dt>이메일</dt>
                          <dd>{Utils.decrypt(portfolioForm.e_mail)}</dd>
                        </dl>
                        <dl>
                          <dt>주소</dt>
                          <dd>
                            {Utils.decrypt(portfolioForm.addr1)}{' '}
                            {Utils.decrypt(portfolioForm.addr2)}
                          </dd>
                        </dl>
                      </div>
                    </Col>
                    <Col sm={3} className="flex-end align-items-start">
                      <Ratio aspectRatio="3x4">
                        {profileImageUrl ? (
                          <div
                            className="img-box"
                            style={{
                              backgroundImage: `url(${profileImageUrl})`,
                            }}
                          />
                        ) : (
                          <div className="no-data" />
                        )}
                      </Ratio>
                    </Col>
                  </Row>
                </section>

                {/* 포트폴리오 (2, 3학년) - 학력사항 */}
                <section>
                  <div className="dl-box">
                    <dl>
                      <dt>학력사항</dt>
                      <dd>
                        <ul>
                          {/* 고등학교 */}
                          {portfolioForm.high_school_name && (
                            <li>
                              <div>
                                <strong>
                                  {portfolioForm.high_school_name}
                                </strong>
                                <p>{`${portfolioForm.high_depart_name} ${portfolioForm.high_status_han}`}</p>
                              </div>
                              <small>{`${Utils.dateToString(
                                new Date(portfolioForm.high_start_date),
                              )} ~ ${Utils.dateToString(
                                new Date(portfolioForm.high_end_date),
                              )}`}</small>
                            </li>
                          )}
                          {/* 중학교 */}
                          {portfolioForm.mid_school_name && (
                            <li>
                              <div>
                                <strong>{portfolioForm.mid_school_name}</strong>
                                <p>{`${portfolioForm.mid_depart_name} ${portfolioForm.mid_status_han}`}</p>
                              </div>
                              <small>{`${Utils.dateToString(
                                new Date(portfolioForm.mid_start_date),
                              )} ~ ${Utils.dateToString(
                                new Date(portfolioForm.mid_end_date),
                              )}`}</small>
                            </li>
                          )}
                        </ul>
                      </dd>
                    </dl>
                  </div>
                </section>

                {/* 포트폴리오 (2, 3학년) - 자격증 */}
                <section>
                  <div className="dl-box">
                    <dl>
                      <dt>자격증</dt>
                      <dd>
                        <ul>
                          {portfolioCertList.length > 0 &&
                            portfolioCertList.map(item => (
                              <li key={`portfolio-cert-${item.seq}`}>
                                <div>
                                  <strong>{item.certificate_name}</strong>
                                  <p>{item.certificate_gov}</p>
                                </div>
                                <small>{item.certificate_date}</small>
                              </li>
                            ))}
                        </ul>
                      </dd>
                    </dl>
                  </div>
                </section>

                {/* 포트폴리오 (2, 3학년) - 수상경력 */}
                <section>
                  <div className="dl-box">
                    <dl>
                      <dt>수상경력</dt>
                      <dd>
                        <ul>
                          {portfolioAwardList.length > 0 &&
                            portfolioAwardList.map(item => (
                              <li key={`portfolio-career-${item.seq}`}>
                                <div>
                                  <strong>{item.award_name}</strong>
                                  <p>{item.award_gov}</p>
                                </div>
                                <small>
                                  {Utils.dateToString(
                                    new Date(item.award_date),
                                  )}
                                </small>
                              </li>
                            ))}
                        </ul>
                      </dd>
                    </dl>
                  </div>
                </section>

                {/* 포트폴리오 (2, 3학년) - 경력 및 활동사항 */}
                <section>
                  <div className="dl-box">
                    <dl>
                      <dt>경력 및 활동사항</dt>
                      <dd>
                        <ul>
                          {portfolioCareerList.length > 0 &&
                            portfolioCareerList.map(item => (
                              <li key={`portfolio-career-${item.seq}`}>
                                <div>
                                  <strong>{item.career_title}</strong>
                                  <p>{item.career_contents}</p>
                                </div>
                                <small>
                                  {`${Utils.dateToString(
                                    new Date(item.career_start_date),
                                  )} ~ ${Utils.dateToString(
                                    new Date(item.career_end_date),
                                  )}`}
                                </small>
                              </li>
                            ))}
                        </ul>
                      </dd>
                    </dl>
                  </div>
                </section>

                {/* 포트폴리오 (2, 3학년) - 자기소개서 */}
                <section>
                  <div className="dl-box">
                    <dl>
                      <dt>자기소개서</dt>
                      <dd>
                        <ul className="li-has-line">
                          {portfolioForm.my_growth_proceed && (
                            <li>
                              <strong>성장과정</strong>
                              <p>{portfolioForm.my_growth_proceed}</p>
                            </li>
                          )}
                          {portfolioForm.my_sinjo && (
                            <li>
                              <strong>성격 및 생활신조</strong>
                              <p>{portfolioForm.my_sinjo}</p>
                            </li>
                          )}
                          {portfolioForm.my_career && (
                            <li>
                              <strong>학교생활 및 경력사항</strong>
                              <p>{portfolioForm.my_career}</p>
                            </li>
                          )}
                          {portfolioForm.my_jiwon && (
                            <li>
                              <strong>지원동기 및 입사 후 포부</strong>
                              <p>{portfolioForm.my_jiwon}</p>
                            </li>
                          )}
                        </ul>
                      </dd>
                    </dl>
                  </div>
                </section>
              </>
            )}

            {/* 산업체, 취업마인드, 인성자존감 */}
            <section>
              {/* 산업체 - 헤더 */}
              <div className="sub-tilte">
                <h5>{Utils.convertIndustryProgramTitle(+studentInfo.grade)}</h5>
              </div>

              {/* 산업체 - 리스트 */}
              {industryList.length > 0 ? (
                industryList.map(item => {
                  return (
                    <div
                      key={`industry-${item.seq}`}
                      className="dl-box bt-bold"
                    >
                      <dl>
                        <dt>방문업체</dt>
                        <dd>{item.visit_company}</dd>
                      </dl>
                      <dl>
                        <dt>방문일</dt>
                        <dd>{item.visit_date}</dd>
                      </dl>
                      <dl>
                        <dt>업체 주소</dt>
                        <dd>{item.visit_address}</dd>
                      </dl>
                      {/* 지원주제 (3학년) */}
                      {+studentInfo.grade === 3 && (
                        <dl>
                          <dt>지원주제</dt>
                          <dd>{item.support_group_gubun_han}</dd>
                        </dl>
                      )}
                      {/* 종료사유 (3학년) */}
                      {+studentInfo.grade === 3 && (
                        <dl>
                          <dt>종료사유</dt>
                          <dd>{item.termination_reason_gubun_han}</dd>
                        </dl>
                      )}
                      {/* 참여활동 내용 (1, 2학년) */}
                      {!isSummary && (
                        <dl className="bt">
                          <dt>참여활동 내용</dt>
                          <dd>{item.visit_contents}</dd>
                        </dl>
                      )}
                      {/* 참여 후 느낀점 (1, 2학년) */}
                      {!isSummary && (
                        <dl className="bt">
                          <dt>참여 후 느낀점</dt>
                          <dd>{item.visit_review}</dd>
                        </dl>
                      )}
                    </div>
                  );
                })
              ) : (
                <div className="dl-box bt-bold">
                  등록된 프로그램이 없습니다.
                </div>
              )}

              {/* 취업마인드 함양 프로그램 - 헤더 */}
              <div className="sub-tilte mt-5">
                <h5>{Utils.convertWorkProgramTitle(+studentInfo.grade)}</h5>
              </div>

              {/* 취업마인드 함양 프로그램 - 리스트 */}
              {workList.length > 0 ? (
                workList.map(item => {
                  return (
                    <div key={`work-${item.seq}`} className="dl-box bt-bold">
                      <dl>
                        <dt>교육일</dt>
                        <dd>{item.program_date}</dd>
                      </dl>
                      <dl>
                        <dt>이수시간</dt>
                        <dd>{item.educated_time}시간</dd>
                      </dl>
                      <dl>
                        <dt>프로그램 주제</dt>
                        <dd>{item.program_title}</dd>
                      </dl>
                      {!isSummary && (
                        <dl className="bt">
                          <dt>프로그램 내용</dt>
                          <dd>{item.program_contents}</dd>
                        </dl>
                      )}
                      {!isSummary && (
                        <dl className="bt">
                          <dt>참여 후 느낀점</dt>
                          <dd>{item.program_review}</dd>
                        </dl>
                      )}
                    </div>
                  );
                })
              ) : (
                <div className="dl-box bt-bold">
                  등록된 프로그램이 없습니다.
                </div>
              )}

              {/* 인성자존감 - 헤더 */}
              <div className="sub-tilte mt-5">
                <h5>인성자존감 활동</h5>
              </div>

              {/* 인성자존감 - 리스트 */}
              {insungList.length > 0 ? (
                insungList.map(item => {
                  return (
                    <div key={`insung-${item.seq}`} className="dl-box bt-bold">
                      <dl>
                        <dt>활동일</dt>
                        <dd>{item.act_date}</dd>
                      </dl>
                      <dl>
                        <dt>활동시간</dt>
                        <dd>{item.act_time}시간</dd>
                      </dl>
                      <dl>
                        <dt>활동내용</dt>
                        <dd>{item.act_title}</dd>
                      </dl>
                    </div>
                  );
                })
              ) : (
                <div className="dl-box bt-bold">등록된 활동이 없습니다.</div>
              )}

              {/* NCS - 헤더 */}
              <div className="sub-tilte mt-5">
                <h5>NCS</h5>
              </div>

              {/* NCS - 리스트 */}
              {ncsList.length > 0 ? (
                ncsList.map(item => {
                  return (
                    <div key={`ncs-${item.seq}`} className="dl-box bt-bold">
                      {/* 추가일 */}
                      <dl>
                        <dt>추가일</dt>
                        <dd>
                          <Moment date={item.reg_date} format="YYYY.MM.DD" />
                        </dd>
                      </dl>
                      {/* 분류번호 */}
                      <dl>
                        <dt>분류번호</dt>
                        <dd>{item.learn_module_seq}</dd>
                      </dl>
                      {/* NCS 분류 (대분류 > 중분류 > 소분류 > 세분류) */}
                      <dl>
                        <dt>NCS분류</dt>
                        <dd>{`${item.l_code_name} > ${item.m_code_name} > ${item.s_code_name} > ${item.sub_code_name}`}</dd>
                      </dl>
                      {/* 능력단위 */}
                      <dl>
                        <dt>분류번호</dt>
                        <dd>{item.learn_module_name}</dd>
                      </dl>
                    </div>
                  );
                })
              ) : (
                <div className="dl-box bt-bold">
                  등록된 NCS 정보가 없습니다.
                </div>
              )}
            </section>
          </Card>

          {/* 하단 버튼 */}
          {/* <div className="btn-area">
            <Button size="lg" variant="outline-primary" onClick={handlePrint}>
              인쇄
            </Button>
          </div> */}
        </article>
      </Container>
    </main>
  );
});
