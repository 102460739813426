import Axios from '@api/axios';
// import { da } from 'date-fns/locale';

const API_TEACHER = 'api/v1/teacher';

// [시스템 관리 > 기업관리] 조회 - 단일
export const getMatchingCompany = seq => {
  return Axios.get(`${API_TEACHER}/matching-company/${seq}`);
};

// [시스템 관리 > 기업관리] 조회 - 리스트
export const getMatchingCompanyList = param => {
  return Axios.get(`${API_TEACHER}/matching-company-list`, {
    params: param,
  });
};
