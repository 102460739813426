/* eslint-disable react/no-array-index-key */
import React, { useState, useRef, useEffect } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import {
  Container,
  Button,
  Card,
  Form,
  Image,
  Dropdown,
  Row,
  Col,
  ToggleButtonGroup,
  ToggleButton,
} from 'react-bootstrap';
import { CustomSelect } from '@components';
import { images } from '@assets';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Swal from 'sweetalert2';

//* Utils
// import Utils from '@commㅋon/Utils';

//* Consts
import {
  CERT_GUBUN,
  LOGIN_TYPE,
  ROLE,
  CERT_EVALUATE_TYPE,
  CERT_PROCESS_TYPE,
  CERT_PROVE_TYPE,
} from '@common/consts';

//* API
import {
  selectCertificateInfoList,
  insertUpdateCertificate,
} from '@api/student/majorJobCert';

// 자격증명 목록
const initialCertListMap = {
  PROCESS: [],
  PROVE: [],
};

//* [ Main ]
export default React.memo(function MajorJobCertRegister(props) {
  //* #################################################################
  //* [ States ]
  //* #################################################################
  const history = useHistory();
  const { loginType, studentIdx } = useParams();
  const authType = props.userInfo.type;

  if (loginType !== LOGIN_TYPE.STUDENT && loginType !== LOGIN_TYPE.TEACHER) {
    Swal.fire({
      text: '잘못된 접근입니다.',
      confirmButtonText: '확인',
    });
    history.goBack();
  }

  if (loginType === LOGIN_TYPE.TEACHER && !window.parseInt(studentIdx)) {
    Swal.fire({
      text: '잘못된 접근입니다.',
      confirmButtonText: '확인',
    });
    history.goBack();
  }

  // 결과 보고서
  // const [certForm, setCertForm] = useState(initialCertForm);

  // 신규등록 변수
  const [certDate, setCertDate] = useState(new Date()); // 취득일
  const [certGubun, setCertGubun] = useState(null); // 자격증 구분
  const [certAssessType, setCertAssessType] = useState(''); // 평가유형
  const [certType, setCertType] = useState(null); // 자격증 종류
  const [certCode, setCertCode] = useState(''); // 자격증 코드
  const [certNumber, setCertNumber] = useState(''); // 자격증 번호
  const [certGov, setCertGov] = useState(''); // 발급 기관
  const [certField, setCertField] = useState(''); // 필드명
  const [certNm, setCertNm] = useState(''); // 자격증명

  const [files, setFiles] = useState([]); // 증빙자료 (복수)
  const fileRef = useRef(''); // 증빙자료

  // 중복요청 방지
  // const [isSentRequest, setIsSentRequest] = useState(false);

  // 자격증 유형 드랍다운
  const [certTypeList, setCertTypeList] = useState(CERT_PROCESS_TYPE);

  // 자격증 리스트
  const [allCertData, setAllCertData] = useState(initialCertListMap); // 원천 데이터
  const [certFieldList, setCertFieldList] = useState([]); // 필드명 리스트 > 드랍다운
  const [certNameList, setCertNameList] = useState([]); // 자격증명 리스트 > 드랍다운

  //* #################################################################
  //* [ Utils ]
  //* #################################################################
  // ------------------------------------------------------------
  // ------------------------------------------------------------
  // 평가유형 선택 (PROCESS, PROVE)
  // ------------------------------------------------------------
  const handleCertAssessType = type => {
    // 기존값 초기화 (구분, 종류, 필드, 이름, 발급기관)
    setCertGubun(null);
    setCertType('');
    setCertFieldList([]);
    setCertNameList([]);
    setCertGov('');
    setCertCode('');
    setCertField('');
    setCertNm('');

    // 자격증 유형 드랍다운
    if (type.value === 'PROCESS') {
      setCertTypeList(CERT_PROCESS_TYPE);
    } else if (type.value === 'PROVE') {
      setCertTypeList(CERT_PROVE_TYPE);
    }

    // 타입 갱신
    setCertAssessType(type);
  };

  // ------------------------------------------------------------
  // 종류 선택 (PUBLIC, PRIVATE, ETC)
  // ------------------------------------------------------------
  const handleCertType = type => {
    // 기존값 초기화 (구분, 이름, 발급기관)
    setCertGubun(null);
    setCertNameList([]);
    setCertGov('');
    setCertCode('');
    setCertField('');
    setCertNm('');

    // 자격명 필터
    const tempCertList = allCertData[certAssessType.value].filter(
      item =>
        item.cert_gubun === certAssessType.value &&
        item.cert_type === type.value,
    );

    // 자격명 리스트
    const tempFieldList = tempCertList.map(item => {
      return { value: item.field_name, label: item.field_name };
    });

    setCertFieldList(removeDupValue(tempFieldList));

    // 타입 갱신
    setCertType(type);
  };

  // ------------------------------------------------------------
  // 구분 선택
  // ------------------------------------------------------------
  const handleCertGubun = gubun => {
    setCertGubun(gubun);
  };

  // ------------------------------------------------------------
  // 자격명 > 필드명 선택
  // ------------------------------------------------------------
  const handleCertField = field => {
    // 기존값 초기화 (이름, 발급기관)
    setCertGov('');
    setCertCode('');
    setCertNm('');

    // 선택 필드 재정렬 (0번 인덱스)
    const copiedFieldList = [...certFieldList];
    const fieldIndex = copiedFieldList.findIndex(item => item.value === field);
    const fieldValue = { ...copiedFieldList[fieldIndex] };

    copiedFieldList.splice(fieldIndex, 1);
    copiedFieldList.unshift(fieldValue);

    setCertFieldList(copiedFieldList);

    // 자격명 필터
    const tempCertList = allCertData[certAssessType.value].filter(
      item =>
        item.cert_gubun === certAssessType.value &&
        item.cert_type === certType.value &&
        item.field_name === field,
    );

    // 자격명 리스트
    const tempCertNameList = tempCertList.map(item => {
      return {
        value: item.cert_name,
        label: item.cert_name,
        data: { ...item },
      };
    });

    setCertNameList(removeDupValue(tempCertNameList));

    // 필드명 갱신
    setCertField(field);
  };

  // ------------------------------------------------------------
  // 자격명 > 자격증 선택
  // ------------------------------------------------------------
  const handleCertName = name => {
    // 선택 이름 재정렬 (0번 인덱스)
    const copiedNameList = [...certNameList];
    const nameIndex = copiedNameList.findIndex(item => item.value === name);
    const nameValue = { ...copiedNameList[nameIndex] };

    copiedNameList.splice(nameIndex, 1);
    copiedNameList.unshift(nameValue);

    setCertNameList(copiedNameList);

    // 발급기관 & 자격증 코드 갱신
    const tempCertList = allCertData[certAssessType.value].filter(
      item =>
        item.cert_gubun === certAssessType.value &&
        item.cert_type === certType.value &&
        item.field_name === certField &&
        item.cert_name === name,
    );

    // 입력값 갱신
    setCertNm(name); // 자격증명
    setCertGov(tempCertList[0].mgmt_gov); // 발급기관
    setCertCode(tempCertList[0].cert_code); // 자격증코드
  };

  // ------------------------------------------------------------
  // 파일 첨부
  // ------------------------------------------------------------
  const addFile = e => {
    if (files.length >= 2) {
      Swal.fire({
        text: '첨부 파일은 2개까지 등록할 수 있습니다.',
        confirmButtonText: '확인',
      });
      return;
    }

    if (e.target.files[0]) setFiles([...files, e.target.files[0]]);
    fileRef.current.value = null;
  };

  // ------------------------------------------------------------
  // 첨부 파일 제거
  // ------------------------------------------------------------
  const removeFile = idx => {
    const filteredList = files.filter((file, index) => index !== idx);
    setFiles([...filteredList]);
  };

  // ------------------------------------------------------------
  // 중복제거
  // ------------------------------------------------------------
  const removeDupValue = list => {
    const map = new Map();
    // eslint-disable-next-line no-restricted-syntax
    for (const item of list) {
      map.set(JSON.stringify(item), item);
    }

    return [...map.values()];
  };

  //* #################################################################
  //* [ API ] 전공 자격증 리스트 - SELECT
  //* #################################################################
  const getCertificateInfoList = async () => {
    // Axios
    try {
      const { data } = await selectCertificateInfoList();

      if (data.code === 0) {
        setAllCertData({
          PROCESS: data.data.process,
          PROVE: data.data.prove,
        });
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 전공 자격증 신규등록 - INSERT
  //* #################################################################
  const insertForm = async (event, reqStatus) => {
    // 중복요청 확인
    // if (isSentRequest) {
    //   return;
    // }
    const target = event.currentTarget;
    target.disabled = true;

    // 필수값 체크 (1) - 구분, 평가유형, 종류
    if (!certGubun || !certAssessType || !certType) {
      Swal.fire({
        text: '자격증 구분 및 유형을 확인해주세요.',
        confirmButtonText: '확인',
      });
      target.disabled = false;
      return;
    }

    // 필수값 체크 (2) - 자격증번호
    // if (!certNumber) {
    //   Swal.fire({
    //     text: '자격증 번호를 확인해주세요.',
    //     confirmButtonText: '확인',
    //   });
    //   return;
    // }

    // 필수값 체크 (3) - 필드, 이름, 발급기관
    if (!certNm || !certField || !certGov) {
      Swal.fire({
        text: '자격명 확인해주세요.',
        confirmButtonText: '확인',
      });
      target.disabled = false;
      return;
    }

    // 필수값 체크 (4) - 첨부파일
    // if (files.length < 1) {
    //   Swal.fire({
    //     text: '첨부파일을 확인해주세요.',
    //     confirmButtonText: '확인',
    //   });
    //   return;
    // }

    if (reqStatus === 'ING') {
      // 파라미터 설정
      const param = {
        status: reqStatus,
        certificate_date: certDate,
        certificate_gubun: certGubun.value,
        certificate_type: certType.value,
        certificate_assess_type: certAssessType.value,
        certificate_name: certNm,
        certificate_number: certNumber,
        certificate_gov: certGov,
        certificate_code: certCode,
      };

      // FormData
      const formData = new FormData();

      // File append
      for (let i = 0; i < files.length; i += 1) {
        formData.append('files', files[i]);
      }

      // JSON append
      formData.append(
        'param',
        new Blob([JSON.stringify(param)], { type: 'application/json' }),
      );

      // 중복요청 방지
      // setIsSentRequest(true);

      // Axios
      try {
        const { data } = await insertUpdateCertificate(formData);

        if (data.code === 0) {
          // 리스트 페이지 이동
          history.push('/student/majorJobCert');
        }
      } catch (e) {
        // 중복요청 방지 해제
        // setIsSentRequest(false);
        // alert(e.response.data.message);
        target.disabled = false;
      }
    } else {
      Swal.fire({
        text: '입력을 확인해주세요.',
        confirmButtonText: '확인',
      });
      target.disabled = false;
    }
    target.disabled = false;
  };

  //* #################################################################
  //* [ useEffect ]
  //* #################################################################
  useEffect(() => {
    if (loginType === LOGIN_TYPE.STUDENT) {
      getCertificateInfoList();
    }
    if (loginType === LOGIN_TYPE.TEACHER) {
      // selectCertificateSummary();
    }
  }, [loginType]);

  const [linkList, setLinkList] = useState([]);

  useEffect(() => {
    switch (authType) {
      case ROLE.ADMIN:
        setLinkList([
          {
            name: '프로그램 현황',
            path: '/admEdu/student/program',
          },
        ]);
        break;
      case ROLE.TEACHER:
        setLinkList([
          {
            name: '학생 관리',
            path: '/teacher/studentMgmt/studentMgmt',
          },
        ]);
        break;
      case ROLE.STUDENT:
        setLinkList([
          {
            name: 'HOME',
            path: '/',
          },
        ]);
        break;
      default:
        break;
    }
  }, [authType]);

  //* #################################################################
  //* [ return ]
  //* #################################################################
  return (
    <main id="student-majorJob-register">
      <Container>
        <article className="content">
          <ul className="path">
            {linkList &&
              linkList.length > 0 &&
              linkList.map((v, i) => {
                return (
                  <li key={`link_${i}`}>
                    <Link to={v.path}>{v.name}</Link>
                  </li>
                );
              })}
            <li>
              <Link to="#none">전공 자격증 취득</Link>
            </li>
            <li>
              <Link to="#none">취득자격증 추가</Link>
            </li>
          </ul>

          {/* ////////// ↓ 상단 타이틀영역 ////////// */}
          <section>
            <div className="title">
              <h5>취득 자격증 추가</h5>
            </div>
          </section>

          {/* ////////// ↓ 컨텐츠 ////////// */}
          <section className="mb-5">
            <Card>
              <ul className="form-list">
                {/* 취득일 */}
                <li>
                  <h5>취득일</h5>
                  <div className="mw-input">
                    <DatePicker
                      className="input-datepicker"
                      name="acquisition-date"
                      dateFormat="yyyy.MM.dd"
                      selected={certDate}
                      onChange={date => setCertDate(date)}
                      // maxDate={new Date()}
                      customInput={<Form.Control style={{ width: '100%' }} />}
                      showPopperArrow={false}
                    />
                  </div>
                </li>

                {/* 평가유형 */}
                <li>
                  <h5>평가유형</h5>
                  <CustomSelect
                    options={CERT_EVALUATE_TYPE}
                    value={certAssessType}
                    onChange={handleCertAssessType}
                    placeholder="자격증 평가유형을 선택하세요."
                    className="w-100"
                  />
                </li>

                {/* 종류 */}
                <li>
                  <h5>종류</h5>
                  <CustomSelect
                    options={certTypeList}
                    value={certType}
                    onChange={handleCertType}
                    placeholder="자격증 타입을 선택하세요."
                    className="w-100"
                    isDisabled={!certAssessType}
                  />
                </li>

                {/* 구분 */}
                <li>
                  <h5>구분</h5>
                  <CustomSelect
                    options={CERT_GUBUN}
                    value={certGubun}
                    onChange={handleCertGubun}
                    placeholder="자격증 구분값을 선택하세요."
                    className="w-100"
                    isDisabled={!certType}
                  />
                </li>

                {/* 자격명 */}
                <li>
                  <h5>자격명</h5>
                  <Dropdown className="w-100">
                    {/* 드랍다운 - Trigger */}
                    <Dropdown.Toggle
                      className="form-select"
                      disabled={!certType}
                    >
                      {certField ? `${certField} > ${certNm}` : '선택하세요.'}
                    </Dropdown.Toggle>

                    {/* 드랍다운 - Body */}
                    <Dropdown.Menu>
                      <Row className="w-100">
                        {/* 선택 1 - 필드 선택 목록 */}
                        <Col>
                          <ToggleButtonGroup
                            type="radio"
                            name="certField"
                            onChange={handleCertField}
                          >
                            {certFieldList.map((item, idx) => {
                              return (
                                <ToggleButton
                                  id={`certField-${idx}`}
                                  key={`certField-${idx}`}
                                  value={item.value}
                                  variant="light"
                                  checked={item.value === certField}
                                >
                                  {item.label}
                                </ToggleButton>
                              );
                            })}
                          </ToggleButtonGroup>
                        </Col>

                        {/* 선택 2 - 코드명 선택 목록 */}
                        <Col>
                          <ToggleButtonGroup
                            type="radio"
                            name="certNm"
                            onChange={handleCertName}
                          >
                            {certNameList.map((item, idx) => {
                              return (
                                <ToggleButton
                                  id={`certNm-${idx}`}
                                  key={`certNm-${idx}`}
                                  value={item.value}
                                  variant="light"
                                  checked={item.value === certNm}
                                >
                                  {item.label}
                                </ToggleButton>
                              );
                            })}
                          </ToggleButtonGroup>
                        </Col>
                      </Row>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>

                {/* 자격증 번호 */}
                <li>
                  <h5>자격증 번호</h5>
                  <Form.Control
                    type="text"
                    placeholder="자격증 번호를 입력하세요."
                    onChange={e =>
                      setCertNumber(
                        e.target.value
                          .replace(/[^a-zA-Z0-9-]/g, '')
                          .toUpperCase(),
                      )
                    }
                    value={certNumber}
                    maxLength={30}
                  />
                </li>

                {/* 발급기관 */}
                <li>
                  <h5>발급기관</h5>
                  <Form.Control
                    type="text"
                    placeholder="발급기관을 입력하세요."
                    value={certGov}
                    // onChange={e => setCertGov(e.target.value)}
                    maxLength={20}
                    disabled
                  />
                </li>

                {/* 증빙자료 - 파일첨부 */}
                <li className="align-items-start">
                  <h5 style={{ paddingTop: '0.75rem' }}>증빙자료</h5>
                  <div className="w-100">
                    {/* ↓ 첨부된 파일 */}
                    <ul className="drag-attachment-list sm mb-3">
                      {files.map((file, idx) => {
                        return (
                          <li key={idx}>
                            <p className="text-primary">{file.name}</p>
                            <Button variant="iconOnly">
                              <Image
                                src={images.icCancel}
                                onClick={() => removeFile(idx)}
                              />
                            </Button>
                          </li>
                        );
                      })}
                    </ul>
                    {/* ↓ 파일 첨부 */}
                    <Button
                      onClick={() => fileRef.current.click()}
                      variant="outline-primary"
                      className="d-block"
                    >
                      파일첨부
                    </Button>
                    <Form.Control
                      ref={fileRef}
                      type="file"
                      // accept="image/*, .pdf"
                      style={{ display: 'none' }}
                      onChange={addFile}
                    />
                  </div>
                </li>
              </ul>
            </Card>

            {/* 저장 */}
            <div className="btn-area">
              <Button
                variant="outline-primary"
                onClick={e => insertForm(e, 'ING')}
                size="lg"
              >
                저장
              </Button>
            </div>
          </section>
        </article>
      </Container>
    </main>
  );
});
