/*
    유저 타입 ( STUDENT | TEACHER | ADMIN | ADMIN_SYSTEM )
*/
export const STUDENT = 'STUDENT'; // 학생
export const TEACHER = 'TEACHER'; // 교사
export const ADMIN = 'ADMIN'; // 교육청 관리자
export const ADMIN_SYSTEM = 'ADMIN_SYSTEM'; // 시스템관리자

export const FormStatus = {
  REQ: { text: '승인대기', class: 'text-blue', value: 'REQ' },
  CON: { text: '승인', class: 'text-success', value: 'CON' },
  ING: { text: '임시저장', class: 'text-dark', value: 'ING' },
  REJ: { text: '보완', class: 'text-red', value: 'REJ' },
  NONE: { text: '-', class: 'text-dark', value: '' },
  DEL: { text: '삭제', class: 'none', value: 'DEL' },
};

// 진로설계 상수
export const CERTIFICATE = 'certificate';
export const ACTIVITY = 'activity';

export const PROGRAM_ID = {
  PROGRAM_ID_CAREER: '1-1', // 진로설계
  PROGRAM_ID_PORTFOLIO: '1-2', // 직무기초 포트폴리오
  PROGRAM_ID_FI_INTERVIEW: '1-3', // 산업체 채용면접
  PROGRAM_ID_FI_VISIT: '2-1', // 산업체 현장견학
  PROGRAM_ID_FI_EXPERIENCE: '2-2', // 산업체 현장체험
  PROGRAM_ID_FI_TRAINING: '2-3', // 산업체 현장실습
  PROGRAM_ID_CERTIFICATE: '3-1', // 전공자격증 취득
  PROGRAM_ID_FME_1: '4-1', // 전공기초 교육
  PROGRAM_ID_FME_2: '4-2', // 전공심화 교육
  PROGRAM_ID_FME_3: '4-3', // 전공응용 교육
  PROGRAM_ID_INSUNG: '5-1', // 인성자존감 활동
  PROGRAM_ID_FO_TEST: '5-2', // 직업기초 능력평가
  PROGRAM_ID_FW_1: '6-1', // 취업마인트 함양
  PROGRAM_ID_FW_2: '6-2', // 취업마인트 강화
  PROGRAM_ID_FW_3: '6-3', // 취업역량 강화
  PROGRAM_ID_FF: '7-1', // 자율교육 프로그램
};

export const RANK_MENU = {
  KOR: [
    { label: '1등급', value: '1' },
    { label: '2등급', value: '2' },
    { label: '3등급', value: '3' },
    { label: '4등급', value: '4' },
    { label: '5등급', value: '5' },
  ],
  ENG: [
    { label: '1등급', value: '1' },
    { label: '2등급', value: '2' },
    { label: '3등급', value: '3' },
    { label: '4등급', value: '4' },
    { label: '5등급', value: '5' },
  ],
  MATH: [
    { label: '1등급', value: '1' },
    { label: '2등급', value: '2' },
    { label: '3등급', value: '3' },
    { label: '4등급', value: '4' },
    { label: '5등급', value: '5' },
  ],
  SOL: [
    { label: '1등급', value: '1' },
    { label: '2등급', value: '2' },
    { label: '3등급', value: '3' },
    { label: '4등급', value: '4' },
    { label: '5등급', value: '5' },
  ],
};

export const RESEARCH_POINT_TYPE_LIST = [
  {
    value: '5',
  },
  {
    value: '4',
  },
  {
    value: '3',
  },
  {
    value: '2',
  },
  {
    value: '1',
  },
];

export const CERT_GUBUN = [
  { label: '필기', value: '1' },
  { label: '실기', value: '2' },
  { label: '필기 + 실기', value: '3' },
  { label: '기타', value: '4' },
];
export const CERT_EVALUATE_TYPE = [
  { label: '과정평가형', value: 'PROCESS' },
  { label: '검정평가형', value: 'PROVE' },
];

export const CERT_TYPE = [
  { label: '국가기술 자격증', value: 'T' },
  { label: '국가전문 자격증', value: 'S' },
  { label: '민간공인 자격증', value: 'PRIVATE' },
  { label: '기타(한국사)', value: 'ETC' },
];

// 전공자격증 > 과정평가형 > 자격증 유형
export const CERT_PROCESS_TYPE = [
  { label: '국가공인 자격증', value: 'PUBLIC' },
];

// 전공자격증 > 검정평가형 > 자격증 유형
export const CERT_PROVE_TYPE = [
  { label: '국가기술 자격증', value: 'T' },
  { label: '국가전문 자격증', value: 'S' },
  { label: '민간공인 자격증', value: 'PRIVATE' },
  { label: '기타(한국사)', value: 'ETC' },
];

export const FREE_PROGRAM_DROPDOWN = [
  { label: 'NONE', value: '1' },
  { label: 'NONE', value: '2' },
  { label: 'NONE', value: '3' },
  { label: 'NONE', value: '4' },
  { label: 'NONE', value: '5' },
  { label: 'NONE', value: '6' },
];

export const FILE_FORM_TYPE = {
  // Form 첨부파일
  FILE_CERTIFICATE: 'CERTIFICATE',
  FILE_CAREER: 'CAREER',
  FILE_PORTFOLIO: 'PORTFOLIO',
  FILE_INDUSTRY: 'INDUSTRY',
  FILE_INSUNG: 'INSUNG',
  FILE_ABILITY_TEST: 'ABILITY_TEST',
  FILE_FREE_PROGRAM: 'FREE_PROGRAM',
  FILE_WORK: 'WORK',
  FILE_MAJOR_EDUCATION: 'MAJOR_EDUCATION',

  // 학생등록 Excel 템플릿
  FILE_STATIC_TEMP_STUDENT_EXCEL: 'TEMP_STUDENT_EXCEL',

  // 승인이수
  FILE_STATIC_FORM_GUIDE_CAREER: 'GUIDE_CAREER',
  FILE_STATIC_FORM_GUIDE_PORTFOLIO: 'GUIDE_PORTFOLIO',
  FILE_STATIC_FORM_GUIDE_INDV_VISIT: 'GUIDE_INDV_VISIT',
  FILE_STATIC_FORM_GUIDE_INDV_EXPERIENCE: 'GUIDE_INDV_EXPERIENCE',
  FILE_STATIC_FORM_GUIDE_INDV_TRAINING: 'GUIDE_INDV_TRAINING',
  FILE_STATIC_FORM_GUIDE_INDV_INTERVIEW: 'GUIDE_INDV_INTERVIEW',
  FILE_STATIC_FORM_GUIDE_CERTIFICATE_1: 'GUIDE_CERTIFICATE_1',
  FILE_STATIC_FORM_GUIDE_CERTIFICATE_2: 'GUIDE_CERTIFICATE_2',
  FILE_STATIC_FORM_GUIDE_CERTIFICATE_3: 'GUIDE_CERTIFICATE_3',
  FILE_STATIC_FORM_GUIDE_MAJOR_EDU_1: 'GUIDE_MAJOR_EDU_1',
  FILE_STATIC_FORM_GUIDE_MAJOR_EDU_2: 'GUIDE_MAJOR_EDU_2',
  FILE_STATIC_FORM_GUIDE_MAJOR_EDU_3: 'GUIDE_MAJOR_EDU_3',
  FILE_STATIC_FORM_GUIDE_WORK_1: 'GUIDE_WORK_1',
  FILE_STATIC_FORM_GUIDE_WORK_2: 'GUIDE_WORK_2',
  FILE_STATIC_FORM_GUIDE_WORK_3: 'GUIDE_WORK_3',
  FILE_STATIC_FORM_GUIDE_INSUNG: 'GUIDE_INSUNG',
  FILE_STATIC_FORM_GUIDE_ABILITY_TEST: 'GUIDE_ABILITY_TEST',
  FILE_STATIC_FORM_GUIDE_FREE_1: 'GUIDE_FREE_1',
  FILE_STATIC_FORM_GUIDE_FREE_2: 'GUIDE_FREE_2',
  FILE_STATIC_FORM_GUIDE_FREE_3: 'GUIDE_FREE_3',
};

// 진로설계 > 취업 유형
export const JOB_TYPE_LIST = [
  {
    label: '대기업',
    value: 1,
  },
  {
    label: '공기업',
    value: 2,
  },
  {
    label: '공무원',
    value: 3,
  },
  {
    label: '중소기업',
    value: 4,
  },
  {
    label: '해외취업',
    value: 5,
  },
  {
    label: '기타',
    value: 9,
  },
];

// 진로설계 > 대학 유형
export const UNIV_TYPE_LIST = [
  {
    label: '일반대학',
    value: 1,
  },
  {
    label: '전문대학',
    value: 2,
  },
];

// 진로설계 > 진학 유형
export const APPLY_TYPE_LIST = [
  {
    label: '수시전형',
    value: 1,
  },
  {
    label: '정시전형',
    value: 2,
  },
];

// 진로설계 > 학기 Default
export const SEMESTER_DROPDOWN = [
  { label: '3-2 학기', value: '3-2' },
  { label: '3-1 학기', value: '3-1' },
  { label: '2-2 학기', value: '2-2' },
  { label: '2-1 학기', value: '2-1' },
  { label: '1-2 학기', value: '1-2' },
  { label: '1-1 학기', value: '1-1' },
];

export const ROLE = {
  STUDENT: 'STUDENT',
  TEACHER: 'TEACHER',
  ADMIN: 'ADMIN',
  ADMIN_SYSTEM: 'ADMIN_SYSTEM',
};

export const TEACHER_ROLE_DETAIL = {
  SCHOOL_HEAD: 'SCHOOL_HEAD',
  GRADE_HEAD: 'GRADE_HEAD',
  CLASS: 'CLASS',
  SUB_CLASS: 'SUB_CLASS',
  AFTER_SCHOOL: 'AFTER_SCHOOL',
  PROGRAM: 'PROGRAM',
  GRADE_1: 'GRADE_1',
  GRADE_2: 'GRADE_2',
  GRADE_3: 'GRADE_3',
};

// 학생관리 > 학생임시현황 > 상태 타입
export const STATUS_TYPE_LIST = [
  { label: '모두', value: 'ALL' },
  { label: '정상', value: 'NORMAL' },
  { label: '오류', value: 'ERROR' },
  { label: '경고', value: 'WARNING' },
];

// 교사 직책
export const TEACHER_ROLE_DETAIL_HAN = {
  SCHOOL_HEAD: '학교 관리자',
  GRADE_HEAD: '학년부장',
  CLASS: '참여교사',
  SUB_CLASS: '부참여교사',
  AFTER_SCHOOL: '방과후교사',
  PROGRAM: '업무담당자',
};

// 차수 드랍다운 리스트
export const NTH_LIST = [{ label: '0차', value: '0' }];

// 학생관리 > 학생현황 > 상세 > 수정 :: 성별 radio
export const GENDER_TYPE_LISTS = [
  {
    label: '남',
    value: '남',
  },
  {
    label: '여',
    value: '여',
  },
];

// radio
export const APPROVE_STATE_TYPE_LIST = [
  {
    label: '승인',
    value: 'CON',
  },
  {
    label: '보완',
    value: 'REJ',
  },
];

export const LOGIN_TYPE = {
  TEACHER: 'teacher',
  STUDENT: 'student',
};

// 만족도 조사 구분
export const RESEARCH_GUBUN = {
  PROGRAM: 'PROGRAM',
  INDUSTRY: 'INDUSTRY',
};

// 산업체 > 이수시간
export const COMPLETE_TIME = [
  { label: '4시간', value: 4 },
  { label: '5시간', value: 5 },
  { label: '6시간', value: 6 },
  { label: '7시간', value: 7 },
  { label: '8시간', value: 8 },
  { label: '9시간', value: 9 },
  { label: '10시간', value: 10 },
  { label: '11시간', value: 11 },
  { label: '12시간', value: 12 },
  { label: '13시간', value: 13 },
  { label: '14시간', value: 14 },
  { label: '15시간', value: 15 },
  { label: '16시간', value: 16 },
  { label: '17시간', value: 17 },
  { label: '18시간', value: 18 },
  { label: '19시간', value: 19 },
  { label: '20시간이상', value: 20 },
];

export const COMPLETE_TIME_10 = [
  { label: '10시간', value: '10' },
  { label: '20시간', value: '20' },
  { label: '30시간', value: '30' },
  { label: '40시간', value: '40' },
  { label: '50시간이상', value: '50' },
];

// 기업관리 > 기업구분
export const COMPANY_SEARCH = [
  {
    label: '사업자번호',
    value: 'number',
  },
  {
    label: '기업명',
    value: 'name',
  },
];

// 기업관리 > 기업구분
export const COMPANY_GUBUN = [
  { label: '참여기업', value: 1 },
  { label: '선도기업', value: 2 },
];

// 기업관리 > 취업형태
export const COMPANY_APPLY_TYPE = [
  { label: '대기업', value: 1 },
  { label: '중소기업', value: 2 },
];

// 기업관리 > 연봉
export const COMPANY_PAYMENT = [
  {
    label: '~ 2,500만원',
    value: 1,
  },
  {
    label: '2,500 ~ 3,000만원',
    value: 2,
  },
  {
    label: '3,000 ~ 3,500만원',
    value: 3,
  },
  {
    label: '3,500 ~ 4,000만원',
    value: 4,
  },
  {
    label: '4,000 ~ 5,000만원',
    value: 5,
  },
  {
    label: '5,000만원 ~',
    value: 6,
  },
];
