import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { CustomSelect } from '@components';
import { Utils, CustomException } from '@common';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { patchTeacher, putTeacher } from '../../../api/eduadmin/teacher';
import { getSchools } from '../../../api/common/common';

export default React.memo(function AdmEduTeacher(props) {
  const history = useHistory();
  const { type, teacherData } = history.location.state;
  const [teacher, setTeacher] = useState(teacherData || {});
  const [selectedSchool, setSelectedSchool] = useState(
    teacherData
      ? {
          value: teacherData.schoolIdx,
          label: teacherData.schoolName,
          teacherId: teacherData.teacherId,
        }
      : null,
  );

  const [teacherName, setTeacherName] = useState(
    teacherData ? Utils.decrypt(teacherData.teacherName) : '',
  );

  const updateTeacher = async event => {
    const target = event.currentTarget;
    target.disabled = true;
    try {
      const params = {
        seq: teacherData.seq,
        teacherName,
      };
      const { data } = await patchTeacher(params);
      if (data.code === 0) {
        Swal.fire({
          text: '정보 변경 되었습니다.',
          confirmButtonText: '확인',
        }).finally(() => {
          history.push('/admEdu/teacher');
        });
      }
    } catch (e) {
      target.disabled = false;
    }
    target.disabled = false;
  };

  const [schoolList, setSchoolList] = useState([]);

  const getSchoolList = async () => {
    const { data } = await getSchools();
    if (data.code === 0) {
      const tempList = data.data.map(v => {
        const tempData = {
          ...v,
          teacherId: Utils.createTeachId(v),
          value: v.seq,
          label: v.schoolName,
        };
        return tempData;
      });
      setSchoolList(tempList);
    }
  };

  const errorMsSwal = (text, goBack) => {
    Swal.fire({
      text,
      icon: 'error',
      confirmButtonText: '확인',
    }).finally(() => {
      if (goBack) {
        history.push('/admEdu/teacher');
      }
    });
  };

  const createTeacher = async event => {
    const target = event.currentTarget;
    target.disabled = true;
    try {
      if (!teacherName) {
        throw new CustomException('이름을 입력해주세요.');
      }
      if (!selectedSchool.value) {
        throw new CustomException('학교를 선택해주세요.');
      }
      if (!selectedSchool.teacherId) {
        throw new CustomException('아이디를 확인해주세요.');
      }

      const params = {
        teacherName,
        teacherId: selectedSchool.teacherId,
        schoolIdx: selectedSchool.value,
      };

      const { data } = await putTeacher(params);
      if (data.code === 0) {
        Swal.fire({
          text: '학교 관리자 등록 되었습니다.',
          confirmButtonText: '확인',
        }).finally(() => {
          history.push('/admEdu/teacher');
        });
      }
    } catch (error) {
      if (error instanceof CustomException) {
        errorMsSwal(error.message);
      }
      target.disabled = false;
    }
    target.disabled = false;
  };

  useEffect(() => {
    getSchoolList();
  }, []);

  return (
    <main id="admEdu-teacher-register" className="type-02">
      <Container>
        <article className="content py">
          <section>
            <div className="title">
              <h5>학교 관리자 {type === 'register' ? '등록' : '수정'}</h5>
            </div>
          </section>

          <Card>
            <Card.Body>
              <section className="mb-5">
                <div className="sub-title">
                  <h5>기본정보</h5>
                </div>
                <div className="split-table type-simple">
                  <Row className="table-row">
                    <Col className="th col-2">이름</Col>
                    <Col className="td col-10">
                      <div className="lw-input">
                        <Form.Control
                          value={teacherName}
                          onChange={e => {
                            setTeacherName(e.target.value);
                          }}
                          placeholder="이름을 입력하세요."
                          maxLength="50"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </section>
              <section className="mb-5">
                <div className="sub-title">
                  <h5>학교정보</h5>
                </div>
                <div className="split-table type-simple">
                  <Row className="table-row">
                    <Col className="th col-2">학교</Col>
                    <Col className="td col-10">
                      <div className="lw-input">
                        <CustomSelect
                          options={schoolList}
                          value={selectedSchool}
                          onChange={setSelectedSchool}
                          placeholder="선택하세요."
                          isDisabled={type === 'modify'}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="table-row">
                    <Col className="th col-2">아이디</Col>
                    <Col className="td col-10">
                      {selectedSchool
                        ? selectedSchool.teacherId
                          ? selectedSchool.teacherId
                          : '아이디 생성에 실패했습니다. 관리자에게 문의하세요.'
                        : ''}
                    </Col>
                  </Row>
                </div>
              </section>
              <div className="btn-area mb-0">
                <Button
                  size="lg"
                  variant="outline-primary"
                  onClick={() => props.history.push('/admEdu/teacher')}
                >
                  취소
                </Button>
                <Button
                  size="lg"
                  variant="primary"
                  onClick={e =>
                    type === 'register' ? createTeacher(e) : updateTeacher(e)
                  }
                >
                  {type === 'register' ? '등록' : '수정'}
                </Button>
              </div>
            </Card.Body>
          </Card>
        </article>
      </Container>
    </main>
  );
});

const schoolOptions = [
  { label: '무슨학교', value: '0' },
  { label: '무슨학교', value: '1' },
  { label: '무슨학교', value: '2' },
];
