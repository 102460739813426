/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Button, Card, Table, Modal, Form } from 'react-bootstrap';
import { SearchSchool, CustomSelect, CustomModal } from '@components';
import { Utils } from '@common';

import classNames from 'classnames';
import ReactPaginate from 'react-paginate';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Moment from 'react-moment';
import { format, subDays, subMonths, subYears } from 'date-fns';

// api
import { getVoucherList } from '@api/eduadmin/voucher';

export default React.memo(function AdmEduVoucherLookup(props) {
  const history = useHistory();
  const locState = history.location.state;

  const firstRender = useRef(true);

  // state
  const pageRefresh = (locState && locState.pageRefresh) || false;
  const period = (locState && locState.period) || periodOptions[0];
  const startDate = (locState && locState.startDate) || null;
  const endDate = (locState && locState.endDate) || null;
  const school = (locState && locState.school) || {};
  const page = (locState && locState.page) || 1;

  // search
  const [searchPeroid, setSearchPeriod] = useState(period);
  const [searchStartDate, setSearchStartDate] = useState(startDate);
  const [searchEndDate, setSearchEndDate] = useState(endDate);
  const [selectedSchool, setSelectedSchool] = useState(school);

  // api
  const [voucherList, setVoucherList] = useState([]);
  const [totalPages, setTotalPages] = useState();

  // list
  const getVoucherTableList = async () => {
    const params = {
      page,
      size: 10,
      schoolId: selectedSchool.value === 'all' ? null : selectedSchool.schoolId,
      startDate:
        searchPeroid.value === 'all'
          ? null
          : format(searchStartDate, 'yyyy-MM-dd').toString(),
      endDate:
        searchPeroid.value === 'all'
          ? null
          : format(searchEndDate, 'yyyy-MM-dd').toString(),
    };

    // console.log(
    //   'getVoucherTableList :: params',
    //   JSON.stringify(params, null, '\t'),
    // );
    const { data } = await getVoucherList(params);
    if (data.code === 0) {
      console.log('voucher data list ::: ', data.data.list);
      setTotalPages(data.data.totalPages);
      setVoucherList(data.data.list);
      // console.log(
      //   'getVoucherTableList :: data',
      //   JSON.stringify(data, null, '\t'),
      // );
    }
  };

  // 페이지 변환 / state 저장(새로고침)
  const movePage = pageNum => {
    const state = {
      period: searchPeroid,
      startDate: searchPeroid.value === 'all' ? null : searchStartDate,
      endDate: searchPeroid.value === 'all' ? null : searchEndDate,
      school: selectedSchool,
      page: pageNum,
      pageRefresh: !pageRefresh,
    };

    // console.log('movePage :: state', JSON.stringify(state, null, '\t'));
    history.push({
      pathname: `/admEdu/voucher/lookup`,
      state,
    });
  };

  const settingState = () => {
    setSearchPeriod(period);
    setSearchStartDate(startDate);
    setSearchEndDate(endDate);
    setSelectedSchool(school);
  };

  // 기간 옵션에 따라 startDate, endDate 변경
  useEffect(() => {
    const val = searchPeroid.value;
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (val === 'all') {
      setSearchStartDate(null);
      setSearchEndDate(null);
    }
    if (val === 'today') {
      setSearchStartDate(today);
      setSearchEndDate(today);
    }
    if (val === '7d') {
      setSearchStartDate(subDays(today, 7));
      setSearchEndDate(today);
    }
    if (val === '1m') {
      setSearchStartDate(subMonths(today, 1));
      setSearchEndDate(today);
    }
    if (val === '3m') {
      setSearchStartDate(subMonths(today, 3));
      setSearchEndDate(today);
    }
    if (val === '1y') {
      setSearchStartDate(subYears(today, 1));
      setSearchEndDate(today);
    }
  }, [searchPeroid]);

  // 리스트 가져오기
  useEffect(() => {
    if (selectedSchool.value) {
      settingState();
      getVoucherTableList();
    }
  }, [pageRefresh]);

  // 기간, 학교 변경에 따라 movePage
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    movePage(1);
  }, [searchStartDate, searchEndDate, selectedSchool]);

  return (
    <main id="admEdu-voucher-lookup" className="type-02">
      <Container>
        <article className="content py">
          <section>
            <div className="title">
              <h5>바우처 조회</h5>
            </div>
          </section>

          <Card>
            <Card.Header>
              <div className="flex-between">
                <div className="flex-start align-items-stretch search-wrap">
                  <div className="search-box me-2">
                    {/* 기간 선택 */}
                    <CustomSelect
                      options={periodOptions}
                      value={searchPeroid}
                      onChange={setSearchPeriod}
                      className="select-inside-box"
                    />
                    <div
                      className={classNames('datepicker-box', {
                        disabled: searchPeroid.value !== 'custom',
                      })}
                    >
                      <DatePicker
                        selected={searchStartDate}
                        onChange={date => setSearchStartDate(date)}
                        selectsStart
                        startDate={searchStartDate}
                        endDate={searchEndDate}
                        dateFormat="yyyy-MM-dd"
                        placeholderText="전체"
                      />
                      <span>~</span>
                      <DatePicker
                        selected={searchEndDate}
                        onChange={date => setSearchEndDate(date)}
                        selectsEnd
                        startDate={searchStartDate}
                        endDate={searchEndDate}
                        // minDate={searchStartDate}
                        dateFormat="yyyy-MM-dd"
                        placeholderText="전체"
                      />
                    </div>
                  </div>
                  {/* 학교 선택 */}
                  <SearchSchool
                    selectedSchool={selectedSchool}
                    setSelectedSchool={item => {
                      setSelectedSchool(item);
                    }}
                  />
                </div>
                <Button
                  variant="primary"
                  onClick={() =>
                    props.history.push('/admEdu/voucher/lookup/allotment')
                  }
                >
                  바우처 할당
                </Button>
              </div>
            </Card.Header>
            <Card.Body className="pt-0">
              <Table className="bt-none tr-even-bg">
                <colgroup>
                  <col width={160} />
                  <col width={100} />
                  <col width={100} />
                  <col width={100} />
                  <col width="*" />
                  <col width={120} />
                  <col width="*" />
                </colgroup>
                <thead>
                  <tr>
                    <th>할당일</th>
                    <th>할당년도</th>
                    <th>할당회차</th>
                    <th>ID</th>
                    <th>학교명</th>
                    <th>바우처 점수</th>
                    <th className="text-end">할당금액</th>
                  </tr>
                </thead>
                <tbody>
                  {voucherList.length > 0 ? (
                    voucherList.map((item, idx) => {
                      return (
                        <tr key={`voucher-${idx}`}>
                          <td>
                            <Moment date={item.regDate} format="YYYY.MM.DD" />
                          </td>
                          <td>{item.year}년</td>
                          <td>{item.nth}차</td>
                          <td>{item.schoolId}</td>
                          <td>{item.schoolName}</td>
                          <td>{Utils.numberComma(item.pointSum)}</td>
                          <td className="text-end">
                            {Utils.numberComma(item.voucherSum)}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={6} className="no-data">
                        {!selectedSchool.value
                          ? '학교를 선택하세요.'
                          : '데이터가 없습니다.'}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>{' '}
              <ReactPaginate
                pageRangeDisplayed={3}
                nextLabel=""
                previousLabel=""
                breakLabel="..."
                pageCount={totalPages}
                renderOnZeroPageCount={null}
                containerClassName="pagination mt-4"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakClassName="page-item"
                breakLinkClassName="page-link"
                activeClassName="active"
                onPageChange={e => {
                  movePage(e.selected + 1);
                }}
                forcePage={page ? page - 1 : 0}
              />
            </Card.Body>
          </Card>
        </article>
      </Container>
    </main>
  );
});

const periodOptions = [
  { label: '전체', value: 'all' },
  { label: '오늘', value: 'today' },
  { label: '7일', value: '7d' },
  { label: '1개월', value: '1m' },
  { label: '3개월', value: '3m' },
  { label: '1년', value: '1y' },
  { label: '기간설정', value: 'custom' },
];

const dataDummy = [
  {
    date: '2022-11-01',
    turn: 2,
    id: 523,
    school: '세정고등학교',
    score: 2700,
    allotment: 30000000,
    remainAmount: 2000000,
  },
];
