/**
 *   [ 전공직무능력2 API ]
 *
 */
import Axios from '../axios';

const API_TEACHER = 'api/v1/teacher';

const MAJOR_EDUCATION_LIST = `${API_TEACHER}/major-education-list`;
const MAJOR_EDUCATION = `${API_TEACHER}/major-education`;
const MAJOR_EDUCATION_INFO = `${API_TEACHER}/major-education-info`;

const formDataConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

// 전공직무능력2 > '전공 기초교육, 심화교육, 응용교육' 결과 보고서 리스트 조회
export const selectFormMajorJobEduAllByTeacher = param => {
  return Axios.post(`${MAJOR_EDUCATION_LIST}`, param);
};

// 전공직무능력2 > '전공 기초교육, 심화교육, 응용교육' 결과 보고서 정보 조회 // 리서치 목록 포함
export const selectFormMajorJobEduInfoByTeacher = param => {
  return Axios.post(`${MAJOR_EDUCATION_INFO}`, param);
};

// 전공직무능력2 > '전공 기초교육, 심화교육, 응용교육 임시저장, 승인요청, 승인요청 취소, 삭제'
export const updateFormMajorJobEduByTeacher = param => {
  return Axios.post(`${MAJOR_EDUCATION}`, param);
};
