/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import { Container, Button, Table } from 'react-bootstrap';

//* CONST
import { ROLE, FormStatus, PROGRAM_ID, LOGIN_TYPE } from '@common/consts';

//* API
import {
  selectFormPracticeAbilityAll,
  upsertFormPracticeAbility,
} from '@api/student/visitIndustry';
import { selectFormPracticeAbilityAllByTeacher } from '@api/teacher/visitIndustry';

//* Utils
import Swal from 'sweetalert2';
import Moment from 'react-moment';

//* [ Main ]
export default React.memo(function VisitIndustry(props) {
  const history = useHistory();

  const { loginType, studentIdx } = useParams();

  const authType = props.userInfo.type;

  if (loginType !== LOGIN_TYPE.STUDENT && loginType !== LOGIN_TYPE.TEACHER) {
    Swal.fire({
      text: '잘못된 접근입니다.',
      confirmButtonText: '확인',
    });
    history.goBack();
  }

  if (loginType === LOGIN_TYPE.TEACHER && !window.parseInt(studentIdx)) {
    Swal.fire({
      text: '잘못된 접근입니다.',
      confirmButtonText: '확인',
    });
    history.goBack();
  }

  const [showReportList, setShowReportList] = useState([]);

  // 프로그램 ID
  const programId = PROGRAM_ID.PROGRAM_ID_FI_INTERVIEW;

  /** API */
  const [firstCallDone, setFirstCallDone] = useState(false);
  // * [API] 취업역량강화 결과 보고서 리스트 조회
  const selectPracticeAbilityList = async () => {
    const params = {
      program_id: programId,
    };
    try {
      const { data } = await selectFormPracticeAbilityAll(params);

      if (data.code === 0) {
        setFirstCallDone(true);
        setShowReportList(data.data);
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  // * 교사 :: [API] 취업역량강화 결과 보고서 리스트 조회
  const selectPracticeAbilityListByTeacher = async () => {
    const params = {
      student_idx: studentIdx,
      program_id: programId,
    };
    try {
      const { data } = await selectFormPracticeAbilityAllByTeacher(params);

      if (data.code === 0) {
        setFirstCallDone(true);
        setShowReportList(data.data);
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  // [ API ] 취업역량 강화 보고서 수정/삭제
  const updateReport = async (event, type, seq) => {
    const target = event.currentTarget;
    target.disabled = true;

    if (
      type === FormStatus.DEL.value &&
      // eslint-disable-next-line no-restricted-globals
      !(await Swal.fire({
        text: '삭제하시겠습니까?',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        showCancelButton: true,
        reverseButtons: true,
      }).then(result => !!result.isConfirmed))
    ) {
      target.disabled = false;
      return;
    }

    if (
      type === FormStatus.ING.value &&
      // eslint-disable-next-line no-restricted-globals
      !(await Swal.fire({
        text: '승인요청을 취소하시겠습니까?',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        showCancelButton: true,
        reverseButtons: true,
      }).then(result => !!result.isConfirmed))
    ) {
      target.disabled = false;
      return;
    }

    try {
      // 파라미터 설정
      const params = {
        formSeq: seq,
        status: type,
        program_id: programId,
      };

      // FormData
      const formData = new FormData();

      // JSON append
      formData.append(
        'param',
        new Blob([JSON.stringify(params)], { type: 'application/json' }),
      );

      const { data } = await upsertFormPracticeAbility(formData);
      if (data.code === 0) {
        window.location.reload();
      }
    } catch (e) {
      // alert(e.response.data.message);
      target.disabled = false;
    }
    target.disabled = false;
  };

  /** HTML 생성 */
  // 타입에 따른 버튼 생성
  const getManageButton = item => {
    switch (item.status) {
      case FormStatus.REQ.value:
        return (
          <div className="btn-area">
            <Button
              size="sm"
              variant="primary"
              onClick={e => {
                e.stopPropagation();
                updateReport(e, FormStatus.ING.value, item.seq);
              }}
            >
              승인요청 취소
            </Button>
          </div>
        );
      case FormStatus.CON.value:
        return '';
      case FormStatus.ING.value:
        return (
          <div className="btn-area">
            <Button
              size="sm"
              variant="outline-primary"
              onClick={e => {
                e.stopPropagation();
                updateReport(e, FormStatus.DEL.value, item.seq);
              }}
            >
              삭제
            </Button>
            <Button
              onClick={e => {
                e.stopPropagation();
                history.push({
                  pathname: `/student/indivAbility/write/visitIndustry/${item.seq}`,
                });
              }}
              size="sm"
              variant="primary"
            >
              수정
            </Button>
          </div>
        );
      case FormStatus.REJ.value:
        return (
          <div className="btn-area">
            <Button
              onClick={e => {
                e.stopPropagation();
                history.push({
                  pathname: `/student/indivAbility/write/visitIndustry/${item.seq}`,
                });
              }}
              size="sm"
              variant="primary"
            >
              수정
            </Button>
          </div>
        );
      default:
        return <div />;
    }
  };

  /** useEffect */
  useEffect(() => {
    if (loginType === LOGIN_TYPE.STUDENT) {
      selectPracticeAbilityList();
    }
    if (loginType === LOGIN_TYPE.TEACHER) {
      selectPracticeAbilityListByTeacher();
    }

    return () => {
      setShowReportList([]);
    };
  }, [loginType]);

  const [linkList, setLinkList] = useState([]);

  useEffect(() => {
    switch (authType) {
      case ROLE.ADMIN:
        setLinkList([
          {
            name: '프로그램 현황',
            path: '/admEdu/student/program',
          },
        ]);
        break;
      case ROLE.TEACHER:
        setLinkList([
          {
            name: '학생 관리',
            path: '/teacher/studentMgmt/studentMgmt',
          },
        ]);
        break;
      case ROLE.STUDENT:
        setLinkList([
          {
            name: 'HOME',
            path: '/',
          },
        ]);
        break;
      default:
        break;
    }
  }, [authType]);

  return (
    <main id="student-visitIndustry">
      <Container>
        <article className="content">
          <ul className="path">
            {linkList &&
              linkList.length > 0 &&
              linkList.map((v, i) => {
                return (
                  <li key={`link_${i}`}>
                    <Link to={v.path}>{v.name}</Link>
                  </li>
                );
              })}
            <li>
              <Link to="#none">산업체 채용면접</Link>
            </li>
          </ul>

          {/* ////////// ↓ 상단 타이틀영역 ////////// */}
          <section>
            <div className="title">
              <h5>산업체 채용면접</h5>
              {loginType === LOGIN_TYPE.STUDENT && (
                <Button
                  onClick={() => {
                    props.history.push(
                      '/student/indivAbility/write/visitIndustry',
                    );
                  }}
                  variant="primary"
                >
                  신규작성
                </Button>
              )}
            </div>
          </section>

          {/* ////////// ↓ 컨텐츠 ////////// */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>결과 보고서</h5>
            </div>
            <Table>
              <colgroup>
                <col width={200} />
                <col width="*" />
                <col width={150} />
                <col width={150} />
                {loginType === LOGIN_TYPE.STUDENT && <col width={150} />}
              </colgroup>
              <thead>
                <tr>
                  <th>방문일</th>
                  <th>방문 업체명</th>
                  <th>상태</th>
                  <th>점수</th>
                  {loginType === LOGIN_TYPE.STUDENT && <th>관리</th>}
                </tr>
              </thead>
              <tbody>
                {showReportList.length > 0
                  ? showReportList.map((item, index) => {
                      return (
                        <tr
                          key={`practiceAbility-report-idx-${index}`}
                          className="go-detail"
                          onClick={() => {
                            if (loginType === LOGIN_TYPE.STUDENT) {
                              props.history.push({
                                pathname: `/student/indivAbility/report/visitIndustry/${item.seq}`,
                              });
                            } else {
                              props.history.push(
                                `/teacher/studentMgmt/visitIndustry/comment/${studentIdx}/${item.seq}`,
                              );
                            }
                          }}
                        >
                          <td>
                            {item.visit_date ? (
                              <Moment date={item.date} format="YYYY.MM.DD" />
                            ) : (
                              '-'
                            )}
                          </td>
                          <td>{item.visit_company}</td>
                          <td>
                            <strong
                              className={
                                FormStatus[item.status]
                                  ? FormStatus[item.status].class
                                  : ''
                              }
                            >
                              {FormStatus[item.status]
                                ? FormStatus[item.status].text
                                : ''}
                            </strong>
                          </td>
                          <td>{item.point}</td>
                          {loginType === LOGIN_TYPE.STUDENT && (
                            <td>{getManageButton(item)}</td>
                          )}
                        </tr>
                      );
                    })
                  : firstCallDone && (
                      <tr>
                        <td
                          colSpan={loginType === LOGIN_TYPE.STUDENT ? '5' : '4'}
                          className="no-data"
                        >
                          작성한 결과 보고서가 없습니다.
                        </td>
                      </tr>
                    )}
              </tbody>
            </Table>
          </section>
          <div className="btn-area">
            <Button
              onClick={() => {
                history.goBack();
              }}
              variant="outline-primary"
              size="lg"
            >
              목록
            </Button>
          </div>
        </article>
      </Container>
    </main>
  );
});
