/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Container, Card, Table } from 'react-bootstrap';
import { CustomSelect } from '@components';
import { Utils } from '@common';
import {
  getVoucherAssignData,
  patchVoucherAll,
  getVoucherBatchList,
  downloadVoucher,
  getStatistics,
} from '@api/eduadmin/voucher';
import { getNthList, getProgramList } from '@api/common/common';
import { useHistory } from 'react-router-dom';

export default React.memo(function AdmEduVoucherScoreStat(props) {
  const history = useHistory();

  const [selectedNth, setSelectedNth] = useState({});
  const [currentNth, setCurrentNth] = useState({});
  const [nthList, setNthList] = useState([]);
  const [statisticData, setStatisticData] = useState({});
  const [statisticList, setStatisticList] = useState([]);

  const getData = async () => {
    const { data } = await getStatistics(selectedNth.value);
    if (data.code === 0) {
      setStatisticList(data.data.list);
      setStatisticData(data.data);
    }
  };
  const getNths = async () => {
    const { data } = await getNthList();
    if (data.code === 0) {
      const tempList = data.data.nthList.map((v, i) => {
        const tempData = {
          value: v,
          label: `${v}차`,
        };

        return tempData;
      });
      const newCurrentNth = tempList.find(
        v => v.value === data.data.currentNth,
      );
      setCurrentNth(newCurrentNth);
      setSelectedNth(newCurrentNth);
      setNthList(tempList);
    }
  };

  useEffect(() => {
    getNths();
  }, []);

  useEffect(() => {
    if (selectedNth.value) {
      getData();
    }
  }, [selectedNth]);

  return (
    <main id="admEdu-voucher-scoreStat" className="type-02">
      <Container>
        <article className="content py">
          <section>
            <div className="title">
              <h5>바우처 지급 통계</h5>
            </div>
          </section>

          <Card>
            <Card.Header>
              <div className="flex-between">
                <div className="sub-title">
                  <h5>{currentNth.label} 진행중</h5>
                </div>
                <div className="sw-input">
                  <CustomSelect
                    options={nthList}
                    value={selectedNth}
                    onChange={setSelectedNth}
                  />
                </div>
              </div>
            </Card.Header>
            <Card.Body className="pt-0">
              <Table className="bt-none tr-even-bg">
                <colgroup>
                  <col width={120} />
                  <col width="*" />
                  <col width={100} />
                  <col width={100} />
                  <col width={100} />
                  <col width={100} />
                  <col width={100} />
                  <col width={100} />
                  <col width={100} />
                  <col width={100} />
                </colgroup>
                <thead>
                  <tr>
                    <th rowSpan={2}>ID</th>
                    <th rowSpan={2}>학교명</th>
                    <th colSpan={2}>1학년</th>
                    <th colSpan={2}>2학년</th>
                    <th colSpan={2}>3학년</th>
                    <th colSpan={2}>합계</th>
                  </tr>
                  <tr>
                    <th>점수</th>
                    <th>학생수</th>
                    <th>점수</th>
                    <th>학생수</th>
                    <th>점수</th>
                    <th>학생수</th>
                    <th>점수</th>
                    <th>학생수</th>
                  </tr>
                </thead>
                <tbody>
                  {statisticList.length > 0 ? (
                    statisticList.map((item, i) => {
                      return (
                        <tr key={`voucher-stat-${i}`}>
                          <td>{item.schoolId}</td>
                          <td>{item.schoolName}</td>
                          <td>{Utils.numberComma(item.grade1Point)}</td>
                          <td>{Utils.numberComma(item.grade1Cnt)}</td>
                          <td>{Utils.numberComma(item.grade2Point)}</td>
                          <td>{Utils.numberComma(item.grade2Cnt)}</td>
                          <td>{Utils.numberComma(item.grade3Point)}</td>
                          <td>{Utils.numberComma(item.grade3Cnt)}</td>
                          <td>{Utils.numberComma(item.totalPoint)}</td>
                          <td>{Utils.numberComma(item.totalCnt)}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={10}>
                        <p className="no-data">데이터가 없습니다.</p>
                      </td>
                    </tr>
                  )}
                  <tr
                    className="fw-bold"
                    style={{ borderTop: '2px solid var(--bs-border)' }}
                  >
                    <td colSpan={2}>총계</td>
                    <td>{Utils.numberComma(statisticData.sumGrade1Point)}</td>
                    <td>{Utils.numberComma(statisticData.sumGrade1Cnt)}</td>
                    <td>{Utils.numberComma(statisticData.sumGrade2Point)}</td>
                    <td>{Utils.numberComma(statisticData.sumGrade2Cnt)}</td>
                    <td>{Utils.numberComma(statisticData.sumGrade3Point)}</td>
                    <td>{Utils.numberComma(statisticData.sumGrade3Cnt)}</td>
                    <td>{Utils.numberComma(statisticData.totalPoint)}</td>
                    <td>{Utils.numberComma(statisticData.totalCnt)}</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </article>
      </Container>
    </main>
  );
});

const dataDummy = [
  {
    id: 524,
    school: '세정고등학교',
    freshman: {
      score: 50,
      number: 13,
    },
    junior: {
      score: 12,
      number: 13,
    },
    senior: {
      score: 12,
      number: 13,
    },
  },
  {
    id: 523,
    school: '세정고등학교',
    freshman: {
      score: 50,
      number: 13,
    },
    junior: {
      score: 12,
      number: 13,
    },
    senior: {
      score: 12,
      number: 13,
    },
  },
  {
    id: 522,
    school: '세정고등학교',
    freshman: {
      score: 50,
      number: 13,
    },
    junior: {
      score: 12,
      number: 13,
    },
    senior: {
      score: 12,
      number: 13,
    },
  },
  {
    id: 521,
    school: '세정고등학교',
    freshman: {
      score: 50,
      number: 13,
    },
    junior: {
      score: 12,
      number: 13,
    },
    senior: {
      score: 12,
      number: 13,
    },
  },
];

const turnOptions = [
  { label: '1차', value: 'turn-01' },
  { label: '2차', value: 'turn-02' },
];
