import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import persistStore from 'redux-persist/es/persistStore';
import thunk from 'redux-thunk';
import reducers from '@reducers';
// import logger from 'redux-logger'
const persistConfig = {
  timeout: 1000, // TODO :: lazy loading 원인
  key: 'root',
  storage,
};
const rootReducer = combineReducers(reducers);

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

const persistor = persistStore(store);
export { store, persistor };
