/**
 *   [ 인성 자존감 API ]
 *
 */
import Axios from '@api/axios';

const API_PROGRAM = 'api/v1/teacher';

const SELECT_LIST_INSUNG_ALL = `${API_PROGRAM}/insung-list-all`;
const UPDATE_INSUNG = `${API_PROGRAM}/update-status-insung`;

// 직업기초 능력 > 인성 자존감 활동 리스트 조회 (결과 보고서)
export const selectFormInsungAll = param => {
  return Axios.post(`${SELECT_LIST_INSUNG_ALL}`, param);
};

// 직업기초 능력 > 인성 자존감 활동 신규등록
export const updateStatusInsung = param => {
  return Axios.post(`${UPDATE_INSUNG}`, param);
};
