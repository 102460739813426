/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import {
  Container,
  Button,
  Table,
  Row,
  Col,
  Card,
  Form,
  Modal,
} from 'react-bootstrap';
import { PdfPreview, PreviewModal } from '@components';

//* Utils
import Utils from '@common/Utils';
import Swal from 'sweetalert2';
import Moment from 'react-moment';

//* CONSTS
import {
  ROLE,
  FormStatus,
  PROGRAM_ID,
  FILE_FORM_TYPE,
  APPROVE_STATE_TYPE_LIST,
} from '@common/consts';

//* API
import {
  selectFormAbilityInfoByTeacher,
  updateStatusFormAbilityTestByTeacher,
} from '@api/teacher/jobAssess';
import { getFilePathFromRedis } from '@api/file';

//* [ Main ]
export default React.memo(function JobAssessComment(props) {
  const history = useHistory();

  // 유저 권한 타입
  const authType = props.userInfo.type;

  const { seq, studentIdx } = useParams();

  if (!window.parseInt(studentIdx)) {
    Swal.fire({
      text: '잘못된 접근입니다.',
      confirmButtonText: '확인',
    });
    history.goBack();
  }

  if (!window.parseInt(seq)) {
    Swal.fire({
      text: '잘못된 접근입니다.',
      confirmButtonText: '확인',
    });
    history.goBack();
  }

  //* #################################################################
  //* [ States ]
  //* #################################################################
  // radio
  const [approveStateType, setApproveStateType] = useState(
    APPROVE_STATE_TYPE_LIST[0].value,
  );

  // 학생 정보, 보고서 정보
  const [reportInfo, setReportInfo] = useState({});

  // 교사 코멘트
  const [teacherCommentList, setTeacherCommentList] = useState([]);

  // 프로그램 ID
  const programId = PROGRAM_ID.PROGRAM_ID_FO_TEST;

  const [comment, setComment] = useState('');
  const [firstCallDone, setFirstCallDone] = useState(false);

  // PDF 미리보기 ( react-pdf )
  const [fileUrl, setFileUrl] = useState(null);
  const [pdfPageNum, setPdfPageNum] = useState(1);
  const [pdfTotalPages, setPdfTotalPages] = useState(1);
  
  //* #################################################################
  //* [ Utils ]
  //* #################################################################
  // PDF 파일 로딩 완료
  const onDocumentLoadSuccess = ({numPages}) => {
    setPdfPageNum(1);
    setPdfTotalPages(numPages);
  }

  //* #################################################################
  //* [ Preview Modal ]
  //* #################################################################
  // Flag
  const [previewModalShow, setPreviewModalShow] = useState(false);
  
  // Open
  const openPreviewModal = () => {
    openFile({fileSeq: null});
    setPreviewModalShow(true);
  };

  // Close
  const closePreviewModal = () => {
    setPreviewModalShow(false);
  };

  //* #################################################################
  //* [ API ] Form 정보 조회
  //* #################################################################
  const getInfo = async () => {
    // 파라미터
    const param = {
      formSeq: seq,
      program_id: programId,
      student_idx: studentIdx,
    };

    // Axios
    try {
      const { data } = await selectFormAbilityInfoByTeacher(param);

      if (data.code === 0) {
        // // 학생 정보, 보고서 정보
        const formData = data.data;
        setFirstCallDone(true);

        setReportInfo({
          formSeq: formData.seq,
          name: formData.name,
          id: formData.id,
          status: formData.status,
          point: formData.point,
          orgPoint: formData.org_point,
          confirmDate: formData.confirm_date,
          testTitle: formData.test_title,
          testDate: new Date(formData.test_date).toISOString().split('T')[0],
          KOR: formData.test_korean,
          ENG: formData.test_english,
          MATH: formData.test_math,
          SOL: formData.test_solve,
          AVG: formData.test_average,
          files: formData.files,
        });

        // // // 교사 코멘트 리스트
        setTeacherCommentList(formData.teachercommentlist);
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 승인요청, 취소, 삭제
  //* #################################################################
  const updateRequest = async (target, reqStatus) => {
    target.disabled = true;

    // Axios
    const params = {
      formSeq: seq,
      status: reqStatus,
      comment,
      program_id: programId,
    };
    try {
      const { data } = await updateStatusFormAbilityTestByTeacher(params);

      if (data.code === 0) {
        history.goBack();
      }
    } catch (e) {
      target.disabled = false;
      // alert(e.response.data.message);
    }

    target.disabled = false;
  };

  //* #################################################################
  //* [ API ] 파일 조회 ( 첨부파일, 승인이수 )
  //* #################################################################
  const openFile = async ({ fileSeq }) => {
    let paramMap;

    // [ CASE 1 ] 첨부파일 조회
    if (fileSeq) {
      paramMap = {
        formType: FILE_FORM_TYPE.FILE_ABILITY_TEST,
        fileSeq,
      };
    }
    // [ CASE 2 ] 승인이수 조회
    else {
      paramMap = {
        formType: FILE_FORM_TYPE.FILE_STATIC_FORM_GUIDE_ABILITY_TEST,
        fileSeq,
      };
    }

    // Axios
    try {
      const { data } = await getFilePathFromRedis(paramMap);

      if (data.code === 0) {
        const redisKey = data.data;
        const hostName = window.location.hostname;

        const url = Utils.getFileOpenUrl(hostName, redisKey);

        // 파일 다운로드 (첨부파일)
        if (fileSeq) {
          window.open(url);
          
        } 
        // PDF 미리보기 (이수기준)
        else {
          setFileUrl(url);
        }
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ useEffect ]
  //* #################################################################
  useEffect(() => {
    getInfo();
    return () => {
      setReportInfo({});
      setTeacherCommentList([]);
    };
  }, []);

  const [linkList, setLinkList] = useState([]);

  useEffect(() => {
    switch (authType) {
      case ROLE.ADMIN:
        setLinkList([
          {
            name: '프로그램 현황',
            path: '/admEdu/student/program',
          },
          {
            name: '직업기초능력평가',
            path: `/teacher/basicJob-teacher/jobAssess/${studentIdx}`,
          },
        ]);
        break;
      case ROLE.TEACHER:
        setLinkList([
          {
            name: '학생 관리',
            path: '/teacher/studentMgmt/studentMgmt',
          },
          {
            name: '직업기초능력평가',
            path: `/teacher/basicJob-teacher/jobAssess/${studentIdx}`,
          },
        ]);
        break;
      default:
        break;
    }
  }, [authType]);

  //* #################################################################
  //* [ return ]
  //* #################################################################
  return (
    <main id="program-jobAssess-comment" className="type-02">
      <Container>
        <article className="content">
          <ul className="path">
            {linkList &&
              linkList.length > 0 &&
              linkList.map((v, i) => {
                return (
                  <li key={`link_${i}`}>
                    <Link to={v.path}>{v.name}</Link>
                  </li>
                );
              })}
            <li>
              <Link to="#none">결과 보고서</Link>
            </li>
          </ul>

          {/* ////////// ↓ 상단 타이틀영역 ////////// */}
          <section>
            <div className="title">
              <h5>직업기초능력평가 결과 보고서</h5>
              <div className="dl-box type-bar lg">
                <dl className="status-text">
                  <strong
                    className={
                      FormStatus[reportInfo.status] &&
                      FormStatus[reportInfo.status].class
                    }
                  >
                    {FormStatus[reportInfo.status] &&
                      FormStatus[reportInfo.status].text}
                    <br/>
                    {reportInfo.status === 'CON' && 
                      <small>{Utils.getJustDateString(reportInfo.confirmDate)}</small>
                    }
                  </strong>
                </dl>
                <dl>
                  <dd>
                    <strong>{reportInfo.point}</strong>
                  </dd>
                </dl>
              </div>
            </div>

            <div className="mb-4">
              <Button variant="text-underline" onClick={openPreviewModal}>
                승인이수 보기
              </Button>
            </div>
          </section>

          {/* ////////// ↓ 컨텐츠 ////////// */}
          <Card>
            <Card.Body>
              {/* 기본정보 */}
              <section className="mb-5">
                <div className="sub-title">
                  <h5>기본정보</h5>
                </div>
                <div className="split-table">
                  <Row className="table-row">
                    <Col className="th col-2">학번</Col>
                    <Col className="td col-10">{reportInfo.id}</Col>
                  </Row>
                  <Row className="table-row">
                    <Col className="th col-2">이름</Col>
                    <Col className="td col-10">
                      {Utils.decrypt(reportInfo.name)}
                    </Col>
                  </Row>
                </div>
              </section>

              {/* 평가정보 */}
              <section className="mb-5">
                <div className="sub-title">
                  <h5>평가정보</h5>
                </div>
                <div className="split-table">
                  <Row className="table-row">
                    <Col className="th col-2">평가일</Col>
                    <Col className="td col-10">{reportInfo.testDate}</Col>
                  </Row>
                  <Row className="table-row">
                    <Col className="th col-2">평가명</Col>
                    <Col className="td col-10">{reportInfo.testTitle}</Col>
                  </Row>
                </div>
              </section>

              {/* 직업기초능력평가 결과 */}
              <section className="mb-5">
                <div className="sub-title">
                  <h5>직업기초능력평가 결과</h5>
                </div>
                <Table>
                  <colgroup>
                    <col width={200} />
                    <col width="*" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>세부역량</th>
                      <th>등급</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-start text-gray">의사소통 국어</td>
                      <td className="text-start">
                        {reportInfo.KOR ? `${reportInfo.KOR}등급` : '-'}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-start text-gray">의사소통 영어</td>
                      <td className="text-start">
                        {reportInfo.ENG ? `${reportInfo.ENG}등급` : '-'}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-start text-gray">수리활용</td>
                      <td className="text-start">
                        {reportInfo.MATH ? `${reportInfo.MATH}등급` : '-'}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-start text-gray">문제해결</td>
                      <td className="text-start">
                        {reportInfo.SOL ? `${reportInfo.SOL}등급` : '-'}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-start text-gray">평균</td>
                      <td className="text-start">
                        {reportInfo.AVG ? `${reportInfo.AVG}등급` : '-'}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </section>

              {/* 파일 첨부 */}
              <section className="mb-5">
                <div className="sub-title flex-start">
                  <h5>파일첨부</h5>
                </div>
                {reportInfo.files && reportInfo.files.length > 0 ? (
                  <ul className="attachment-list">
                    {reportInfo.files.map((file, idx) => {
                      return (
                        <li key={idx}>
                          <Button
                            className="btn-icon ic-down"
                            onClick={() => openFile({ fileSeq: file.file_seq })}
                          >
                            {file.file_name_org}
                          </Button>
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  firstCallDone && (
                    <ul className="attachment-list">
                      <li>없음</li>
                    </ul>
                  )
                )}
              </section>

              {authType !== ROLE.ADMIN &&
                reportInfo.status === FormStatus.REQ.value && (
                  <section className="mb-5">
                    <div className="sub-title flex-start">
                      <h5>승인여부</h5>
                    </div>
                    <div className="card-radio-group">
                      {APPROVE_STATE_TYPE_LIST.map((item, index) => {
                        return (
                          <Form.Check
                            key={`approve-state-type-${item.value}`}
                            name="approve-state-type"
                            type="radio"
                            label={item.label}
                            value={item.value}
                            id={item.value}
                            onChange={e => setApproveStateType(e.target.value)}
                            checked={approveStateType === item.value}
                          />
                        );
                      })}
                    </div>
                  </section>
                )}
              {/* 교사 코멘트 */}
              <section className="mb-5">
                {authType !== ROLE.ADMIN &&
                  (reportInfo.status === FormStatus.REQ.value ||
                    reportInfo.status === FormStatus.CON.value) && (
                    <div>
                      <div className="sub-title">
                        <h5>교사 코멘트</h5>
                      </div>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        placeholder="최대 700자 이내로 입력하세요."
                        onChange={e => {
                          setComment(e.target.value);
                        }}
                        value={comment || ''}
                        maxLength="700"
                      />
                      <small className="text-gray flex-end mt-2">{`${
                        comment ? comment.length : '0'
                      }/700자`}</small>
                    </div>
                  )}

                <div className="comment-box disabled mt-4">
                  <ul className="comment-list">
                    {teacherCommentList.length > 0 &&
                      teacherCommentList.map((item, index) => {
                        return (
                          <li key={`comment-${index}`}>
                            <p className="date">
                              {item.reg_date && (
                                <Moment
                                  format="YYYY.MM.DD"
                                  date={item.reg_date}
                                />
                              )}
                            </p>
                            <p className="text">{item.comment}</p>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </section>

              {/* ////////// ↓ 하단 버튼 //////////  */}
              {authType !== ROLE.ADMIN &&
                reportInfo.status === FormStatus.CON.value && (
                  <div className="btn-area mb-0">
                    <Button
                      onClick={() => {
                        history.goBack();
                      }}
                      variant="outline-primary"
                      size="lg"
                    >
                      목록
                    </Button>
                    <Button
                      size="lg"
                      variant="primary"
                      onClick={e => {
                        const target = e.currentTarget;
                        
                        Swal.fire({
                          title: '알림',
                          text: '취소 하시겠습니까?',
                          confirmButtonText: '확인',
                          cancelButtonText: '취소',
                          showCancelButton: true,
                          reverseButtons: true,
                        }).then(result => {
                          if (result.isConfirmed) {
                            updateRequest(target, FormStatus.REJ.value);
                          }
                        });                     
                      }}
                    >
                      승인취소
                    </Button>
                  </div>
                )}
              {authType !== ROLE.ADMIN &&
                reportInfo.status === FormStatus.REQ.value && (
                  <div className="btn-area mb-0">
                    <Button
                      onClick={() => {
                        history.goBack();
                      }}
                      variant="outline-primary"
                      size="lg"
                    >
                      목록
                    </Button>
                    <Button
                      size="lg"
                      variant="primary"
                      onClick={e => {
                        const target = e.currentTarget;
                        
                        Swal.fire({
                          title: '알림',
                          text: '제출 하시겠습니까?',
                          confirmButtonText: '확인',
                          cancelButtonText: '취소',
                          showCancelButton: true,
                          reverseButtons: true,
                        }).then(result => {
                          if (result.isConfirmed) {
                            updateRequest(target, approveStateType);
                          }
                        });                        
                      }}
                    >
                      제출
                    </Button>
                  </div>
                )}
              {authType !== ROLE.ADMIN &&
                reportInfo.status !== FormStatus.CON.value &&
                reportInfo.status !== FormStatus.REQ.value && (
                  <div className="btn-area mb-0">
                    <Button
                      onClick={() => {
                        history.goBack();
                      }}
                      variant="outline-primary"
                      size="lg"
                    >
                      목록
                    </Button>
                  </div>
                )}
              {authType === ROLE.ADMIN && (
                <div className="btn-area mb-0">
                  <Button
                    onClick={() => {
                      history.goBack();
                    }}
                    variant="outline-primary"
                    size="lg"
                  >
                    목록
                  </Button>
                </div>
              )}
            </Card.Body>
          </Card>
        </article>
      </Container>

      {/* PDF 미리보기 모달 */}
      <PreviewModal
        className="preview-modal"
        title="승인이수"
        show={previewModalShow}
        onHide={closePreviewModal}
      >
        <Modal.Body className="border-0 text-center">
          <PdfPreview 
            fileUrl={fileUrl}
            loadHandler={onDocumentLoadSuccess}
            pageNum={pdfPageNum}
            totalPages={pdfTotalPages}
            isPageDisabled={false}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={closePreviewModal}
          >
            닫기
          </Button>
        </Modal.Footer>
      </PreviewModal>

    </main>
  );
});
