/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import {
  Container,
  Table,
  Row,
  Col,
  Button,
  Card,
  Form,
  Modal,
} from 'react-bootstrap';
import { Utils } from '@common';
import { FormStatus } from '@common/consts';
import { CustomSelect, CustomModal } from '@components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDaumPostcodePopup } from 'react-daum-postcode';
import Swal from 'sweetalert2';
import { GENDER_TYPE_LISTS } from '@common/consts';

//* API
import { selectStudentDetail } from '@api/teacher/studentMgmt';
import { updateStudentInfo } from '@api/teacher/studentMgmt';

//* 반 & 번호 드랍다운 리스트
const classDropdownList = Utils.createClassDropdownList(20);
const studentNumDropdownList = Utils.createClassDropdownList(50);

export default React.memo(function StudentEdit(props) {
  const history = useHistory();
  const { seq } = useParams();

  // 제대로 된 페이지로 들어왔는지 확인
  if (!window.parseInt(seq)) {
    history.replace('/teacher/studentMgmt/studentMgmt');
  }

  //* #################################################################
  //* [ Modal ]
  //* #################################################################
  // 모달 - 저장
  const [saveModalShow, setSaveModalShow] = useState(false);
  const openSaveModal = () => {
    setSaveModalShow(true);
  };
  const closeSaveModal = () => {
    setSaveModalShow(false);
    history.push(`/teacher/studentMgmt/statusList/detail/${seq}`);
  };
  const submitSaveModal = () => {
    closeSaveModal();
  };

  // 모달 - 취소
  const [cancelModalShow, setCancelModalShow] = useState(false);
  const openCancelModal = () => {
    setCancelModalShow(true);
  };
  const closeCancelModal = () => {
    setCancelModalShow(false);
  };
  const submitCancelModal = () => {
    closeCancelModal();
    history.goBack();
  };

  //* #################################################################
  //* [ State ]
  //* #################################################################
  // 학생 정보 전체
  const [studentInfo, setStudentInfo] = useState({});

  // 프로그램 리스트
  const [programs, setPrograms] = useState([]);

  // 이름
  const [name, setName] = useState();

  // 학교명
  const [schoolOptions, setSchoolOptions] = useState([
    { label: '선택하세요.', value: null },
  ]);

  // 전공
  const [depart, setDepart] = useState();

  // 생년월일
  const [birthDate, setBirthDate] = useState(new Date());

  // 학년
  const [gradeOptions, setGradeOptions] = useState([
    { label: '선택하세요.', value: null },
  ]);

  // 반
  const [banNo, setBanNo] = useState(null);

  // 번호
  const [studentNo, setStudentNo] = useState(null);

  // 성별
  const [sex, setSex] = useState();

  // 주소
  const [address, setAddress] = useState();
  const [addressDetail, setAddressDetail] = useState();

  // 휴대폰 번호
  const [phoneNo1, setPhoneNo1] = useState();
  const [phoneNo2, setPhoneNo2] = useState();
  const [phoneNo3, setPhoneNo3] = useState();

  // 이메일
  const [email, setEmail] = useState();

  // 상태
  const [status, setStatus] = useState();
  const [statusOptions, setStatusOptions] = useState(null);

  //* #################################################################
  //* [ API ] 학생정보 조회
  //* #################################################################
  const getStudentInfo = async () => {
    const paramMap = {
      student_idx: seq,
      options: true,
    };

    // Axios
    try {
      const { data } = await selectStudentDetail(paramMap);

      if (data.code === 0) {
        const studentData = data.data.studentInfo;

        if (data.data.studentInfo) {
          setStudentInfo(studentData);

          // 이름
          setName(Utils.decrypt(studentData.student_name));

          // 학교 이름
          setSchoolOptions([
            {
              label: studentData.school_name,
              value: studentData.school_name,
            },
          ]);

          // 전공
          setDepart(studentData.student_depart);

          // 학년
          setGradeOptions([
            {
              label: studentData.grade,
              value: studentData.grade,
            },
          ]);

          // 반
          studentData?.ban_no
            ? setBanNo({
                label: +studentData.ban_no,
                value: studentData.ban_no,
              })
            : null;

          // 번호
          studentData?.student_no
            ? setStudentNo({
                label: +studentData.student_no,
                value: studentData.student_no,
              })
            : null;

          // 생년월일
          setAddress(Utils.decrypt(studentData.addr1));
          setAddressDetail(Utils.decrypt(studentData.addr2));
          if (studentData.student_birth) {
            setBirthDate(
              new Date(
                Utils.dateHypenFormat(Utils.decrypt(studentData.student_birth)),
              ),
            );
          }

          // 성별
          setSex(studentData.sex);

          // 휴대폰 번호
          if (studentData.phone_no) {
            const phoneNo = Utils.decrypt(studentData.phone_no);

            const no1 = phoneNo.substring(0, 3);
            const no2 = phoneNo
              .substring(3, phoneNo.length - 4)
              .replaceAll('-', '');
            const no3 = phoneNo.substring(phoneNo.length - 4);
            setPhoneNo1(no1);
            setPhoneNo2(no2);
            setPhoneNo3(no3);
          }

          // 이메일
          if (studentData.e_mail) {
            setEmail(Utils.decrypt(studentData.e_mail));
          }
        }
        // 프로그램  리스트 갱신
        setPrograms(data.data.programList);

        // 상태 옵션 리스트
        if (data.data.statusList) {
          setStatusOptions(data.data.statusList);
          const findStatus = data.data.statusList.find(item => {
            return `${item.value}` === `${studentData.status}`;
          });
          setStatus(findStatus);
        }
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 학생 정보 수정
  //* #################################################################
  const modifyStudentInfo = async event => {
    const target = event.currentTarget;
    target.disabled = true;

    if (!name) {
      Swal.fire({
        text: '이름을 입력해주시기 바랍니다.',
        confirmButtonText: '확인',
      });
      target.disabled = false;
      return;
    }

    if (!depart) {
      Swal.fire({
        text: '전공을 입력해주시기 바랍니다.',
        confirmButtonText: '확인',
      });
      target.disabled = false;
      return;
    }

    if (!banNo || !studentNo) {
      Swal.fire({
        text: '반/번호를 입력해주시기 바랍니다.',
        confirmButtonText: '확인',
      });
      target.disabled = false;
      return;
    }

    if (!phoneNo1 || !phoneNo2 || !phoneNo3) {
      Swal.fire({
        text: '연락처를 입력해주시기 바랍니다.',
        confirmButtonText: '확인',
      });
      target.disabled = false;
      return;
    }

    // 휴대폰 번호 체크
    const phoneNoFull = `${phoneNo1}-${phoneNo2}-${phoneNo3}`;

    if (!Utils.phoneNumberTest(phoneNoFull)) {
      Swal.fire({
        text: '연락처 형식을 확인해주시기 바랍니다.',
        confirmButtonText: '확인',
      });
      target.disabled = false;
      return;
    }

    // 이메일 체크
    if (email && !Utils.emailTest(email)) {
      Swal.fire({
        text: '이메일 형식을 확인해주시기 바랍니다.',
        confirmButtonText: '확인',
      });
      target.disabled = false;
      return;
    }

    const params = {
      student_idx: seq,
      student_name: Utils.encrypt(name),
      student_depart: depart,
      student_birth: Utils.encrypt(Utils.dateToString(birthDate)),
      ban_no: banNo.value,
      student_no: studentNo.value,
      sex,
      addr1: Utils.encrypt(address),
      addr2: Utils.encrypt(addressDetail),
      phone_no: Utils.encrypt(phoneNoFull),
      e_mail: Utils.encrypt(email),
      status: status.value,
    };

    try {
      const { data } = await updateStudentInfo(params);
      if (data.code === 0) {
        openSaveModal();
      }
    } catch (e) {
      // alert(e.response.data.message);
      target.disabled = false;
    }

    target.disabled = false;
  };

  //* #################################################################
  //* [ API ] Daum 주소검색
  //* #################################################################
  const open = useDaumPostcodePopup();

  const handleComplete = data => {
    let fullAddress = data.address;
    let extraAddress = '';

    // R : 도로명, J : 지번
    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }
    // console.log(data.zonecode); // 우편번호
    setAddress(fullAddress);
  };

  const searchAddress = () => {
    open({
      onComplete: handleComplete,
      defaultQuery: address,
    });
  };

  //* #################################################################
  //* [ useEffect ]
  //* #################################################################
  useEffect(() => {
    getStudentInfo();
  }, []);

  //* #################################################################
  //* [ Return ]
  //* #################################################################
  return (
    <main id="teacher-studentStatus-edit" className="type-02">
      <Container>
        <article className="content">
          <ul className="path">
            <li>
              <Link to="/teacher/studentMgmt/statusList">학생현황</Link>
            </li>
            <li>
              <Link to={`/teacher/studentMgmt/statusList/detail/${seq}`}>
                학생정보
              </Link>
            </li>
            <li>
              <Link to="#none">학생정보 수정</Link>
            </li>
          </ul>

          {/* ////////// ↓ 상단 타이틀영역 ////////// */}
          <section>
            <div className="title">
              <h5>학생정보 수정</h5>
            </div>
          </section>

          {/* ////////// ↓ 컨텐츠 ////////// */}
          {/* 학교정보 */}
          {studentInfo.seq && (
            <Card>
              {/* 이름 */}
              <Card.Header>
                <div className="split-table type-simple bp-none">
                  <Row className="table-row">
                    <Col className="th col-2">이름</Col>
                    <Col className="td col-10">
                      <div className="lw-input">
                        <Form.Control
                          placeholder="이름을 입력해주세요."
                          type="text"
                          onChange={e => {
                            setName(e.target.value);
                          }}
                          value={name || ''}
                          maxLength="50"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card.Header>

              <Card.Body>
                {/* 학교 정보 */}
                <section className="mb-5">
                  {/* 학교 정보 - 타이틀 */}
                  <div className="sub-title">
                    <h5>학교정보</h5>
                  </div>

                  {/* 학교 정보 - 바디 */}
                  <div className="split-table type-simple">
                    {/* 학교명 */}
                    <Row className="table-row">
                      <Col className="th col-2">학교</Col>
                      <Col className="td col-10">
                        <div className="lw-input">
                          <CustomSelect
                            options={schoolOptions}
                            value={schoolOptions[0] ? schoolOptions[0] : null}
                            isDisabled
                          />
                        </div>
                      </Col>
                    </Row>

                    {/* 전공 */}
                    <Row className="table-row">
                      <Col className="th col-2">전공</Col>
                      <Col className="td col-10">
                        <div className="lw-input">
                          <Form.Control
                            type="text"
                            placeholder="전공을 입력해주세요."
                            onChange={e => {
                              setDepart(e.target.value);
                            }}
                            value={depart || ''}
                            maxLength="22"
                          />
                        </div>
                      </Col>
                    </Row>

                    {/* 학년/반/번호 */}
                    <Row className="table-row">
                      {/* 라벨 */}
                      <Col className="th col-2">학년/반/번호</Col>

                      {/* 학년 */}
                      <Col className="td col-10">
                        <div className="sw-input smallest">
                          <CustomSelect
                            options={gradeOptions}
                            value={gradeOptions[0] ? gradeOptions[0] : null}
                            isDisabled
                          />
                        </div>

                        {/* 반 */}
                        <div className="sw-input smallest ms-2">
                          <CustomSelect
                            options={classDropdownList}
                            value={banNo}
                            placeholder="반"
                            onChange={setBanNo}
                          />
                        </div>

                        {/* 번호 */}
                        <div className="sw-input smallest ms-2">
                          <CustomSelect
                            options={studentNumDropdownList}
                            value={studentNo}
                            placeholder="번호"
                            onChange={setStudentNo}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </section>

                {/* 기본정보 */}
                <section className="mb-5">
                  {/* 기본정보 - 타이틀 */}
                  <div className="sub-title">
                    <h5>기본정보</h5>
                  </div>

                  {/* 기본정보 - 바디 */}
                  <div className="split-table type-simple">
                    {/* 생년월일 */}
                    <Row className="table-row">
                      <Col className="th col-2">생년월일</Col>
                      <Col className="td col-10">
                        <div className="mw-input">
                          <DatePicker
                            className="input-datepicker"
                            name="birth-date"
                            dateFormat="yyyy.MM.dd"
                            selected={birthDate}
                            onChange={date => setBirthDate(date)}
                            customInput={
                              <Form.Control style={{ width: '100%' }} />
                            }
                            maxDate={new Date()}
                            showPopperArrow={false}
                          />
                        </div>
                      </Col>
                    </Row>

                    {/* 성별 */}
                    <Row className="table-row">
                      <Col className="th col-2">성별</Col>
                      <Col className="td col-10">
                        <div className="radio-group">
                          {GENDER_TYPE_LISTS.map((item, index) => {
                            return (
                              <Form.Check
                                key={`gender-type-${item.value}`}
                                name="gender-type"
                                type="radio"
                                className="type-priamry"
                                label={item.label}
                                value={item.value}
                                id={item.value}
                                onChange={e => setSex(e.target.value)}
                                checked={sex === item.value}
                              />
                            );
                          })}
                        </div>
                      </Col>
                    </Row>

                    {/* 주소 */}
                    <Row className="table-row">
                      <Col className="th col-2">주소</Col>
                      <Col className="td col-10">
                        <div className="input-has-btn">
                          <Form.Control
                            type="text"
                            placeholder="주소를 검색하세요."
                            className="input-search"
                            onChange={e => {
                              setAddress(e.target.value);
                            }}
                            value={address || ''}
                            maxLength="50"
                          />
                          <Button
                            size="sm"
                            variant="primary"
                            onClick={e => {
                              searchAddress();
                            }}
                          >
                            검색
                          </Button>
                        </div>
                      </Col>
                    </Row>

                    {/* 상세주소 */}
                    <Row className="table-row">
                      <Col className="th col-2">상세주소</Col>
                      <Col className="td col-10">
                        <Form.Control
                          type="text"
                          placeholder="상세주소를 입력하세요."
                          onChange={e => {
                            setAddressDetail(e.target.value);
                          }}
                          value={addressDetail || ''}
                          maxLength="50"
                        />
                      </Col>
                    </Row>

                    {/* 연락처 */}
                    <Row className="table-row">
                      <Col className="th col-2">연락처</Col>
                      <Col className="td col-10">
                        <div className="input-phone">
                          <Form.Control
                            type="phone"
                            placeholder="000"
                            onChange={e => {
                              if (
                                window.parseInt(e.target.value) ||
                                window.parseInt(e.target.value) === 0 ||
                                e.nativeEvent.inputType ===
                                  'deleteContentBackward'
                              ) {
                                setPhoneNo1(e.target.value);
                              }
                            }}
                            value={phoneNo1 || ''}
                            maxLength="3"
                          />
                          <div className="hyphen">-</div>
                          <Form.Control
                            type="phone"
                            placeholder="0000"
                            onChange={e => {
                              if (
                                window.parseInt(e.target.value) ||
                                window.parseInt(e.target.value) === 0 ||
                                e.nativeEvent.inputType ===
                                  'deleteContentBackward'
                              ) {
                                setPhoneNo2(e.target.value);
                              }
                            }}
                            value={phoneNo2 || ''}
                            maxLength="4"
                          />
                          <div className="hyphen">-</div>
                          <Form.Control
                            type="phone"
                            placeholder="0000"
                            onChange={e => {
                              if (
                                window.parseInt(e.target.value) ||
                                window.parseInt(e.target.value) === 0 ||
                                e.nativeEvent.inputType ===
                                  'deleteContentBackward'
                              ) {
                                setPhoneNo3(e.target.value);
                              }
                            }}
                            value={phoneNo3 || ''}
                            maxLength="4"
                          />
                        </div>
                      </Col>
                    </Row>

                    {/* 이메일 */}
                    <Row className="table-row">
                      <Col className="th col-2">이메일</Col>
                      <Col className="td col-10">
                        <Form.Control
                          type="text"
                          placeholder="이메일을 입력하세요."
                          onChange={e => {
                            setEmail(e.target.value);
                          }}
                          value={email || ''}
                        />
                      </Col>
                    </Row>
                  </div>
                </section>

                {/* 바우처/점수현황 */}
                <section className="mb-5">
                  {/* 바우처/점수현황 - 타이틀 */}
                  <div className="sub-title">
                    <h5>바우처/점수현황</h5>
                  </div>

                  {/* 바우처/점수현황 - 바디 */}
                  <Table className="type-narrow">
                    <colgroup>
                      {programs.map((program, idx) => {
                        return <col key={idx} width="*" />;
                      })}
                      <col width={80} />
                      {/*<col width={80} />*/}
                      <col width={80} />
                    </colgroup>
                    <thead>
                      <tr>
                        {/* 학년별 컬럼 (프로그램) */}
                        {programs.map((program, idx) => {
                          return <th key={idx}>{program.program_name}</th>;
                        })}
                        {/* 바우처 대상점수 */}
                        <th>
                          바우처
                          <br />
                          대상점수
                        </th>
                        {/* 누계점수 */}
                        {/*<th>*/}
                        {/*  누계*/}
                        {/*  <br />*/}
                        {/*  점수*/}
                        {/*</th>*/}
                        {/* 바우처 */}
                        <th className="text-end">바우처</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {/* 학년별 프로그램 */}
                        {programs.map((program, i) => {
                          // 상태 변수
                          const statusVal =
                            studentInfo[
                              Utils.convertProgramData(program.program_id)
                                .status
                            ];

                          // 점수 변수
                          const point =
                            studentInfo[
                              Utils.convertProgramData(program.program_id).point
                            ];

                          // return
                          return (
                            <td
                              key={i}
                              // className="go-detail"
                              aria-hidden="true"
                              // onClick={() =>
                              //   props.history.push(
                              //     Utils.convertProgramData(program.program_id)
                              //       .url,
                              //   )
                              // }
                            >
                              {/* 상태 */}
                              <strong className={FormStatus[statusVal].class}>
                                {FormStatus[statusVal].text}
                              </strong>
                              <br />
                              {/* 점수 */}
                              {statusVal === 'CON' && (
                                <small>점수: {point}</small>
                              )}
                            </td>
                          );
                        })}

                        {/* 바우처 대상점수 */}
                        <td>{studentInfo.point_sum}</td>

                        {/* 누계 점수 */}
                        {/*<td>{studentInfo.total_point}</td>*/}

                        {/* 바우처 */}
                        <td className="text-end">
                          {Utils.numberComma(studentInfo.voucher_sum)}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </section>

                {/* 학생관리 */}
                <section className="mb-5">
                  {/* 학생관리 - 타이틀 */}
                  <div className="sub-title">
                    <h5>학생관리</h5>
                  </div>

                  {/* 학생관리 - 바디 */}
                  <div className="split-table type-simple">
                    <Row className="table-row">
                      <Col className="th col-2">상태</Col>
                      <Col className="td col-10">
                        <div className="mw-input">
                          <CustomSelect
                            options={statusOptions}
                            value={status}
                            onChange={setStatus}
                            placeholder="상태선택"
                          />
                        </div>
                      </Col>
                    </Row>
                    {/* <Row className="table-row">
                    <Col className="th col-2">비밀번호</Col>
                    <Col className="td col-10">
                      <Button variant="outline-primary">비밀번호 초기화</Button>
                    </Col>
                  </Row> */}
                  </div>
                </section>

                {/* ////////// ↓ 하단 버튼 //////////  */}
                <div className="btn-area mb-0">
                  <Button
                    size="lg"
                    variant="outline-primary"
                    onClick={openCancelModal}
                  >
                    취소
                  </Button>
                  <Button
                    size="lg"
                    variant="primary"
                    onClick={modifyStudentInfo}
                  >
                    저장
                  </Button>
                </div>
              </Card.Body>
            </Card>
          )}
        </article>

        {/* 모달 - 저장 */}
        <CustomModal
          show={saveModalShow}
          onHide={closeSaveModal}
          title="알림"
          size="xs"
        >
          <Modal.Body className="text-center border-0">
            저장되었습니다.
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={submitSaveModal}>확인</Button>
          </Modal.Footer>
        </CustomModal>

        {/* 모달 - 취소 */}
        <CustomModal
          show={cancelModalShow}
          onHide={closeCancelModal}
          title="알림"
          size="xs"
        >
          <Modal.Body className="text-center border-0">
            취소하시겠습니까?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={closeCancelModal}>
              취소
            </Button>
            <Button onClick={submitCancelModal}>확인</Button>
          </Modal.Footer>
        </CustomModal>
      </Container>
    </main>
  );
});
