/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useState, useRef, useEffect } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import {
  Container,
  Button,
  Row,
  Col,
  Form,
  Card,
  Image,
} from 'react-bootstrap';
import Utils from '@common/Utils';
import { images } from '@assets';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  selectFormPracticeAbilityInfo,
  upsertFormPracticeAbility,
} from '@api/student/visitIndustry';
import {
  FormStatus,
  PROGRAM_ID,
  RESEARCH_POINT_TYPE_LIST,
} from '@common/consts';
import Moment from 'react-moment';
import { useDaumPostcodePopup } from 'react-daum-postcode';
import Swal from 'sweetalert2';

export default React.memo(function VisitIndustryWrite(props) {
  const history = useHistory();

  // type
  const { seq } = useParams();
  if (seq && !window.parseInt(seq)) {
    history.replace('/home');
  }

  // datepicker
  const [visitDate, setVisitDate] = useState(new Date());

  // radio
  const [researchRadioResult, setResearchRadioResult] = useState({});

  // 학생 정보, 보고서 정보
  const [reportInfo, setReportInfo] = useState({});

  // 교사 코멘트
  const [teacherCommentList, setTeacherCommentList] = useState([]);

  // 리서치 정보
  const [researchList, setResearchList] = useState([]);

  // 방문업체명
  const [company, setCompany] = useState();

  // 방문업체주소
  const [address, setAddress] = useState();

  // 프로그램 내용
  const [contents, setContents] = useState();

  // 참여 후 느낀 점
  const [review, setReview] = useState();

  // 파일
  const [files, setFiles] = useState([]);
  const [initialFiles, setInitalFiles] = useState([]);
  const selectFile = useRef('');

  // 프로그램 ID
  const programId = PROGRAM_ID.PROGRAM_ID_FI_INTERVIEW;

  /** API */
  // [ API ] 보고서 정보 조회
  const getIndustryInfo = async () => {
    const params = {
      formSeq: seq || '0',
      program_id: programId,
      isUpsert: true,
    };
    try {
      const { data } = await selectFormPracticeAbilityInfo(params);

      if (data.code === 0) {
        // null이 반환되었다면 잘못된 요청으로 판단한다.
        if (!data.data) history.goBack();

        // 학생 정보, 보고서 정보
        const { info } = data.data;

        setReportInfo(info);

        // 교사 코멘트 리스트
        setTeacherCommentList(data.data.teacherCommentList);

        // 리서치 목록 구성
        setResearchList(data.data.researchList);

        if (seq) {
          // 보고서 정보 세팅
          if (info.visit_date) {
            setVisitDate(new Date(Utils.getJustDateString(info.visit_date)));
          }
          setCompany(info.visit_company);
          setContents(info.visit_contents);
          setReview(info.visit_review);
          setAddress(info.visit_address);
          setFiles([...data.data.files]);
          setInitalFiles([...data.data.files]);

          // 리서치 값 세팅
          settingResearchValue(info.research_idx, info.research_response);
        }
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  // [ API ] 취업역량강화 보고서 추가
  const upsertWork = async (event, status) => {
    const target = event.currentTarget;
    target.disabled = true;
    if (!inputConditionCheck(status)) {
      Swal.fire({
        text: '입력 제한을 확인해주시기 바랍니다.',
        confirmButtonText: '확인',
      });
      return false;
    }

    const allFileSeq = [...initialFiles.map(file => file.file_seq)];
    const saveFileSeq = [];
    const deleteFileSeq = [];

    // FormData
    const formData = new FormData();

    // 파일 append (File 타입 Only)
    for (let i = 0; i < files.length; i += 1) {
      // File 타입
      if (Object.getPrototypeOf(files[i]) === File.prototype) {
        formData.append('files', files[i]);
      }
      // Object 타입
      else {
        saveFileSeq.push(files[i].file_seq);
      }
    }

    // 삭제 파일 Seq 추출 (기존파일)
    // eslint-disable-next-line no-shadow
    allFileSeq.forEach(seq => {
      if (saveFileSeq.indexOf(seq) === -1) deleteFileSeq.push(seq);
    });
    // JSON 파라미터
    const params = {
      formSeq: seq || '0',
      program_id: programId,
      visit_date: Utils.dateToString(visitDate),
      visit_company: company,
      visit_address: address,
      visit_contents: contents,
      visit_review: review,
      status,
      deleteFileSeq,
      ...getResearchResponse(),
    };

    // JSON append
    formData.append(
      'param',
      new Blob([JSON.stringify(params)], { type: 'application/json' }),
    );

    try {
      const { data } = await upsertFormPracticeAbility(formData);
      if (data.code === 0) {
        history.push({
          pathname: `/student/indivAbility/report/visitIndustry/${
            data.data.formSeq || seq
          }`,
        });
      }
    } catch (e) {
      // alert(e.response.data.message);
      target.disabled = false;
    }
    target.disabled = false;
    return true;
  };

  /** API help function */
  // 입력 제한 조건 체크
  const inputConditionCheck = () => {
    if (
      !Utils.limitCheck(contents, 0, 1000) ||
      !Utils.limitCheck(review, 0, 1000)
    ) {
      return false;
    }
    // 리서치 조사를 한 경우에 대해서만 값 체크
    const researchKeys = Object.keys(researchRadioResult);
    if (researchKeys.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const key of researchKeys) {
        if (
          researchRadioResult[key] &&
          !Utils.rangeCheck(researchRadioResult[key], 1, 5)
        )
          return false;
      }
    }

    return true;
  };

  // 설문조사 값 설정
  const settingResearchValue = (researchIdx, researchResponse) => {
    if (researchIdx && researchResponse) {
      const idxs = researchIdx.split(';');
      const responses = researchResponse.split(';');
      const researchObject = {};
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const i in idxs) {
        researchObject[`${idxs[i]}`] = responses[i];
      }
      setResearchRadioResult(researchObject);
    }
  };

  // research_idx, research_response 문자열 생성
  const getResearchResponse = () => {
    const result = {};
    const researchKeys = Object.keys(researchRadioResult);
    if (researchKeys.length > 0) {
      result.research_idx = researchKeys.join(';');
      const resultValueList = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const key of researchKeys) {
        resultValueList.push(researchRadioResult[key]);
      }
      result.research_response = resultValueList.join(';');
    }
    return result;
  };

  // 파일 첨부
  const addFile = e => {
    if (files.length >= 2) {
      Swal.fire({
        text: '첨부 파일은 2개까지 등록할 수 있습니다.',
        confirmButtonText: '확인',
      });
      return;
    }
    if (e.target.files[0]) setFiles([...files, e.target.files[0]]);
    selectFile.current.value = null;
  };

  // 파일 삭제
  const removeFile = idx => {
    const filteredList = files.filter((file, index) => index !== idx);
    setFiles([...filteredList]);
  };

  // 파일 드래그 앤 드랍 :: 파일 클래스명 처리
  const fileDragHover = e => {
    e.stopPropagation();
    e.preventDefault();
    e.currentTarget.className =
      e.type === 'dragover' ? 'drag-box hover' : 'drag-box';
  };
  // 파일 드래그 앤 드랍 :: 파일 처리
  const fileSelectHandler = e => {
    // cancel event and hover styling
    fileDragHover(e);

    // fetch FileList object
    const insertFiles = e.target.files || e.dataTransfer.files;
    const totlaFilesLength = files.length;
    const insertFilesLength = insertFiles.length;

    if (totlaFilesLength >= 2) {
      Swal.fire({
        text: '첨부 파일은 2개까지 등록할 수 있습니다.',
        confirmButtonText: '확인',
      });
      return;
    }
    if (totlaFilesLength + insertFilesLength > 2) {
      Swal.fire({
        text: '첨부 파일은 2개까지 등록할 수 있습니다.',
        confirmButtonText: '확인',
      });
      return;
    }

    setFiles([...files, ...insertFiles]);
  };

  /** Daum 주소검색 API */
  const open = useDaumPostcodePopup();

  const handleComplete = data => {
    let fullAddress = data.address;
    let extraAddress = '';

    // R : 도로명, J : 지번
    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }
    // console.log(data.zonecode); // 우편번호
    setAddress(fullAddress);
  };

  const searchAddress = () => {
    open({
      onComplete: handleComplete,
      defaultQuery: address,
    });
  };

  /** useEffect */
  useEffect(() => {
    getIndustryInfo();
    return () => {
      setResearchList([]);
      setReportInfo({});
      setTeacherCommentList([]);
      setResearchList([]);
    };
  }, []);

  return (
    <main id="student-visitIndustry-write">
      <Container>
        <article className="content">
          <ul className="path">
            <li>
              <Link to="/">HOME</Link>
            </li>
            <li>
              <Link to="/student/indivAbility/visitIndustry">
                산업체 채용면접
              </Link>
            </li>
            <li>
              <Link to="#none">작성 페이지</Link>
            </li>
          </ul>

          {/* ////////// ↓ 상단 타이틀영역 ////////// */}
          <section>
            <div className="title">
              <h5>산업체 채용면접 결과 보고서</h5>
            </div>
          </section>

          {/* ////////// ↓ 컨텐츠 ////////// */}
          {/* 기본정보 */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>기본정보</h5>
            </div>
            <Card>
              <ul className="form-list">
                <li>
                  <h5>학번</h5>
                  <Form.Control
                    type="text"
                    defaultValue={reportInfo.student_id}
                    readOnly
                  />
                </li>
                <li>
                  <h5>이름</h5>
                  <Form.Control
                    type="text"
                    defaultValue={Utils.decrypt(reportInfo.student_name)}
                    readOnly
                  />
                </li>
              </ul>
            </Card>
          </section>

          {/* 산업체 참여 정보 */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>산업체 참여 정보</h5>
            </div>
            <Card>
              <ul className="form-list">
                <li>
                  <h5>방문일</h5>
                  <div className="mw-input">
                    <DatePicker
                      className="input-datepicker"
                      name="visit-date"
                      dateFormat="yyyy.MM.dd"
                      selected={visitDate}
                      onChange={date => setVisitDate(date)}
                      customInput={<Form.Control style={{ width: '100%' }} />}
                      // maxDate={new Date()}
                      showPopperArrow={false}
                    />
                  </div>
                </li>
                <li>
                  <h5>방문업체명</h5>
                  <Form.Control
                    type="text"
                    placeholder="방문업체명을 입력하세요"
                    onChange={e => {
                      setCompany(e.target.value);
                    }}
                    value={company || ''}
                  />
                </li>
                <li>
                  <h5>방문업체주소</h5>
                  <div className="input-has-btn">
                    <Form.Control
                      type="text"
                      placeholder="주소를 검색하세요."
                      className="input-search"
                      maxLength="100"
                      onChange={e => {
                        setAddress(e.target.value);
                      }}
                      value={address || ''}
                    />
                    <Button
                      size="sm"
                      variant="primary"
                      onClick={e => {
                        searchAddress();
                      }}
                    >
                      검색
                    </Button>
                  </div>
                </li>
              </ul>
            </Card>
          </section>

          {/* 참여활동 내용 */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>참여활동 내용</h5>
            </div>
            <Card>
              <Form.Control
                as="textarea"
                rows={5}
                placeholder="최소 1자이상 최대 1000자 이내로 입력하세요."
                maxLength="1000"
                onChange={e => {
                  setContents(e.target.value);
                }}
                value={contents || ''}
              />
              <small className="text-gray flex-end mt-2">
                {(contents && contents.length) || 0}/1000자
              </small>
            </Card>
          </section>

          {/* 참여 후 느낀점 */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>참여 후 느낀점</h5>
            </div>
            <Card>
              <Form.Control
                as="textarea"
                rows={5}
                placeholder="최소 1자이상 최대 1000자 이내로 입력하세요."
                maxLength="1000"
                onChange={e => {
                  setReview(e.target.value);
                }}
                value={review || ''}
              />
              <small className="text-gray flex-end mt-2">
                {(review && review.length) || 0}/1000자
              </small>
            </Card>
          </section>

          {/* 만족도 조사 */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>만족도 조사</h5>
            </div>
            <div className="split-table bt-none h-56">
              <Row className="table-row">
                <Col className="th col-2 text-gray">조사내용</Col>
                <Col className="th col-4 text-center text-gray">질문</Col>
                <Col className="th col-6">
                  <ul className="type-flex-1">
                    <li>매우 그렇다</li>
                    <li>그렇다</li>
                    <li>보통</li>
                    <li>그렇지 않다</li>
                    <li>매우 그렇지 않다</li>
                  </ul>
                </Col>
              </Row>
              {researchList &&
                researchList.length > 0 &&
                researchList.map((item, index) => {
                  return (
                    <Row className="table-row" key={`research-${index}`}>
                      <Col className="th col-2 text-gray">
                        {item.research_contents}
                      </Col>
                      <Col className="td col-4">{item.research_question}</Col>
                      <Col className="td col-6 flex-between">
                        <div className="radio-group type-flex-1">
                          {RESEARCH_POINT_TYPE_LIST.map(pointItem => {
                            return (
                              <Form.Check
                                key={`${index}-${pointItem.value}`}
                                name={`${index}`}
                                type="radio"
                                label=""
                                value={pointItem.value}
                                id={pointItem.value}
                                onChange={e => {
                                  setResearchRadioResult({
                                    ...researchRadioResult,
                                    [item.seq]: e.target.value,
                                  });
                                }}
                                checked={
                                  researchRadioResult[item.seq] ===
                                  pointItem.value
                                }
                              />
                            );
                          })}
                        </div>
                      </Col>
                    </Row>
                  );
                })}
            </div>
          </section>

          {/* 파일 첨부 */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>파일 첨부</h5>
              <Button
                onClick={() => selectFile.current.click()}
                variant="outline-primary"
                size="md"
              >
                파일첨부
              </Button>
              <Form.Control
                ref={selectFile}
                onChange={addFile}
                type="file"
                // accept="image/*, .pdf"
                style={{ display: 'none' }}
              />
            </div>
            {files.length === 0 ? (
              <Card>
                {/* // ↓ 파일 첨부 */}
                <div
                  className="drag-box"
                  draggable
                  onDragOver={e => {
                    fileDragHover(e);
                  }}
                  onDragLeave={e => {
                    fileDragHover(e);
                  }}
                  onDrop={e => {
                    fileSelectHandler(e);
                  }}
                >
                  <p>파일을 마우스로 끌어 오세요.</p>
                </div>
              </Card>
            ) : (
              <Card>
                {/* // ↓ 첨부된 파일 */}
                <ul className="drag-attachment-list">
                  {files.map((file, idx) => {
                    return (
                      <li key={idx}>
                        <p>{file.name || file.file_name_org}</p>
                        <Button variant="iconOnly">
                          <Image
                            src={images.icCancel}
                            onClick={() => removeFile(idx)}
                          />
                        </Button>
                      </li>
                    );
                  })}
                </ul>
              </Card>
            )}
          </section>

          {/* 교사 코멘트 */}
          {reportInfo.status === FormStatus.REJ.value && (
            <section className="mb-5">
              <div className="sub-title flex-start">
                <h5 className="text-primary">교사 코멘트</h5>
              </div>
              <div className="comment-box type-primary">
                <ul className="comment-list">
                  {teacherCommentList.length > 0 &&
                    teacherCommentList.map((item, index) => {
                      return (
                        <li key={`comment-${index}`}>
                          <p className="date">
                            {item.reg_date && (
                              <Moment
                                format="YYYY.MM.DD"
                                date={item.reg_date}
                              />
                            )}
                          </p>
                          <p className="text">{item.comment}</p>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </section>
          )}

          {/* ////////// ↓ 하단 버튼(신규작성/임시저장) //////////  */}
          <div className="btn-area">
            <Button
              size="lg"
              variant="outline-primary"
              onClick={e => {
                upsertWork(e, FormStatus.ING.value);
              }}
            >
              저장
            </Button>
          </div>
        </article>
      </Container>
    </main>
  );
});
