import Axios from '@api/axios';

const API_VOUCHER = 'api/v1/edu/voucher';

const formDataConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

// [바우처 관리 > 바우처 조회] 바우처 리스트 조회
export const getVoucherList = data => {
  return Axios.get(`${API_VOUCHER}/list`, { params: data });
};

// [바우처 관리 > 바우처 조회] 바우처 할당 내용 조회
export const getVoucherAssignData = () => {
  return Axios.get(`${API_VOUCHER}/assign-data`);
};

// [바우처 관리 > 바우처 조회] 개별 바우처 할당금액 수정
// export const patchVoucher = data => {
//   return Axios.patch(`${API_VOUCHER}/save-one`, data);
// };

// [바우처 관리 > 바우처 조회] 바우처 일괄 할당
export const patchVoucherAll = () => {
  return Axios.patch(`${API_VOUCHER}/save-all`);
};

// [바우처 관리 > 바우처 지급 현황] 바우처 지급 현황 리스트 조회
export const getVoucherBatchList = () => {
  return Axios.get(`${API_VOUCHER}/batch-list`);
};

// [바우처 관리 > 바우처 지급 현황] 바우처 지급 현황 상세 조회
export const getVoucherBatchDetail = schoolIdx => {
  return Axios.get(`${API_VOUCHER}/batch/${schoolIdx}`);
};

// [바우처 관리 > 바우처 지급 현황] 바우처 지급내역 다운로드 ( .txt )
export const downloadVoucherTxt = data => {
  return Axios.post(`${API_VOUCHER}/download/voucher-txt`, data, {
    responseType: 'blob',
  });
};

// [바우처 관리 > 바우처 지급 현황] 바우처 지급내역 다운로드 ( .xlsx )
export const downloadVoucherExcel = data => {
  return Axios.post(`${API_VOUCHER}/download/voucher-excel`, data, {
    responseType: 'blob',
  });
};

// [바우처 관리 > 바우처 점수 통계] 바우처 점수 통계 조회
export const getStatistics = nth => {
  return Axios.get(`${API_VOUCHER}/statistics/${nth}`);
};

// [바우처 관리 > 바우처 점수 관리] 바우처 점수 관리 조회
export const getPoints = () => {
  return Axios.get(`${API_VOUCHER}/points`);
};

// [바우처 관리 > 바우처 점수 관리] 바우처 점수 관리 수정
export const patchPoints = data => {
  return Axios.patch(`${API_VOUCHER}/points`, data);
};
