/* eslint-disable no-undef */
/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { css } from '@emotion/react';

function CustomDropdown({
  value,
  selected,
  setSelected,
  options,
  disabled,
  typeDark,
  typeWhite,
  typeFull,
  typeMd,
  typeSm,
  typeLanguage,
  selectedValue,
  setSelectedValue,
  selectedLabelAndValue,
}) {
  const [isActive, setIsActive] = useState(false);
  return (
    <Dropdown className="dropdown">
      <Dropdown.Toggle value={value} id="dropdown-basic" disabled={disabled}>
        {selected}
      </Dropdown.Toggle>

      <Dropdown.Menu className="m-0">
        {options.map((option, index) => (
          <Dropdown.Item
            className={selected === option.label && 'selected'}
            key={option.value}
            onClick={e => {
              setSelected(option.label);
              setIsActive(false);
              if (setSelectedValue) setSelectedValue(option.value);
              if (selectedLabelAndValue) selectedLabelAndValue(option);
            }}
          >
            {option.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default CustomDropdown;
