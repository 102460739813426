/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { Container, Form, Button, Card, Table } from 'react-bootstrap';
import { CustomSelect } from '@components';
import Moment from 'react-moment';
import ReactPaginate from 'react-paginate';
import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import classNames from 'classnames';
import { deleteNotice, getNoticeList } from '../../../api/board/notice';
import { deleteArchive, getArchiveList } from '../../../api/board/archive';

const searchFilterOptions = [
  {
    label: '내용',
    value: 'CONTENT',
  },
  {
    label: '제목',
    value: 'TITLE',
  },
  {
    label: '내용+제목',
    value: 'ALL',
  },
];

export default React.memo(function AdmEduPostMgmt(props) {
  const history = useHistory();

  const { boardId } = useParams();

  const refresh =
    (props.history.location.state && props.history.location.state.refresh) ||
    'refresh';

  const pageRefresh =
    (history.location.state && history.location.state.pageRefresh) || false;

  const page = (history.location.state && history.location.state.page) || 1;

  const keyword =
    (history.location.state && history.location.state.keyword) || '';

  const searchType =
    (history.location.state && history.location.state.searchType) ||
    searchFilterOptions[0];

  const [totalPages, setTotalPages] = useState();
  const [firstCallDone, setFirstCallDone] = useState(true);
  const [size, setSize] = useState(10);
  const [searchedKeyword, setSearchedKeyword] = useState(keyword || '');
  const [selectedSearchType, setSelectedSearchType] = useState(
    searchType || searchFilterOptions[0],
  );
  const [noticeList, setNoticeList] = useState([]);

  const getNotices = async () => {
    const params = {
      page,
      size,
      keyword,
      searchType: searchType.value,
    };
    setFirstCallDone(false);
    const { data } = await getNoticeList(params);
    if (data.code === 0) {
      setTotalPages(data.data.totalPages);
      setNoticeList(data.data.list);
      setFirstCallDone(true);
    }
  };

  const getArchives = async () => {
    const params = {
      page,
      size,
      keyword,
      searchType: searchType.value,
    };
    setFirstCallDone(false);
    const { data } = await getArchiveList(params);
    if (data.code === 0) {
      setTotalPages(data.data.totalPages);
      setNoticeList([...data.data.list]);
      setFirstCallDone(true);
    }
  };

  const movePage = (pageNum, isSearch) => {
    const state = {
      page: isSearch ? 1 : pageNum,
      keyword: isSearch ? searchedKeyword : keyword,
      searchType: isSearch ? selectedSearchType : searchType,
      pageRefresh: !pageRefresh,
    };

    history.push({
      pathname: `/admEdu/post/${boardId}/list`,
      state,
    });
  };

  const settingState = () => {
    setSearchedKeyword(keyword || '');
    setSelectedSearchType(searchType || searchFilterOptions[0]);
  };

  const removeNotice = async seq => {
    const { data } = await deleteNotice(seq);
    if (data.code === 0) {
      Swal.fire({
        text: '정상적으로 삭제 되었습니다.',
        confirmButtonText: '확인',
      }).finally(() => {
        movePage(page);
      });
    }
  };

  const removeArchive = async seq => {
    const { data } = await deleteArchive(seq);
    if (data.code === 0) {
      Swal.fire({
        text: '정상적으로 삭제 되었습니다.',
        confirmButtonText: '확인',
      }).finally(() => {
        movePage(page);
      });
    }
  };

  // 게시판 id 확인
  const [thisBoard, setThisBoard] = useState({});
  const handleBoard = id => {
    switch (id) {
      case 'notice':
        setThisBoard({
          name: '공지사항',
        });
        settingState();
        getNotices();
        break;
      case 'eduArchive':
        setThisBoard({
          name: '교육자료실',
        });
        settingState();
        getArchives();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    handleBoard(boardId);
  }, [boardId, pageRefresh]);

  useEffect(() => {
    settingState();
  }, [refresh]);

  // 검색필터
  const [searchFilter, setSearchFilter] = useState(searchFilterOptions[0]);

  return (
    <main id="admEdu-post-mgmt" className="type-02">
      <Container>
        <article className="content py">
          <section>
            <div className="title">
              <h5>{thisBoard.name}</h5>
            </div>

            <Card>
              <Card.Header>
                <div className="search-box">
                  <div className="select-inside-box">
                    <CustomSelect
                      options={searchFilterOptions}
                      value={selectedSearchType}
                      onChange={setSelectedSearchType}
                    />
                  </div>
                  <div className="input-has-btn ms-2">
                    <Form.Control
                      type="text"
                      placeholder={`${selectedSearchType.label}을 검색하세요.`}
                      className="input-search border-0"
                      value={searchedKeyword}
                      onChange={e => {
                        setSearchedKeyword(e.target.value);
                      }}
                    />
                    <Button
                      size="sm"
                      variant="primary"
                      onClick={() => {
                        movePage(1, true);
                      }}
                    >
                      검색
                    </Button>
                  </div>
                </div>
              </Card.Header>
              <Card.Body className="pt-0">
                <div className="btn-area justify-content-end m-0">
                  <Button
                    variant="primary"
                    onClick={() => {
                      props.history.push({
                        pathname: `/admEdu/post/${boardId}/write`,
                        state: { thisBoard, writeType: 'reg' },
                      });
                    }}
                  >
                    글쓰기
                  </Button>
                </div>
                <Table className="bt-none mt-3">
                  <colgroup>
                    <col width={80} />
                    <col width="*" />
                    <col width={120} />
                    <col width={120} />
                    <col width={120} />
                    <col width={160} />
                    <col width={120} />
                    <col width={180} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>순번</th>
                      <th>제목</th>
                      <th>작성자</th>
                      <th>메인등록</th>
                      <th>공개대상</th>
                      <th>작성일</th>
                      <th>조회수</th>
                      <th>관리</th>
                    </tr>
                  </thead>
                  <tbody>
                    {noticeList.length > 0 ? (
                      noticeList.map(item => {
                        return (
                          <tr key={`post-${item.seq}`} className="go-detail">
                            <td>{item.seq}</td>
                            <td
                              className="text-start"
                              onClick={() => {
                                props.history.push({
                                  pathname: `/admEdu/post/${boardId}/detail/${item.seq}`,
                                });
                              }}
                            >
                              {/* <p
                                className={classNames('ellipsis', {
                                  attatchment: item.fileCnt && item.fileCnt > 0,
                                })}
                              >
                                {item.title}
                              </p> */}
                              <p>{item.title}</p>
                            </td>
                            <td>{item.writer}</td>
                            <td>{item.mainYn}</td>
                            <td>
                              {item.publicYn === 'Y'
                                ? '학생'
                                : item.publicYn === 'N'
                                ? '학교'
                                : '전체'}
                            </td>

                            <td>
                              <Moment date={item.regDate} format="YYYY.MM.DD" />
                            </td>
                            <td>{item.viewCnt}</td>
                            <td>
                              <div className="btn-area">
                                <Button
                                  variant="outline-primary"
                                  onClick={() => {
                                    if (boardId === 'notice') {
                                      removeNotice(item.seq);
                                    } else {
                                      removeArchive(item.seq);
                                    }
                                  }}
                                >
                                  삭제
                                </Button>
                                <Button
                                  variant="primary"
                                  onClick={() => {
                                    props.history.push({
                                      pathname: `/admEdu/post/${boardId}/write`,
                                      state: {
                                        thisBoard,
                                        writeType: 'modify',
                                        boardSeq: item.seq,
                                      },
                                    });
                                  }}
                                >
                                  수정
                                </Button>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={8}>
                          <p className="no-data">데이터가 없습니다.</p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                <ReactPaginate
                  pageRangeDisplayed={3}
                  nextLabel=""
                  previousLabel=""
                  breakLabel="..."
                  pageCount={totalPages}
                  renderOnZeroPageCount={null}
                  containerClassName="pagination mt-4"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  activeClassName="active"
                  onPageChange={e => {
                    movePage(e.selected + 1);
                  }}
                  forcePage={page ? page - 1 : 0}
                />
              </Card.Body>
            </Card>
          </section>
        </article>
      </Container>
    </main>
  );
});
