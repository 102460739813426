/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  Container,
  Button,
  Row,
  Col,
  Table,
  ToggleButtonGroup,
  ToggleButton,
  Form,
} from 'react-bootstrap';

//* API
import {
  selectNcsInfo,
  selectNcsInfoByCode,
  insertUpdateNcs,
} from '@api/student/ncs';

// 카테고리 선택 초기값
const initialCateCodeMap = {
  mainCate: '', // 선택 대분류
  middleCate: '', // 선택 중분류
  smallCate: '', // 선택 소분류
  detailCate: '', // 선택 세분류
};

//* Main
export default React.memo(function IndAbilityNcsRegister(props) {
  //* #################################################################
  //* [ States ]
  //* #################################################################
  const [allListByMainCode, setAllListByMainCode] = useState([]); // 대분류 하위 전체 리스트

  const [mainCodeList, setMainCodeList] = useState([]); // 대분류 코드 리스트
  const [middleCodeList, setMiddleCodeList] = useState([]); // 중분류 코드 리스트
  const [smallCodeList, setSmallCodeList] = useState([]); // 소분류 코드 리스트
  const [detailCodeList, setDetailCodeList] = useState([]); // 세분류 코드 리스트
  const [learnModuleList, setLearnModuleList] = useState([]); // 능력단위(학습모듈) 리스트

  const [cateCodeMap, setCateCodeMap] = useState(initialCateCodeMap); // 카테고리 선택값 (대분류, 중분류, 소분류, 세분류)

  const [isSearched, setIsSearched] = useState(false); // 검색 완료 Flag

  const [checkedList, setCheckedList] = useState([]);

  //* #################################################################
  //* [ Utils ]
  //* #################################################################
  // ------------------------------------------------------------
  // 대분류 선택 > 중분류 출력
  // ------------------------------------------------------------
  const handleMainCate = code => {
    setIsSearched(false);

    getNcsInfoByCode(code); // API
  };

  // ------------------------------------------------------------
  // 중분류 선택 > 소분류 출력
  // ------------------------------------------------------------
  const handleMiddleCate = code => {
    setIsSearched(false);

    // 선택값 초기화
    cateCodeMap.middleCate = code;
    cateCodeMap.smallCate = '';
    cateCodeMap.detailCate = '';
    setCateCodeMap({ ...cateCodeMap });

    // 소분류 출력 (데이터 필터링)
    const filteredList = allListByMainCode
      .filter(
        item =>
          item.ncsLclasCd === cateCodeMap.mainCate && item.ncsMclasCd === code,
      )
      .map(item => {
        return { code: item.ncsSclasCd, codeText: item.ncsSclasCdnm };
      });

    setSmallCodeList(removeDupValue(filteredList));
  };

  // ------------------------------------------------------------
  // 소분류 선택 > 세분류 출력
  // ------------------------------------------------------------
  const handleSmallCate = code => {
    setIsSearched(false);

    // 선택값 초기화
    cateCodeMap.smallCate = code;
    cateCodeMap.detailCate = '';
    setCateCodeMap({ ...cateCodeMap });

    // 세분류 출력 (데이터 필터링)
    const filteredList = allListByMainCode
      .filter(
        item =>
          item.ncsLclasCd === cateCodeMap.mainCate &&
          item.ncsMclasCd === cateCodeMap.middleCate &&
          item.ncsSclasCd === code,
      )
      .map(item => {
        return { code: item.ncsSubdCd, codeText: item.ncsSubdCdnm };
      });

    setDetailCodeList(removeDupValue(filteredList));
  };

  // ------------------------------------------------------------
  // 세분류 선택
  // ------------------------------------------------------------
  const handleDetailCate = code => {
    // 선택값 초기화
    cateCodeMap.detailCate = code;
    setCateCodeMap({ ...cateCodeMap });
    setCheckedList([]);

    // 세분류 출력 (데이터 필터링)
    const filteredList = allListByMainCode.filter(
      item =>
        item.ncsLclasCd === cateCodeMap.mainCate &&
        item.ncsMclasCd === cateCodeMap.middleCate &&
        item.ncsSclasCd === cateCodeMap.smallCate &&
        item.ncsSubdCd === code,
    );
    // .map(item => {
    //   return {
    //     code: item.learnModulSeq,
    //     codeText: item.learnModulName,
    //     codeDetail: item.learnModulText,
    //   };
    // });

    setLearnModuleList(removeDupValue(filteredList));

    setIsSearched(true);
  };

  // ------------------------------------------------------------
  // 선택값 리셋
  // ------------------------------------------------------------
  const resetHandler = () => {
    setIsSearched(false);

    setMiddleCodeList([]);
    setSmallCodeList([]);
    setDetailCodeList([]);
    setLearnModuleList([]);

    setCateCodeMap(initialCateCodeMap);
  };

  // ------------------------------------------------------------
  // 중복제거
  // ------------------------------------------------------------
  const removeDupValue = list => {
    const map = new Map();
    // eslint-disable-next-line no-restricted-syntax
    for (const item of list) {
      map.set(JSON.stringify(item), item);
    }

    return [...map.values()];
  };

  // ------------------------------------------------------------
  // 능력단위(학습모듈) 체크 & 체크해제
  // ------------------------------------------------------------
  const checkHandler = (data, isChecked) => {
    const copyList = [...checkedList];

    // 체크 > 목록 추가
    if (isChecked) {
      copyList.push(data);
      setCheckedList(copyList);
    }

    // 체크 해제 > 목록 제거
    else {
      setCheckedList(
        copyList.filter(item => item.learnModulSeq !== data.learnModulSeq),
      );
    }
  };

  //* #################################################################
  //* [ API ] 대분류 리스트 가져오기
  //* #################################################################
  const getNcsInfo = async () => {
    // Axios
    try {
      const { data } = await selectNcsInfo();

      if (data.code === 0) {
        // 선택값 초기화
        setAllListByMainCode([]);

        cateCodeMap.mainCate = '';
        cateCodeMap.middleCate = '';
        cateCodeMap.smallCate = '';
        cateCodeMap.detailCate = '';
        setCateCodeMap({ ...cateCodeMap });

        // 대분류 리스트 갱신
        setMainCodeList([...data.data]);
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 대분류 하위 리스트 가져오기
  //* #################################################################
  const getNcsInfoByCode = async code => {
    // 선택값 갱신 & 초기화
    cateCodeMap.mainCate = code;
    cateCodeMap.middleCate = '';
    cateCodeMap.smallCate = '';
    cateCodeMap.detailCate = '';
    setCateCodeMap({ ...cateCodeMap });

    // Axios
    try {
      const { data } = await selectNcsInfoByCode({ code });

      if (data.code === 0) {
        // 데이터 후처리
        const list = data.data.map(item => {
          return { code: item.ncsMclasCd, codeText: item.ncsMclasCdnm };
        });

        // 대분류 하위 리스트 갱신
        setMiddleCodeList(removeDupValue(list));
        setAllListByMainCode([...data.data]);
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 선택추가
  //* #################################################################
  const addModules = async () => {
    // 선택된 능력단위(학습모듈)가 없을 경우
    if (checkedList.length < 1) {
      Swal.fire({
        text: '능력단위(학습모듈)를 선택해주세요.',
        confirmButtonText: '확인',
      });
      return;
    }

    // NCS 파라미터 전처리
    const ncsList = checkedList.map(item => {
      return {
        l_code: item.ncsLclasCd, // 대분류 코드명
        l_code_name: item.ncsLclasCdnm, // 대분류 코드명
        m_code: item.ncsMclasCd, // 중분류 코드
        m_code_name: item.ncsMclasCdnm, // 중분류 코드명
        s_code: item.ncsSclasCd, // 세분류 코드
        s_code_name: item.ncsSclasCdnm, // 세분류 코드명
        sub_code: item.ncsSubdCd, // 소분류 코드
        sub_code_name: item.ncsSubdCdnm, // 소분류 코드명
        learn_module_seq: item.learnModulSeq, // 능력단위(학습모듈) SEQ
        learn_module_name: item.learnModulName, // 능력단위(학습모듈)명
        learn_module_text: item.learnModulText, // 능력단위(학습모듈) 상세
      };
    });

    const paramMap = {
      status: 'ING',
      ncsList,
    };

    // Axios
    try {
      const { data } = await insertUpdateNcs(paramMap);

      if (data.code === 0) {
        props.history.push('/student/indivAbility/ncs');
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ useEffect ]
  //* #################################################################
  useEffect(() => {
    getNcsInfo();
  }, []);

  //* #################################################################
  //* [ return ]
  //* #################################################################
  return (
    <main id="student-ncs-register">
      <Container>
        <article className="content">
          <ul className="path">
            <li>
              <Link to="/">HOME</Link>
            </li>
            <li>
              <Link to="#none">자기역량관리</Link>
            </li>
            <li>
              <Link to="#none">NCS관리</Link>
            </li>
            <li>
              <Link to="#none">NCS 및 능력단위검색</Link>
            </li>
          </ul>

          {/* ////////// ↓ 상단 타이틀영역 ////////// */}
          <section>
            <div className="title">
              <h5>NCS 신규추가</h5>
            </div>
          </section>

          {/* ////////// ↓ 컨텐츠 ////////// */}
          <section>
            <div className="sub-title">
              <h5>분야별 검색</h5>
            </div>
            <ToggleButtonGroup
              type="radio"
              name="mainCate"
              value={cateCodeMap.mainCate}
              onChange={handleMainCate}
              className="mainCate-search"
            >
              {mainCodeList.map((item, idx) => {
                return (
                  <ToggleButton
                    id={`mainCate-${item.code}`}
                    key={`mainCate-${item.code}`}
                    value={item.code}
                    variant="light"
                    checked={
                      cateCodeMap.mainCate && item.code === cateCodeMap.mainCate
                    }
                  >
                    {`${item.code}. ${item.codeText}`}
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          </section>

          {cateCodeMap.mainCate && (
            <section className="sec-subcategory mt-3">
              {/* 선택된 대분류 */}
              <h5>{`${cateCodeMap.mainCate}. ${
                mainCodeList.find(item => item.code === cateCodeMap.mainCate)
                  .codeText
              }`}</h5>
              {/* 중분류 선택 */}
              <Row>
                <Col>
                  <h6>중분류</h6>
                  <ToggleButtonGroup
                    type="radio"
                    name="middleCate"
                    value={cateCodeMap.middleCate}
                    onChange={handleMiddleCate}
                    className="subCate-search"
                  >
                    {middleCodeList.map((item, idx) => {
                      return (
                        <ToggleButton
                          id={`middleCate-${item.code}`}
                          key={`middleCate-${item.code}`}
                          value={item.code}
                          variant="light"
                          checked={item.code === cateCodeMap.middleCate}
                        >
                          {`${item.code}. ${item.codeText}`}
                        </ToggleButton>
                      );
                    })}
                  </ToggleButtonGroup>
                </Col>

                {cateCodeMap.middleCate && (
                  <Col>
                    <h6>소분류</h6>
                    <ToggleButtonGroup
                      type="radio"
                      name="smallCate"
                      value={cateCodeMap.smallCate}
                      onChange={handleSmallCate}
                      className="subCate-search"
                    >
                      {smallCodeList.map((item, idx) => {
                        return (
                          <ToggleButton
                            id={`smallCate-${item.code}`}
                            key={`smallCate-${item.code}`}
                            value={item.code}
                            variant="light"
                            checked={
                              cateCodeMap.smallCate &&
                              item.code === cateCodeMap.smallCate
                            }
                          >
                            {`${item.code}. ${item.codeText}`}
                          </ToggleButton>
                        );
                      })}
                    </ToggleButtonGroup>
                  </Col>
                )}
                {cateCodeMap.smallCate && (
                  <Col>
                    <h6>세분류</h6>
                    <ToggleButtonGroup
                      type="radio"
                      name="detailCate"
                      value={cateCodeMap.detailCate}
                      onChange={handleDetailCate}
                      className="subCate-search"
                    >
                      {detailCodeList.map((item, idx) => {
                        return (
                          <ToggleButton
                            id={`detailCate-${item.code}`}
                            key={`detailCate-${item.code}`}
                            value={item.code}
                            variant="light"
                            checked={
                              cateCodeMap.detailCate &&
                              item.code === cateCodeMap.detailCate
                            }
                          >
                            {`${item.code}. ${item.codeText}`}
                          </ToggleButton>
                        );
                      })}
                    </ToggleButtonGroup>
                  </Col>
                )}
              </Row>
              {/* 선택 초기화 */}
              <div className="btn-area justify-content-end m-0 mt-3">
                <Button variant="ts" size="sm" onClick={resetHandler}>
                  <i className="material-icons">refresh</i>선택 초기화
                </Button>
              </div>
              {/* 검색 */}
              {/* <div className="btn-area">
                <Button size="lg" onClick={searchHandler}>
                  검색
                </Button>
              </div> */}
            </section>
          )}

          {isSearched && (
            <section className="mt-4">
              <div className="sub-title">
                <h5>검색결과</h5>
              </div>
              <Table className="bt-none">
                <colgroup>
                  <col width={160} />
                  <col width="*" />
                  <col width={200} />
                  <col width={120} />
                </colgroup>
                <thead>
                  <tr>
                    <th>분류번호</th>
                    <th>능력단위 상세</th>
                    <th>능력단위</th>
                    <th>선택</th>
                  </tr>
                </thead>
                <tbody>
                  {learnModuleList.length > 0 ? (
                    learnModuleList.map((item, idx) => {
                      return (
                        <tr key={`search-${idx}`}>
                          <td>{item.learnModulSeq}</td>
                          <td>{item.learnModulText}</td>
                          <td>{item.learnModulName}</td>
                          <td>
                            <Form.Check
                              type="checkbox"
                              id={`search-check-${idx}`}
                              label=""
                              className="only-input"
                              value={item}
                              onChange={e =>
                                checkHandler(item, e.target.checked)
                              }
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={4}>
                        <p className="no-data">검색결과가 없습니다.</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <div className="btn-area justify-content-end mt-3">
                <Button onClick={addModules}>선택추가</Button>
              </div>
            </section>
          )}
        </article>
      </Container>
    </main>
  );
});
