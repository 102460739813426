/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { Utils } from '@common';
import {
  Container,
  Form,
  Button,
  Card,
  Table,
  Tab,
  Nav,
} from 'react-bootstrap';
import { format } from 'date-fns';

import { SearchGradeClass } from '@components';
import LoadingSpinner from '@components/LoadingSpinner';
import ReactPaginate from 'react-paginate';
import {
  selectStudentList,
  updateStudentPassword,
} from '@api/teacher/studentMgmt';
import { excelDownloadStudents } from '@api/eduadmin/student';
import { TEACHER_ROLE_DETAIL } from '@common/consts';

export default React.memo(function StatusList(props) {
  /** history state */
  const history = useHistory();
  const historyState = history.location.state;
  const fmPage = historyState && historyState.fmPage;
  const jnPage = historyState && historyState.jnPage;
  const snPage = historyState && historyState.snPage;
  const activeKey = (historyState && historyState.activeKey) || '1';
  const search = (historyState && historyState.search) || false;
  const searchedKeyword = historyState && historyState.keyword;
  const searchedGradeList =
    (historyState && historyState.searchedGradeList) || [];

  const [showSpinner, setShowSpinner] = useState(false);

  const { userInfo } = props;

  /** type */
  let teacherType = null; // basic/director
  if (userInfo) {
    if (
      TEACHER_ROLE_DETAIL.SCHOOL_HEAD === userInfo.roleDetail ||
      TEACHER_ROLE_DETAIL.GRADE_HEAD === userInfo.roleDetail ||
      TEACHER_ROLE_DETAIL.PROGRAM === userInfo.roleDetail
    ) {
      teacherType = 'director';
    } else if (
      TEACHER_ROLE_DETAIL.CLASS === userInfo.roleDetail ||
      TEACHER_ROLE_DETAIL.SUB_CLASS === userInfo.roleDetail
    ) {
      teacherType = 'basic';
    }
  }

  /** paging state */
  const [size, setSize] = useState(10);
  const pageRefresh =
    historyState && historyState.pageRefresh ? historyState.pageRefresh : null;

  /** form state */

  // tab
  const [showTabKey, setShowTabKey] = useState({});

  /* 검색 */
  // 반/ 번호 선택
  const [checkedGradeList, setCheckedGradeList] = useState([]); // 선택된 반 리스트
  const [keyword, setKeyword] = useState();
  const [teacherGrade, setTeacherGrade] = useState(1);

  // 학교 정보
  const [schoolInfo, setSchoolInfo] = useState({});
  const [teacherInfo, setTeacherInfo] = useState({});

  // 학교 관리자, 학년부장 :: 리스트
  const [fmStudentPageInfo, setFmStudentPageInfo] = useState({});
  const [jnStudentPageInfo, setJnStudentPageInfo] = useState({});
  const [snStudentPageInfo, setSnStudentPageInfo] = useState({});

  // 참여교사 :: 리스트
  const [classStudentList, setClassStudentList] = useState([]);

  /** API */
  const [firstCallDone, setFirstCallDone] = useState(false);
  const getStudentList = async () => {
    let banNoList = [];
    let page;
    switch (`${activeKey}`) {
      case '1':
        page = fmPage;
        break;
      case '2':
        page = jnPage;
        break;
      case '3':
        page = snPage;
        break;
      default:
        break;
    }
    // 보여줄 탭 리스트
    showTabList(searchedGradeList);
    // eslint-disable-next-line no-case-declarations
    if (checkedGradeList && checkedGradeList.length > 0) {
      const classList = checkedGradeList.find(
        item => item.grade === window.parseInt(activeKey),
      );
      if (classList && classList.classes && classList.classes.length > 0) {
        banNoList = [...classList.classes];
      }
    }

    // JSON 파라미터
    const params = {
      grade: activeKey,
      keyword: searchedKeyword,
      banNoList,
      page: page || 1,
      size,
    };

    // Axios
    try {
      const { data } = await selectStudentList(params);
      if (data.code === 0) {
        setFirstCallDone(true);
        if (data.data.pageResponse) {
          setSchoolInfo(data.data.schoolInfo);
          setTeacherGrade(userInfo.grade);
          let fmPageInfo = {};
          let jnPageInfo = {};
          let snPageInfo = {};
          switch (`${activeKey}`) {
            case '1':
              fmPageInfo = data.data.pageResponse;
              break;
            case '2':
              jnPageInfo = data.data.pageResponse;
              break;
            case '3':
              snPageInfo = data.data.pageResponse;
              break;
            default:
              page = 1;
              break;
          }
          setFmStudentPageInfo({ ...fmPageInfo });
          setJnStudentPageInfo({ ...jnPageInfo });
          setSnStudentPageInfo({ ...snPageInfo });
        }

        // 학교 ^ 학생 리스트 & 교사 정보
        if (data.data.studentList) {
          setSchoolInfo(data.data.schoolInfo);
          setClassStudentList([...data.data.studentList]);
          setTeacherInfo({
            grade: data.data.grade,
            class: data.data.class,
          });
        }
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  // 비밀번호 초기화
  const resetPw = async (event, idx) => {
    event.stopPropagation();
    const target = event.currentTarget;
    target.disabled = true;

    Swal.fire({
      text: '해당 학생의 비밀번호를 초기화 하시겠습니까?',
      confirmButtonText: '확인',
      cancelButtonText: '취소',
      showCancelButton: true,
      reverseButtons: true,
    }).then(async result => {
      if (result.isConfirmed) {
        const params = {
          student_idx: idx,
        };
        try {
          const { data } = await updateStudentPassword(params);
          if (data.code === 0) {
            Swal.fire({
              text: '해당 학생의 비밀번호가 초기화되었습니다.',
              confirmButtonText: '확인',
            });
          }
        } catch (e) {
          // console.log(e);
          target.disabled = false;
        }
      }
    });
    target.disabled = false;
  };

  /** help function */
  const showTabList = list => {
    // 보여줄 탭
    const showTab = {};
    if (list) {
      list.forEach(item => {
        showTab[item.grade] = item.grade;
      });
    } else {
      showTab['1'] = '1';
    }
    setShowTabKey(showTab);
    return showTab;
  };

  const getPageByGrade = grade => {
    switch (`${grade}`) {
      case '1':
        return fmPage;
      case '2':
        return jnPage;
      case '3':
        return snPage;
      default:
        return null;
    }
  };

  const rememberState = () => {
    setKeyword(searchedKeyword);
    setCheckedGradeList([...searchedGradeList]);
  };

  /** paging */
  // 페이지 이동
  const movePage = (pageNum, tabKey) => {
    const state = {
      keyword,
      pageRefresh: !pageRefresh,
      activeKey: tabKey || activeKey,
      search,
      fmPage,
      jnPage,
      snPage,
      searchedGradeList: checkedGradeList,
    };
    switch (`${tabKey}`) {
      case '1':
        state.fmPage = pageNum;
        break;
      case '2':
        state.jnPage = pageNum;
        break;
      case '3':
        state.snPage = pageNum;
        break;
      default:
        break;
    }

    history.push({
      pathname: `/teacher/studentMgmt/statusList`,
      state,
    });
  };

  /** searching */
  // 검색
  const [throttle, setThrottle] = useState(false);
  const searching = e => {
    if (throttle) return;
    setThrottle(true);

    // 반/번호 미선택시 검색되지 않음
    if (
      (TEACHER_ROLE_DETAIL.SCHOOL_HEAD === userInfo.roleDetail ||
        TEACHER_ROLE_DETAIL.GRADE_HEAD === userInfo.roleDetail ||
        TEACHER_ROLE_DETAIL.PROGRAM === userInfo.roleDetail) &&
      checkedGradeList.length === 0
    ) {
      setThrottle(false);
      return;
    }

    // 처음으로 보여줄 Tab
    const showActiveTabKey =
      showTabKey[Object.keys(showTabList(checkedGradeList)).sort()[0]];

    const state = {
      keyword,
      pageRefresh: !pageRefresh,
      activeKey: showActiveTabKey,
      search: true,
      fmPage: 1,
      jnPage: 1,
      snPage: 1,
      searchedGradeList: checkedGradeList,
    };
    history.push({
      pathname: `/teacher/studentMgmt/statusList`,
      state,
    });

    setTimeout(() => {
      setThrottle(false);
    }, 200);
  };

  // Excel 다운로드
  const excelDownload = async event => {
    const target = event.currentTarget;
    target.disabled = true;
    try {
      const params = {
        // downloadType: selectedDownloadData,
        schoolIdx: schoolInfo.seq,
      };
      setShowSpinner(true);
      const { data } = await excelDownloadStudents(params);
      const blob = new Blob([data]);

      const link = document.createElement('a');

      link.href = URL.createObjectURL(blob);
      link.download = `${format(new Date(), 'yyyyMMdd')}.xlsx`;

      link.style.display = 'none';
      link.click();
      link.remove();
    } catch (error) {
      target.disabled = false;
      setShowSpinner(false);
    }
    target.disabled = false;
    setShowSpinner(false);
  };

  /** useEffect */
  useEffect(() => {
    return () => {
      setSchoolInfo({});
      setFmStudentPageInfo({});
      setJnStudentPageInfo({});
      setSnStudentPageInfo({});
      setSchoolInfo({});
      setClassStudentList([]);
      setKeyword();
      setCheckedGradeList([]);
      setTeacherInfo({});
    };
  }, []);

  useEffect(() => {
    if (userInfo) {
      setFirstCallDone(false);
      rememberState();
      getStudentList();
    }
  }, [fmPage, jnPage, snPage, pageRefresh, activeKey, userInfo]);

  return (
    <main id="teacher-studentStatus" className="type-02">
      <Container>
        {firstCallDone && (
          <article className="content">
            {/* ////////// ↓ 상단 타이틀영역 ////////// */}
            <section>
              <div className="title">
                <h5>학생현황</h5>
              </div>
            </section>
            {/* ////////// ↓ 컨텐츠 ////////// */}
            {schoolInfo.seq && showSpinner ? (
              <LoadingSpinner show={showSpinner} message="다운로드 중입니다." />
            ) : (
              <Card>
                <Card.Header>
                  {teacherType === 'director' && (
                    <div className="flex-start">
                      <SearchGradeClass
                        schoolidx={schoolInfo.seq}
                        setCheckedGradeList={setCheckedGradeList}
                        checkedGradeList={checkedGradeList}
                      />
                      <div className="input-has-btn ms-2">
                        <Form.Control
                          type="text"
                          placeholder="학생 이름을 검색하세요."
                          className="input-search"
                          onChange={e => {
                            setKeyword(e.target.value);
                          }}
                          value={keyword || ''}
                        />
                        <Button size="sm" variant="primary" onClick={searching}>
                          검색
                        </Button>
                      </div>
                      <Button
                        className="ms-2"
                        variant="primary"
                        onClick={excelDownload}
                      >
                        다운로드
                      </Button>
                    </div>
                  )}
                  {teacherType === 'basic' && (
                    <div className="input-has-btn">
                      <Form.Control
                        type="text"
                        placeholder="학생 이름을 검색하세요."
                        className="input-search"
                        onChange={e => {
                          setKeyword(e.target.value);
                        }}
                        value={keyword || ''}
                      />
                      <Button size="sm" variant="primary" onClick={searching}>
                        검색
                      </Button>
                    </div>
                  )}
                  <div className="table-title mt-4">
                    <h5>{schoolInfo.school_name}</h5>
                  </div>
                </Card.Header>
                <Card.Body className="pt-0">
                  {teacherType === 'basic' && (
                    <>
                      <div className="table-sub-title">
                        <h5>{`${
                          teacherInfo.grade
                        }학년 ${+teacherInfo.class}반`}</h5>
                      </div>
                      <Table className="bt-none tr-even-bg">
                        <colgroup>
                          <col width={62} />
                          <col width={62} />
                          <col width="*" />
                          <col width="*" />
                          <col width="*" />
                          <col width="*" />
                          <col width="*" />
                        </colgroup>
                        <thead>
                          <tr>
                            <th>반</th>
                            <th>번호</th>
                            <th>ID</th>
                            <th className="text-start">이름</th>
                            <th>상태</th>
                            <th>연락처</th>
                            <th>관리</th>
                          </tr>
                        </thead>
                        <tbody>
                          {classStudentList.length > 0 &&
                            classStudentList.map((item, index) => {
                              return (
                                <tr
                                  key={`${index}`}
                                  className="go-detail"
                                  onClick={() =>
                                    props.history.push({
                                      pathname: `/teacher/studentMgmt/statusList/detail/${item.seq}`,
                                      state: {
                                        keyword,
                                        pageRefresh,
                                        activeKey,
                                        search,
                                        fmPage,
                                        jnPage,
                                        snPage,
                                        searchedGradeList,
                                      },
                                    })
                                  }
                                >
                                  <td>{item.ban_no}</td>
                                  <td>{item.student_no}</td>
                                  <td>{item.student_id}</td>
                                  <td className="text-start">
                                    {Utils.decrypt(item.student_name)}
                                  </td>
                                  <td>{item.status_han}</td>
                                  <td>{Utils.decrypt(item.phone_no)}</td>
                                  <td>
                                    <Button
                                      size="sm"
                                      variant="outline-primary"
                                      onClick={e => {
                                        resetPw(e, item.seq);
                                      }}
                                    >
                                      비밀번호 초기화
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                    </>
                  )}

                  {teacherType === 'director' && search && (
                    <Tab.Container
                      activeKey={activeKey}
                      onChange={key => {
                        movePage(getPageByGrade(key), key);
                      }}
                      id="grade-tab"
                    >
                      <Nav className="text-tabs">
                        <Nav.Item>
                          {showTabKey[1] && (
                            <Nav.Link
                              eventKey="1"
                              onClick={e => movePage(getPageByGrade(1), 1)}
                            >
                              1학년
                            </Nav.Link>
                          )}
                          {showTabKey[2] && (
                            <Nav.Link
                              eventKey="2"
                              onClick={e => movePage(getPageByGrade(2), 2)}
                            >
                              2학년
                            </Nav.Link>
                          )}
                          {showTabKey[3] && (
                            <Nav.Link
                              eventKey="3"
                              onClick={e => movePage(getPageByGrade(3), 3)}
                            >
                              3학년
                            </Nav.Link>
                          )}
                        </Nav.Item>
                      </Nav>
                      <Tab.Content>
                        {/* ↓ 1학년 탭 */}
                        <Tab.Pane eventKey="1">
                          <Table className="bt-none tr-even-bg">
                            <colgroup>
                              <col width={62} />
                              <col width={62} />
                              <col width="*" />
                              <col width="*" />
                              <col width="*" />
                              <col width="*" />
                              <col width="*" />
                            </colgroup>
                            <thead>
                              <tr>
                                <th>반</th>
                                <th>번호</th>
                                <th>ID</th>
                                <th className="text-start">이름</th>
                                <th>상태</th>
                                <th>연락처</th>
                                <th>관리</th>
                              </tr>
                            </thead>
                            <tbody>
                              {fmStudentPageInfo.list &&
                              fmStudentPageInfo.list.length > 0 ? (
                                fmStudentPageInfo.list.map((item, index) => {
                                  return (
                                    <tr
                                      key={`${index}`}
                                      className="go-detail"
                                      onClick={() =>
                                        props.history.push({
                                          pathname: `/teacher/studentMgmt/statusList/detail/${item.seq}`,
                                          state: {
                                            keyword,
                                            pageRefresh,
                                            activeKey,
                                            search,
                                            fmPage,
                                            jnPage,
                                            snPage,
                                            searchedGradeList,
                                          },
                                        })
                                      }
                                    >
                                      <td>{item.ban_no}</td>
                                      <td>{item.student_no}</td>
                                      <td>{item.student_id}</td>
                                      <td className="text-start">
                                        {Utils.decrypt(item.student_name)}
                                      </td>
                                      <td>{item.status_han}</td>
                                      <td>{Utils.decrypt(item.phone_no)}</td>
                                      <td>
                                        <Button
                                          size="sm"
                                          variant="outline-primary"
                                          onClick={e => {
                                            resetPw(e, item.seq);
                                          }}
                                        >
                                          비밀번호 초기화
                                        </Button>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan={7} className="no-data">
                                    등록된 학생이 없습니다.
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                          {/* 페이징 */}
                          <ReactPaginate
                            pageRangeDisplayed={3}
                            nextLabel=""
                            previousLabel=""
                            breakLabel="..."
                            pageCount={
                              fmStudentPageInfo.totalPages || fmPage || 1
                            }
                            renderOnZeroPageCount={null}
                            containerClassName="pagination mt-4"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            activeClassName="active"
                            onPageChange={e => {
                              movePage(e.selected + 1, '1');
                            }}
                            forcePage={fmPage ? fmPage - 1 : 0}
                          />
                        </Tab.Pane>
                        {/* ↓ 2학년 탭 */}
                        <Tab.Pane eventKey="2">
                          <Table className="bt-none tr-even-bg">
                            <colgroup>
                              <col width={62} />
                              <col width={62} />
                              <col width="*" />
                              <col width="*" />
                              <col width="*" />
                              <col width="*" />
                              <col width="*" />
                            </colgroup>
                            <thead>
                              <tr>
                                <th>반</th>
                                <th>번호</th>
                                <th>ID</th>
                                <th className="text-start">이름</th>
                                <th>상태</th>
                                <th>연락처</th>
                                <th>관리</th>
                              </tr>
                            </thead>
                            <tbody>
                              {jnStudentPageInfo.list &&
                              jnStudentPageInfo.list.length > 0 ? (
                                jnStudentPageInfo.list.map((item, index) => {
                                  return (
                                    <tr
                                      key={`${index}`}
                                      className="go-detail"
                                      onClick={() =>
                                        props.history.push({
                                          pathname: `/teacher/studentMgmt/statusList/detail/${item.seq}`,
                                          state: {
                                            keyword,
                                            pageRefresh,
                                            activeKey,
                                            search,
                                            fmPage,
                                            jnPage,
                                            snPage,
                                            searchedGradeList,
                                          },
                                        })
                                      }
                                    >
                                      <td>{item.ban_no}</td>
                                      <td>{item.student_no}</td>
                                      <td>{item.student_id}</td>
                                      <td className="text-start">
                                        {Utils.decrypt(item.student_name)}
                                      </td>
                                      <td>{item.status_han}</td>
                                      <td>{Utils.decrypt(item.phone_no)}</td>
                                      <td>
                                        <Button
                                          size="sm"
                                          variant="outline-primary"
                                          onClick={e => {
                                            resetPw(e, item.seq);
                                          }}
                                        >
                                          비밀번호 초기화
                                        </Button>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan={7} className="no-data">
                                    등록된 학생이 없습니다.
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                          {/* 페이징 */}
                          <ReactPaginate
                            pageRangeDisplayed={3}
                            nextLabel=""
                            previousLabel=""
                            breakLabel="..."
                            pageCount={
                              jnStudentPageInfo.totalPages || jnPage || 1
                            }
                            renderOnZeroPageCount={null}
                            containerClassName="pagination mt-4"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            activeClassName="active"
                            onPageChange={e => {
                              movePage(e.selected + 1, '2');
                            }}
                            forcePage={jnPage ? jnPage - 1 : 0}
                          />
                        </Tab.Pane>
                        {/* ↓ 3학년 탭 */}
                        <Tab.Pane eventKey="3">
                          <Table className="bt-none tr-even-bg">
                            <colgroup>
                              <col width={62} />
                              <col width={62} />
                              <col width="*" />
                              <col width="*" />
                              <col width="*" />
                              <col width="*" />
                              <col width="*" />
                            </colgroup>
                            <thead>
                              <tr>
                                <th>반</th>
                                <th>번호</th>
                                <th>ID</th>
                                <th className="text-start">이름</th>
                                <th>상태</th>
                                <th>연락처</th>
                                <th>관리</th>
                              </tr>
                            </thead>
                            <tbody>
                              {snStudentPageInfo.list &&
                              snStudentPageInfo.list.length > 0 ? (
                                snStudentPageInfo.list.map((item, index) => {
                                  return (
                                    <tr
                                      key={`${index}`}
                                      className="go-detail"
                                      onClick={() =>
                                        props.history.push({
                                          pathname: `/teacher/studentMgmt/statusList/detail/${item.seq}`,
                                          state: {
                                            keyword,
                                            pageRefresh,
                                            activeKey,
                                            search,
                                            fmPage,
                                            jnPage,
                                            snPage,
                                            searchedGradeList,
                                          },
                                        })
                                      }
                                    >
                                      <td>{item.ban_no}</td>
                                      <td>{item.student_no}</td>
                                      <td>{item.student_id}</td>
                                      <td className="text-start">
                                        {Utils.decrypt(item.student_name)}
                                      </td>
                                      <td>{item.status_han}</td>
                                      <td>{Utils.decrypt(item.phone_no)}</td>
                                      <td>
                                        <Button
                                          size="sm"
                                          variant="outline-primary"
                                          onClick={e => {
                                            resetPw(e, item.seq);
                                          }}
                                        >
                                          비밀번호 초기화
                                        </Button>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan={7} className="no-data">
                                    등록된 학생이 없습니다.
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                          {/* 페이징 */}
                          <ReactPaginate
                            pageRangeDisplayed={3}
                            nextLabel=""
                            previousLabel=""
                            breakLabel="..."
                            pageCount={
                              snStudentPageInfo.totalPages || snPage || 1
                            }
                            renderOnZeroPageCount={null}
                            containerClassName="pagination mt-4"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            activeClassName="active"
                            onPageChange={e => {
                              movePage(e.selected + 1, '3');
                            }}
                            forcePage={snPage ? snPage - 1 : 0}
                          />
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  )}
                  {teacherType === 'director' && !search && (
                    <div className="w-100 text-center pb-3">
                      <p>검색하고 싶은 학년, 반을 검색해주세요.</p>
                    </div>
                  )}
                </Card.Body>
              </Card>
            )}
          </article>
        )}
      </Container>
    </main>
  );
});
