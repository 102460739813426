/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useState, useRef, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import {
  Container,
  Button,
  Card,
  Modal,
  Form,
  Image,
  Table,
} from 'react-bootstrap';
import { CustomModal, CustomSelect } from '@components';
import Swal from 'sweetalert2';
import { images } from '@assets';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ko } from 'date-fns/esm/locale';
import { useDaumPostcodePopup } from 'react-daum-postcode';
import Moment from 'react-moment';

//* Utils
import Utils from '@common/Utils';

//* CONSTS
import { FormStatus, FILE_FORM_TYPE } from '@common/consts';

//* API
import {
  selectPortfolioInfo,
  selectCertificateList,
  upsertFormPortfolio,
} from '@api/student/Portfolio';
import { getFilePathFromRedis } from '@api/file';

//* [ Main ]
export default React.memo(function PortfolioWrite(props) {
  const history = useHistory();
  const [pageRender, setPageRender] = useState(false); // 페이지 강제 랜더링

  // type
  const { grade } = props.userInfo;

  // select
  const sexOptions = [
    { label: '남', value: '남' },
    { label: '여', value: '여' },
  ];

  //* #################################################################
  //* [ States ]
  //* #################################################################
  // image
  const selectProfileFile = useRef('');
  const [fileProfile, setFileProfile] = useState();
  const [profileReviewFile, setProfileReviewFile] = useState();

  // 직무포트폴리오 정보
  const [portfolioInfo, setPortfolioInfo] = useState({});

  /* 인적사항 form */
  // 한자이름
  const [chineseCharacterName, setChineseCharacterName] = useState();
  // 생년월일
  const [birthDate, setBirthDate] = useState(new Date());
  // 성별
  const [selectedSex, setSelectedSex] = useState(sexOptions[0]);
  // 연락처
  const [phoneNumber, setPhoneNumber] = useState();
  // 이메일
  const [email, setEmail] = useState();
  // 주소
  const [address, setAddress] = useState();
  // 프로필 사진

  /* 학력사항 - 고등학교 */
  // 기간
  const [highEduStartDate, setHighEduStartDate] = useState(new Date());
  const [highEduEndDate, setHighEduEndDate] = useState(new Date());
  // 학교명
  const [highSchoolName, setHighSchoolName] = useState();
  // 학과(전공)
  const [highDepartName, setHighDepartName] = useState();
  // 재적여부(select box options)
  const [highStatusOptions, setHighStatusOptions] = useState([]);
  // 재적여부
  const [highStatus, setHighStatus] = useState(null);

  /* 학력사항 - 중학교 */
  // 기간
  const [midEduStartDate, setMidEduStartDate] = useState(new Date());
  const [midEduEndDate, setMidEduEndDate] = useState(new Date());
  // 학교명
  const [midSchoolName, setMidSchoolName] = useState();
  // 학과(전공)
  const [midDepartName, setMidDepartName] = useState();
  // 재적여부(select box options)
  const [midStatusOptions, setMidStatusOptions] = useState([]);
  // 재적여부
  const [midStatus, setMidStatus] = useState(null);

  /* 자격증 */
  const [certOptionList, setCertOptionList] = useState([]); // 전공 자격증 리스트
  const [certList, setCertList] = useState([]); // 전공 자격증 리스트
  const [certCheckObj, setCertCheckObj] = useState({}); // 체크한 자격증 리스트
  const [selectedCertObj, setSelectedCertObj] = useState({}); // 최종 선택된 자격증 리스트
  const [certIdx, setCertIdx] = useState(); // certIdx String ex ) "1;2;3"
  const [loadCertModalShow, setLoadCertModalShow] = useState(false);

  /* 수상경력 */
  // 수상일, 수상명, 등급(위), 수여기관
  const [awardList, setAwardList] = useState([
    {
      award_date: new Date(),
      award_name: null,
      award_number: null,
      award_gov: null,
    },
  ]); // 수상경력 리스트

  /* 경력 및 활동사항 */
  // 구분(select box options)
  const [programGubunList, setProgramGubunList] = useState([]);

  // 활동명, 활동기간, 주요활동내용, 구분
  const [careerList, setCareerList] = useState([
    {
      program_gubun: null,
      career_start_date: new Date(),
      career_end_date: new Date(),
      career_title: null,
      career_contents: null,
    },
  ]); // 경력 활동사항

  /* 참여활동 내용 */
  // 성장과정
  const [myGrowthProceed, setMyGrowthProceed] = useState();
  // 성격 및 생활신조
  const [lifeAndCredo, setLifeAndCredo] = useState();
  // 학교 및 경력사항
  const [myCareer, setMyCareer] = useState();
  // 지원동기 및 입사 후 포부
  const [motivationAndAspirations, setMotivationAndAspirations] = useState();

  /* 교사 코멘트 */
  const [teacherCommentList, setTeacherCommentList] = useState([]);

  //* #################################################################
  //* [ API ]포트폴리오 정보 조회
  //* #################################################################
  const getPortfolioInfo = async () => {
    const params = {
      isUpsert: true,
    };
    try {
      const { data } = await selectPortfolioInfo(params);
      if (data.code === 0) {
        const resultData = data.data;
        const { info } = data.data;
        /* select box data */
        // 고등학교 재적여부
        if (resultData.highStatusList) {
          setHighStatusOptions(resultData.highStatusList);
          setHighStatus(resultData.highStatusList[0]);
        }
        // 중학교 재적여부
        if (resultData.midStatusList) {
          setMidStatusOptions(resultData.midStatusList);
          setMidStatus(resultData.midStatusList[0]);
        }
        // 경력 및 활동사항
        if (resultData.programGubunList) {
          setProgramGubunList(resultData.programGubunList);
        }
        // 인적사항 > 전화번호
        if (info.phone_no) {
          setPhoneNumber(Utils.decrypt(info.phone_no));
        }
        // 인적사항 > 이메일
        if (info.e_mail) {
          setEmail(Utils.decrypt(info.e_mail));
        }
        // 인적사 > 주소
        let tempAddr = '';
        if (info.addr1) {
          tempAddr += `${Utils.decrypt(info.addr1)}`;
        }
        if (info.addr2) {
          tempAddr += ` ${Utils.decrypt(info.addr2)}`;
        }
        setAddress(tempAddr);
        // 인적사항 > 생년월일
        if (info.student_birth) {
          setBirthDate(
            new Date(Utils.dateHypenFormat(Utils.decrypt(info.student_birth))),
          );
        }
        if (info.sex) {
          console.log(info.sex);
          setSelectedSex(findSex(sexOptions, info.sex));
        }

        /* info data */
        setPortfolioInfo(info);

        if (info.seq) {
          // 한자명
          setChineseCharacterName(info.student_name_hanja);

          if (info.seq) {
            if (info.isExistsPhoto === 'Y') {
              const url = await openFile(info.seq);
              if (url) {
                setProfileReviewFile(url);
              }
            }
          }

          // 학력사항
          setHighSchoolName(info.high_school_name);
          setHighDepartName(info.high_depart_name);
          if (info.high_start_date) {
            setHighEduStartDate(
              new Date(Utils.getJustDateString(info.high_start_date)),
            );
          }
          if (info.high_end_date) {
            setHighEduEndDate(
              new Date(Utils.getJustDateString(info.high_end_date)),
            );
          }
          setHighStatus(
            findStatus(resultData.highStatusList, info.high_status),
          );
          setMidSchoolName(info.mid_school_name);
          setMidDepartName(info.mid_depart_name);
          if (info.mid_start_date) {
            setMidEduStartDate(
              new Date(Utils.getJustDateString(info.mid_start_date)),
            );
          }
          if (info.mid_end_date) {
            setMidEduEndDate(
              new Date(Utils.getJustDateString(info.mid_end_date)),
            );
          }
          setMidStatus(findStatus(resultData.midStatusList, info.mid_status));

          // 자격증
          if (
            resultData.certificateList &&
            resultData.certificateList.length > 0
          ) {
            setCertList(resultData.certificateList);
          }
          setCertIdx(info.certificate_idx);

          // 수상경력
          if (resultData.awardList && resultData.awardList.length > 0) {
            const list = resultData.awardList;
            list.forEach(item => {
              // item.award_date = new Date(item.award_date);
              item.award_date = item.award_date
                ? new Date(item.award_date)
                : new Date();
            });
            setAwardList(list);
          }

          // 경력 및 활동사항
          if (resultData.careerList && resultData.careerList.length > 0) {
            const list = resultData.careerList;
            list.forEach(item => {
              item.career_start_date = item.career_start_date
                ? new Date(item.career_start_date)
                : new Date();
              item.career_end_date = item.career_end_date
                ? new Date(item.career_end_date)
                : new Date();
            });
            // list.forEach(item => {
            //   item.career_start_date = new Date(item.career_start_date);
            //   item.career_end_date = new Date(item.career_end_date);
            // });
            setCareerList(resultData.careerList);
          }

          // 참여활동 내용
          setMyGrowthProceed(info.my_growth_proceed);
          setLifeAndCredo(info.my_sinjo);
          setMyCareer(info.my_career);
          setMotivationAndAspirations(info.my_jiwon);

          // 교사 코멘트
          setTeacherCommentList(resultData.teacherCommentList);
        }
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  // 자격증 리스트
  const getCertificateList = async () => {
    if (certOptionList.length > 0) return;
    try {
      const { data } = await selectCertificateList();
      if (data.code === 0) {
        setCertOptionList(data.data);
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 학생 프로필 사진 url 조회
  //* #################################################################
  const openFile = async fileSeq => {
    // API 파라미터
    const paramMap = {
      formType: FILE_FORM_TYPE.FILE_PORTFOLIO,
      fileSeq,
    };

    // Axios
    try {
      const { data } = await getFilePathFromRedis(paramMap);

      if (data.code === 0) {
        const redisKey = data.data;
        const hostName = window.location.hostname;

        const url = Utils.getFileOpenUrl(hostName, redisKey);

        return url;
      }
    } catch (e) {
      return null;
    }
    return null;
  };

  //* #################################################################
  //* [ API ] 포트폴리오 저장/수정
  //* #################################################################
  const upsertPortfolio = async (event, status) => {
    const target = event.currentTarget;
    target.disabled = true;
    // input check
    if (!inputConditionCheck()) {
      Swal.fire({
        text: '입력 제한을 확인해주시기 바랍니다.',
        confirmButtonText: '확인',
      });
      target.disabled = false;
      return;
    }

    // // phone_no check
    // if (phoneNumber && !Utils.phoneNumberTest(phoneNumber)) {
    //   Swal.fire({
    //     text: '휴대폰 번호 형식을 확인해주시기 바랍니다.',
    //     confirmButtonText: '확인',
    //   });
    //   target.disabled = false;
    //   return;
    // }
    // // email check
    // if (email && !Utils.emailTest(email)) {
    //   Swal.fire({
    //     text: '이메일 형식을 확인해주시기 바랍니다.',
    //     confirmButtonText: '확인',
    //   });
    //   target.disabled = false;
    //   return;
    // }

    // FormData
    const formData = new FormData();

    // 파일 append
    formData.append('file', fileProfile);

    // JSON 파라미터
    const params = {
      status,
      student_name_hanja: chineseCharacterName,
      high_school_name: highSchoolName,
      high_depart_name: highDepartName,
      high_start_date: Utils.dateToString(highEduStartDate),
      high_end_date: Utils.dateToString(highEduEndDate),
      high_status: highStatus.value || null,
      mid_school_name: midSchoolName,
      mid_depart_name: midDepartName,
      mid_start_date: Utils.dateToString(midEduStartDate),
      mid_end_date: Utils.dateToString(midEduEndDate),
      mid_status: midStatus.value || null,
      my_growth_proceed: myGrowthProceed,
      my_sinjo: lifeAndCredo,
      my_career: myCareer,
      my_jiwon: motivationAndAspirations,
      certificate_idx: certIdx,
      careerList: careerListCheck(careerList),
      awardList: awardListCheck(awardList),
      // sex: selectedSex.value || null,
      // student_phone_no: phoneNumber,
      // student_e_mail: email,
      // student_addr: address,
      // student_birth: Utils.dateToString(birthDate),
    };

    // JSON append
    formData.append(
      'param',
      new Blob([JSON.stringify(params)], { type: 'application/json' }),
    );

    try {
      const { data } = await upsertFormPortfolio(formData);
      if (data.code === 0) {
        history.push({
          pathname: `/student/indivAbility/portfolio`,
        });
      }
    } catch (e) {
      // alert(e.response.data.message);
      target.disabled = false;
    }
    target.disabled = false;
  };

  //* #################################################################
  //* [ Utils ]
  //* #################################################################
  // 입력 제한 조건 체크
  const inputConditionCheck = () => {
    // input 길이체크
    if (
      !Utils.limitCheck(myGrowthProceed, 0, 1000) ||
      !Utils.limitCheck(lifeAndCredo, 0, 1000) ||
      !Utils.limitCheck(myCareer, 0, 1000) ||
      !Utils.limitCheck(motivationAndAspirations, 0, 1000)
    ) {
      return false;
    }
    return true;
  };

  // input 값이 모두 비어있는 아이템을 제거한 수상경력 리스트 반환
  // 날짜들 string -> date 로 변환
  const awardListCheck = () => {
    if (awardList.length === 0) return null;
    const list = awardList;
    const resultList = [];
    list.forEach(item => {
      if (item.award_name || item.award_number || item.award_gov) {
        if (item.award_date) {
          item.award_date = Utils.dateToString(item.award_date);
        }
        resultList.push(item);
      }
    });
    return resultList;
  };

  // input 값이 모두 비어있는 아이템을 제거한 경력 및 활동사항 리스트 반환
  // 날짜들 string -> date 로 변환
  const careerListCheck = () => {
    if (careerList.length === 0) return null;
    const list = careerList;
    const resultList = [];
    list.forEach(item => {
      if (item.career_title || item.career_contents) {
        if (item.career_start_date) {
          item.career_start_date = Utils.dateToString(item.career_start_date);
        }
        if (item.career_end_date) {
          item.career_end_date = Utils.dateToString(item.career_end_date);
        }
        resultList.push(item);
      }
    });
    return resultList.length === 0 ? null : resultList;
  };

  /** form help function */
  const forceRendering = () => {
    setPageRender(!pageRender);
  };

  const readProfileImage = input => {
    if (input.files && input.files[0]) {
      // eslint-disable-next-line no-shadow
      const image = new window.Image();
      const reader = new FileReader();

      reader.onload = e => {
        image.src = e.target.result;
      };
      reader.readAsDataURL(input.files[0]);

      image.onload = async function () {
        const { width, height, src } = image;
        if (checkProfileImageSize(width, height)) {
          setFileProfile(input.files[0]);
          setProfileReviewFile(src);
        } else {
          Swal.fire({
            text: '프로필 이미지 사이즈는 180 x 220 이어야 합니다.',
            confirmButtonText: '확인',
          });
        }
      };
    }
  };

  const checkProfileImageSize = (width, height) => {
    if (width !== 180 || height !== 220) return false;
    return true;
  };

  const findSex = (list, sex) => {
    if (!list) return null;
    // eslint-disable-next-line array-callback-return,consistent-return
    const findItem = list.find(item => {
      if (item.value === sex) return item;
    });
    if (findItem) {
      return findItem;
    }
    return null;
  };

  const findStatus = (list, value) => {
    if (!list) return null;
    // eslint-disable-next-line array-callback-return,consistent-return
    const findItem = list.find(item => {
      if (window.parseInt(item.value) === window.parseInt(value)) return item;
    });
    if (findItem) {
      return findItem;
    }
    return null;
  };

  const findProgramGubun = (careerItem, list, value) => {
    if (!list) return null;
    // eslint-disable-next-line array-callback-return,consistent-return
    const findItem = list.find(item => {
      if (window.parseInt(item.value) === window.parseInt(value)) return item;
    });
    if (findItem) {
      return findItem;
    }
    // eslint-disable-next-line prefer-destructuring
    careerItem.program_gubun = list[0] && list[0].value ? list[0].value : null;
    return list[0];
  };

  const checkCertificateAllEvent = checked => {
    if (!certOptionList || certOptionList.length === 0) return;
    if (checked) {
      certOptionList.forEach(item => {
        certCheckObj[item.seq] = item.seq;
      });
    } else {
      certOptionList.forEach(item => {
        delete certCheckObj[item.seq];
      });
    }
    setPageRender(!pageRender);
  };

  const checkCertificateEvent = (portFolioSeq, checked) => {
    if (checked) {
      certCheckObj[portFolioSeq] = portFolioSeq;
    } else {
      delete certCheckObj[portFolioSeq];
    }
    forceRendering();
  };

  // 선택 버튼 클릭시 체크한 자격증 정보만 따로 획득한다.
  const addCheckedCert = () => {
    setSelectedCertObj({ ...certCheckObj });
    closeLoadCertModal();
  };

  // 체크하여 선택버튼을 통해 선택한 자격증을 전체 자격증 리스트에서 추려낸다. && 선택한 자격증들에 대한 idx를 모아 하나의 문자열로 만든다.
  const getCertList = () => {
    if (!certOptionList || certOptionList.length === 0) return;

    let keys = Object.keys(selectedCertObj);

    const certIdxList = [];
    const resultList = [];

    keys = keys.map(item => {
      return window.parseInt(item);
    });
    certOptionList.forEach(item => {
      if (keys.indexOf(window.parseInt(item.seq)) > -1) {
        certIdxList.push(item.seq);
        resultList.push(item);
      }
    });
    setCertIdx(certIdxList.join(';'));
    setCertList(resultList);
  };

  // /** Daum 주소검색 API */
  // const open = useDaumPostcodePopup();

  // const handleComplete = data => {
  //   let fullAddress = data.address;
  //   let extraAddress = '';

  //   // R : 도로명, J : 지번
  //   if (data.addressType === 'R') {
  //     if (data.bname !== '') {
  //       extraAddress += data.bname;
  //     }
  //     if (data.buildingName !== '') {
  //       extraAddress +=
  //         extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
  //     }
  //     fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
  //   }
  //   // console.log(data.zonecode); // 우편번호
  //   setAddress(fullAddress);
  // };

  // const searchAddress = () => {
  //   open({
  //     onComplete: handleComplete,
  //     defaultQuery: address,
  //   });
  // };

  //* #################################################################
  //* [ Modal ]
  //* #################################################################
  /* 자격증 */
  const openLoadCertModal = async () => {
    // 자격증 리스트 조회
    await getCertificateList();
    // 선택했던 체크 정보 확인
    setCertCheckObj({ ...selectedCertObj });
    setLoadCertModalShow(true);
  };

  const closeLoadCertModal = () => {
    setLoadCertModalShow(false);
  };

  //* #################################################################
  //* [ useEffect ]
  //* #################################################################
  useEffect(() => {
    getPortfolioInfo();
    return () => {
      setCareerList([{}]);
      setAwardList([{}]);
      setHighStatusOptions([]);
      setHighStatus(null);
      setMidStatusOptions([]);
      setMidStatus(null);
      setProgramGubunList([]);
      setPortfolioInfo({});
      setChineseCharacterName(null);
      setPhoneNumber(null);
      setEmail(null);
      setAddress(null);
      setHighSchoolName(null);
      setHighDepartName(null);
      setHighEduStartDate(new Date());
      setHighEduEndDate(new Date());
      setHighStatus();
      setMidSchoolName(null);
      setMidDepartName(null);
      setMidEduStartDate(new Date());
      setMidEduEndDate(new Date());
      setMidStatus(null);
      setCertList([]);
      setCertIdx(null);
      setAwardList([]);
      setCareerList([]);
      setMyGrowthProceed(null);
      setLifeAndCredo(null);
      setMyCareer(null);
      setMotivationAndAspirations(null);
    };
  }, []);

  useEffect(() => {
    getCertList();
  }, [selectedCertObj]);

  //* #################################################################
  //* [ return ]
  //* #################################################################
  return (
    <main id="student-resume-write">
      <Container>
        <article className="content">
          <ul className="path">
            <li>
              <Link to="/">HOME</Link>
            </li>
            <li>
              <Link to="/student/indivAbility/portfolio">
                직무기초 포트폴리오
              </Link>
            </li>
            <li>
              <Link to="#none">작성 페이지</Link>
            </li>
          </ul>
          {/* ////////// ↓ 상단 타이틀영역 ////////// */}
          <section>
            <div className="title">
              <h5>이력서 및 자기소개서</h5>
            </div>
          </section>
          {/* ////////// ↓ 컨텐츠 ////////// */}
          {/* 인적사항 */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>인적사항</h5>
            </div>
            <Card>
              <ul className="form-list" style={{ marginBottom: 20 }}>
                <li>
                  <h5>이름</h5>
                  <Form.Control
                    type="text"
                    defaultValue={Utils.decrypt(portfolioInfo.student_name)}
                    readOnly
                  />
                </li>
              </ul>
              <div className="split-form-list">
                <ul className="form-list">
                  <li>
                    <h5>한자이름</h5>
                    <Form.Control
                      type="text"
                      placeholder="한자이름을 입력해주세요."
                      maxLength="10"
                      onChange={e => {
                        setChineseCharacterName(e.target.value);
                      }}
                      value={chineseCharacterName || ''}
                    />
                  </li>
                  <li>
                    <h5>생년월일</h5>
                    <div className="w-100">
                      <DatePicker
                        className="input-datepicker"
                        name="visit-date"
                        dateFormat="yyyy-MM-dd"
                        selected={birthDate}
                        onChange={date => setBirthDate(date)}
                        customInput={<Form.Control style={{ width: '100%' }} />}
                        showPopperArrow={false}
                        maxDate={new Date()}
                        readOnly
                      />
                    </div>
                    <h5 className="ms-4">성별</h5>
                    <div className="w-100">
                      {/* <CustomSelect
                        options={sexOptions}
                        value={selectedSex}
                        onChange={setSelectedSex}
                      /> */}
                      <Form.Control
                        // placeholder="연락처를 숫자로만 입력해주시기 바랍니다."
                        type="text"
                        value={selectedSex.value}
                        readOnly
                      />
                    </div>
                  </li>
                  <li>
                    <h5>연락처</h5>
                    <Form.Control
                      // placeholder="연락처를 숫자로만 입력해주시기 바랍니다."
                      // onChange={e => {
                      //   const value = e.target.value.replace(/[^0-9]/g, '');
                      //   setPhoneNumber(value);
                      // }}
                      // value={phoneNumber || ''}
                      // maxLength="20"
                      type="text"
                      // defaultValue={phoneNumber}
                      defaultValue={
                        phoneNumber && Utils.phoneNumberHypenFormat(phoneNumber)
                      }
                      readOnly
                    />
                    <h5 className="ms-4">이메일</h5>
                    <Form.Control
                      // placeholder="이메일을 입력해주시기 바랍니다."
                      // onChange={e => {
                      //   setEmail(e.target.value);
                      // }}
                      // value={email || ''}
                      // maxLength="50"
                      type="text"
                      defaultValue={email}
                      readOnly
                    />
                  </li>
                  <li>
                    <h5>주소</h5>
                    <div className="input-has-btn">
                      <Form.Control
                        // placeholder="주소를 검색하세요."
                        // onChange={e => {
                        //   setAddress(e.target.value);
                        // }}
                        // value={address || ''}
                        defaultValue={address}
                        type="text"
                        className="input-search"
                        maxLength="100"
                        readOnly
                      />
                      {/* <Button
                        size="sm"
                        variant="primary"
                        onClick={e => {
                          searchAddress();
                        }}
                      >
                        검색
                      </Button> */}
                    </div>
                  </li>
                </ul>
                <div className="add-profile">
                  {profileReviewFile ? (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                    <div
                      className="img-box"
                      style={{
                        backgroundImage: `url(${profileReviewFile})`,
                      }}
                      onClick={() => selectProfileFile.current.click()}
                    />
                  ) : (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                    <div
                      className="no-data"
                      onClick={() => selectProfileFile.current.click()}
                    >
                      <Image src={images.icAddImg} />
                      <p>(180 x 220)</p>
                    </div>
                  )}
                  <Form.Control
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    ref={selectProfileFile}
                    onChange={e => {
                      readProfileImage(e.target);
                    }}
                  />
                </div>
              </div>
            </Card>
          </section>
          {/* 학력사항 */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>학력사항</h5>
            </div>
            <Card>
              {/* 고등학교 */}
              <div className="sub-title sm">
                <h5>고등학교</h5>
              </div>
              <ul className="form-list">
                <li>
                  <h5>기간</h5>
                  <div className="mw-input">
                    <DatePicker
                      locale={ko}
                      className="input-datepicker"
                      name="high-edu-start-date"
                      dateFormat="yyyy-MM"
                      selected={highEduStartDate}
                      onChange={date => setHighEduStartDate(date)}
                      customInput={<Form.Control style={{ width: '100%' }} />}
                      showPopperArrow={false}
                      showMonthYearPicker
                      // maxDate={highEduEndDate}
                    />
                  </div>
                  <div className="tilde">~</div>
                  <div className="mw-input">
                    <DatePicker
                      locale={ko}
                      className="input-datepicker"
                      name="high-edu-end-date"
                      dateFormat="yyyy-MM"
                      selected={highEduEndDate}
                      onChange={date => setHighEduEndDate(date)}
                      customInput={<Form.Control style={{ width: '100%' }} />}
                      showPopperArrow={false}
                      showMonthYearPicker
                      // minDate={highEduStartDate}
                    />
                  </div>
                </li>
                <li>
                  <h5>학교명</h5>
                  <Form.Control
                    type="text"
                    placeholder="학교명을 입력하세요."
                    onChange={e => {
                      setHighSchoolName(e.target.value);
                    }}
                    value={highSchoolName || ''}
                    maxLength="25"
                  />
                </li>
                <li>
                  <h5>학과(전공)</h5>
                  <Form.Control
                    type="text"
                    placeholder="학과(전공)을 입력하세요."
                    onChange={e => {
                      setHighDepartName(e.target.value);
                    }}
                    value={highDepartName || ''}
                    maxLength="25"
                  />
                </li>
                <li>
                  <h5>재적여부</h5>
                  <div className="lw-input">
                    <CustomSelect
                      options={highStatusOptions}
                      value={highStatus}
                      onChange={setHighStatus}
                    />
                  </div>
                </li>
              </ul>
              {/* 중학교 */}
              <div className="sub-title sm border-top mt-0 pt-4">
                <h5>중학교</h5>
              </div>
              <ul className="form-list">
                <li>
                  <h5>기간</h5>
                  <div className="mw-input">
                    <DatePicker
                      locale={ko}
                      className="input-datepicker"
                      name="middle-edu-start-date"
                      dateFormat="yyyy-MM"
                      selected={midEduStartDate}
                      onChange={date => setMidEduStartDate(date)}
                      customInput={<Form.Control style={{ width: '100%' }} />}
                      showPopperArrow={false}
                      showMonthYearPicker
                      // maxDate={midEduEndDate}
                    />
                  </div>
                  <div className="tilde">~</div>
                  <div className="mw-input">
                    <DatePicker
                      locale={ko}
                      className="input-datepicker"
                      name="middle-edu-end-date"
                      dateFormat="yyyy-MM"
                      selected={midEduEndDate}
                      onChange={date => setMidEduEndDate(date)}
                      customInput={<Form.Control style={{ width: '100%' }} />}
                      showPopperArrow={false}
                      showMonthYearPicker
                      // minDate={midEduStartDate}
                    />
                  </div>
                </li>
                <li>
                  <h5>학교명</h5>
                  <Form.Control
                    type="text"
                    placeholder="학교명을 입력하세요."
                    onChange={e => {
                      setMidSchoolName(e.target.value);
                    }}
                    value={midSchoolName || ''}
                    maxLength="25"
                  />
                </li>
                <li>
                  <h5>학과(전공)</h5>
                  <Form.Control
                    type="text"
                    placeholder="학과(전공)을 입력하세요."
                    onChange={e => {
                      setMidDepartName(e.target.value);
                    }}
                    value={midDepartName || ''}
                    maxLength="25"
                  />
                </li>
                <li>
                  <h5>재적여부</h5>
                  <div className="lw-input">
                    <CustomSelect
                      options={midStatusOptions}
                      value={midStatus}
                      onChange={setMidStatus}
                    />
                  </div>
                </li>
              </ul>
            </Card>
          </section>

          {/* 자격증 */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>자격증</h5>
              <Button variant="primary" onClick={openLoadCertModal}>
                취득 자격증 불러오기
              </Button>
            </div>
            {/* 불러온 자격증 목록 */}
            {certList.length > 0 && (
              <Table>
                <colgroup>
                  <col width={140} />
                  <col width={120} />
                  <col width={130} />
                  <col width="*" />
                  <col width={170} />
                </colgroup>
                <thead>
                  <tr>
                    <th>취득일</th>
                    <th>구분</th>
                    <th>종류</th>
                    <th className="text-start">자격증</th>
                    <th>발급기관</th>
                  </tr>
                </thead>
                <tbody>
                  {certList.map((item, index) => {
                    return (
                      <tr key={`cert-${index}`}>
                        <td>
                          {item.certificate_date ? (
                            <Moment
                              date={item.certificate_date}
                              format="YYYY-MM-DD"
                              interval={0}
                            />
                          ) : (
                            ''
                          )}
                        </td>
                        <td>
                          {' '}
                          {item.certificate_gubun_han === '결과없음'
                            ? ''
                            : item.certificate_gubun_han}
                        </td>
                        <td>
                          {item.certificate_type_han === '결과없음'
                            ? ''
                            : item.certificate_type_han}
                        </td>
                        <td className="text-start">
                          {item.certificate_number && (
                            <small className="text-gray">
                              자격증 번호 : {item.certificate_number}
                            </small>
                          )}
                          <p className="table-ellipsis">
                            {item.certificate_name}
                          </p>
                        </td>
                        <td>{item.certificate_gov}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
            <CustomModal
              show={loadCertModalShow}
              onHide={closeLoadCertModal}
              title="취득 자격증"
              size="xl"
            >
              <Modal.Body className="border-0">
                <Table className="bt-none">
                  <colgroup>
                    <col width={60} />
                    <col width={140} />
                    <col width={120} />
                    <col width={130} />
                    <col width="*" />
                    <col width={170} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>
                        <Form.Check
                          type="checkbox"
                          id="check-all"
                          label=""
                          onChange={e => {
                            checkCertificateAllEvent(e.target.checked);
                          }}
                        />
                      </th>
                      <th>취득일</th>
                      <th>구분</th>
                      <th>종류</th>
                      <th className="text-start">자격증</th>
                      <th>발급기관</th>
                    </tr>
                  </thead>
                  <tbody>
                    {certOptionList.length > 0 ? (
                      certOptionList.map((item, index) => {
                        return (
                          <tr key={`voucher-${index}`}>
                            <td>
                              <Form.Check
                                type="checkbox"
                                id={`check-${item.seq}`}
                                label=""
                                onChange={e => {
                                  checkCertificateEvent(
                                    item.seq,
                                    e.target.checked,
                                  );
                                }}
                                checked={certCheckObj[item.seq] || false}
                              />
                            </td>
                            <td>
                              {item.certificate_date ? (
                                <Moment
                                  date={item.certificate_date}
                                  format="YYYY-MM-DD"
                                  interval={0}
                                />
                              ) : (
                                ''
                              )}
                            </td>
                            <td>
                              {item.certificate_gubun_han === '결과없음'
                                ? ''
                                : item.certificate_gubun_han}
                            </td>
                            <td>
                              {item.certificate_type_han === '결과없음'
                                ? ''
                                : item.certificate_type_han}
                            </td>
                            <td className="text-start">
                              {item.certificate_number && (
                                <small className="text-gray">
                                  자격증 번호 : {item.certificate_number}
                                </small>
                              )}
                              <p className="table-ellipsis">
                                {item.certificate_name}
                              </p>
                            </td>
                            <td>{item.certificate_gov}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <td colSpan="5" className="no-data">
                        취득한 자격증이 없습니다.
                      </td>
                    )}
                  </tbody>
                </Table>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="outline-primary" onClick={closeLoadCertModal}>
                  취소
                </Button>
                <Button
                  onClick={e => {
                    addCheckedCert();
                  }}
                >
                  선택
                </Button>
              </Modal.Footer>
            </CustomModal>
          </section>

          {/* 수상경력 */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>수상경력</h5>
            </div>
            <Card>
              {/* 수상경력_추가버튼 클릭시 */}
              {awardList.length > 0 &&
                awardList.map((item, index) => {
                  return (
                    <div key={index}>
                      {awardList.length > 1 && (
                        <div className="flex-end">
                          <Button
                            variant="outline-primary"
                            onClick={e => {
                              awardList.splice(index, 1);
                              forceRendering();
                            }}
                          >
                            삭제
                          </Button>
                        </div>
                      )}
                      <ul className="form-list border-bottom pb-4 mb-4">
                        <li>
                          <h5>수상일</h5>
                          <div className="mw-input">
                            <DatePicker
                              className="input-datepicker"
                              name="awards-date"
                              dateFormat="yyyy-MM-dd"
                              selected={item.award_date}
                              onChange={date => {
                                item.award_date = date;
                                forceRendering();
                              }}
                              customInput={
                                <Form.Control style={{ width: '100%' }} />
                              }
                              showPopperArrow={false}
                              // maxDate={new Date()}
                            />
                          </div>
                        </li>
                        <li>
                          <h5>수상명</h5>
                          <Form.Control
                            type="text"
                            placeholder="수상명 입력하세요."
                            onChange={e => {
                              item.award_name = e.target.value;
                              forceRendering();
                            }}
                            value={item.award_name || ''}
                            maxLength="50"
                          />
                          <h5 className="ms-4">등급(위)</h5>
                          <Form.Control
                            type="text"
                            placeholder="등급(위)를 입력하세요."
                            onChange={e => {
                              item.award_number = e.target.value;
                              forceRendering();
                            }}
                            value={item.award_number || ''}
                            maxLength="50"
                          />
                        </li>
                        <li>
                          <h5>수여기관</h5>
                          <Form.Control
                            type="text"
                            placeholder="수여기관을 입력하세요."
                            onChange={e => {
                              item.award_gov = e.target.value;
                              forceRendering();
                            }}
                            value={item.award_gov || ''}
                            maxLength="50"
                          />
                        </li>
                      </ul>
                    </div>
                  );
                })}
              <div className="flex-end">
                <Button
                  variant="primary"
                  onClick={e => {
                    setAwardList([
                      ...awardList,
                      {
                        award_date: new Date(),
                        award_name: null,
                        award_number: null,
                        award_gov: null,
                      },
                    ]);
                  }}
                >
                  추가
                </Button>
              </div>
            </Card>
          </section>
          {/* 경력 및 활동사항 */}
          <section>
            <div className="sub-title">
              <h5>경력 및 활동사항</h5>
            </div>
            <Card>
              {/* 경력 및 활동사항_추가버튼 클릭 시 */}
              {careerList.length > 0 &&
                careerList.map((item, index) => {
                  return (
                    <div key={index}>
                      {careerList.length > 1 && (
                        <div className="flex-end">
                          <Button
                            variant="outline-primary"
                            onClick={e => {
                              careerList.splice(index, 1);
                              forceRendering();
                            }}
                          >
                            삭제
                          </Button>
                        </div>
                      )}

                      <ul className="form-list border-bottom pb-4 mb-4">
                        <li>
                          <h5>구분</h5>
                          <div className="mw-input">
                            <CustomSelect
                              value={findProgramGubun(
                                item,
                                programGubunList,
                                item.program_gubun,
                              )}
                              onChange={selectedItem => {
                                item.program_gubun = selectedItem.value;
                                forceRendering();
                              }}
                              options={programGubunList}
                            />
                          </div>
                        </li>
                        <li>
                          <h5>활동명</h5>
                          <Form.Control
                            type="text"
                            placeholder="활동명을 입력하세요."
                            onChange={e => {
                              item.career_title = e.target.value;
                              forceRendering();
                            }}
                            value={item.career_title || ''}
                            maxLength="50"
                          />
                        </li>
                        <li>
                          <h5>활동기간</h5>
                          <div className="mw-input">
                            <DatePicker
                              className="input-datepicker"
                              name="activity-start-date"
                              dateFormat="yyyy-MM-dd"
                              selected={item.career_start_date}
                              onChange={date => {
                                item.career_start_date = date;
                                forceRendering();
                              }}
                              customInput={
                                <Form.Control style={{ width: '100%' }} />
                              }
                              showPopperArrow={false}
                              // maxDate={item.career_end_date}
                            />
                          </div>
                          <div className="tilde">~</div>
                          <div className="mw-input">
                            <DatePicker
                              className="input-datepicker"
                              name="activity-end-date"
                              dateFormat="yyyy-MM-dd"
                              selected={
                                item.career_end_date
                                  ? new Date(item.career_end_date)
                                  : new Date()
                              }
                              onChange={date => {
                                item.career_end_date = date;
                                forceRendering();
                              }}
                              customInput={
                                <Form.Control style={{ width: '100%' }} />
                              }
                              showPopperArrow={false}
                              // minDate={
                              //   item.career_start_date
                              //     ? new Date(item.career_start_date)
                              //     : new Date()
                              // }
                            />
                          </div>
                        </li>
                        <li>
                          <h5>
                            주요활동내용
                            <br />
                            (수행역할)
                          </h5>
                          <Form.Control
                            type="text"
                            placeholder="주요활동내용(수행역할)을 입력하세요."
                            onChange={e => {
                              item.career_contents = e.target.value;
                              forceRendering();
                            }}
                            value={item.career_contents || ''}
                            maxLength="150"
                          />
                        </li>
                      </ul>
                    </div>
                  );
                })}
              <div className="flex-end">
                <Button
                  variant="primary"
                  onClick={e => {
                    setCareerList([
                      ...careerList,
                      {
                        program_gubun: null,
                        career_start_date: new Date(),
                        career_end_date: new Date(),
                        career_title: null,
                        career_contents: null,
                      },
                    ]);
                  }}
                >
                  추가
                </Button>
              </div>
            </Card>
          </section>
          {/* 서명 */}
          <section className="signature-area">
            <h5>
              위와 같이 지원서를 제출하며 일체 허위 기재 사실이 없음을
              확인합니다.
            </h5>
            <p>
              <Moment format="YYYY년 MM월 DD일" />
            </p>
            <p>
              지원자 : <span>{Utils.decrypt(portfolioInfo.student_name)}</span>{' '}
              (서명)
            </p>
          </section>
          {/* 참여활동 내용 */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>참여활동 내용</h5>
            </div>
            <Card>
              {/* 성장과정 */}
              <div className="sub-title sm mt-2">
                <h5>성장과정</h5>
              </div>
              <Form.Control
                as="textarea"
                rows={5}
                placeholder="최소 1자이상 최대 1000자 이내로 입력하세요."
                onChange={e => {
                  setMyGrowthProceed(e.target.value);
                }}
                value={myGrowthProceed || ''}
                maxLength="1000"
              />
              <small className="text-gray flex-end mt-2">
                {(myGrowthProceed && myGrowthProceed.length) || 0}/1000자
              </small>
              {/* 성격 및 생활신조 */}
              <div className="sub-title sm border-top pt-4">
                <h5>성격 및 생활신조</h5>
              </div>

              <Form.Control
                as="textarea"
                rows={5}
                placeholder="최소 1자이상 최대 1000자 이내로 입력하세요."
                onChange={e => {
                  setLifeAndCredo(e.target.value);
                }}
                value={lifeAndCredo || ''}
                maxLength="1000"
              />
              <small className="text-gray flex-end mt-2">
                {(lifeAndCredo && lifeAndCredo.length) || 0}/1000자
              </small>
              {/* 학교 및 경력사항 */}
              <div className="sub-title sm border-top pt-4">
                <h5>학교 및 경력사항</h5>
              </div>

              <Form.Control
                as="textarea"
                rows={5}
                placeholder="최소 1자이상 최대 1000자 이내로 입력하세요."
                onChange={e => {
                  setMyCareer(e.target.value);
                }}
                value={myCareer || ''}
                maxLength="1000"
              />
              <small className="text-gray flex-end mt-2">
                {(myCareer && myCareer.length) || 0}/1000자
              </small>
              {/* 지원동기 및 입사 후 포부 */}
              <div className="sub-title sm border-top pt-4">
                <h5>지원동기 및 입사 후 포부</h5>
              </div>

              <Form.Control
                as="textarea"
                rows={5}
                placeholder="최소 1자이상 최대 1000자 이내로 입력하세요."
                onChange={e => {
                  setMotivationAndAspirations(e.target.value);
                }}
                value={motivationAndAspirations || ''}
                maxLength="1000"
              />

              <small className="text-gray flex-end mt-2">
                {(motivationAndAspirations &&
                  motivationAndAspirations.length) ||
                  0}
                /1000자
              </small>
            </Card>
          </section>

          {/* 교사 코멘트 */}
          {portfolioInfo.status === FormStatus.REJ.value && (
            <section className="mb-5">
              <div className="sub-title flex-start">
                <h5 className="text-primary">교사 코멘트</h5>
              </div>
              <div className="comment-box type-primary">
                <ul className="comment-list">
                  {teacherCommentList.length > 0 &&
                    teacherCommentList.map((item, index) => {
                      return (
                        <li key={`comment-${index}`}>
                          <p className="date">
                            {item.reg_date && (
                              <Moment
                                format="YYYY.MM.DD"
                                date={item.reg_date}
                              />
                            )}
                          </p>
                          <p className="text">{item.comment}</p>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </section>
          )}

          {/* ////////// ↓ 하단 버튼 //////////  */}
          {(grade === '2' || grade === '3') && (
            <div className="btn-area">
              <Button
                size="lg"
                variant="outline-primary"
                onClick={e => {
                  upsertPortfolio(e, FormStatus.ING.value);
                }}
                disabled={!inputConditionCheck()}
              >
                저장
              </Button>
            </div>
          )}
        </article>
      </Container>
    </main>
  );
});
