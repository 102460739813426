/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { Container, Form, Table, Button, Card } from 'react-bootstrap';

//* API
import { selectFreeProgram, updateFreeProgram } from '@api/teacher/programMgmt';
import Swal from 'sweetalert2';

//* [ Main ]
export default React.memo(function ProgramMgmt(props) {
  //* #################################################################
  //* [ States ]
  //* #################################################################
  // 자율교육 프로그램 목록
  const [programList, setProgramList] = useState([]);

  //* #################################################################
  //* [ Utils ]
  //* #################################################################
  // 자율 프로그램 이름 Input
  const programInputHandler = (data, index) => {
    programList[index].title = data;

    setProgramList([...programList]);
  };

  //* #################################################################
  //* [ API ] 자율 프로그램 정보 가져오기
  //* #################################################################
  const getFreeProgram = async () => {
    // Axios
    try {
      const { data } = await selectFreeProgram();

      if (data.code === 0) {
        const {
          free_program_max: programMax = 4,
          free_program_1: program1 = '',
          free_program_2: program2 = '',
          free_program_3: program3 = '',
          free_program_4: program4 = '',
          free_program_5: program5 = '',
          free_program_6: program6 = '',
        } = data.data;

        // 최대 등록 가능 프로그램 수
        const tempProgramList = [
          {
            gubun: 1,
            title: program1,
          },
          {
            gubun: 2,
            title: program2,
          },
          {
            gubun: 3,
            title: program3,
          },
          {
            gubun: 4,
            title: program4,
          },
          {
            gubun: 5,
            title: program5,
          },
          {
            gubun: 6,
            title: program6,
          },
        ];

        // 프로그램 목록 제한
        tempProgramList.length = programMax;

        // 프로그램 목록 갱신
        setProgramList(tempProgramList);
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 자율 프로그램 정보 업데이트
  //* #################################################################
  const postFreeProgram = async () => {
    // JSON 파라미터
    const param = {
      program1:
        programList[0] && programList[0].title ? programList[0].title : null,
      program2:
        programList[1] && programList[1].title ? programList[1].title : null,
      program3:
        programList[2] && programList[2].title ? programList[2].title : null,
      program4:
        programList[3] && programList[3].title ? programList[3].title : null,
      program5:
        programList[4] && programList[4].title ? programList[4].title : null,
      program6:
        programList[5] && programList[5].title ? programList[5].title : null,
    };

    // Axios
    try {
      const { data } = await updateFreeProgram(param);

      if (data.code === 0) {
        Swal.fire({
          text: '자율 프로그램명이 갱신되었습니다.',
          confirmButtonText: '확인',
        });

        getFreeProgram();
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ useEffect ]
  //* #################################################################
  // 프로그램 정보 가져오기
  useEffect(() => {
    getFreeProgram();

    return () => {};
  }, []);

  //* #################################################################
  //* [ return ]
  //* #################################################################
  return (
    <main id="teacher-programMgmt" className="type-02">
      <Container>
        <article className="content">
          {/* ////////// ↓ 상단 타이틀영역 ////////// */}
          <section>
            <div className="title">
              <h5>프로그램 관리</h5>
            </div>
          </section>

          {/* ////////// ↓ 컨텐츠 ////////// */}
          <Card>
            <Card.Header>
              <div className="flex-end">
                <Button variant="primary" onClick={postFreeProgram}>
                  저장
                </Button>
              </div>
            </Card.Header>
            <Card.Body className="pt-0">
              <Table className="bt-none tr-even-bg">
                <colgroup>
                  <col width="*" />
                  <col width="*" />
                </colgroup>
                <thead>
                  <tr>
                    <th>프로그램 번호</th>
                    <th>자율 프로그램 이름</th>
                  </tr>
                </thead>
                <tbody>
                  {programList.length > 0 &&
                    programList.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{`자율교육 ${item.gubun}`}</td>
                          <td>
                            <Form.Control
                              type="text"
                              placeholder="자율 프로그램 이름을 입력하세요."
                              value={item.title || ''}
                              onChange={e =>
                                programInputHandler(e.target.value, index)
                              }
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </article>
      </Container>
    </main>
  );
});
