/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import Moment from 'react-moment';
import { Container, Button, Row, Col, Form } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';
import Swal from 'sweetalert2';

//* CONSTS
import {
  FormStatus,
  PROGRAM_ID,
  RESEARCH_POINT_TYPE_LIST,
  FILE_FORM_TYPE,
} from '@common/consts';

//* Utils
import { Utils } from '@common';

//* API
import {
  selectFormMajorJobEduInfo,
  upsertFormMajorJobEdu,
} from '@api/student/majorJobEdu';
import { getFilePathFromRedis } from '@api/file';

// FORM 정보
const initialFormInfo = {
  id: '',
  grade: '',
  status: 'NONE',
  point: '0',
  student_id: '',
  student_name: '',
  program_id: '',
  edu_start_date: new Date(),
  edu_end_date: new Date(),
  edu_time: '',
  edu_title: '',
  edu_contents: '',
  edu_review: '',
  files: [],
};

//* [ Main ]
export default React.memo(function MajorJobEduReport(props) {
  //* #################################################################
  //* [ States ]
  //* #################################################################
  const history = useHistory();

  const { type: viewType, pageType, seq } = useParams();

  // type
  const viewTypes = ['student', 'teacher', 'eduAdm'];

  const pageTypes = ['basic', 'advanced', 'applied'];

  // 제대로 된 페이지로 들어왔는지 확인
  if (!viewTypes.includes(viewType)) {
    history.replace('/home');
    return false;
  }
  if (!pageTypes.includes(pageType)) {
    history.replace('/home');
    return false;
  }
  if (seq && !window.parseInt(seq)) {
    history.replace('/home');
  }

  // 페이지별 학년 구분
  const getPageGrade = type => {
    switch (type) {
      case 'basic':
        return 1;
      case 'advanced':
        return 2;
      case 'applied':
        return 3;
      default:
        return null;
    }
  };

  /** form state */

  // 보고서 정보
  const [reportInfo, setReportInfo] = useState(initialFormInfo);
  const [teacherCommentList, setTeacherCommentList] = useState([]);

  // 리서치 정보
  const [researchList, setResearchList] = useState([]);

  // radio :: 리서치 조사 결과 값
  const [researchRadioResult, setResearchRadioResult] = useState({});

  // 프로그램 ID
  const programId = PROGRAM_ID[`PROGRAM_ID_FME_${getPageGrade(pageType)}`];

  //* #################################################################
  //* [ Utils ]
  //* #################################################################
  /** print function */
  const printWindowRef = useRef();
  const handlePrint = useReactToPrint({
    onBeforeGetContent: () => {
      printWindowRef.current.classList.add('print');
    },
    onAfterPrint: () => {
      printWindowRef.current.classList.remove('print');
    },
    onPrintError: () => {
      printWindowRef.current.classList.remove('print');
    },
    content: () => {
      return printWindowRef.current;
    },
  });

  // 승인 요청 가능 여부 체크
  const inputConditionCheck = item => {
    // 산업안전보건교육 확인
    if (item.indv_yn === 'N') {
      if (!item.edu_title) {
        Swal.fire({
          text: '전공 교육명이 존재하지 않습니다.',
          confirmButtonText: '확인',
        });
        return false;
      }
      // 교육시간 여부
      if (!item.edu_time || item.edu_time <= 0) {
        Swal.fire({
          text: '교육시간을 확인해주세요.',
          confirmButtonText: '확인',
        });
        return false;
      }
      // 입력 제한 1 ~ 1000
      if (!Utils.limitCheck(item.edu_contents, 1, 1000)) {
        Swal.fire({
          text: '교육 내용 요약 및 정리는 최소 1자 이상 최대 1000자여야 합니다.',
          confirmButtonText: '확인',
        });
        return false;
      }
      if (!Utils.limitCheck(item.edu_review, 1, 1000)) {
        Swal.fire({
          text: '느낀점은 최소 1자 이상 최대 1000자여야 합니다.',
          confirmButtonText: '확인',
        });
        return false;
      }
    }
    // 범위 제한 1 ~ 5
    // 리서치 조사를 했는지 체크
    const researchKeys = Object.keys(researchRadioResult);
    if (researchKeys.length !== researchList.length) {
      Swal.fire({
        text: '응답되지 않은 만족도 조사가 존재합니다.',
        confirmButtonText: '확인',
      });
      return false;
    }
    if (researchKeys.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const key of researchKeys) {
        if (
          researchRadioResult[key] &&
          !Utils.rangeCheck(researchRadioResult[key], 1, 5)
        ) {
          Swal.fire({
            text: '만족도 조사에 응답된 값이 잘못되었습니다.',
            confirmButtonText: '확인',
          });
          return false;
        }
      }
    }
    return true;
  };

  //* #################################################################
  //* [ API ] 파일 조회
  //* #################################################################
  const openFile = async fileSeq => {
    // API 파라미터
    const paramMap = {
      formType: FILE_FORM_TYPE.FILE_WORK, // = WORK
      fileSeq,
    };

    // Axios
    try {
      const { data } = await getFilePathFromRedis(paramMap);

      if (data.code === 0) {
        const redisKey = data.data;
        const hostName = window.location.hostname;

        const url = Utils.getFileOpenUrl(hostName, redisKey);

        window.open(url);
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 취업역량강화 보고서 조회
  //* #################################################################
  const selectWorkInfo = async () => {
    try {
      const params = {
        formSeq: seq,
        program_id: programId,
      };
      const { data } = await selectFormMajorJobEduInfo(params);
      if (data.code === 0) {
        // 학생 정보, 보고서 정보
        const { info } = data.data;
        setReportInfo(info);

        // 교사 코멘트 리스트
        setTeacherCommentList(data.data.teacherCommentList);

        // 리서치 목록 구성
        setResearchList(data.data.researchList);

        // 리서치 값 세팅
        // eslint-disable-next-line camelcase
        const researchIdx = info.research_idx;
        const researchResponse = info.research_response;
        // eslint-disable-next-line camelcase
        if (researchIdx && researchResponse) {
          const idxs = researchIdx.split(';');
          const responses = researchResponse.split(';');
          const researchObject = {};
          // eslint-disable-next-line guard-for-in,no-restricted-syntax
          for (const i in idxs) {
            researchObject[`${idxs[i]}`] = responses[i];
          }
          setResearchRadioResult(researchObject);
        }
      }
    } catch (e) {
      // alert(e.response.data.message);
    }
  };

  //* #################################################################
  //* [ API ] 취업역량 강화 보고서 수정/삭제
  //* #################################################################
  const updateRequest = async (event, reqStatus) => {
    const target = event.currentTarget;
    target.disabled = true;

    // 승인요청 > 입력 검증
    if (reqStatus === 'REQ' && !reportInfo.indv_yn === 'Y') {
      // if (
      //   !Utils.checkDateTime(new Date(reportInfo.edu_start_date)) ||
      //   !Utils.checkDateTime(new Date(reportInfo.edu_end_date))
      // ) {
      //   Swal.fire({
      //     text: '교육일이 입력되지 않았습니다.',
      //     confirmButtonText: '확인',
      //   });
      //   target.disabled = false;
      //   return;
      // }

      if (!reportInfo.edu_time) {
        Swal.fire({
          text: '교육시간이 입력되지 않았습니다.',
          confirmButtonText: '확인',
        });
        target.disabled = false;
        return;
      }

      if (!reportInfo.edu_title) {
        Swal.fire({
          text: '교육명이 입력되지 않았습니다.',
          confirmButtonText: '확인',
        });
        target.disabled = false;
        return;
      }

      if (!reportInfo.edu_contents) {
        Swal.fire({
          text: '교육 내용이 입력되지 않았습니다.',
          confirmButtonText: '확인',
        });
        target.disabled = false;
        return;
      }

      if (!reportInfo.edu_review) {
        Swal.fire({
          text: '느낀점이 입력되지 않았습니다.',
          confirmButtonText: '확인',
        });
        target.disabled = false;
        return;
      }

      if (!researchRadioResult || !Object.keys(researchRadioResult).length) {
        Swal.fire({
          text: '설문이 입력되지 않았습니다.',
          confirmButtonText: '확인',
        });
        target.disabled = false;
        return;
      }

      // if (!reportInfo.files || reportInfo.files.length < 1) {
      //   Swal.fire({
      //     text: '첨부파일이 등록되지 않았습니다.',
      //     confirmButtonText: '확인',
      //   });
      //   target.disabled = false;
      //   return;
      // }
    }

    if (
      reqStatus === FormStatus.DEL.value &&
      // eslint-disable-next-line no-restricted-globals
      !(await Swal.fire({
        text: '삭제하시겠습니까?',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        showCancelButton: true,
        reverseButtons: true,
      }).then(result => !!result.isConfirmed))
    ) {
      target.disabled = false;
      return;
    }

    if (
      reqStatus === FormStatus.ING.value &&
      // eslint-disable-next-line no-restricted-globals
      !(await Swal.fire({
        text: '승인요청을 취소하시겠습니까?',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        showCancelButton: true,
        reverseButtons: true,
      }).then(result => !!result.isConfirmed))
    ) {
      target.disabled = false;
      return;
    }

    if (
      reqStatus === FormStatus.REQ.value &&
      // eslint-disable-next-line no-restricted-globals
      !(await Swal.fire({
        text: '승인요청하시겠습니까?',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        showCancelButton: true,
        reverseButtons: true,
      }).then(result => !!result.isConfirmed))
    ) {
      target.disabled = false;
      return;
    }

    // FormData
    const formData = new FormData();

    // JSON 파라미터
    let params = {
      formSeq: seq,
      status: reqStatus,
      program_id: reportInfo.program_id,
    };
    if (reqStatus === FormStatus.REQ.value) {
      if (!inputConditionCheck(reportInfo)) {
        target.disabled = false;
        return;
      }
      params = { ...reportInfo, ...params };
    }

    // JSON append
    formData.append(
      'param',
      new Blob([JSON.stringify(params)], { type: 'application/json' }),
    );

    try {
      const { data } = await upsertFormMajorJobEdu(formData);
      if (data.code === 0) {
        history.push(`/student/majorJobEdu/${pageType}`);
      }
    } catch (e) {
      // alert(e.response.data.message);
      target.disabled = false;
    }
    target.disabled = false;
  };

  //* #################################################################
  //* [ useEffect ]
  //* #################################################################
  useEffect(() => {
    selectWorkInfo();
    return () => {
      setReportInfo({});
      setTeacherCommentList([]);
      setResearchList([]);
    };
  }, []);

  //* #################################################################
  //* [ useEffect ]
  //* #################################################################
  return (
    <main id="program-majorJobEdu-report">
      <Container>
        <article className="content" ref={printWindowRef}>
          {viewType === 'student' && (
            <ul className="path">
              <li>
                <Link to="/">HOME</Link>
              </li>
              <li>
                <Link to={`/student/majorJobEdu/${pageType}`}>
                  직무
                  {pageType === 'basic' && '기초'}
                  {pageType === 'advanced' && '심화'}
                  {pageType === 'applied' && '응용'}
                  교육
                </Link>
              </li>
              <li>
                <Link to="#none">결과 보고서</Link>
              </li>
            </ul>
          )}

          {/* ////////// ↓ 상단 타이틀영역 ////////// */}
          <section>
            <div className="title">
              <h5>
                직무
                {pageType === 'basic' && '기초'}
                {pageType === 'advanced' && '심화'}
                {pageType === 'applied' && '응용'}교육 결과 보고서
              </h5>
              <div className="dl-box type-bar lg">
                <dl>
                  <strong
                    className={
                      FormStatus[reportInfo.status] &&
                      FormStatus[reportInfo.status].class
                    }
                  >
                    {FormStatus[reportInfo.status] &&
                      FormStatus[reportInfo.status].text}
                  </strong>
                </dl>
                {/* <dl>
                  <dd>
                    <strong>{reportInfo.point}</strong>
                  </dd>
                </dl> */}
              </div>
            </div>
          </section>

          {/* ////////// ↓ 컨텐츠 ////////// */}
          {/* 기본정보 */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>기본정보</h5>
            </div>
            <div className="split-table">
              <Row className="table-row">
                <Col className="th col-2">학번</Col>
                <Col className="td col-10">{reportInfo.student_id}</Col>
              </Row>
              <Row className="table-row">
                <Col className="th col-2">이름</Col>
                <Col className="td col-10">
                  {Utils.decrypt(reportInfo.student_name)}
                </Col>
              </Row>
            </div>
          </section>

          {/* 교육정보 */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>교육정보</h5>
            </div>
            <div className="split-table">
              {/* 산업안전보건교육 */}
              <Row className="table-row">
                <Col className="th col-2">산업안전보건교육</Col>
                <Col className="td col-10">
                  {reportInfo.indv_yn === 'Y' ? 'O' : 'X'}
                </Col>
              </Row>

              {/* 교육일 */}
              {reportInfo.indv_yn === 'N' && (
                <Row className="table-row">
                  <Col className="th col-2">교육일</Col>
                  <Col className="td col-10">
                    {reportInfo.edu_start_date && (
                      <Moment
                        format="YYYY-MM-DD"
                        date={reportInfo.edu_start_date}
                      />
                    )}
                    &nbsp; ~ &nbsp;
                    {reportInfo.edu_end_date && (
                      <Moment
                        format="YYYY-MM-DD"
                        date={reportInfo.edu_end_date}
                      />
                    )}
                  </Col>
                </Row>
              )}

              {/* 교육시간 */}
              {reportInfo.indv_yn === 'N' && (
                <Row className="table-row">
                  <Col className="th col-2">교육시간</Col>
                  <Col className="td col-10">
                    {reportInfo.edu_time || '0'} 시간
                  </Col>
                </Row>
              )}
            </div>
          </section>

          {/* 전공 교육명 */}
          {reportInfo.indv_yn === 'N' && (
            <section className="mb-5">
              <div className="sub-title flex-start">
                <h5>전공 교육명</h5>
              </div>
              <div className="comment-box">
                <ul className="comment-list">
                  <li>
                    <p className="text">{reportInfo.edu_title}</p>
                  </li>
                </ul>
              </div>
            </section>
          )}

          {/* 교육 내용 요약 및 정리 */}
          {reportInfo.indv_yn === 'N' && (
            <section className="mb-5">
              <div className="sub-title flex-start">
                <h5>교육 내용 요약 및 정리</h5>
              </div>
              <div className="comment-box">
                <ul className="comment-list">
                  <li>
                    <p className="text">{reportInfo.edu_contents}</p>
                  </li>
                </ul>
              </div>
            </section>
          )}

          {/* 느낀점 */}
          {reportInfo.indv_yn === 'N' && (
            <section className="mb-5">
              <div className="sub-title flex-start">
                <h5>느낀점</h5>
              </div>
              <div className="comment-box">
                <ul className="comment-list">
                  <li>
                    <p className="text">{reportInfo.edu_review}</p>
                  </li>
                </ul>
              </div>
            </section>
          )}

          {/* 만족도 조사 */}
          <section className="mb-5">
            <div className="sub-title">
              <h5>만족도 조사</h5>
            </div>
            <div className="split-table h-56">
              <Row className="table-row">
                <Col className="th col-2 text-gray">조사내용</Col>
                <Col className="th col-4 text-center text-gray">질문</Col>
                <Col className="th col-6">
                  <ul className="type-flex-1">
                    <li>매우 그렇다</li>
                    <li>그렇다</li>
                    <li>보통</li>
                    <li>그렇지 않다</li>
                    <li>매우 그렇지 않다</li>
                  </ul>
                </Col>
              </Row>
              {researchList &&
                researchList.length > 0 &&
                researchList.map((item, index) => {
                  return (
                    <Row className="table-row" key={`research-${index}`}>
                      <Col className="th col-2 text-gray">
                        {item.research_contents}
                      </Col>
                      <Col className="td col-4">{item.research_question}</Col>
                      <Col className="td col-6 flex-between">
                        <div className="radio-group type-flex-1">
                          {RESEARCH_POINT_TYPE_LIST.map(pointItem => {
                            return (
                              <Form.Check
                                key={`${index}-${pointItem.value}`}
                                name={`${index}`}
                                type="radio"
                                label=""
                                value={pointItem.value}
                                id={pointItem.value}
                                onChange={e => {
                                  setResearchRadioResult({
                                    ...researchRadioResult,
                                    [item.seq]: e.target.value,
                                  });
                                }}
                                checked={
                                  researchRadioResult[item.seq] ===
                                  pointItem.value
                                }
                                disabled
                              />
                            );
                          })}
                        </div>
                      </Col>
                    </Row>
                  );
                })}
            </div>
          </section>

          {/* 파일 첨부 */}
          <section className="mb-5">
            <div className="sub-title flex-start">
              <h5>파일 첨부</h5>
            </div>
            <ul className="attachment-list">
              {reportInfo && reportInfo.files.length > 0 ? (
                reportInfo.files.map((file, idx) => {
                  return (
                    <li key={idx}>
                      <Button
                        className="btn-icon ic-down"
                        onClick={() => openFile(file.file_seq)}
                      >
                        {file.name || file.file_name_org}
                      </Button>
                    </li>
                  );
                })
              ) : (
                <ul className="attachment-list">
                  <li>없음</li>
                </ul>
              )}
            </ul>
          </section>

          {/* 교사 코멘트 */}
          {(reportInfo.status === FormStatus.REJ.value ||
            reportInfo.status === FormStatus.CON.value) && (
            <section className="mb-5">
              <div className="sub-title flex-start">
                <h5 className="text-primary">교사 코멘트</h5>
              </div>
              <div className="comment-box type-primary">
                <ul className="comment-list">
                  {teacherCommentList.length > 0 &&
                    teacherCommentList.map((item, index) => {
                      return (
                        <li key={`comment-${index}`}>
                          <p className="date">
                            {item.reg_date && (
                              <Moment
                                format="YYYY.MM.DD"
                                date={item.reg_date}
                              />
                            )}
                          </p>
                          <p className="text">{item.comment}</p>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </section>
          )}

          {/* ////////// ↓ 하단 버튼 //////////  */}
          {viewType === 'student' &&
            reportInfo.status === FormStatus.CON.value && (
              <div className="btn-area">
                <Button
                  size="lg"
                  variant="outline-primary"
                  onClick={e => handlePrint(e)}
                >
                  인쇄
                </Button>
                <Button
                  onClick={() => {
                    history.push(`/student/majorJobEdu/${pageType}`);
                  }}
                  variant="primary"
                  size="lg"
                >
                  목록으로
                </Button>
              </div>
            )}
          {viewType === 'student' &&
            reportInfo.status === FormStatus.REQ.value && (
              <div className="btn-area">
                <Button
                  size="lg"
                  variant="outline-primary"
                  onClick={e => handlePrint(e)}
                >
                  인쇄
                </Button>
                <Button
                  size="lg"
                  variant="primary"
                  onClick={e => {
                    updateRequest(e, FormStatus.ING.value);
                  }}
                >
                  승인요청 취소
                </Button>
                <Button
                  onClick={() => {
                    history.push(`/student/majorJobEdu/${pageType}`);
                  }}
                  variant="primary"
                  size="lg"
                >
                  목록으로
                </Button>
              </div>
            )}
          {viewType === 'student' &&
            reportInfo.status === FormStatus.ING.value && (
              <div className="btn-area">
                <Button
                  size="lg"
                  variant="outline-primary"
                  onClick={e => handlePrint(e)}
                >
                  인쇄
                </Button>
                <Button
                  size="lg"
                  variant="outline-primary"
                  onClick={e => {
                    updateRequest(e, FormStatus.DEL.value);
                  }}
                >
                  삭제
                </Button>
                <Button
                  size="lg"
                  variant="primary"
                  onClick={e => {
                    history.push({
                      pathname: `/student/majorJobEdu/write/${pageType}/${seq}`,
                    });
                  }}
                >
                  수정
                </Button>
                <Button
                  size="lg"
                  variant="primary"
                  onClick={e => {
                    updateRequest(e, FormStatus.REQ.value);
                  }}
                >
                  승인요청
                </Button>
                <Button
                  onClick={() => {
                    history.push(`/student/majorJobEdu/${pageType}`);
                  }}
                  variant="primary"
                  size="lg"
                >
                  목록으로
                </Button>
              </div>
            )}
          {viewType === 'student' &&
            reportInfo.status === FormStatus.REJ.value && (
              <div className="btn-area">
                <Button
                  size="lg"
                  variant="outline-primary"
                  onClick={e => handlePrint(e)}
                >
                  인쇄
                </Button>
                <Button
                  size="lg"
                  variant="primary"
                  onClick={e => {
                    history.push({
                      pathname: `/student/majorJobEdu/write/${pageType}/${seq}`,
                    });
                  }}
                >
                  수정
                </Button>
                <Button
                  onClick={() => {
                    history.push(`/student/majorJobEdu/${pageType}`);
                  }}
                  variant="primary"
                  size="lg"
                >
                  목록으로
                </Button>
              </div>
            )}
          {viewType !== 'student' && (
            <div className="btn-area">
              <Button
                onClick={() => {
                  history.goBack();
                }}
                variant="primary"
                size="lg"
              >
                목록으로
              </Button>
            </div>
          )}
        </article>
      </Container>
    </main>
  );
});
