import React, { useEffect, useState } from 'react';
import { Container, Form, Row, Col, Button, Card } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Utils } from '@common';
import { getStudentDetail } from '../../../../api/eduadmin/student';

export default React.memo(function AdmEduStudentDetail(props) {
  const history = useHistory();

  const { seq } = useParams();

  const [studentData, setStudentData] = useState();

  const getStudentData = async () => {
    const { data } = await getStudentDetail(seq);
    if (data.code === 0) {
      setStudentData(data.data);
    }
  };

  useEffect(() => {
    if (!window.parseInt(seq)) {
      history.replace('/admEdu/student/status');
    } else {
      getStudentData();
    }
  }, []);

  return (
    <main id="admEdu-student-detail" className="type-02">
      <Container>
        <article className="content py">
          <Card>
            {studentData && (
              <Card.Body>
                <section className="mb-5">
                  <div className="sub-title">
                    <h5>학교정보</h5>
                  </div>
                  <div className="split-table type-simple">
                    <Row className="table-row">
                      <Col className="th col-2">학교명</Col>
                      <Col className="td col-10">{studentData.schoolName}</Col>
                    </Row>
                    <Row className="table-row">
                      <Col className="th col-2">학적</Col>
                      <Col className="td col-10">{studentData.status}</Col>
                    </Row>
                    <Row className="table-row">
                      <Col className="th col-2">이름</Col>
                      <Col className="td col-10">
                        {Utils.decrypt(studentData.studentName)}
                      </Col>
                    </Row>
                    <Row className="table-row">
                      <Col className="th col-2">성별</Col>
                      <Col className="td col-10">{studentData.sex}</Col>
                    </Row>
                    <Row className="table-row">
                      <Col className="th col-2">학년/반/번호</Col>
                      <Col className="td col-10">{`${studentData.grade}학년 ${studentData.banNo}반 ${studentData.studentNo}번`}</Col>
                    </Row>
                  </div>
                </section>
                <section className="mb-5">
                  <div className="sub-title">
                    <h5>인적사항</h5>
                  </div>
                  <div className="split-table type-simple">
                    <Row className="table-row">
                      <Col className="th col-2">주소</Col>
                      <Col className="td col-10">
                        {Utils.decrypt(studentData.addr1) || '-'}
                      </Col>
                    </Row>
                    <Row className="table-row">
                      <Col className="th col-2">상세주소</Col>
                      <Col className="td col-10">
                        {Utils.decrypt(studentData.addr2) || '-'}
                      </Col>
                    </Row>
                    <Row className="table-row">
                      <Col className="th col-2">생일</Col>
                      <Col className="td col-10">
                        {Utils.dateDotFormat(
                          Utils.decrypt(studentData.studentBirth),
                        ) || '-'}
                      </Col>
                    </Row>
                    <Row className="table-row">
                      <Col className="th col-2">연락처</Col>
                      <Col className="td col-10">
                        {Utils.phoneNumberHypenFormat(
                          Utils.decrypt(studentData.phoneNo),
                        ) || '-'}
                      </Col>
                    </Row>
                    <Row className="table-row">
                      <Col className="th col-2">이메일</Col>
                      <Col className="td col-10">
                        {Utils.decrypt(studentData.email) || '-'}
                      </Col>
                    </Row>
                  </div>
                </section>
                <section className="btn-area mb-0">
                  <Button
                    size="lg"
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    목록
                  </Button>
                </section>
              </Card.Body>
            )}
          </Card>
        </article>
      </Container>
    </main>
  );
});
