/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import { Container, Button, Row, Col, Table, Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import Moment from 'react-moment';
import classNames from 'classnames';
import { getNoticeDetail } from '../../../api/board/notice';
import { publicDownload } from '../../../api/file';

export default React.memo(function NoticeDetail(props) {
  const history = useHistory();

  const { seq } = useParams();
  const [thisPost, setThisPost] = useState({});

  const getNotice = async () => {
    const { data } = await getNoticeDetail(seq);
    if (data.code === 0) {
      setThisPost(data.data);
    }
  };

  const openFile = async (fileSeq, fileName) => {
    const { data } = await publicDownload(fileSeq);
    const blob = new Blob([data]);
    const fileUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName;
    link.style.display = 'none';
    link.click();
    link.remove();
  };

  useEffect(() => {
    if (!window.parseInt(seq)) {
      Swal.fire({
        text: '잘못된 접근입니다.',
        confirmButtonText: '확인',
      });
      history.goBack();
    } else {
      getNotice();
    }
  }, []);

  return (
    <main id="service-notice-detail">
      <Container>
        <article className="content">
          <ul className="path">
            <li>
              <Link to="/">HOME</Link>
            </li>
            <li>
              <Link to="/student/service/notice">공지사항</Link>
            </li>
          </ul>

          {/* ////////// ↓ 컨텐츠 ////////// */}
          <section className="post-box-wrap">
            <div className="title">
              <h5>{thisPost.title}</h5>
            </div>
            <div className="post-box">
              <div className="dl-box type-bar">
                <dl>
                  <dt>작성자</dt>
                  <dd>{thisPost.writer}</dd>
                </dl>
                <dl>
                  <dt>작성일</dt>
                  <dd>
                    <Moment date={thisPost.regDate} format="YYYY-MM-DD" />
                  </dd>
                </dl>
                <dl>
                  <dt>조회수</dt>
                  <dd>{thisPost.viewCnt}</dd>
                </dl>
              </div>
              <div className="dl-box type-bar by-1">
                <dl>
                  <dt>첨부파일</dt>
                  {thisPost.files &&
                    thisPost.files.length > 0 &&
                    thisPost.files.map((v, i) => {
                      let comma = '';
                      if (i !== thisPost.files.length - 1) {
                        comma = ' , ';
                      }
                      return (
                        <dd
                          onClick={() => {
                            openFile(v.seq, v.fileName);
                          }}
                        >
                          <p
                            className={classNames('ellipsis', {
                              attatchment: true,
                            })}
                          >
                            {v.fileName}
                            {comma}
                          </p>
                        </dd>
                      );
                    })}
                </dl>
              </div>
              <div className="text-area">
                <div
                  className="post-content"
                  dangerouslySetInnerHTML={{ __html: thisPost.content }}
                />
              </div>
            </div>
          </section>

          {/* ////////// ↓ 하단 버튼 //////////  */}
          <div className="btn-area">
            <Button
              onClick={() => {
                history.goBack();
              }}
              variant="outline-primary"
              size="lg"
            >
              목록
            </Button>
          </div>
        </article>
      </Container>
    </main>
  );
});
