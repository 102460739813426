/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import { Container, Form, Row, Col, Button, Card } from 'react-bootstrap';

export default React.memo(function TeacherMypageEdit(props) {
  const history = useHistory();

  const { type: typeParam } = useParams();

  // type
  const [viewType, setViewType] = useState(typeParam);
  const [type, setType] = useState('edit'); // viewType (write, edit)
  const [teacherType, setTeacherType] = useState('basic'); // basic/director

  // checkbox
  const [unfreezeCheck, setUnfreezeCheck] = useState(false);
  const handleUnfreezeCheck = () => {
    setUnfreezeCheck(!unfreezeCheck);
  };

  return (
    <main id="teacher-mypage-edit" className="type-02">
      <Container>
        <article className="content">
          <ul className="path">
            <li>
              <Link to="/">HOME</Link>
            </li>
            <li>
              <Link to="#none">마이페이지</Link>
            </li>
            <li>
              <Link to="#none">마이페이지 수정</Link>
            </li>
          </ul>

          {/* ////////// ↓ 상단 타이틀영역 ////////// */}
          <section>
            <div className="title">
              <h5>마이페이지 수정</h5>
            </div>
          </section>

          {/* ////////// ↓ 컨텐츠 ////////// */}
          {/* 학교정보 */}
          <Card>
            <Card.Body>
              <section className="mb-5">
                <div className="sub-title">
                  <h5>학교정보</h5>
                </div>
                <div className="split-table type-simple">
                  <Row className="table-row">
                    <Col className="th col-2">학교</Col>
                    <Col className="td col-10">보건고등학교</Col>
                  </Row>
                  <Row className="table-row">
                    <Col className="th col-2">담당</Col>
                    <Col className="td col-10">1학년 2반</Col>
                  </Row>
                  {teacherType === 'director' && (
                    <Row className="table-row">
                      <Col className="th col-2">권한</Col>
                      <Col className="td col-10">학교 관리자</Col>
                    </Row>
                  )}
                </div>
              </section>

              {/* 기본정보 */}
              <section className="mb-5">
                <div className="sub-title">
                  <h5>기본정보</h5>
                </div>
                <div className="split-table type-simple">
                  <Row className="table-row">
                    <Col className="th col-2">이름</Col>
                    <Col className="td col-10">이평한</Col>
                  </Row>
                  <Row className="table-row">
                    <Col className="th col-2">아이디</Col>
                    <Col className="td col-10">ET51201</Col>
                  </Row>
                </div>
              </section>

              {/* 고정 IP 확인 */}
              <section className="mb-5">
                <div className="sub-title">
                  <h5>고정 IP 확인</h5>
                </div>
                <div className="split-table type-simple">
                  <Row className="table-row">
                    <Col className="th col-2">고정 IP 주소</Col>
                    <Col className="td col-10">
                      <div className="w-100 flex-between">
                        <p>159.83.174.08</p>
                        <Form.Check
                          type="checkbox"
                          label="고정해제"
                          id="student-saveId-checked"
                          name="student-saveId-checked"
                          checked={unfreezeCheck}
                          onChange={handleUnfreezeCheck}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </section>

              {/* 비밀번호 */}
              <section>
                <div className="sub-title">
                  <h5>비밀번호</h5>
                </div>
                <div className="split-table type-simple">
                  <Row className="table-row">
                    <Col className="th col-2">현재 비밀번호</Col>
                    <Col className="td col-10">
                      {viewType === 'write' ? (
                        <Form.Control
                          type="password"
                          placeholder="현재 비밀번호를 입력하세요."
                        />
                      ) : (
                        <Form.Control
                          type="password"
                          defaultValue="1235132135"
                        />
                      )}
                    </Col>
                  </Row>
                  <Row className="table-row">
                    <Col className="th col-2">새 비밀번호</Col>
                    <Col className="td col-10">
                      {viewType === 'write' ? (
                        <Form.Control
                          type="password"
                          placeholder="새 비밀번호를 입력하세요."
                        />
                      ) : (
                        <Form.Control
                          type="password"
                          defaultValue="1235132135"
                        />
                      )}
                    </Col>
                  </Row>
                  <Row className="table-row">
                    <Col className="th col-2">비밀번호 확인</Col>
                    <Col className="td col-10">
                      {viewType === 'write' ? (
                        <Form.Control
                          type="password"
                          placeholder="새 비밀번호를 다시 입력하여 비밀번호를 확인해주세요."
                        />
                      ) : (
                        <Form.Control
                          type="password"
                          defaultValue="1235132135"
                        />
                      )}
                    </Col>
                  </Row>
                  <Row className="table-row">
                    <Col className="th col-2">&nbsp;</Col>
                    <Col className="td col-10">
                      <Button variant="primary">비밀번호 변경</Button>
                    </Col>
                  </Row>
                </div>
              </section>
            </Card.Body>
          </Card>
        </article>
      </Container>
    </main>
  );
});
