import Axios from '@api/axios';
import { da } from 'date-fns/locale';

const API_NOTICE = 'api/v1/board/notice';

const formDataConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

// [게시물 관리 > 공지사항] 공지사항 리스트 조회
export const getNoticeList = data => {
  return Axios.get(`${API_NOTICE}/list`, { params: data });
};

// [게시물 관리 > 공지사항] 공지사항 상세 조회
export const getNoticeDetail = seq => {
  return Axios.get(`${API_NOTICE}/${seq}`);
};

// [게시물 관리 > 공지사항] 공지사항 등록
export const putNotice = data => {
  return Axios.put(`${API_NOTICE}`, data, formDataConfig);
};
// [게시물 관리 > 공지사항] 공지사항 수정
export const patchNotice = data => {
  return Axios.patch(`${API_NOTICE}`, data, formDataConfig);
};

// [게시물 관리 > 공지사항] 공지사항 삭제
export const deleteNotice = seq => {
  return Axios.delete(`${API_NOTICE}/${seq}`);
};

// [게시물 관리 > 공지사항] 공지사항 메인 등록/등록취소
export const patchNoticeMainYn = data => {
  return Axios.patch(`${API_NOTICE}/update-main`, data);
};
